import { StyleSheet } from "react-native";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
});
