import {
  CourseInterface,
  CpxProfileInterface,
  INTERVAL_TYPE,
  TrainingPlanInterface,
  WorkoutIntervalInterface,
  WorkoutSetInterface,
} from "../redux/types";
import { MAIN_THEME } from "../themes";
import { DefaultWorkoutSvgType, DrawingType } from "./types";
import { NativeScrollEvent } from "react-native";

interface GetWorkoutImgSegmentColorProps {
  step: WorkoutIntervalInterface;
  styles: DefaultWorkoutSvgType;
  isCourseStep?: boolean;
}
interface GetDoteDrawingValueProps {
  interval: WorkoutIntervalInterface;
  drawingObject?: DrawingType;
}

interface getCourseProps {
  plan: TrainingPlanInterface | null;
  currentStep: WorkoutIntervalInterface | null;
}

export function toRank(rank: any) {
  let result = {
    icon: "",
    color: "#000",
  };
  switch (rank) {
    case 1:
      result.icon = "①";
      result.color = "#FFD700";
      break;
    case 2:
      result.icon = "②";
      result.color = "#C0C0C0";
      break;
    case 3:
      result.icon = "③";
      result.color = "#CD7F32";
      break;
    default:
      break; //skip
  }
  return result;
}
export function toPath(lx: number, ly: number, mx: number, my: number) {
  if (!isNaN(lx)) return `M${mx} ${my} L${lx} ${ly}`;
}
export function toRotate(r: number, x: number, y: number) {
  return `rotate(${r},${x},${y})`;
}

export function toArcPath(
  cx: number,
  cy: number,
  r: number,
  startAngle: number,
  arcAngle: number,
  relativeStartAngle: number,
  ry: number | null = null,
) {
  cx = parseFloat(`${cx}`);
  cy = parseFloat(`${cy}`);
  let rx = parseFloat(`${r}`);
  ry = ry === null ? rx : parseFloat(`${ry}`);
  let tl = startAngle ? (parseFloat(`${startAngle}`) * Math.PI) / 180 : 0;
  let delta = (parseFloat(`${arcAngle}`) * Math.PI) / 180;
  let phi = (parseFloat(`${relativeStartAngle}`) * Math.PI) / 180;

  return svg_ellipse_arc([cx, cy], [rx, ry], [tl, delta], phi) || undefined;
}

//////////////////////////////////// http://xahlee.info/js/svg_circle_arc.html
const cos = Math.cos;
const sin = Math.sin;
const PI = Math.PI;

const f_matrix_times = ([[a, b], [c, d]]: number[][], [x, y]: number[]) => [
  a * x + b * y,
  c * x + d * y,
];
const f_rotate_matrix = (x: number) => [
  [cos(x), -sin(x)],
  [sin(x), cos(x)],
];
const f_vec_add = ([a1, a2]: number[], [b1, b2]: number[]) => [a1 + b1, a2 + b2];

const svg_ellipse_arc = (
  [cx, cy]: number[],
  [rx, ry]: number[],
  [t1, delta]: number[],
  phi: number,
) => {
  /* [
    returns a SVG path element that represent a ellipse.
    cx,cy → center of ellipse
    rx,ry → major minor radius
    t1 → start angle, in radian.
    delta → angle to sweep, in radian. positive.
    phi → rotation on the whole, in radian
    URL: SVG Circle Arc http://xahlee.info/js/svg_circle_arc.html
    Version 2019-06-19
     ] */
  delta = delta % (2 * PI);
  let rotMatrix = f_rotate_matrix(phi);
  let [sX, sY] = f_vec_add(f_matrix_times(rotMatrix, [rx * cos(t1), ry * sin(t1)]), [cx, cy]);
  let [eX, eY] = f_vec_add(
    f_matrix_times(rotMatrix, [rx * cos(t1 + delta), ry * sin(t1 + delta)]),
    [cx, cy],
  );
  let fA = delta > PI ? 1 : 0;
  let fS = delta > 0 ? 1 : 0;
  if (isNaN(eX) || isNaN(eY)) {
    return null;
  }
  //return  "M " + sX + " " + sY + " A " + [ rx , ry , phi / (2*PI) *360, fA, fS, eX, eY ];
  return `M ${sX.toFixed(0)} ${sY.toFixed(0)} A ${rx.toFixed(0)} ${ry.toFixed(0)} ${(
    (phi / (2 * PI)) *
    360
  ).toFixed(0)} ${fA.toFixed(0)} ${fS.toFixed(0)} ${eX.toFixed(0)} ${eY.toFixed(0)}`;
};

interface GetGaugeTextColor {
  value: number;
  min: number;
  max: number;
}

export const getGaugeTextColor = ({ value, min, max }: GetGaugeTextColor) => {
  if (value > max) {
    return MAIN_THEME.GAUGE.ABOVE_ZONE_COLOR;
  }
  if (value < min) {
    return MAIN_THEME.GAUGE.BELOW_ZONE_COLOR;
  }
  return MAIN_THEME.GAUGE.IN_ZONE_COLOR;
};

export const getWorkoutImgSegmentColor = ({
  step,
  styles,
  isCourseStep,
}: GetWorkoutImgSegmentColorProps) => {
  if (step.visualExperience?.display_rider_metrics === false) {
    return styles.colors.hidden;
  } else if (isCourseStep) {
    return styles.colors.course;
  } else {
    switch (step.intervalType) {
      case INTERVAL_TYPE.TAR:
        return styles.colors.tar;

      case INTERVAL_TYPE.AGR:
        return styles.colors.agr;

      case INTERVAL_TYPE.TIZ:
        return styles.colors.tiz;

      case INTERVAL_TYPE.SPR:
        return styles.colors.spr;

      case INTERVAL_TYPE.KOD:
        return styles.colors.kod;

      case INTERVAL_TYPE.KOM:
        return styles.colors.kom;

      default:
        return null;
    }
  }
};

export const getDoteDrawingValue = ({ interval, drawingObject }: GetDoteDrawingValueProps) => {
  const defaultPoint = 0;
  const middlePoint = 40;
  switch (drawingObject) {
    case DrawingType.Cadence:
      return interval.cadence || defaultPoint;
    case DrawingType.Slope:
      return interval.slope ? interval.slope * 10 + middlePoint : middlePoint;
    default:
      return interval.cadence || defaultPoint;
  }
};

export const getUserNickname = (cpxProfile: CpxProfileInterface | null) => {
  if (!cpxProfile) {
    return "Unregister";
  } else if (cpxProfile.cpxNickname) {
    return cpxProfile.cpxNickname;
  } else if (cpxProfile.name) {
    const userName = cpxProfile.name;
    const firstName = userName.split(" ")[0];
    const lastName = userName.split(" ")[1];
    return `${firstName} ${lastName[0]}.`;
  } else return "Unregister";
};

export const getCourse = ({ plan, currentStep }: getCourseProps) => {
  if (plan && plan.courses && currentStep) {
    let setId = parseInt(currentStep.intervalId.split("-")[0]);
    let matchingSets = plan.sets.filter((s: WorkoutSetInterface) => s.index === setId);
    if (matchingSets.length > 0 && matchingSets[0].courseName !== "") {
      let matchingCourses = plan.courses.filter(
        (c: CourseInterface) => c.courseId === matchingSets[0].courseName,
      );
      if (matchingCourses.length > 0) {
        return matchingCourses[0];
      }
    }
  }
  return null;
};

export const isCloseToBottom = ({
  layoutMeasurement,
  contentOffset,
  contentSize,
}: NativeScrollEvent) => {
  const paddingToBottom = 20;
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
};
