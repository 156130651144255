import React, { useState } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { themedStyles } from "./styles";
import { StateInterface } from "../../redux/reducers";
import { DefaultText } from "../index";
import { getTimeFromStepStart } from "../../redux/selectors";
import { LeaderboardScoreLine } from "./LeaderboardScoreLine";
import { INTERVAL_TYPE } from "../../redux/types";
import { isGameInterval } from "../../utils/Statistics";
import { getSortedUserListByPlace } from "../../utils/GameScoreCalculating";

export enum LEADERBOARD_TITLE {
  LEADERBOARD = "Leaderboard",
  RESULTS_SUMMARY = "Results Summary",
}

function LeaderboardComponent({
  userList,
  userId,
  currentSteps,
  timeFromStepStart,
  workoutSteps,
}: CombinedProps) {
  const [title, setTitle] = useState<LEADERBOARD_TITLE>(LEADERBOARD_TITLE.LEADERBOARD);
  const usersCount = userList.length;
  const userListWithPlace = getSortedUserListByPlace(userList);

  const sortedUserList = userListWithPlace.sort((a, b) => a.nickName.localeCompare(b.nickName));

  const myPlace = userListWithPlace.find((item) => item.userId === userId)?.place || 0;

  const isPreviousStepRecover =
    workoutSteps?.[(currentSteps.currentStepIndex || 1) - 1].intervalType === INTERVAL_TYPE.RECOVER;
  const isPreviousStepGame = isGameInterval(
    workoutSteps?.[(currentSteps.currentStepIndex || 1) - 1].intervalType,
  );

  const isGameStep = isGameInterval(currentSteps.currentStep?.intervalType);

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.headerContainer}>
        <DefaultText style={themedStyles.workoutName}>{title}</DefaultText>
        <View style={themedStyles.stepsCountContainer}>
          <DefaultText style={themedStyles.currentStep}>{`${myPlace}`}</DefaultText>
          <DefaultText style={themedStyles.allSteps}>{`/${usersCount}`}</DefaultText>
        </View>
      </View>
      {sortedUserList.map((item) => (
        <LeaderboardScoreLine
          key={`user-id-${item.userId}`}
          item={item}
          place={item.place}
          currentStepIndex={currentSteps.currentStepIndex}
          currentStep={currentSteps.currentStep}
          timeFromStepStart={timeFromStepStart}
          userId={userId}
          isPreviousStepRecover={isPreviousStepRecover}
          isPreviousStepGame={isPreviousStepGame}
          isGameStep={isGameStep}
          setTitle={setTitle}
        />
      ))}
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  userList: state.coachControls.activeMembers,
  userId: state.user.profile?.cpxUserId,
  currentSteps: state.activeTraining.currentSteps,
  timeFromStepStart: getTimeFromStepStart(state),
  workoutSteps: state.activeTraining.plan?.steps,
});
const mapDispatchToProps = (_dispatch: Dispatch) => ({});
export const Leaderboard = connect(mapStateToProps, mapDispatchToProps)(LeaderboardComponent);
