import React, { useState } from "react";
import { View } from "react-native";

import { themedStyles } from "../styles";
import { SideBarItem } from "../SideBarItem";
import { WelcomeTabInterface } from "../../../screens";

interface PropsType {
  tab: WelcomeTabInterface;
  tabIndex: number;
  itemIndex: number;
  onItemPress: (index: number) => void;
  isGBUser: boolean;
  subTabIndex: number | null;
  onSubItemPress: (index: number | null) => void;
}

export const SideBarTabItem = ({
  tab,
  itemIndex,
  onItemPress,
  tabIndex,
  subTabIndex,
  onSubItemPress,
}: PropsType) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const onCollapsedItemPress = () => {
    onItemPress(itemIndex);
    setIsCollapsed(!isCollapsed);
    onSubItemPress(0);
  };
  const onSubPress = (subIndex: number) => {
    onSubItemPress(subIndex);
    onItemPress(itemIndex);
  };
  const onRegularPress = () => {
    onItemPress(itemIndex);
    onSubItemPress(null);
  };

  if (!tab) {
    return <View style={themedStyles.divider} key={`divider-key-${itemIndex}`} />;
  }
  if (!tab.isAccess) {
    return null;
  }
  const isSelectedTab = tabIndex === itemIndex;
  if (tab.component) {
    return (
      <SideBarItem
        key={`${tab.title}-${itemIndex}`}
        isSelected={isSelectedTab}
        icon={tab.icon}
        iconColor={tab.iconColor}
        text={tab.title}
        onPress={onRegularPress}
      />
    );
  } else {
    return (
      <View key={`${tab.title}-${itemIndex}`}>
        <SideBarItem
          isSelected={isSelectedTab}
          icon={tab.icon}
          iconColor={tab.iconColor}
          text={tab.title}
          onPress={onCollapsedItemPress}
        />
        <View style={themedStyles.subItemContainer}>
          {isCollapsed &&
            tab.components &&
            tab.components.map((subItem, subIndex) => (
              <SideBarItem
                key={`${subItem.title}-${itemIndex}`}
                isSelected={subTabIndex === subIndex}
                icon={subItem.icon || tab.icon}
                iconColor={subItem.iconColor || tab.iconColor}
                text={subItem.title}
                onPress={() => onSubPress(subIndex)}
              />
            ))}
        </View>
      </View>
    );
  }
};
