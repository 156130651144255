import React from "react";
import { View, ScrollView } from "react-native";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { StepsMetrics } from "../StepsMetrics";
import { wrapScrollView } from "react-native-scroll-into-view";
import { DefaultText } from "../index";

const CustomScrollView = wrapScrollView(ScrollView);

const StepsTableComponent = ({
  isHiddenStep,
  trainingName,
  trainingSteps,
  currentStepIndex,
}: CombinedProps) => {
  if (isHiddenStep) {
    return <View style={themedStyles.container} />;
  }

  const allSteps = trainingSteps?.length || 0;
  const currentStep = currentStepIndex === null ? 0 : currentStepIndex + 1;

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.metricsContainer}>
        <View style={themedStyles.headerContainer}>
          <DefaultText style={themedStyles.workoutName}>{trainingName}</DefaultText>
          <View style={themedStyles.stepsCountContainer}>
            <DefaultText style={themedStyles.currentStep}>{currentStep}</DefaultText>
            <DefaultText style={themedStyles.allSteps}>{`/${allSteps}`}</DefaultText>
          </View>
        </View>
        <CustomScrollView>
          <StepsMetrics />
        </CustomScrollView>
      </View>
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  isHiddenStep:
    state.activeTraining.currentSteps.currentStep?.visualExperience?.display_rider_metrics ===
    false,
  trainingName: state.activeTraining.plan?.workoutName,
  trainingSteps: state.activeTraining.plan?.steps,
  currentStepIndex: state.activeTraining.currentSteps.currentStepIndex,
});
const mapDispatchToProps = (_dispatch: Dispatch) => ({});
export const StepsTable = connect(mapStateToProps, mapDispatchToProps)(StepsTableComponent);
