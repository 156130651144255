// import firebase from "firebase/app";
import { put, call, select } from "redux-saga/effects";
import { /* FirebaseApi, */ MetricApi, SessionApi, SubscriptionApi } from "../../api/api";
import SettingsCreators from "../reducers/settingsReducer";
import SubscriptionCreators from "../reducers/subscriptionReducer";
import TimerCreators from "../reducers/timerReducer";
import UserCreators from "../reducers/userReducer";
import WorkoutCreators from "../reducers/workoutsReducer";
import NotificationsCreators from "../reducers/notificationsReducer";
import {
  BookingDataResponse,
  CoachProfileInterface,
  CpInterface,
  CpxProfileInterface,
  FetchConnectionsListResponse,
  FetchSessionsResponse,
  FetchTrainingPeaksResponse,
  GetCpxUserProfileResponse,
  // GetFirebaseTokenResponse,
  GetTrainingPlansResponse,
  InitializeUserAction,
  NotificationLocation,
  ProductsDataResponse,
  ProfileInterface,
  SubscriptionsDataResponse,
  TypesOfNotification,
  UpdateCriticalPowerAction,
  UpdateUserProfileAction,
  UpdateUserProfileResponse,
  USER_TYPES,
} from "../types";
import { setBearerToken } from "../../api/api";
import { Event, TrackingProps } from "../../services/googleAnalyticsTracking";
import { Platform } from "react-native";
import { StateInterface } from "../reducers";
import { getUserAccessLvl } from "../../utils/sagasHelper";
import { logOut } from "./authSaga";
import { pascalAndCamelKeys } from "../../utils/pascalAndCamelCase";

export function* initializeUser({ user, token }: InitializeUserAction) {
  setBearerToken(token);
  Event("user_login", {
    email: user.email,
    roles: user.roles,
    cpxUserId: user["https://myapp.example.com/CPXUserId"],
  });

  // try {
  //   const firebaseToken: GetFirebaseTokenResponse = yield FirebaseApi.getFirebaseToken();
  //   if (firebaseToken.data) {
  //     yield firebase.auth().signInWithCustomToken(firebaseToken.data);
  //   }
  // } catch (error) {
  //   yield put(
  //     NotificationsCreators.addNotification({
  //       location: NotificationLocation.UserSaga,
  //       notificationType: TypesOfNotification.Error,
  //       description: `${error}`,
  //     }),
  //   );
  // }

  yield put(TimerCreators.startTimer());
  const profile: ProfileInterface = {
    email: user.email || "",
    first_name: user.given_name || "",
    last_name: user.family_name || "",
    full_name: user.name || "",
    nickname: user.nickname || "",
    picture: user.picture || "",
    cpxUserId: user["https://myapp.example.com/CPXUserId"],
  };

  yield put(UserCreators.setUserProfile(token, profile));
  try {
    const res: FetchSessionsResponse = yield SessionApi.fetchSession();

    if (res.success) {
      yield put(WorkoutCreators.setWorkoutsList(res.workouts));
      yield put(WorkoutCreators.setIntroductionsList(res.introductions));
    }
  } catch (error) {
    yield call(logOut);
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.UserSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }

  try {
    const res: FetchTrainingPeaksResponse = yield SessionApi.fetchTrainingPeaksSession();
    if (res.success) {
      yield put(WorkoutCreators.setTrainingPeeksWorkouts(res.training_peaks_wods));
    }
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.UserSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }

  try {
    const trainingPlansResponse: GetTrainingPlansResponse = yield SessionApi.getTrainingPlanes();
    yield put(WorkoutCreators.setTrainingPlans(trainingPlansResponse.training_plans));
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.UserSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }

  try {
    const { data } = yield MetricApi.getCpxUserProfile();
    const cpxUserProfileData: GetCpxUserProfileResponse = pascalAndCamelKeys(data);
    try {
      if (Platform.OS === "web") {
        const bDate = new Date(cpxUserProfileData.BirthDate || "");
        const now = new Date();
        const age = now.getFullYear() - bDate.getFullYear();
        yield call(TrackingProps, {
          email: cpxUserProfileData.Email,
          cpx_id: cpxUserProfileData.Id,
          age: age,
          gender: cpxUserProfileData.Sex,
          center: cpxUserProfileData.PrimaryStudioIndex,
        });
      }
    } catch (error) {
      yield put(
        NotificationsCreators.addNotification({
          location: NotificationLocation.UserSaga,
          notificationType: TypesOfNotification.Error,
          description: `${error}`,
        }),
      );
    }

    const cpxUserProfile: CpxProfileInterface = {
      name: cpxUserProfileData.Name,
      birthDate: cpxUserProfileData.BirthDate,
      weight: cpxUserProfileData.Weight,
      height: cpxUserProfileData.Height,
      isMale: cpxUserProfileData.Sex === "male",
      country: cpxUserProfileData.Country,
      primaryPowerWattsCenter: cpxUserProfileData.Referrer,
      wPrime: cpxUserProfileData.WPrime,
      wPrimeCP: cpxUserProfileData.WPrimeCp,
      cpxNickname: cpxUserProfileData.Nickname,
      personalRecords: cpxUserProfileData.PersonalRecords,
    };
    const criticalPower = cpxUserProfileData.Cp20M || 200;
    const cp: CpInterface = {
      cp5s: cpxUserProfileData.Cp5S,
      cp10s: cpxUserProfileData.Cp10S,
      cp20s: cpxUserProfileData.Cp20S,
      cp45s: cpxUserProfileData.Cp45S,
      cp1m: cpxUserProfileData.Cp1M,
      cp2m: cpxUserProfileData.Cp2M,
      cp3m: cpxUserProfileData.Cp3M,
      cp5m: cpxUserProfileData.Cp5M,
      cp10m: cpxUserProfileData.Cp10M,
      cp20m: cpxUserProfileData.Cp20M,
      cp30m: cpxUserProfileData.Cp30M,
      cp45m: cpxUserProfileData.Cp45M,
      cp60m: cpxUserProfileData.Cp60M,
    };

    const userType: USER_TYPES =
      cpxUserProfileData.Role === "Client" ? USER_TYPES.CPX_USER : USER_TYPES.COACH;
    if (userType === USER_TYPES.COACH) {
      Event("coach_login", {
        email: user.email,
        name: user.given_name + " " + user.family_name,
        roles: user.roles,
        center: cpxUserProfileData.Referrer,
      });
    }
    try {
      const { data: productsRes }: ProductsDataResponse = yield SubscriptionApi.getProducts();
      if (productsRes.success === true) {
        yield put(SubscriptionCreators.setProducts(productsRes.products));
      } else {
        yield put(SubscriptionCreators.failedProducts("TODO: error response"));
      }
    } catch (error) {
      yield put(SubscriptionCreators.failedProducts(`${error}`));
    }

    try {
      const { data: userSubscription }: SubscriptionsDataResponse =
        yield SubscriptionApi.getSubscriptions();

      if (userSubscription.success === true) {
        yield put(SubscriptionCreators.setSubscriptions(userSubscription));
        const userAccessLvl = getUserAccessLvl(userSubscription.pricing_mode);
        yield put(UserCreators.setUserAccessLvl(userAccessLvl));
      } else {
        yield put(SubscriptionCreators.failedSubscriptions("TODO: error response"));
      }
    } catch (error) {
      yield put(SubscriptionCreators.failedSubscriptions(`${error}`));
    }

    try {
      const { data: bookedLiveClasses }: BookingDataResponse =
        yield SubscriptionApi.getBookedClasses();

      if (bookedLiveClasses.success === true) {
        yield put(SubscriptionCreators.setBookings(bookedLiveClasses.booking));
      } else {
        yield put(SubscriptionCreators.failedBookings("TODO: error response"));
      }
    } catch (error) {
      yield put(SubscriptionCreators.failedBookings(`${error}`));
    }

    yield put(UserCreators.setUserType(userType));
    yield put(SettingsCreators.setCriticalPower(criticalPower, cp));
    yield put(UserCreators.setCpxUserProfile(cpxUserProfile));

    if (!cpxUserProfileData.ProfileCompleted) {
      yield put(UserCreators.toggleUpdateProfileModal(true));
    }

    if (userType === USER_TYPES.COACH) {
      const coachProfile: CoachProfileInterface = {
        coach_id: user["https://myapp.example.com/CPXUserId"] || null,
        first_name: user.given_name || "",
        center: null,
        last_name: user.family_name || "",
      };
      yield put(UserCreators.setCoachProfile(coachProfile));
    }
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.UserSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
    yield put(UserCreators.setUserType(USER_TYPES.USER));
  }
}

export function* getServicesConnections() {
  try {
    const res: FetchConnectionsListResponse = yield SessionApi.getConnectionsList();
    if (res.success === true) {
      yield put(SettingsCreators.setServicesConnected(res.connections));
    }
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.UserSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}

export function* updateCriticalPower({ criticalPower }: UpdateCriticalPowerAction) {
  const data = {
    Cp20M: criticalPower,
  };
  try {
    yield MetricApi.updateCp20m(JSON.stringify(data));
    const { data: cpxData } = yield MetricApi.getCpxUserProfile();
    const cpxUserProfileData: GetCpxUserProfileResponse = pascalAndCamelKeys(cpxData);
    const criticalPower = cpxUserProfileData.Cp20M || 200;
    const cp: CpInterface = {
      cp5s: cpxUserProfileData.Cp5S,
      cp10s: cpxUserProfileData.Cp10S,
      cp20s: cpxUserProfileData.Cp20S,
      cp45s: cpxUserProfileData.Cp45S,
      cp1m: cpxUserProfileData.Cp1M,
      cp2m: cpxUserProfileData.Cp2M,
      cp3m: cpxUserProfileData.Cp3M,
      cp5m: cpxUserProfileData.Cp5M,
      cp10m: cpxUserProfileData.Cp10M,
      cp20m: cpxUserProfileData.Cp20M,
      cp30m: cpxUserProfileData.Cp30M,
      cp45m: cpxUserProfileData.Cp45M,
      cp60m: cpxUserProfileData.Cp60M,
    };
    yield put(SettingsCreators.setCriticalPower(criticalPower, cp));
    Event("set_critical_power", { email: cpxUserProfileData.Email });
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.UserSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}

export function* updateUserProfile({ updatedUserProfile }: UpdateUserProfileAction) {
  const jsonData = JSON.stringify(updatedUserProfile);
  const email: string | undefined = yield select(
    (state: StateInterface) => state.user.profile?.email,
  );
  try {
    const res: UpdateUserProfileResponse = yield MetricApi.updateUserProfile(jsonData);
    if (res.data) {
      yield put(UserCreators.updateUserProfileSuccess(updatedUserProfile));
    } else {
      yield put(UserCreators.updateUserProfileFailure());
    }
    Event("update_profile", { email: email });
  } catch (error) {
    yield put(UserCreators.updateUserProfileFailure());
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.UserSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}
