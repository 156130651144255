import { BEST_EFFORTS_INTERVALS } from "../constants";
import { PersonalRecordInterface, PowerSampleInterface } from "../redux/types";
import { RecordType } from "../redux/types";

interface CheckBestEffortsProps {
  powerSamples: PowerSampleInterface[];
  personalRecords: PersonalRecordInterface[];
  timeFromWorkoutStart: number;
}

type AwgPowerByIntervalType = {
  avgPower: number;
  interval: number;
};
export const checkBestEfforts = ({
  powerSamples,
  personalRecords,
  timeFromWorkoutStart,
}: CheckBestEffortsProps) => {
  const awgPowerByIntervals: AwgPowerByIntervalType[] = [];

  /*--- calculate the average user power for each interval ---*/
  for (let i = 0; i < BEST_EFFORTS_INTERVALS.length; i++) {
    if (timeFromWorkoutStart >= BEST_EFFORTS_INTERVALS[i]) {
      let validSampleCount = 0;
      let powerSum = 0;
      for (let k = 0; k < powerSamples.length; k++) {
        const isSampleInInterval =
          powerSamples[k].timeFromWorkoutStart >= timeFromWorkoutStart - BEST_EFFORTS_INTERVALS[i];
        if (isSampleInInterval) {
          validSampleCount++;
          powerSum += powerSamples[k].power;
        }
      }
      const awgPower = powerSum / validSampleCount;
      const avgPowerByInterval = {
        avgPower: awgPower,
        interval: BEST_EFFORTS_INTERVALS[i],
      };
      awgPowerByIntervals.push(avgPowerByInterval);
    }
  }

  const newRecords: RecordType = [];

  /*---- compare the average power of the user for each interval with the user's records ----*/
  for (let i = 0; i < awgPowerByIntervals.length; i++) {
    if (awgPowerByIntervals[i] !== null) {
      for (let k = 0; k < personalRecords.length; k++) {
        const isSameInterval =
          personalRecords[k].IntervalLength === awgPowerByIntervals[i].interval;
        const isBiggestPower = personalRecords[k].Power < awgPowerByIntervals[i].avgPower;
        /*---- if the average user power for one interval is higher than the record, then write it out as a new record ----*/
        if (isSameInterval && isBiggestPower) {
          newRecords.push({
            rank: personalRecords[k].Rank,
            timeFrame: personalRecords[k].TimeFrame,
            intervalDuration: personalRecords[k].IntervalLength,
            power: awgPowerByIntervals[i].avgPower,
          });
        }
      }
    }
  }
  /*---- return new record arr ----*/

  return newRecords;
};
