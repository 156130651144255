import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import { FETCHING_STATUS } from "../../constants";
import {
  Auth0UserInterface,
  CoachProfileInterface,
  CpxProfileInterface,
  ProfileInterface,
  SetCoachProfileAction,
  SetCpxUserProfileAction,
  SetUserProfileAction,
  SetIsAuthorizedCheckedAction,
  UserState,
  USER_TYPES,
  SetUserTypeAction,
  UpdatedUserProfileInterface,
  UpdateUserProfileAction,
  UpdateUserProfileSuccessAction,
  UpdateUserProfileFailureAction,
  ToggleUpdateProfileModalAction,
  SetUserAccessLvlAction,
} from "../types";

interface ActionTypesInterface {
  GET_COACH_PROFILE: "GET_COACH_PROFILE";
  GET_CPX_USER_PROFILE: "GET_CPX_USER_PROFILE";
  INITIALIZE_USER: "INITIALIZE_USER";
  SET_CPX_USER_PROFILE: "SET_CPX_USER_PROFILE";
  SET_COACH_PROFILE: "SET_COACH_PROFILE";
  SET_USER_PROFILE: "SET_USER_PROFILE";
  SET_USER_TYPE: "SET_USER_TYPE";
  SET_IS_AUTHORIZED_CHECKED: "SET_IS_AUTHORIZED_CHECKED";
  SET_USER_ACCESS_LVL: "SET_USER_ACCESS_LVL";
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE";
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS";
  UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE";
  TOGGLE_UPDATE_PROFILE_MODAL: "TOGGLE_UPDATE_PROFILE_MODAL";
}

interface ActionCreatorsInterface extends ActionCreators {
  getCoachProfile: () => AnyAction;
  getCpxUserProfile: () => AnyAction;
  initializeUser: (token: string, user: Auth0UserInterface) => AnyAction;
  setCpxUserProfile: (cpxProfile: CpxProfileInterface) => AnyAction;
  setUserType: (userType: USER_TYPES) => AnyAction;
  setCoachProfile: (coachProfile: CoachProfileInterface) => AnyAction;
  setUserProfile: (token: string, profile: ProfileInterface) => AnyAction;
  setIsAuthorizedChecked: (isAuthorizedChecked: boolean) => AnyAction;
  setUserAccessLvl: (userAccessLvl: number) => AnyAction;
  updateUserProfile: (updatedUserProfile: UpdatedUserProfileInterface) => AnyAction;
  updateUserProfileSuccess: (updatedUserProfile: UpdatedUserProfileInterface) => AnyAction;
  updateUserProfileFailure: () => AnyAction;
  toggleUpdateProfileModal: (isVisible: boolean) => AnyAction;
}

type Handler<A> = (state: UserState, action: A) => UserState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  getCoachProfile: null,
  getCpxUserProfile: null,
  initializeUser: ["token", "user"],
  setCpxUserProfile: ["cpxProfile"],
  setUserType: ["userType"],
  setCoachProfile: ["coachProfile"],
  setUserProfile: ["token", "profile"],
  setIsAuthorizedChecked: ["isAuthorizedChecked"],
  setUserAccessLvl: ["userAccessLvl"],
  updateUserProfile: ["updatedUserProfile"],
  updateUserProfileSuccess: ["updatedUserProfile"],
  updateUserProfileFailure: null,
  toggleUpdateProfileModal: ["isVisible"],
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: UserState = {
  isAuthenticated: false,
  isFullProfileReceived: false,
  isAuthorizedChecked: false,
  userType: null,
  token: null,
  profile: null,
  cpxProfile: null,
  coachProfile: null,
  isUpdateModalVisible: false,
  updateStatus: null,
  isGBUser: false,
  userAccessLvl: null,
};

/* ------------- Reducers ------------- */

const setIsAuthorizedChecked: Handler<SetIsAuthorizedCheckedAction> = (
  state,
  { isAuthorizedChecked },
) => {
  return {
    ...state,
    isAuthorizedChecked: isAuthorizedChecked,
    isAuthenticated: isAuthorizedChecked ? state.isAuthenticated : false,
  };
};

const setCpxUserProfile: Handler<SetCpxUserProfileAction> = (state, { cpxProfile }) => {
  return {
    ...state,
    cpxProfile: cpxProfile,
    isGBUser: cpxProfile.country === "GB",
    isFullProfileReceived: true,
  };
};

const setUserType: Handler<SetUserTypeAction> = (state, { userType }) => {
  return {
    ...state,
    userType: userType,
  };
};

const setCoachProfile: Handler<SetCoachProfileAction> = (state, { coachProfile }) => {
  return {
    ...state,
    coachProfile: coachProfile,
  };
};

const setUserProfile: Handler<SetUserProfileAction> = (state, { token, profile }) => {
  return {
    ...state,
    isAuthenticated: true,
    token: token,
    profile: profile,
    isAuthorizedChecked: true,
  };
};

const updateProfile: Handler<UpdateUserProfileAction> = (state) => {
  return {
    ...state,
    updateStatus: FETCHING_STATUS.PROCESSED,
  };
};

const updateProfileSuccess: Handler<UpdateUserProfileSuccessAction> = (
  state,
  { updatedUserProfile },
) => {
  return {
    ...state,
    updateStatus: FETCHING_STATUS.SUCCESS,
    cpxProfile: {
      ...state.cpxProfile!,
      cpxNickname: updatedUserProfile.Nickname,
      height: updatedUserProfile.Height,
      weight: updatedUserProfile.Weight,
      name: updatedUserProfile.Name,
      country: updatedUserProfile.Country,
    },
  };
};

const updateProfileFailure: Handler<UpdateUserProfileFailureAction> = (state) => {
  return {
    ...state,
    updateStatus: FETCHING_STATUS.FAIL,
  };
};

const toggleUpdateProfileModal: Handler<ToggleUpdateProfileModalAction> = (
  state,
  { isVisible },
) => {
  return {
    ...state,
    isUpdateModalVisible: isVisible,
    updateStatus: null,
  };
};

const setUserAccessLvl: Handler<SetUserAccessLvlAction> = (state, { userAccessLvl }) => {
  return {
    ...state,
    userAccessLvl,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_CPX_USER_PROFILE]: setCpxUserProfile,
  [Types.SET_COACH_PROFILE]: setCoachProfile,
  [Types.SET_USER_PROFILE]: setUserProfile,
  [Types.SET_IS_AUTHORIZED_CHECKED]: setIsAuthorizedChecked,
  [Types.SET_USER_TYPE]: setUserType,
  [Types.SET_USER_ACCESS_LVL]: setUserAccessLvl,
  [Types.UPDATE_USER_PROFILE]: updateProfile,
  [Types.UPDATE_USER_PROFILE_SUCCESS]: updateProfileSuccess,
  [Types.UPDATE_USER_PROFILE_FAILURE]: updateProfileFailure,
  [Types.TOGGLE_UPDATE_PROFILE_MODAL]: toggleUpdateProfileModal,
};

export const userReducer = createReducer(INITIAL_STATE, HANDLERS);
