import firebase from "firebase/app";
// import "firebase/firestore";
// import { Platform } from "react-native";
// import { FIREBASE } from "../constants";

// const FIREBASE_CONFIG = {
//   apiKey: FIREBASE.API_KEY,
//   authDomain: FIREBASE.AUTH_DOMAIN,
//   projectId: FIREBASE.PROJECT_ID,
//   appId: FIREBASE.APP_ID,
// };

// firebase.initializeApp(FIREBASE_CONFIG);

// if (Platform.OS === "android") {
//   firebase.firestore().settings({ experimentalForceLongPolling: true });
// }

export const db = {} as firebase.firestore.Firestore | undefined // firebase.firestore();
