import { DeviceType } from "../redux/types";
import {
    UnknownDeviceString,
    SERVICE_UUID_CYCLING_POWER,
    CHARACTERISTIC_UUID_CYCLING_POWER_MEASUREMENT
} from "./common/BleMeterCommon";
import { BlePowerMeterBase } from "./common/BlePowerMeterBase";
import { BleMeter } from "./BleMeter.web";
import { DebugApi } from "../api/api";

export class BleCyclingPowerMeter extends BlePowerMeterBase {
    services: BluetoothRemoteGATTService[];
    constructor(
        device: BluetoothDevice,
        server: BluetoothRemoteGATTServer | undefined,
        services: BluetoothRemoteGATTService[],
        userWeight: number
    ) {
        const bleDevice = new BleMeter(
            DeviceType.PowerMeterDevice,
            null,
            device,
            server,
            userWeight
        );
        const deviceName = device.name || UnknownDeviceString;

        super(bleDevice, deviceName, userWeight);
        this.services = services;
    }

    async SetupCharacteristics() {
        const service = this.services.find(
            (o) => o.uuid === SERVICE_UUID_CYCLING_POWER);
        const characteristic = await service?.getCharacteristic(
            CHARACTERISTIC_UUID_CYCLING_POWER_MEASUREMENT
        );

        if (characteristic) {
            const bleMeterWeb = this.bleDevice as BleMeter;
            bleMeterWeb.notifyCharacteristic = characteristic;
            bleMeterWeb.listen();
        } else {
            console.log("Error: Unable to find characteristic");
            DebugApi.log({
                function: "bleCyclingPowerMeter SetupCharacteristic",
                message: "Error: Unable to find characteristic",
            });
        }
    }
}
