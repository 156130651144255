import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    paddingVertical: 100,
  },
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
    padding: 24,
    borderRadius: 8,
  },
  button: {
    alignSelf: "center",
    // width: "100%",
    marginTop: 16,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
    backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
  },
  buttonText: { fontWeight: "500", fontSize: 16, lineHeight: 24 },
  titleContainer: { marginBottom: 15 },
  title: { fontSize: 28, lineHeight: 32 },
  contentContainer: {},
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  cancelButton: {
    backgroundColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
  },
});
