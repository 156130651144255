import React, { useCallback, useState } from "react";
import DeviceCreators from "../../../redux/reducers/devicesReducer";
import ActiveTrainingCreators from "../../../redux/reducers/activeTrainingReducer";
import { TouchableOpacity, View, ActivityIndicator  } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CONNECTION_STATUS, ICON } from "../../../constants";
import { StateInterface } from "../../../redux/reducers";
import { MAIN_THEME } from "../../../themes";
import { themedStyles } from "./styles";
import { ButtonWithIcon, DefaultText } from "../../index";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackParams } from "../../../navigation";
import { UserMetricsGauge } from "../../MetricsGauge";
import { RFValue } from "../../../utils/resizeHelper";
import { DeviceType, TRAINING_TYPES } from "../../../redux/types";

type NavigationProps = StackNavigationProp<StackParams, "VirtualTraining">;

type PropsTypes = {
  closeBleModal: () => void;
  request: (type?: any) => Promise<void>;
  onConnectDevice?: () => void;
};

function BleConnectionModalComponent({
  cp20m,
  deviceData,
  trainingType,
  blePowerMeterName,
  blePowerMeterConnectionStatus,
  bleHeartRateMeterName,
  bleHeartRateMeterConnectionStatus,
  bleSpeedCadenceSensorName,
  bleSpeedCadenceSensorConnectionStatus,
  bleSmartTrainerName,
  bleSmartTrainerConnectionStatus,
  trainingIsInitialized,
  connectMockBle,
  closeBleModal,
  searchForBleDevices,
  request,
  onConnectDevice,
  resumeTraining,
}: CombinedProps) {
  const { replace } = useNavigation<NavigationProps>();
  const [clickCount, setClickCount] = useState(0);

  // Smart Trainer parameters
  const isBleSmartTrainerMeterConnected =
    bleSmartTrainerConnectionStatus === CONNECTION_STATUS.CONNECTED;
  const smartTrainerConnectButtonText = isBleSmartTrainerMeterConnected
    ? "Disconnect"
    : "Connect Smart Trainer";
  const smartTrainerConnectButtonColor = isBleSmartTrainerMeterConnected
    ? MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR
    : MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR;
  const smartTrainerStatusTextColor = isBleSmartTrainerMeterConnected
    ? MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR
    : MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR;

  // Power Meter parameters
  const isBlePowerMeterConnected = blePowerMeterConnectionStatus === CONNECTION_STATUS.CONNECTED;
  const powerMeterConnectButtonText = isBlePowerMeterConnected
    ? "Disconnect"
    : "Connect Power Meter";
  const powerMeterConnectButtonColor = isBlePowerMeterConnected
    ? MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR
    : MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR;
  const powerMeterStatusTextColor = isBlePowerMeterConnected
    ? MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR
    : MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR;

  // Heart Rate Monitor parameters
  const isBleHeartRateMeterConnected =
    bleHeartRateMeterConnectionStatus === CONNECTION_STATUS.CONNECTED;
  const heartRateMeterConnectButtonText = isBleHeartRateMeterConnected
    ? "Disconnect"
    : "Connect Heart Rate Meter";
  const heartRateMeterConnectButtonColor = isBleHeartRateMeterConnected
    ? MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR
    : MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR;
  const heartRateMeterStatusTextColor = isBleHeartRateMeterConnected
    ? MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR
    : MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR;

  // Speed & Cadence parameters
  const isSpeedCadenceSensorConnected =
    bleSpeedCadenceSensorConnectionStatus === CONNECTION_STATUS.CONNECTED;
  const speedCadenceSensorConnectButtonText = isSpeedCadenceSensorConnected
    ? "Disconnect"
    : "Connect Speed & Cadence Sensor";
  const speedCadenceSensorConnectButtonColor = isSpeedCadenceSensorConnected
    ? MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR
    : MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR;
  const speedCadenceSensorStatusTextColor = isSpeedCadenceSensorConnected
    ? MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR
    : MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR;

  const connectionCheckMessageText = isBlePowerMeterConnected
    ? "Connection checked successfully"
    : "Pedal to see your Stats in the Gauge";
  const checkMessageBackgroundColor = isBlePowerMeterConnected
    ? MAIN_THEME.DEFAULT?.CONFORMING_BACKGROUND_COLOR
    : MAIN_THEME.DEFAULT?.REJECTING_BACKGROUND_COLOR;

  const onSearchSmartTrainerButtonPress = useCallback(() => {
    searchForBleDevices(DeviceType.SmartTrainer);
  }, [searchForBleDevices]);

  const onSearchPowerMeterButtonPress = useCallback(() => {
    searchForBleDevices(DeviceType.PowerMeterDevice);
  }, [searchForBleDevices]);

  const onSearchHeartRateMeterButtonPress = useCallback(() => {
    searchForBleDevices(DeviceType.HeartRateMeterDevice);
  }, [searchForBleDevices]);

  const onSearchSpeedCadenceSensorButtonPress = useCallback(() => {
    searchForBleDevices(DeviceType.SpeedCadenceSensor);
  }, [searchForBleDevices]);

  const onBackButtonPress = useCallback(() => {
    closeBleModal();
    replace("Welcome");
  }, [replace, closeBleModal]);

  const onContinueButtonPress = useCallback(() => {
    closeBleModal();
    request();
    onConnectDevice?.();
    if (trainingType !== TRAINING_TYPES.LIVE_SESSION) {
      resumeTraining();
    }
  }, [closeBleModal, request, onConnectDevice, resumeTraining, trainingType]);

  const onSecretMockConnection = useCallback(() => {
    if (clickCount < 5) {
      setClickCount(clickCount + 1);
    } else {
      connectMockBle();
    }
  }, [connectMockBle, clickCount, setClickCount]);

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.modalContainer}>
        <View style={themedStyles.gaugeStatusContainer}>
          <View style={themedStyles.gaugeContainer}>
            <UserMetricsGauge
              width={RFValue(450)}
              height={RFValue(450)}
              deviceData={deviceData}
              cp20m={cp20m}
            />
          </View>
          <TouchableOpacity activeOpacity={1} onPress={onSecretMockConnection}>
            <View
              style={[
                themedStyles.connectionCheckMessageContainer,
                { backgroundColor: checkMessageBackgroundColor },
              ]}
            >
              <DefaultText style={themedStyles.connectionCheckMessageText}>
                {connectionCheckMessageText}
              </DefaultText>
            </View>
          </TouchableOpacity>
        </View>

        <View style={themedStyles.rightBlock}>
          <DefaultText style={themedStyles.titleText}>Connect your Bluetooth Devices</DefaultText>

          <View style={themedStyles.connectionStatusContainer}>
            <ButtonWithIcon
              onPress={onSearchSmartTrainerButtonPress}
              icon={ICON.BLUETOOTH}
              style={{
                container: {
                  backgroundColor: smartTrainerConnectButtonColor,
                  marginBottom: RFValue(8),
                },
              }}
            >
              {smartTrainerConnectButtonText}
            </ButtonWithIcon>
            {bleSmartTrainerName && (
              <DefaultText style={[themedStyles.connectionStatusText, { fontWeight: "bold" }]}>
                {`${bleSmartTrainerName} `}
              </DefaultText>
            )}
            <DefaultText
              style={[themedStyles.connectionStatusText, { color: smartTrainerStatusTextColor }]}
            >
              {bleSmartTrainerConnectionStatus}
            </DefaultText>
          </View>

          <View style={themedStyles.connectionStatusContainer}>
            <ButtonWithIcon
              onPress={onSearchPowerMeterButtonPress}
              icon={ICON.BLUETOOTH}
              style={{
                container: {
                  backgroundColor: powerMeterConnectButtonColor,
                  marginBottom: RFValue(8),
                },
              }}
            >
              {powerMeterConnectButtonText}
            </ButtonWithIcon>

            {blePowerMeterName && (
              <DefaultText style={[themedStyles.connectionStatusText, { fontWeight: "bold" }]}>
                {`${blePowerMeterName} `}
              </DefaultText>
            )}
            <DefaultText
              style={[themedStyles.connectionStatusText, { color: powerMeterStatusTextColor }]}
            >
              {blePowerMeterConnectionStatus}
            </DefaultText>
          </View>

          <View style={themedStyles.connectionStatusContainer}>
            <ButtonWithIcon
              onPress={onSearchHeartRateMeterButtonPress}
              icon={ICON.BLUETOOTH}
              style={{
                container: {
                  backgroundColor: heartRateMeterConnectButtonColor,
                  marginBottom: RFValue(8),
                },
              }}
            >
              {heartRateMeterConnectButtonText}
            </ButtonWithIcon>
            {bleHeartRateMeterName && (
              <DefaultText style={[themedStyles.connectionStatusText, { fontWeight: "bold" }]}>
                {`${bleHeartRateMeterName} `}
              </DefaultText>
            )}
            <DefaultText
              style={[themedStyles.connectionStatusText, { color: heartRateMeterStatusTextColor }]}
            >
              {bleHeartRateMeterConnectionStatus}
            </DefaultText>
          </View>

          <View style={themedStyles.connectionStatusContainer}>
            <ButtonWithIcon
              onPress={onSearchSpeedCadenceSensorButtonPress}
              icon={ICON.BLUETOOTH}
              style={{
                container: {
                  backgroundColor: speedCadenceSensorConnectButtonColor,
                  marginBottom: RFValue(8),
                },
              }}
            >
              {speedCadenceSensorConnectButtonText}
            </ButtonWithIcon>
            {bleSpeedCadenceSensorName && (
              <DefaultText style={[themedStyles.connectionStatusText, { fontWeight: "bold" }]}>
                {`${bleSpeedCadenceSensorName} `}
              </DefaultText>
            )}
            <DefaultText
              style={[
                themedStyles.connectionStatusText,
                { color: speedCadenceSensorStatusTextColor },
              ]}
            >
              {bleSpeedCadenceSensorConnectionStatus}
            </DefaultText>
          </View>

          <View style={themedStyles.bottomButtonContainer}>
            <TouchableOpacity
              onPress={onBackButtonPress}
              style={[
                themedStyles.button,
                { backgroundColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR },
              ]}
            >
              <DefaultText style={themedStyles.buttonText}>Back</DefaultText>
            </TouchableOpacity>
            {(isBlePowerMeterConnected || isBleSmartTrainerMeterConnected) && (
              <TouchableOpacity
                onPress={onContinueButtonPress}
                style={[
                  themedStyles.button,
                  { backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR },
                  themedStyles.continueButton,
                ]}
                disabled={!trainingIsInitialized}
              >
                { trainingIsInitialized ?
                  <DefaultText style={themedStyles.buttonText}>Continue</DefaultText> :
                  <ActivityIndicator size="small" color={MAIN_THEME.HEADER?.ACTIVE_BUTTON_ICON_COLOR} />
                }
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PropsTypes;

const mapStateToProps = (state: StateInterface) => ({
  blePowerMeterName: state.devices.powerMeterDevice?.name,
  bleHeartRateMeterName: state.devices.heartRateMeterDevice?.name,
  blePowerMeterConnectionStatus: state.devices.blePowerMeterConnectionStatus,
  bleHeartRateMeterConnectionStatus: state.devices.bleHeartRateMeterConnectionStatus,
  bleSpeedCadenceSensorName: state.devices.speedCadenceSensorDevice?.name,
  bleSpeedCadenceSensorConnectionStatus: state.devices.bleSpeedCadenceSensorConnectionStatus,
  bleSmartTrainerName: state.devices.smartTrainerDevice?.name,
  bleSmartTrainerConnectionStatus: state.devices.bleSmartTrainerConnectionStatus,
  deviceData: state.devices.data,
  cp20m: state.settings.criticalPower,
  trainingType: state.trainingDetails.trainingType,
  trainingIsInitialized: state.activeTraining.isInitialized,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchForBleDevices: (deviceType: DeviceType) =>
    dispatch(DeviceCreators.searchForBleDevices(deviceType)),
  connectMockBle: () => dispatch(DeviceCreators.connectMockBle()),
  resumeTraining: () => dispatch(ActiveTrainingCreators.resumeTraining()),
});

export const BleConnectionModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BleConnectionModalComponent);
