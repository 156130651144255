import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../../../themes";
import { RFValue } from "../../../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: RFValue(8),
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    padding: RFValue(24),
    marginTop: RFValue(24),
    marginHorizontal: "15%",
  },
  buttonContainer: {
    padding: RFValue(12),
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: RFValue(8),
    borderColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
  },
});
