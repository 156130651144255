import { delay, put, takeLatest, race, call, take, select } from "redux-saga/effects";
import time from "../../utils/Time";
import { StateInterface } from "../reducers";
import TimerCreators, { TimerTypes } from "../reducers/timerReducer";

export function* globalTimer() {
  while (true) {
    yield put(TimerCreators.timerTick(time.getTime()));
    const dateTime: number = yield select((state: StateInterface) => state.timer.dateTime);
    const timeDelay = time.getTime() - dateTime;
    yield delay(1000 - timeDelay);
  }
}

export function* startTimer() {
  yield race({
    timer: call(globalTimer),
    stop: take(TimerTypes.PAUSE_TIMER),
  });
}

export function* timerSaga() {
  yield takeLatest(TimerTypes.START_TIMER, startTimer);
}
