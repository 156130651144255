import { StyleSheet } from "react-native";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(13),
    borderBottomWidth: RFValue(1),
    justifyContent: "center",
  },
  number: {
    marginRight: RFValue(12),
    fontWeight: "bold",
  },
  defaultText: {
    fontSize: RFValue(20),
    lineHeight: RFValue(30),
  },
});
