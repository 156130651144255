import React, { useRef, useCallback, useEffect } from "react";
import { View, TouchableOpacity } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/core";
import { useScrollIntoView } from "react-native-scroll-into-view";
import {
  PaymentFormInterface,
  ProductInterface,
  PurchaseDetailsInterface,
} from "../../../../../redux/types";
import { themedStyles } from "./styles";
import { DefaultText } from "../../../../CommonElements/DefaultText";
import { PaymentForm } from "../PaymentForm";
import { StackParams } from "../../../../../navigation/index.web";

type PropsType = {
  productsList: ProductInterface[] | null;
  selectedProduct: ProductInterface | null;
  buyProduct: (purchaseDetails: PurchaseDetailsInterface) => void;
  title?: string;
  isPaymentInProgress: boolean;
  paymentErrorMessage: string | null;
  paymentSuccessMessage?: string | null;
  isPaymentSuccess: boolean;
};

type NavigationProps = StackNavigationProp<StackParams, "Welcome">;

type OnPaymentSubmitProps = {
  selectedProduct: ProductInterface;
  paymentInfo: PaymentFormInterface;
};

export const ProductCards = ({
  productsList,
  selectedProduct,
  buyProduct,
  title,
  isPaymentInProgress,
  paymentErrorMessage,
  isPaymentSuccess,
}: PropsType) => {
  const todaysDate = new Date().getDate();
  const { setParams } = useNavigation<NavigationProps>();
  const refContainer = useRef<View>(null);
  const scrollIntoView = useScrollIntoView();

  const scrollToCurrentStep = useCallback(() => {
    if (refContainer.current) {
      scrollIntoView(refContainer.current);
    }
  }, [refContainer, scrollIntoView]);

  useEffect(() => {
    scrollToCurrentStep();
  }, [selectedProduct, scrollToCurrentStep]);

  const onProductHandler = (product: ProductInterface) => {
    if (product.id === selectedProduct?.id) {
      setParams({ productId: undefined });
    } else {
      setParams({ productId: product.id });
    }
  };

  const onPaymentSubmit = ({ paymentInfo, selectedProduct }: OnPaymentSubmitProps) => {
    const purchaseDetails: PurchaseDetailsInterface = {
      productId: selectedProduct.id,
      productType: selectedProduct.type,
      paymentInfo,
    };
    buyProduct(purchaseDetails);
  };

  return (
    <View style={themedStyles.container}>
      <DefaultText style={themedStyles.title}>{title}</DefaultText>
      <View style={themedStyles.productContainer}>
        {productsList?.map((product) => {
          const isSelectedProduct = product.id === selectedProduct?.id;
          const isLive = product.type === "service";
          const name = product.name.replace("PowerWatts Online", "");
          const isRenewal = product.auto_renewal;
          return (
            <View
              ref={isSelectedProduct ? refContainer : null}
              key={`product-id-${product.id}`}
              style={themedStyles.itemContainer}
            >
              <TouchableOpacity
                key={product.id}
                style={
                  isSelectedProduct
                    ? [themedStyles.productCardContainer, themedStyles.selectedCard]
                    : themedStyles.productCardContainer
                }
                onPress={() => onProductHandler(product)}
              >
                <DefaultText style={themedStyles.productName}>{name}</DefaultText>
                <DefaultText style={themedStyles.productPrice}>
                  {`$${product.price.toFixed(2)}CAD`}
                  {!isLive && isRenewal && (
                    <DefaultText style={themedStyles.productPrice}>{`/month`}</DefaultText>
                  )}
                </DefaultText>
                <DefaultText
                  style={themedStyles.productTotalPrice}
                >{`$${product.total}CAD incl tax`}</DefaultText>
                {!isLive && isRenewal && (
                  <DefaultText
                    style={themedStyles.paymentDescription}
                  >{`All future payments will be processed on the ${todaysDate} of each month`}</DefaultText>
                )}
                <DefaultText style={themedStyles.paymentDescription}>{`${
                  isRenewal ? "Each payment" : "Payment"
                } is ${product.price.toFixed(2)} + ${product.tax} tax = ${
                  product.total
                }CAD`}</DefaultText>
              </TouchableOpacity>
              {isSelectedProduct && selectedProduct && (
                <PaymentForm
                  onHandleSubmit={(paymentInfo) =>
                    onPaymentSubmit({ paymentInfo, selectedProduct })
                  }
                  isPaymentInProgress={isPaymentInProgress}
                  paymentErrorMessage={paymentErrorMessage}
                  isPaymentSuccess={isPaymentSuccess}
                />
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};
