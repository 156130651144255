import FontAwesome_ttf from "react-native-vector-icons/Fonts/FontAwesome.ttf";
import Entypo_ttf from "react-native-vector-icons/Fonts/Entypo.ttf";
import icomoon_ttf from "../fonts/icomoon.ttf";
import { Platform } from "react-native";

const IconsCSS = `
@font-face {
  src: url(${FontAwesome_ttf});
  font-family: FontAwesome;
}
@font-face {
  src: url(${Entypo_ttf});
  font-family: Entypo;
}
@font-face {
  src: url(${icomoon_ttf});
  font-family: Icomoon;
}
`;
if (Platform.OS === "web") {
  const style: any = document.createElement("style");
  style.type = "text/css";
  if (style.styleSheet) style.styleSheet.cssText = IconsCSS;
  else style.appendChild(document.createTextNode(IconsCSS));

  document.head.appendChild(style);
}
