import React, { ReactNode, useRef } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import { ToolTipWrapper } from "../..";
import { themedStyles } from "../styles";

interface PropsType {
  onPress: () => void;
  children: ReactNode;
  tooltip?: string;
}

export const TrainingItemButton = ({ onPress, children, tooltip }: PropsType) => {
  const buttonRef = useRef<View>(null);
  const isButtonHovered = useHover(buttonRef);

  const isTooltipEnabled = Platform.OS === "web" && tooltip;
  const Wrapper = isTooltipEnabled ? ToolTipWrapper : React.Fragment;
  const buttonStyles = isButtonHovered
    ? [themedStyles.iconContainer, themedStyles.hoveredButtonContainer]
    : themedStyles.iconContainer;

  return (
    <Wrapper
      {...(isTooltipEnabled && {
        text: tooltip,
      })}
    >
      <TouchableOpacity onPress={onPress}>
        <View ref={buttonRef} style={buttonStyles}>
          {children}
        </View>
      </TouchableOpacity>
    </Wrapper>
  );
};
