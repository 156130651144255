import { DeviceDataPoint } from "../../redux/types";
import { IParser, AntPageWindResistance, AntPageControlTrackResistance, AntPageUserConfiguration } from "./BleParserHelpers";


export class TacxDataParser implements IParser {
  userWeight: number;
  constructor(userWeight: number) {
    this.userWeight = userWeight;
  }

  GetDataPoint(dataview: DataView) {
    let offset = 0;
    //const syncByte =
    dataview.getUint8(offset);
    offset += 1;

    const messageLength = dataview.getUint8(offset);
    offset += 1;

    //const messageId =
    dataview.getUint8(offset);
    offset += 1;

    //const channel =
    dataview.getUint8(offset);
    offset += 1;

    //console.log('sync: ', syncByte, ' messageLength ', messageLength, ' messageId ', messageId, ' channel: ', channel);
    let data = new Uint8Array(messageLength - 1);
    for (let dataIndex = 0; dataIndex < messageLength - 1; dataIndex++) {
      const dataByte = dataview.getUint8(offset);
      offset++;
      data[dataIndex] = dataByte;
    }
    //console.log(data);
    //const checksum =
    dataview.getUint8(offset);
    //console.log('checksum', checksum);
    const dataPoint = this.ParseAntData(data);
    //console.log(dataPoint);
    return dataPoint;
  }

  ParseAntData(data: Uint8Array) {
    let deviceDataPoint: DeviceDataPoint = {
      cadence: null,
      power: null,
      speed: null,
      powerBalance: null,
      heartRate: null,
    };

    const dataPage = data[0];
    switch (dataPage) {
      case 25: // Specific Trainer/Stationary Bike Data
        //const updateEventCount = data[1];
        //console.log('update event', updateEventCount);
        const cadence = data[2];
        //const accumulatedPower = data[3] + (data[4] << 8);
        const power = data[5] + (data[6] & 0x0f) * 256;
        //const trainerStatus = data[6] >> 4;
        //const flagsBitField = data[7] & 0x0F;
        //const feStateBitField = data[7] >> 4;
        deviceDataPoint.cadence = cadence;
        deviceDataPoint.power = power;
        break;
      case 16: // General FE Data
        // Speed is in units of 0.001 m/s, convert to km/hour
        const SpeedConversion = 0.0036; // 60 sec/min * 60 min/hour / 1000 (0.001m/m) * 1000 m/km;



        //const equipmentType = data[1] & 0x1F;
        //const elapsedTime = data[2];
        //const distanceTraveled = data[3];
        const speed = (data[4] + (data[5] << 8)) * SpeedConversion;
        //const heartRate = data[6];
        //const capabilities = data[7] & 0x0F;
        //const feStateBitField = data[7] >> 4;
        deviceDataPoint.speed = speed;
        break;
      case AntPageWindResistance: // Wind Resistance
        const windResistanceCoefficient = data[5] * 0.01;
        const windSpeed = data[6] - 127;
        const draftingFactor = data[7] * 0.01;

        console.log(
          "Wind Resistance: coefficient: ",
          windResistanceCoefficient,
          " speed: ",
          windSpeed,
          " drafting factor: ",
          draftingFactor
        );
        break;
      case AntPageControlTrackResistance: // Track Resistance
        const grade = ((data[6] << 8) + data[5]) / 100 - 200;
        const crr = data[7] * 0.00005;

        console.log(
          "Track Resistance Page - Grade: ",
          grade,
          "%, Coefficient of Rolling Resistance: ",
          crr
        );
        break;
      case AntPageUserConfiguration: // User Configuration
        const userWeight = ((data[2] << 8) + data[1]) * 0.01; // weight in kg
        const wheelDiameterOffset = data[4] & 0x0f; // offset in mm
        const bikeWeight = ((data[5] << 4) + (data[4] >> 4)) * 0.05; // bike weight in kg
        const wheelDiameter = data[6] * 10; // wheel diameter in mm

        console.log(
          "User Configuration: weight: ",
          userWeight,
          "kg wheelDiameterOffset: ",
          wheelDiameterOffset,
          "mm bike weight: ",
          bikeWeight,
          "kg wheelDiameter: ",
          wheelDiameter,
          "mm"
        );
        break;
      case 71: // Command Status
        break;
      case 80: // Manufacturer's Identification
        break;
      case 81: // Product Information
        break;
      case 240:
        break;
      case 249:
        break;
      case 250:
        break;
      case 251:
        break;
      case 252:
        break;
      default:
      //console.log('UNKNOWN DATA PAGE', dataPage);
    }
    return deviceDataPoint;
  }
}
