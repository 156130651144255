import React, { ReactNode } from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";

export interface ButtonProps extends TouchableOpacityProps {
  text?: string;
  children?: ReactNode | string;
  isLoading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { style, children, text, ...rest } = props;
  const content = text || children;

  return (
    <TouchableOpacity
      {...rest}
      style={[themedStyles.container, style, props.disabled ? themedStyles.disabled : {}]}
    >
      {typeof content === "string" ? (
        <DefaultText style={themedStyles.text}>{content}</DefaultText>
      ) : (
        content
      )}
    </TouchableOpacity>
  );
};
