import { DeviceType } from "../../redux/types";

export const UnknownDeviceString = "Unknown Device";

export const MaxGradeDefault = 20;
export const WheelCircumference = 2.136; // meters, based on 700x28 tire
export const WheelDiameter = (WheelCircumference / 3.14159265359) * 1000; // wheel diameter in mm.  679.91 mm for a circumference of 2.136 meters.

export const SERVICE_UUID_CYCLING_POWER = "00001818-0000-1000-8000-00805f9b34fb";
export const SERVICE_UUID_HEART_RATE = "0000180d-0000-1000-8000-00805f9b34fb";
export const SERVICE_UUID_CYCLING_SPEED_AND_CADENCE = "00001816-0000-1000-8000-00805f9b34fb";
export const SERVICE_UUID_FTMS = "00001826-0000-1000-8000-00805f9b34fb";
export const SERVICE_UUID_TACX_TRAINER_CONTROL = "6e40fec1-b5a3-f393-e0a9-e50e24dcca9e";
export const SERVICE_UUID_USER_DATA = "0000181c-0000-1000-8000-00805f9b34fb";
export const SERVICE_UUID_WATTBIKE_ATOM_PROPRIETARY = "b4cc1223-bc02-4cae-adb9-1217ad2860d1";
export const SERVICE_UUID_WATTBIKE_ATOMX_PROPRIETARY = "babf1723-cedb-444c-88c3-c672c7a59806";

export const CHARACTERISTIC_UUID_CYCLING_POWER_MEASUREMENT = "00002a63-0000-1000-8000-00805f9b34fb";
export const CHARACTERISTIC_UUID_HEART_RATE_MEASUREMENT = "00002a37-0000-1000-8000-00805f9b34fb";
export const CHARACTERISTIC_UUID_CSC_MEASUREMENT = "00002a5b-0000-1000-8000-00805f9b34fb";
export const CHARACTERISTIC_UUID_INDOOR_BIKE_DATA = "00002ad2-0000-1000-8000-00805f9b34fb";
export const CHARACTERISTIC_UUID_FTMS_CONTROL_POINT = "00002ad9-0000-1000-8000-00805f9b34fb";
export const CHARACTERISTIC_UUID_TACX_TRAINER_CONTROL_RX = "6e40fec2-b5a3-f393-e0a9-e50e24dcca9e";
export const CHARACTERISTIC_UUID_TACX_TRAINER_CONTROL_TX = "6e40fec3-b5a3-f393-e0a9-e50e24dcca9e";
export const CHARACTERISTIC_UUID_WAHOO_LEGACY_CONTROL = "a026e005-0a7d-4ab3-97fa-f1500f9feb8b";
export const CHARACTERISTIC_UUID_WEIGHT = "00002a98-0000-1000-8000-00805f9b34fb";
export const CHARACTERISTIC_UUID_HEIGHT = "00002a8e-0000-1000-8000-00805f9b34fb";
export const CHARACTERISTIC_UUID_WATTBIKE_ATOM_READ = "b4cc1224-bc02-4cae-adb9-1217ad2860d1";
export const CHARACTERISTIC_UUID_WATTBIKE_ATOM_WRITE = "b4cc1225-bc02-4cae-adb9-1217ad2860d1";
export const CHARACTERISTIC_UUID_WATTBIKE_ATOMX_READ = "babf1724-cedb-444c-88c3-c672c7a59806";
export const CHARACTERISTIC_UUID_WATTBIKE_ATOMX_WRITE = "babf1725-cedb-444c-88c3-c672c7a59806";

// Constants for various data tweaks based on device name
export const NamePowerTapP1L = "powertap P1.L";
export const NamePowerTapP1R = "powertap P1.R";

const frontalSurfaceArea = 0.51; // Value from Mark
const dragCoefficient = 0.63; // Value from Mark
const airDensity = 1.275; //kg/m3 (default)
export const WindResistanceCoefficient = frontalSurfaceArea * dragCoefficient * airDensity;
export const DraftingFactor = 1.0; // 1.0 indicates no drafting
export const RollingResistanceCoeff = 0.004;

export async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Returns an array of possible service UUIDs for the given device type.
export function getDeviceServiceUuids(deviceType: DeviceType): string[] {
  switch (deviceType) {
    case DeviceType.PowerMeterDevice:
      return [SERVICE_UUID_CYCLING_POWER];
    case DeviceType.HeartRateMeterDevice:
      return [SERVICE_UUID_HEART_RATE];
    case DeviceType.SpeedCadenceSensor:
      return [SERVICE_UUID_CYCLING_SPEED_AND_CADENCE];
    case DeviceType.SmartTrainer:
      return [SERVICE_UUID_TACX_TRAINER_CONTROL, SERVICE_UUID_CYCLING_POWER, SERVICE_UUID_FTMS];
    default:
      throw new Error("Invalid DeviceType");
  }
}

export function getDeviceCharacteristicUuid(serviceUuid: string) {
  switch (serviceUuid) {
    case SERVICE_UUID_CYCLING_POWER:
      return CHARACTERISTIC_UUID_CYCLING_POWER_MEASUREMENT;
    case SERVICE_UUID_HEART_RATE:
      return CHARACTERISTIC_UUID_HEART_RATE_MEASUREMENT;
    case SERVICE_UUID_CYCLING_SPEED_AND_CADENCE:
      return CHARACTERISTIC_UUID_CSC_MEASUREMENT;
    case SERVICE_UUID_TACX_TRAINER_CONTROL:
      return CHARACTERISTIC_UUID_TACX_TRAINER_CONTROL_RX;
    case SERVICE_UUID_FTMS:
      return CHARACTERISTIC_UUID_INDOOR_BIKE_DATA;
    default:
      throw new Error("Invalid DeviceType");
  }
}

// This function takes a device name and returns true if that device is a KICKR device
// based on the device name.  It returns false otherwise.
export function isKickrDevice(deviceName: string | null) {
  if (deviceName?.startsWith("KICKR CORE") ||
    deviceName?.startsWith("KICKR SNAP") ||
    deviceName?.startsWith("Wahoo KICKR") ||
    deviceName?.startsWith("KICKR BIKE")) {
    return true;
  }

  return false;
}

export function determineDeviceType(services: BluetoothRemoteGATTService[]) {
  if (services.some((o) => o.uuid === SERVICE_UUID_TACX_TRAINER_CONTROL)) {
    return DeviceType.SmartTrainer;
  }

  if (services.some((o) => o.uuid === SERVICE_UUID_CYCLING_POWER)) {
    return DeviceType.PowerMeterDevice;
  }

  if (services.some((o) => o.uuid === SERVICE_UUID_HEART_RATE)) {
    return DeviceType.HeartRateMeterDevice;
  }

  if (services.some((o) => o.uuid === SERVICE_UUID_CYCLING_SPEED_AND_CADENCE)) {
    return DeviceType.SpeedCadenceSensor;
  }

  return DeviceType.UnknownDevice;
}


