import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  modalContainer: {
    alignItems: "center",
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
    paddingVertical: RFValue(24),
    paddingHorizontal: RFValue(84),
    borderRadius: RFValue(8),
  },
  buttonsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: RFValue(14),
  },
  button: {
    padding: RFValue(14),
    borderRadius: RFValue(8),
    paddingHorizontal: RFValue(24),
  },
  confirmButton: {
    backgroundColor: MAIN_THEME.DEFAULT?.CONFORMING_BACKGROUND_COLOR,
  },
  rejectingButton: {
    backgroundColor: MAIN_THEME.DEFAULT?.REJECTING_BACKGROUND_COLOR,
  },
});
