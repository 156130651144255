import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { COMMON_STYLES } from "../../themes/commonStyles";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...COMMON_STYLES.BOTTOM_SHADOW,
  },
  stepsIndicatorContainer: {
    flex: 3,
    height: "100%",
  },
  timerContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    paddingHorizontal: "0.5vw",
    height: "9vh",
  },
  timer: {
    fontSize: RFValue(29),
    fontWeight: "500",
  },
  centralTimerBlock: {
    borderRadius: RFValue(4),
    paddingVertical: RFValue(16),
    overflow: "hidden",
    width: "12vw",
    minHeight: "10vh",
    justifyContent: "center",
    alignItems: "center",
  },
  centralTimer: {
    color: MAIN_THEME.DEFAULT?.TEXT_COLOR,
    fontWeight: "600",
    fontSize: RFValue(48),
    zIndex: 999,
  },
  stepInfoContainer: { justifyContent: "center", alignItems: "center" },
});
