import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Picker, TextInput, View } from "react-native";
import { WorkoutInterface, WORKOUT_CATEGORY } from "../../redux/types";
import { themedStyles } from "./styles";
import { DefaultText } from "../CommonElements/DefaultText";
import { TrainingItem } from "../TrainingItem";
import { ICON } from "../../constants";

interface PropsType {
  workouts: WorkoutInterface[] | undefined;
  disableSearchLine?: boolean;
  disableDropdown?: boolean;
  defaultCategory?: WORKOUT_CATEGORY;
}

interface ISearchObj {
  text: string;
  category: WORKOUT_CATEGORY;
}

export function VirtualCoachTab({
  workouts,
  disableDropdown,
  disableSearchLine,
  defaultCategory,
}: PropsType) {
  const workoutsCount = workouts?.length;
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState(WORKOUT_CATEGORY.ALL_CATEGORIES);
  const [filteredDataSource, setFilteredDataSource] = useState(workouts);
  const categories = workouts?.map((item) => item.category);
  const categoriesList = categories?.filter((item, index) => categories?.indexOf(item) === index);

  useEffect(() => {
    setFilteredDataSource(workouts);
  }, [workouts]);

  useEffect(() => {
    if (defaultCategory) {
      setSearchCategory(defaultCategory);
    } else {
      setSearchCategory(WORKOUT_CATEGORY.ALL_CATEGORIES);
    }
  }, [defaultCategory]);

  const searchFilterFunction = useCallback(
    ({ text, category }: ISearchObj) => {
      const initialWorkoutList: WorkoutInterface[] = [];
      const categoryData =
        category === WORKOUT_CATEGORY.ALL_CATEGORIES
          ? workouts || initialWorkoutList
          : (workouts || initialWorkoutList).filter((item) => {
              const itemData = item.category ? item.category : WORKOUT_CATEGORY.ALL_CATEGORIES;
              return itemData.indexOf(category) > -1;
            });
      if (text) {
        const newData = categoryData.filter((item) => {
          const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
          const textData = text.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
        setFilteredDataSource(newData);
        setSearchText(text);
      } else {
        setFilteredDataSource(categoryData);
        setSearchText(text);
      }
    },
    [workouts, setFilteredDataSource, setSearchText],
  );

  useEffect(() => {
    searchFilterFunction({ text: searchText, category: searchCategory });
  }, [searchText, searchCategory, searchFilterFunction]);

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.trainWithCoach}>
        <View style={themedStyles.searchContainer}>
          {!disableSearchLine && (
            <TextInput
              style={themedStyles.searchInput}
              onChangeText={(text) => setSearchText(text)}
              value={searchText}
              underlineColorAndroid="transparent"
              placeholder="Search for a training"
            />
          )}
          {!disableDropdown && (
            <Picker
              style={[themedStyles.searchInput, themedStyles.pikerLine]}
              onValueChange={(category) => {
                setSearchCategory(category);
              }}
              selectedValue={searchCategory}
            >
              <Picker.Item
                label={WORKOUT_CATEGORY.ALL_CATEGORIES}
                value={WORKOUT_CATEGORY.ALL_CATEGORIES}
              />
              {categoriesList?.map((item) => (
                <Picker.Item label={`${item}`} value={item} key={item} />
              ))}
            </Picker>
          )}
        </View>
        {workouts && (
          <FlatList
            keyExtractor={(item) => item.id}
            data={filteredDataSource}
            ListHeaderComponent={() => (
              <DefaultText style={themedStyles.remark}>All workouts ({workoutsCount})</DefaultText>
            )}
            renderItem={({ item }) => (
              <TrainingItem
                icon={ICON.PAD}
                trainingName={item.name}
                category={item.category}
                virtualCoach={item.virtual_coach}
                wid={item.id}
                coachNoteId={item.wid}
                supportedLanguages={item.supported_languages}
              />
            )}
          />
        )}
      </View>
    </View>
  );
}
