import React from "react";
import { TouchableOpacity, View, ViewStyle } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../index";
import { WelcomeTabInterface } from "../../screens";

interface PropsType {
  tabsComponentArr: WelcomeTabInterface[];
  defaultActiveTabIndex?: number;
  tabIndex?: number;
  style?: {
    container?: ViewStyle | ViewStyle[] | [ViewStyle, undefined];
  };
  subTabIndex: number | null;
}

export function TabsComponent({
  tabsComponentArr,
  tabIndex = 0,
  subTabIndex,
  style: propStyle = {},
}: PropsType) {
  const currentTab =
    subTabIndex === null
      ? tabsComponentArr[tabIndex]
      : tabsComponentArr[tabIndex]?.components?.[subTabIndex] || tabsComponentArr[tabIndex];

  if (!currentTab) {
    return null;
  }
  return (
    <View style={[themedStyles.contentContainer, propStyle.container]}>
      <View style={themedStyles.headerTabsMenu}>
        <TouchableOpacity style={[themedStyles.tabContainer, themedStyles.activeTabContainer]}>
          <DefaultText style={[themedStyles.tabText, themedStyles.activeTabText]}>
            {currentTab.title}
          </DefaultText>
        </TouchableOpacity>
      </View>
      {currentTab.component}
    </View>
  );
}
