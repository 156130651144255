import React from "react";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StateInterface } from "../../../redux/reducers";
import { themedStyles } from "./styles";
import WorkoutCreators from "../../../redux/reducers/workoutsReducer";
import HTML from "react-native-render-html";
import { DefaultText } from "../../index";
import { RFValue } from "../../../utils/resizeHelper";
import { IconButton } from "../../CommonElements";
import { ICON } from "../../../constants";
import { MAIN_THEME } from "../../../themes";

type PropsTypes = {
  closeNotesModal: () => void;
};

function NotesModalComponent({ notes, closeNotesModal, clearNotes }: CombinedProps) {
  const onCloseButtonPress = () => {
    closeNotesModal();
    clearNotes();
  };
  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.modalContainer}>
        <IconButton
          oneButton={{
            icon: ICON.CROSS,
            onPress: onCloseButtonPress,
            color: MAIN_THEME.HEADER.ALTERNATIVE_ICON_COLOR,
            size: 24,
          }}
          style={themedStyles.closeIcon}
        />
        {notes?.html ? (
          <HTML source={{ html: notes?.html }} contentWidth={RFValue(1000)} />
        ) : (
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <ActivityIndicator size="small" />
          </View>
        )}

        <TouchableOpacity onPress={onCloseButtonPress} style={themedStyles.button}>
          <DefaultText style={themedStyles.buttonText}>Close</DefaultText>
        </TouchableOpacity>
      </View>
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PropsTypes;

const mapStateToProps = (state: StateInterface) => ({
  notes: state.workouts.notes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearNotes: () => dispatch(WorkoutCreators.clearNotes()),
});

export const NotesModal = connect(mapStateToProps, mapDispatchToProps)(NotesModalComponent);
