import React, { memo, useMemo, useState } from "react";
import countryList from "react-select-country-list";
import { UpdatedUserProfileInterface, UserState } from "../../../../redux/types";
import { Formik } from "formik";
import { View, ScrollView, Platform } from "react-native";

import { DefaultText, PickerItem, InputItem, DatePickerItem, Button } from "../../../index";
import { themedStyles } from "./styles";
import { validation, validationObjectType } from "./hooks";
import { FETCHING_STATUS } from "../../../../constants";
import { Event } from "../../../../services/googleAnalyticsTracking";
import { LIMIT_VALUES } from "../../../../constants";

interface PropsTypes {
  user: UserState;
  cp20m: number;
  updateStatus: FETCHING_STATUS | null;
  updateUserProfile: (updatedUserProfile: UpdatedUserProfileInterface) => void;
  hideUpdateProfileModal: () => void;
}

function UserProfile({
  user,
  cp20m,
  updateUserProfile,
  hideUpdateProfileModal,
  updateStatus,
}: PropsTypes) {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [validationObject, setValidationObject] = useState<validationObjectType>({
    firstName: { isValid: true },
    lastName: { isValid: true },
    nickname: { isValid: true },
    sex: { isValid: true },
    country: { isValid: true },
    birthDate: { isValid: true },
    height: { isValid: true },
    weight: { isValid: true },
    cp20m: { isValid: true },
  });

  const onSubmitHandler = (values: typeof initialFormValues) => {
    const [isValidForm, validObject] = validation(values);
    if (!isValidForm) {
      setValidationObject(validObject);
      return;
    }
    const heightToMeter =
      parseFloat(values.height) < 10 ? values.height : (parseFloat(values.height) / 100).toFixed(2);

    const updatedUserProfile: UpdatedUserProfileInterface = {
      Name: `${values.firstName} ${values.lastName}`,
      Nickname: values.nickname,
      BirthDate: values.birthDate,
      Sex: values.sex,
      Height: parseFloat(heightToMeter),
      Weight: parseFloat(values.weight),
      Cp20M: parseFloat(values.cp20m),
      Country: values.country,
      ProfileCompleted: true,
    };
    updateUserProfile(updatedUserProfile);
    Event("click", {
      page: "home",
      component: "UserProfile",
      target: "introduction workout",
      workout_name: "item.name",
    });
  };

  const { cpxProfile } = user;
  if (cpxProfile === null) {
    return null;
  }

  const initFirstName = cpxProfile?.name?.split(" ")[0];
  const initLastName = cpxProfile?.name?.split(" ")[1];

  const initialFormValues = {
    firstName: initFirstName || "",
    lastName: initLastName || "",
    nickname: cpxProfile.cpxNickname || "",
    sex: cpxProfile.isMale ? "male" : "female",
    country: cpxProfile.country || "",
    birthDate: cpxProfile.birthDate || "",
    height: cpxProfile.height?.toFixed(2) || "",
    weight: cpxProfile.weight?.toFixed() || "",
    cp20m: cp20m.toString(),
  };
  const pickerOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  let pickerPlaceholder: { label?: string; value?: string };
  let countryPickerPlaceholder: { label?: string; value?: string };

  if (Platform.OS !== "android") {
    pickerPlaceholder = cpxProfile.isMale ? pickerOptions[0] : pickerOptions[1];
    countryPickerPlaceholder = countryOptions.find(
      (country) => country.value === cpxProfile.country,
    ) || { label: "Please Choose your Country", value: "" };
  } else {
    pickerPlaceholder = {};
    countryPickerPlaceholder = { label: "Please Choose your Country", value: "" };
  }

  const renderMessage = () => {
    switch (updateStatus) {
      case FETCHING_STATUS.SUCCESS:
        return (
          <DefaultText style={themedStyles.successMessage}>
            Profile successfully updated
          </DefaultText>
        );
      case FETCHING_STATUS.FAIL:
        return <DefaultText style={themedStyles.failMessage}>Fail to update profile</DefaultText>;
      default:
        return null;
    }
  };

  const autoCorrect = (values: typeof initialFormValues) => {
    if (+values.height < +LIMIT_VALUES.MIN_HEIGHT) {
      values.height = LIMIT_VALUES.MIN_HEIGHT;
    }
    if (+values.height > +LIMIT_VALUES.MAX_HEIGHT) {
      values.height = LIMIT_VALUES.MAX_HEIGHT;
    }
    values.height = (+values.height).toFixed(2).toString();

    if (+values.weight < +LIMIT_VALUES.MIN_WEIGHT) {
      values.weight = LIMIT_VALUES.MIN_WEIGHT;
    }
    if (+values.weight > +LIMIT_VALUES.MAX_WEIGHT) {
      values.weight = LIMIT_VALUES.MAX_WEIGHT;
    }
    values.weight = (+values.weight).toFixed(1).toString();

    if (+values.cp20m < +LIMIT_VALUES.MIN_CP20M) {
      values.cp20m = LIMIT_VALUES.MIN_CP20M;
    }
    if (+values.cp20m > +LIMIT_VALUES.MAX_CP20M) {
      values.cp20m = LIMIT_VALUES.MAX_CP20M;
    }
    values.cp20m = (+values.cp20m).toFixed().toString();
  };

  return (
    <View style={themedStyles.container}>
      <Formik initialValues={initialFormValues} onSubmit={onSubmitHandler} validate={autoCorrect}>
        {({ handleChange, handleBlur, handleSubmit, values }) => {
          return (
            <ScrollView
              style={themedStyles.formContainer}
              contentContainerStyle={themedStyles.formContentContainer}
              persistentScrollbar
              scrollEventThrottle={400}
              showsVerticalScrollIndicator={false}
            >
              <InputItem
                itemText="First Name:"
                itemName="firstName"
                itemValue={values.firstName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isValid={validationObject.firstName?.isValid}
              />
              {!validationObject.firstName?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.firstName.message}
                </DefaultText>
              )}
              <InputItem
                itemText="Last Name:"
                itemName="lastName"
                itemValue={values.lastName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isValid={validationObject.lastName?.isValid}
              />
              {!validationObject.lastName?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.lastName.message}
                </DefaultText>
              )}
              <InputItem
                itemText="Nickname:"
                itemName="nickname"
                itemValue={values.nickname}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isValid={validationObject.nickname?.isValid}
              />
              {!validationObject.nickname?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.nickname.message}
                </DefaultText>
              )}
              <PickerItem
                itemText="Gender:"
                itemName="sex"
                handleChange={handleChange}
                pickerOptions={pickerOptions}
                placeholder={pickerPlaceholder}
                isValid={validationObject.sex?.isValid}
              />
              {!validationObject.sex?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.sex.message}
                </DefaultText>
              )}
              <PickerItem
                itemText="Country:"
                itemName="country"
                handleChange={handleChange}
                pickerOptions={countryOptions}
                placeholder={countryPickerPlaceholder}
                isValid={validationObject.country?.isValid}
              />
              {!validationObject.country?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.country.message}
                </DefaultText>
              )}
              <DatePickerItem
                itemText="Birth Date:"
                itemName="birthDate"
                itemValue={values.birthDate}
                handleChange={handleChange}
                isValid={validationObject.birthDate?.isValid}
              />
              {!validationObject.birthDate?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.birthDate.message}
                </DefaultText>
              )}
              <InputItem
                maskType="custom"
                maskOptions={{ mask: "9.99" }}
                itemText="Height (m):"
                itemName="height"
                itemValue={values.height}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isValid={validationObject.height?.isValid}
                keyboardType="number-pad"
              />
              {!validationObject.height?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.height.message}
                </DefaultText>
              )}
              <InputItem
                maskType="custom"
                // maskOptions={{ mask: "999.9" }}
                itemText="Weight (kg):"
                itemName="weight"
                itemValue={values.weight}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isValid={validationObject.weight?.isValid}
                keyboardType="number-pad"
              />
              {!validationObject.weight?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.weight.message}
                </DefaultText>
              )}
              <InputItem
                itemText="CP20m:"
                itemName="cp20m"
                itemValue={values.cp20m}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isValid={validationObject.cp20m?.isValid}
                keyboardType="number-pad"
              />
              {!validationObject.cp20m?.isValid && (
                <DefaultText style={themedStyles.erMessage}>
                  {validationObject.cp20m.message}
                </DefaultText>
              )}
              {renderMessage()}
              <View style={themedStyles.buttonsContainer}>
                <Button
                  style={themedStyles.cancelButton}
                  onPress={() => hideUpdateProfileModal()}
                  text="Cancel"
                />
                <Button
                  style={themedStyles.buttonTouchable}
                  onPress={() => handleSubmit()}
                  disabled={updateStatus === FETCHING_STATUS.PROCESSED}
                  text="Save"
                />
              </View>
            </ScrollView>
          );
        }}
      </Formik>
    </View>
  );
}

export default memo(UserProfile);
