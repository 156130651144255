import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  contentContainer: {
    height: "100vh",
    width: "100%",
    paddingTop: RFValue(24),
    overflow: "hidden",
    paddingBottom: RFValue(24),
  },
  headerTabsMenu: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: RFValue(16),
  },
  tabText: {
    fontWeight: "bold",
    fontSize: RFValue(20),
    lineHeight: RFValue(30),
    paddingHorizontal: RFValue(16),
    paddingBottom: RFValue(9),
    textAlign: "center",
  },
  tabContainer: {
    flex: 1,
    alignItems: "center",
    width: "100%",
    borderBottomWidth: RFValue(2),
    borderBottomColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
  },
  activeTabContainer: {
    borderBottomColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
    borderBottomWidth: RFValue(3),
  },
  activeTabText: {
    color: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
  },
  loaderContainer: { flex: 1, justifyContent: "center" },
});
