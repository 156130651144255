import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { ICON } from "../../constants";
import { WorkoutIntervalInterface, INTERVAL_TYPE } from "../../redux/types";
import { themedStyles } from "./styles";
import { round, secondsToTime } from "../../utils/Statistics";
import { DefaultText, MyIcon } from "../index";
import { getResponsiveStyle } from "./styles";

type StepBlockPropsType = {
  step: WorkoutIntervalInterface | null;
  type?: "current" | "next";
};

interface RenderLineIconProps {
  iconName: string;
  condition?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  iconSizeInPx?: number;
  color?: string;
}

interface StepTextProps {
  stepText: string;
  condition?: boolean;
}

const SHORT_TEXT = {
  CALIBRATE: "Calibrate",
  GET_READY: "Get Ready",
  PREVIEW: "Preview",
  COURSE: " COURSE",
  SUMMARY: " Summary",
};

export const StepInfoLine = ({ step, type }: StepBlockPropsType) => {
  const responseStyle = getResponsiveStyle(type);
  const {
    containerStyle,
    iconColor,
    lineStyle,
    iconSize,
    lightingSize,
    targetSize,
    eyeSize,
    stopwatchSize,
    fireSize,
    plusSize,
    rocketSize,
  } = responseStyle;
  if (!step) {
    return <View></View>;
  }
  const stepDuration = secondsToTime(step?.durationInSeconds);
  const isStandingStep = step.shortText.includes("\uf148");
  const isSittingStep = step.shortText.includes("\uf149");
  const isGetReadyStep = step.shortText.includes(SHORT_TEXT.GET_READY);
  const isPreviewStep = step.shortText.includes(SHORT_TEXT.PREVIEW);
  const isCourseStep = step.shortText.includes(SHORT_TEXT.COURSE);
  const isSummaryStep = step.shortText.includes(SHORT_TEXT.SUMMARY);
  const relativePowerLine =
    step.powerWindowLow === -2
      ? `>${round(step.relativePower, 0)}%`
      : step.cadenceWindowLow === -1
      ? `<${round(step.relativePower, 0)}%`
      : !!step.rampUp
      ? `${step.rampUp[0]}-${step.rampUp[1]}%`
      : `${round(step.relativePower, 0)}%`;
  const stepCadenceLine = `${
    step.cadenceWindowLow === -2 ? ">" : step.cadenceWindowHigh === -1 ? "<" : ""
  }${step.cadence || ""}`;

  const StepText = ({ stepText, condition = true }: StepTextProps) => {
    if (condition) {
      return <DefaultText style={lineStyle}>{stepText}</DefaultText>;
    } else return null;
  };

  const LineIcon = ({
    iconName,
    condition = true,
    containerStyle = themedStyles.iconContainer,
    iconSizeInPx = iconSize,
    color = iconColor,
  }: RenderLineIconProps) => {
    if (condition) {
      return (
        <View style={containerStyle}>
          <MyIcon icon={iconName} color={color} size={iconSizeInPx} />
        </View>
      );
    } else return null;
  };

  const renderStepSlope = () => {
    if (!!step.slope) {
      return (
        <>
          <LineIcon iconName={ICON.STATS_DOTS} />
          <StepText stepText={`${round(step.slope, 1)}%`} />
        </>
      );
    } else {
      return null;
    }
  };

  switch (step.intervalType) {
    case INTERVAL_TYPE.POWER:
    case INTERVAL_TYPE.TIZ:
    case INTERVAL_TYPE.AGR:
      return (
        <View style={containerStyle}>
          <StepText stepText={stepDuration} />
          <LineIcon
            iconName={ICON.EYE}
            condition={step.visualExperience?.display_rider_metrics === false}
            iconSizeInPx={eyeSize}
          />
          <LineIcon
            iconName={ICON.FIRE}
            condition={step.intervalType === INTERVAL_TYPE.AGR}
            iconSizeInPx={fireSize}
          />
          <LineIcon
            iconName={ICON.STOPWATCH}
            condition={step.intervalType === INTERVAL_TYPE.TIZ}
            iconSizeInPx={stopwatchSize}
          />
          <LineIcon iconName={ICON.LEVEL_UP} condition={isStandingStep} />
          <LineIcon iconName={ICON.LEVEL_DOWN} condition={isSittingStep} />
          <LineIcon
            iconName={ICON.LIGHTING}
            iconSizeInPx={lightingSize}
            containerStyle={themedStyles.lightingContainer}
          />
          <StepText stepText={relativePowerLine} />
          <StepText
            stepText={`±${step.powerWindowHigh}w`}
            condition={step.intervalType === INTERVAL_TYPE.TIZ}
          />
          {renderStepSlope()}
          <StepText condition={isCourseStep} stepText={SHORT_TEXT.COURSE} />
        </View>
      );

    case INTERVAL_TYPE.POWER_CADENCE:
    case INTERVAL_TYPE.TAR:
    case INTERVAL_TYPE.SPR:
      return (
        <View style={containerStyle}>
          <StepText stepText={stepDuration} />
          <LineIcon
            iconName={ICON.EYE}
            condition={step.visualExperience?.display_rider_metrics === false}
          />
          <LineIcon
            iconName={ICON.ROCKET}
            condition={step.intervalType === INTERVAL_TYPE.SPR}
            iconSizeInPx={rocketSize}
          />
          <LineIcon
            iconName={ICON.TARGET}
            condition={step.intervalType === INTERVAL_TYPE.TAR}
            iconSizeInPx={targetSize}
          />
          <LineIcon iconName={ICON.LEVEL_UP} condition={isStandingStep} />
          <LineIcon iconName={ICON.LEVEL_DOWN} condition={isSittingStep} />
          <LineIcon
            iconName={ICON.LIGHTING}
            iconSizeInPx={lightingSize}
            containerStyle={themedStyles.lightingContainer}
          />
          <StepText stepText={relativePowerLine} />
          <LineIcon condition={!!step.cadence} iconName={ICON.SETTINGS} />
          <StepText condition={!!step.cadence} stepText={stepCadenceLine} />
          {renderStepSlope()}
          <StepText condition={isCourseStep} stepText={SHORT_TEXT.COURSE} />
        </View>
      );

    case INTERVAL_TYPE.RECOVER:
      return (
        <View style={containerStyle}>
          <StepText stepText={stepDuration} />
          <StepText condition={isSummaryStep} stepText={SHORT_TEXT.SUMMARY} />
          <LineIcon
            iconName={ICON.PLUS}
            iconSizeInPx={plusSize}
            containerStyle={themedStyles.iconPlus}
          />
          {renderStepSlope()}
          <StepText condition={isGetReadyStep} stepText={SHORT_TEXT.GET_READY} />
          <StepText condition={isPreviewStep} stepText={SHORT_TEXT.PREVIEW} />
          <StepText condition={isCourseStep} stepText={SHORT_TEXT.COURSE} />
        </View>
      );

    case INTERVAL_TYPE.SEGMENT_BASED:
      return (
        <View style={containerStyle}>
          <StepText stepText={stepDuration} />
          <StepText condition={isCourseStep} stepText={SHORT_TEXT.COURSE} />
        </View>
      );

    case INTERVAL_TYPE.POWER_SLOPE:
    case INTERVAL_TYPE.KOD:
    case INTERVAL_TYPE.KOM:
      return (
        <View style={containerStyle}>
          <StepText stepText={stepDuration} />
          <LineIcon iconName={ICON.KOD} condition={step.intervalType === INTERVAL_TYPE.KOD} />
          <LineIcon iconName={ICON.KOM} condition={step.intervalType === INTERVAL_TYPE.KOM} />
          <LineIcon
            iconName={ICON.LIGHTING}
            iconSizeInPx={lightingSize}
            containerStyle={themedStyles.lightingContainer}
          />
          <StepText stepText={relativePowerLine} />
          {renderStepSlope()}
          <LineIcon iconName={ICON.LEVEL_UP} condition={isStandingStep} />
          <LineIcon iconName={ICON.LEVEL_DOWN} condition={isSittingStep} />
          <StepText condition={isCourseStep} stepText={SHORT_TEXT.COURSE} />
        </View>
      );

    default:
      return (
        <View style={containerStyle}>
          <StepText stepText={stepDuration} />
          <LineIcon
            iconName={ICON.LIGHTING}
            iconSizeInPx={lightingSize}
            containerStyle={themedStyles.lightingContainer}
          />
          <StepText stepText={relativePowerLine} />
          {(step.cadenceWindowHigh === -1 || step.cadenceWindowLow === -2) && (
            <>
              <LineIcon iconName={ICON.SETTINGS} />
              <StepText stepText={stepCadenceLine} />
            </>
          )}
          {renderStepSlope()}
          <LineIcon iconName={ICON.LEVEL_UP} condition={isStandingStep} />
          <LineIcon iconName={ICON.LEVEL_DOWN} condition={isSittingStep} />
          <StepText condition={isCourseStep} stepText={SHORT_TEXT.COURSE} />
        </View>
      );
  }
};
