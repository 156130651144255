import React from "react";
import { TextStyle, TouchableOpacity, ViewStyle } from "react-native";
import { DefaultText, MyIcon } from "../../CommonElements";
import { themedStyles } from "./styles";

interface PropsType {
  icon: string;
  iconColor?: string | null;
  isSelected?: boolean;
  text: string;
  style?: {
    container?: ViewStyle | ViewStyle[] | [ViewStyle, undefined];
    text?: TextStyle | undefined;
  };
  onPress?: () => void;
}

export function SideBarItem({
  icon,
  text,
  isSelected,
  iconColor,
  style: propStyle = {},
  onPress,
  ...props
}: PropsType) {
  const styles = isSelected
    ? [themedStyles.container, themedStyles.selectedContainer, propStyle.container]
    : [themedStyles.container, propStyle.container];
  return (
    <TouchableOpacity onPress={onPress} style={styles} {...props}>
      <MyIcon icon={icon} color={iconColor || undefined} />
      <DefaultText style={[themedStyles.text, propStyle.text]}>{text}</DefaultText>
    </TouchableOpacity>
  );
}
