import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import { getCourse } from "../../utils/SvgHelpers";
import {
  ActiveTrainingState,
  TrainingPlanInterface,
  WorkoutIntervalInterface,
  SetTrainingPlanAction,
  SetCurrentStepsAction,
  SetCurrentCourseStepsAction,
  ChangeTrainingStatusAction,
  ResetActiveTrainingAction,
  TRAINING_STATUS_TYPES,
  SetCoachTrainingStatusAction,
  CourseSegmentStepInterface,
  FinishCourseStepAction,
  COURSE_MOVEMENT_TYPE,
} from "../types";
import { CoachControlsTypes } from "./coachControlsReducer";

interface ActionTypesInterface {
  SET_TRAINING_PLAN: "SET_TRAINING_PLAN";
  SET_CURRENT_STEPS: "SET_TRAINING_DETAILS";
  SET_CURRENT_COURSE_STEPS: "SET_CURRENT_COURSE_STEPS";
  CHANGE_TRAINING_STATUS: "CHANGE_TRAINING_STATUS";
  PENDING_START_TRAINING: "PENDING_START_TRAINING";
  START_TRAINING: "START_TRAINING";
  PAUSE_TRAINING: "PAUSE_TRAINING";
  RESUME_TRAINING: "RESUME_TRAINING";
  FINISH_TRAINING: "FINISH_TRAINING";
  FINISH_LIVE_TRAINING: "FINISH_LIVE_TRAINING";
  NEXT_STEP: "NEXT_STEP";
  NEXT_COURSE_STEP: "NEXT_COURSE_STEP";
  FINISH_COURSE_STEP: "FINISH_COURSE_STEP";
  RESET_ACTIVE_TRAINING: "RESET_ACTIVE_TRAINING";
}

interface ActionCreatorsInterface extends ActionCreators {
  setTrainingPlan: (plan: TrainingPlanInterface) => AnyAction;
  setCurrentSteps: (
    currentStep: WorkoutIntervalInterface,
    nextStep: WorkoutIntervalInterface,
    currentStepIndex: number,
    currentCourseStep: CourseSegmentStepInterface | null,
    nextCourseStep: CourseSegmentStepInterface | null,
    currentCourseStepIndex: number | null,
  ) => AnyAction;
  setCurrentCourseSteps: (
    currentCourseStep: CourseSegmentStepInterface | null,
    nextCourseStep: CourseSegmentStepInterface | null,
    currentCourseStepIndex: number | null,
  ) => AnyAction;
  changeTrainingStatus: (status: TRAINING_STATUS_TYPES) => AnyAction;
  pendingStartTraining: () => AnyAction;
  startTraining: () => AnyAction;
  pauseTraining: () => AnyAction;
  resumeTraining: () => AnyAction;
  finishTraining: () => AnyAction;
  finishLiveTraining: () => AnyAction;
  nextStep: () => AnyAction;
  nextCourseStep: () => AnyAction;
  finishCourseStep: () => AnyAction;
  resetActiveTraining: () => AnyAction;
}

type Handler<A> = (state: ActiveTrainingState, action: A) => ActiveTrainingState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  setTrainingPlan: ["plan"],
  setCurrentSteps: [
    "currentStep",
    "nextStep",
    "currentStepIndex",
    "currentCourseStep",
    "nextCourseStep",
    "currentCourseStepIndex",
  ],
  setCurrentCourseSteps: ["currentCourseStep", "nextCourseStep", "currentCourseStepIndex"],
  changeTrainingStatus: ["status"],
  pendingStartTraining: null,
  startTraining: null,
  pauseTraining: null,
  resumeTraining: null,
  finishTraining: null,
  finishLiveTraining: null,
  nextStep: null,
  nextCourseStep: null,
  resetActiveTraining: null,
  finishCourseStep: null,
});

export const ActiveTrainingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ActiveTrainingState = {
  isInitialized: false,
  status: TRAINING_STATUS_TYPES.NOT_INITIALIZED,
  plan: null,
  currentSteps: {
    currentStep: null,
    nextStep: null,
    currentStepIndex: null,
    course: null,
    currentCourseStep: null,
    nextCourseStep: null,
    currentCourseStepIndex: null,
  },
};

/* ------------- Reducers ------------- */

const resetActiveTraining: Handler<ResetActiveTrainingAction> = () => {
  return {
    ...INITIAL_STATE,
  };
};

const setTrainingPlan: Handler<SetTrainingPlanAction> = (state, { plan }) => {
  return {
    ...state,
    isInitialized: true,
    plan: plan,
  };
};

const setCurrentSteps: Handler<SetCurrentStepsAction> = (
  state,
  {
    currentStep,
    nextStep,
    currentStepIndex,
    currentCourseStep,
    nextCourseStep,
    currentCourseStepIndex,
  },
) => {
  const course = getCourse({ plan: state.plan, currentStep });
  return {
    ...state,
    currentSteps: {
      ...state.currentSteps,
      currentStep,
      nextStep,
      currentStepIndex,
      course: course,
      currentCourseStep,
      nextCourseStep,
      currentCourseStepIndex,
    },
  };
};

const setCurrentCourseSteps: Handler<SetCurrentCourseStepsAction> = (
  state,
  { currentCourseStep, nextCourseStep, currentCourseStepIndex },
) => {
  return {
    ...state,
    currentSteps: {
      ...state.currentSteps,
      currentCourseStep,
      nextCourseStep,
      currentCourseStepIndex,
    },
  };
};

const changeTrainingStatus: Handler<ChangeTrainingStatusAction> = (state, { status }) => {
  return {
    ...state,
    status: status,
  };
};

const finishCourseStep: Handler<FinishCourseStepAction> = (state) => {
  return {
    ...state,
    currentSteps: {
      ...state.currentSteps,
      currentStep: state.currentSteps.currentStep && {
        ...state.currentSteps.currentStep,
        courseMovementStatus: COURSE_MOVEMENT_TYPE.FINISHED,
      },
      currentCourseStep: null,
      currentCourseStepIndex: null,
    },
  };
};

const setCoachTrainingStatus: Handler<SetCoachTrainingStatusAction> = (
  state,
  { coachTrainingStatus },
) => {
  const currentSteps =
    state.plan?.steps.slice(
      coachTrainingStatus.workoutCurrentStepIndex,
      coachTrainingStatus.workoutCurrentStepIndex + 2,
    ) || [];

  const currentStep = currentSteps[0] || null;
  const nextStep = currentSteps[1] || null;
  const course = getCourse({ plan: state.plan, currentStep });
  const currentSegments =
    coachTrainingStatus.segmentCurrentStepIndex !== null
      ? currentStep?.courseSegments?.slice(
          coachTrainingStatus.segmentCurrentStepIndex,
          coachTrainingStatus.segmentCurrentStepIndex + 2,
        )
      : null;

  const currentSegment = currentSegments ? currentSegments[0] : null;
  const nextSegment = currentSegments ? currentSegments[1] : null;
  const currentCourseIndex = currentSegment ? 0 : null;

  return {
    ...state,
    status: coachTrainingStatus.trainingStatus,
    currentSteps: {
      ...state.currentSteps,
      currentStepIndex:
        coachTrainingStatus.workoutCurrentStepIndex >= 0
          ? coachTrainingStatus.workoutCurrentStepIndex
          : null,
      currentStep: currentStep,
      nextStep: nextStep,
      course: course,
      currentCourseStep: currentSegment,
      currentCourseStepIndex: currentCourseIndex,
      nextCourseStep: nextSegment,
    },
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.RESET_ACTIVE_TRAINING]: resetActiveTraining,
  [Types.SET_TRAINING_PLAN]: setTrainingPlan,
  [Types.SET_CURRENT_STEPS]: setCurrentSteps,
  [Types.CHANGE_TRAINING_STATUS]: changeTrainingStatus,
  [Types.SET_CURRENT_COURSE_STEPS]: setCurrentCourseSteps,
  [Types.FINISH_COURSE_STEP]: finishCourseStep,
  [CoachControlsTypes.SET_COACH_TRAINING_STATUS]: setCoachTrainingStatus,
};

export const activeTrainingReducer = createReducer(INITIAL_STATE, HANDLERS);
