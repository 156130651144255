import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { COMMON_STYLES } from "../../themes/commonStyles";
import { RFMinValue, RFValue } from "../../utils/resizeHelper";

export const GAUGE_INFO_CONTAINER_WIDTH = RFMinValue(500);

export const themedStyles = StyleSheet.create({
  container: {
    width: GAUGE_INFO_CONTAINER_WIDTH,
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    borderRadius: RFValue(20),
    marginRight: RFValue(20),
    overflow: "hidden",
    ...COMMON_STYLES.SHADOW_BOX,
  },
  liveSessionContainer: {
    width: RFMinValue(420),
    marginRight: RFValue(10),
  },
  gaugeContainer: { flex: 1, justifyContent: "center", alignItems: "center" },
  gaugeSubContainer: { position: "absolute", bottom: -5, width: "100%" },
  bottomParamsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    paddingTop: RFValue(5),
    backgroundColor: MAIN_THEME.DEFAULT?.INFO_BACKGROUND_COLOR,
  },
  paramContainer: { flex: 1, alignItems: "center" },
  paramName: {
    fontWeight: "500",
    fontSize: RFMinValue(15),
    color: MAIN_THEME.HOME_SCREEN.PARAMS_NAME_TEXT_COLOR,
  },
  paramValue: {
    fontWeight: "500",
    fontSize: RFMinValue(36),
    lineHeight: RFMinValue(42),
  },
  connectBleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#5C5300",
    padding: RFValue(18),
  },
  overlay: {
    position: "absolute",
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    width: "100%",
    height: "100%",
  },
  currentParamValue: {
    color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR,
  },
  questionContainer: { position: "absolute", right: RFValue(10), top: RFValue(10), zIndex: 1 },
});
