import React, { ReactNode } from "react";
import { TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../DefaultText";
import { MyIcon } from "../MyIcon";

type PropsStyle = {
  children?: ReactNode | string;
  icon: string;
  style?: {
    container?: ViewStyle | ViewStyle[] | [ViewStyle, undefined];
    text?: TextStyle | undefined;
  };
  onPress?: () => void;
};

export function ButtonWithIcon({
  children,
  icon,
  style: propStyle = {},
  onPress,
  ...props
}: PropsStyle) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[themedStyles.defaultViewStyle, propStyle.container]} {...props}>
        <DefaultText style={[themedStyles.defaultTextStyle, propStyle.text]}>
          {children}
        </DefaultText>
        <View style={themedStyles.iconContainer}>
          <MyIcon icon={icon} />
        </View>
      </View>
    </TouchableOpacity>
  );
}
