import { RollingResistanceCoeff, WindResistanceCoefficient } from "./BleMeterCommon";

export function CreateWahooGradePacket(grade: number) {
  const gradeValue = Math.round((grade / 100.0 + 1.0) * 32768);
  const packet = new Uint8Array(3);
  let index = 0;

  //console.log("!Wahoo Legacy Grade: ",grade);
  packet[index++] = 0x46;
  packet[index++] = gradeValue & 0xff;
  packet[index++] = gradeValue >> 8;

  return packet;
}

export function CreateWahooRiderParametersPacket(userWeight: number) {
  // Set Rider characteristics
  const valueWeight = Math.round(userWeight * 100);
  const valueCrr = Math.round((RollingResistanceCoeff + 0.001) * 10000.0);
  const valueCwr = Math.round(WindResistanceCoefficient * 1000.0);
  const packet = new Uint8Array(7);
  let index = 0;
  //console.log("!Wahoo Legacy weight: ",valueWeight, " crr: ", valueCrr, " cwr: ", valueCwr);
  packet[index++] = 0x43;
  packet[index++] = valueWeight & 0xff;
  packet[index++] = valueWeight >> 8;
  packet[index++] = valueCrr & 0xff;
  packet[index++] = valueCrr >> 8;
  packet[index++] = valueCwr & 0xff;
  packet[index++] = valueCwr >> 8;

  return packet;
}

export function CreateWahooWindSpeedPacket(windSpeed: number) {
  // Set wind speed
  const value = Math.round((windSpeed + 32.768) * 1000.0);
  const packet = new Uint8Array(3);
  let index = 0;
  //console.log("!Wahoo Legacy Wind Speed: ",value);
  packet[index++] = 0x47;
  packet[index++] = value & 0xff;
  packet[index++] = value >> 8;

  return packet;
}

export function CreateWahooWheelCircumferencePacket(wheelCircumference: number) {
  // Set wheel circumference
  const value = Math.round(wheelCircumference * 10000); // send wheel circumference in mm
  const packet = new Uint8Array(3);
  let index = 0;
  //console.log("!Wahoo Legacy Wheel Circumference: ",value);
  packet[index++] = 0x48;
  packet[index++] = value & 0xff;
  packet[index++] = value >> 8;

  return packet;
}
