import React, { ReactNode } from "react";
import { Text, TextStyle, TextProps } from "react-native";
import { themedStyles } from "./styles";

type PropsStyle = {
  children?: ReactNode | string;
  style?: TextStyle | TextStyle[] | [TextStyle, TextStyle | undefined] | undefined;
  onPress?: () => void;
} & TextProps;

export function DefaultText({ children, style: propStyle = {}, ...props }: PropsStyle) {
  return (
    <Text style={[themedStyles.defaultTextStyle, propStyle]} {...props}>
      {children}
    </Text>
  );
}
