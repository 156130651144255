type valuesType = {
  firstName: string;
  lastName: string;
  nickname: string;
  sex: string;
  birthDate: string;
  height: string;
  weight: string;
  cp20m: string;
};

type validItem = { isValid: boolean; message?: string };

export type validationObjectType = {
  firstName: validItem;
  lastName: validItem;
  nickname: validItem;
  sex: validItem;
  country: validItem;
  birthDate: validItem;
  height: validItem;
  weight: validItem;
  cp20m: validItem;
};

export const validation = (values: valuesType): [boolean, validationObjectType] => {
  let isValidForm = true;
  let validationObject: validationObjectType = {
    firstName: { isValid: true },
    lastName: { isValid: true },
    nickname: { isValid: true },
    sex: { isValid: true },
    country: { isValid: true },
    birthDate: { isValid: true },
    height: { isValid: true },
    weight: { isValid: true },
    cp20m: { isValid: true },
  };
  if (!values.firstName) {
    isValidForm = false;
    validationObject.firstName.isValid = false;
    validationObject.firstName.message = "Please fill this field";
  }

  if (!values.lastName) {
    isValidForm = false;
    validationObject.lastName.isValid = false;
    validationObject.lastName.message = "Please fill this field";
  }

  if (!values.nickname) {
    isValidForm = false;
    validationObject.nickname.isValid = false;
    validationObject.nickname.message = "Please fill this field";
  }

  // if (values.nickname.length > 10) {
  //   isValidForm = false;
  //   validationObject.nickname.isValid = false;
  //   validationObject.nickname.message =
  //     "Nickname should be no longer than 10 characters";
  // }

  if (!values.birthDate) {
    isValidForm = false;
    validationObject.birthDate.isValid = false;
    validationObject.birthDate.message = "Please fill this field";
  }

  if (!Number.isInteger(parseInt(values.height))) {
    isValidForm = false;
    validationObject.height.isValid = false;
    validationObject.height.message = "Please fill valid height";
  }

  if (!Number.isInteger(parseInt(values.weight))) {
    isValidForm = false;
    validationObject.weight.isValid = false;
    validationObject.weight.message = "Please fill valid weight";
  }

  if (!Number.isInteger(parseInt(values.cp20m))) {
    isValidForm = false;
    validationObject.cp20m.isValid = false;
    validationObject.cp20m.message = "Please fill valid CP20m";
  }

  return [isValidForm, validationObject];
};
