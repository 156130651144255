import React from "react";
import NotificationsCreators from "../../../redux/reducers/notificationsReducer";
import { View, Linking, Platform } from "react-native";
import { Dispatch } from "redux";
import { DefaultText, Button } from "../../index";
import { themedStyles } from "./styles";
import { StateInterface } from "../../../redux/reducers";
import { connect } from "react-redux";
import { Event } from "../../../services/googleAnalyticsTracking";
import {
  NotificationInterface,
  NotificationLocation,
  TypesOfNotification,
} from "../../../redux/types";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackParams } from "../../../navigation";
import { useNavigation } from "@react-navigation/native";

type PropsType = { onLogoutPress: () => void };

type NavigationProps = StackNavigationProp<StackParams, "Welcome">;

export const MembershipModalComponent = ({ addNotification, onLogoutPress }: CombinedProps) => {
  const { setParams } = useNavigation<NavigationProps>();

  const learnMoreUrl =
    Platform.OS === "web"
      ? "https://www.powerwatts.com/"
      : "https://www.powerwatts.com/subscription-expired";

  const onLearnMorePress = () => {
    Event("click", { target: "buy learn more" });
    Linking.canOpenURL(learnMoreUrl).then((supported) => {
      if (supported) {
        if (Platform.OS === "web") {
          window.open(learnMoreUrl, "_blank");
        } else {
          Linking.openURL(learnMoreUrl);
        }
      } else {
        addNotification({
          location: NotificationLocation.Components,
          notificationType: TypesOfNotification.Warning,
          description: `Don't know how to open URI: ${learnMoreUrl}`,
        });
      }
    });
  };

  const onBuyNowPress = () => {
    setParams({ profile: true, tabIndex: 1 });
    Event("click", { target: "buy now" });
  };

  const renderTextMessage = () => {
    if (Platform.OS === "web") {
      return (
        <>
          <DefaultText>You do not have an active PowerWatts Online Membership!</DefaultText>
        </>
      );
    } else {
      return (
        <>
          <DefaultText>You do not have an active PowerWatts Online Membership!</DefaultText>
        </>
      );
    }
  };

  const renderButtonSection = () => {
    if (Platform.OS === "web") {
      return (
        <View style={themedStyles.buttonContainer}>
          <Button onPress={onLearnMorePress} style={themedStyles.button}>
            <DefaultText style={themedStyles.buttonText}>Lean More</DefaultText>
          </Button>
          <Button onPress={onBuyNowPress} style={themedStyles.button}>
            <DefaultText style={themedStyles.buttonText}>Buy Now</DefaultText>
          </Button>
          <Button onPress={onLogoutPress} style={themedStyles.button}>
            <DefaultText style={themedStyles.buttonText}>Log Out</DefaultText>
          </Button>
        </View>
      );
    } else {
      return (
        <View style={themedStyles.buttonContainer}>
          <Button onPress={onLearnMorePress} style={themedStyles.button}>
            <DefaultText style={themedStyles.buttonText}>Lean More</DefaultText>
          </Button>
          <Button onPress={onLogoutPress} style={themedStyles.button}>
            <DefaultText style={themedStyles.buttonText}>Log Out</DefaultText>
          </Button>
        </View>
      );
    }
  };

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.modalContainer}>
        <View style={themedStyles.titleContainer}>
          <DefaultText style={themedStyles.title}>Subscription Expired</DefaultText>
        </View>
        <View style={themedStyles.contentContainer}>{renderTextMessage()}</View>
        {renderButtonSection()}
      </View>
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PropsType;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationsCreators.addNotification(notification)),
});

const mapStateToProps = (_state: StateInterface) => ({});
export const MembershipModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MembershipModalComponent);
