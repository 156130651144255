import React from "react";
import { Platform } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import icoMoonConfig from "../../../selection.json";
import { RFValue } from "../../../utils/resizeHelper";

const Icon = createIconSetFromIcoMoon(icoMoonConfig, "Icomoon", "icomoon.ttf");

interface PropsType {
  icon: string;
  color?: string;
  size?: number;
}

export function MyIcon({ icon, color, size }: PropsType) {
  return <Icon name={icon} size={size} color={color || MAIN_THEME.DEFAULT?.TEXT_COLOR} />;
}

Icon.defaultProps = {
  size: Platform.OS === "web" ? RFValue(21) : 21,
};
