import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  leftSideContainer: { flex: 2, marginRight: RFValue(10) },
  rightSideContainer: { flex: 1.6 },
  contentContainer: {
    flex: 2,
    height: "65vh",
    flexDirection: "row",
    padding: 5,
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
  },
  statisticsBarContainer: { flexDirection: "row", marginBottom: RFValue(10) },
  bottomSvgContainer: { maxHeight: "20vh" },
  container: { maxHeight: "100vh" },
  soloContentContainer: { marginHorizontal: "20vw" },
  statsContainerFullWidth: {},
});
