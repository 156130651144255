import { Platform } from "react-native";

const Youtube = `
[class~=youtubeContainer]{
    width: 100%;
    height: 100%;
  }
`;

if (Platform.OS === "web") {
  const style: any = document.createElement("style");
  style.type = "text/css";
  if (style.styleSheet) style.styleSheet.cssText = Youtube;
  else style.appendChild(document.createTextNode(Youtube));

  document.head.appendChild(style);
}
