import { RouteProp, useRoute } from "@react-navigation/native";
import React, { useCallback, useEffect, /* useRef, */ useState } from "react";
import Modal from "modal-react-native-web";
import WorkoutsCreators from "../../redux/reducers/workoutsReducer";
import ActiveTrainingCreators from "../../redux/reducers/activeTrainingReducer";
import CoachControlsCreators from "../../redux/reducers/coachControlsReducer";
import TrainingDetailsCreators from "../../redux/reducers/trainingDetailsReducer";
import NotificationsCreators from "../../redux/reducers/notificationsReducer";
import { Picker, ScrollView, View, TouchableOpacity, Dimensions } from "react-native";
import { connect, /* useDispatch */ } from "react-redux";
import { Dispatch } from "redux";
import {
  Container,
  Header,
  HeaderTimers,
  LiveMembersMetric,
  MyIcon,
  StatisticsBarChart,
  NotesModal,
  ActiveMemberGauge,
} from "../../components";
import { StackParams } from "../../navigation";
// import { useUpdateActiveMembersCallback } from "../../redux/hooks";
import { StateInterface } from "../../redux/reducers";
import {
  // ActiveMemberInterface,
  NotificationInterface,
  NotificationLocation,
  RemoveMemberInfoInterface,
  TRAINING_STATUS_TYPES,
  TypesOfNotification,
} from "../../redux/types";
// import { FirebaseSocketReactivePagination } from "../../services/socket";
// import { SocketCollectionPreset } from "../../services/socket.options";
import { themedStyles } from "./styles";

import { ICON } from "../../constants";
import { RFValue } from "../../utils/resizeHelper";
import { Event } from "../../services/googleAnalyticsTracking";
import { USER_TYPES } from "../../redux/types";
import { getSortedUserListByPlace } from "../../utils/GameScoreCalculating";
import { wrapScrollView } from "react-native-scroll-into-view";

const CustomScrollView = wrapScrollView(ScrollView);

const { height } = Dimensions.get("window");

type RouteProps = RouteProp<StackParams, "TrainingControls">;

const TrainingControls = ({
  activeMembers,
  user,
  workouts,
  currentWorkoutsPlan,
  trainingStatus,
  initializeGroupTraining,
  changeSessionWorkout,
  addNotification,
  finishTraining,
  removeMember,
  getNotes,
  userType,
}: CombinedProps) => {
  const { params } = useRoute<RouteProps>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const membersSortedByScore = getSortedUserListByPlace(activeMembers);
  Modal.setAppElement("body");

  const sortedActiveMembers = activeMembers.sort((a, b) => a.nickName.localeCompare(b.nickName));

  useEffect(() => {
    Event("page_view", { page_title: "Coach Screen" });
    return () => {
      finishTraining();
    };
  }, [finishTraining]);

  const removeUser = useCallback(() => {
    if (params?.externalId && user.profile?.cpxUserId) {
      removeMember({
        liveTrainingId: params?.externalId,
        userId: user.profile?.cpxUserId,
      });
    }
  }, [user, params, removeMember]);

  useEffect(() => {
    try {
      if (user.cpxProfile) {
        const isItCoach = userType === USER_TYPES.COACH;
        params?.externalId && initializeGroupTraining(params?.externalId, isItCoach);
      }
    } catch (error) {
      addNotification({
        location: NotificationLocation.Components,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      });
    }
    return removeUser;
  }, [params, user.cpxProfile, userType, initializeGroupTraining, removeUser, addNotification]);

  // const dispatch = useDispatch();

  // const onUpdateActiveMembersList = useUpdateActiveMembersCallback(dispatch);

  // const firebaseSocketConnection = useRef(
  //   new FirebaseSocketReactivePagination<ActiveMemberInterface>(
  //     SocketCollectionPreset.ActiveMembersList,
  //     params.externalId,
  //   ),
  // );

  // useEffect(() => {
  //   const currentFBSocket = firebaseSocketConnection.current;
  //   currentFBSocket.subscribe(onUpdateActiveMembersList);

  //   return () => {
  //     currentFBSocket.unsubscribe();
  //   };
  // }, [onUpdateActiveMembersList]);

  const onPickerValueChange = (workoutId: string) => {
    changeSessionWorkout(workoutId);
  };

  const onIconPress = useCallback(() => {
    getNotes(currentWorkoutsPlan?.workoutId || "");
    setIsModalVisible(true);
  }, [getNotes, setIsModalVisible, currentWorkoutsPlan]);

  const closeNotesModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const graphHeight = height * 0.1;

  const renderModals = () => {
    return (
      <>
        <Modal animationType="fade" transparent={true} visible={isModalVisible}>
          <NotesModal closeNotesModal={closeNotesModal} />
        </Modal>
      </>
    );
  };

  return (
    <Container>
      {renderModals()}
      <Header isCoachScreen />
      <HeaderTimers />
      {(trainingStatus === TRAINING_STATUS_TYPES.PENDING_START ||
        trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED) && (
        <View style={themedStyles.pickerContainer}>
          <Picker
            style={themedStyles.workoutPickerLine}
            onValueChange={(workoutId) => {
              onPickerValueChange(workoutId);
            }}
          >
            <Picker.Item
              label={currentWorkoutsPlan?.workoutName || ""}
              value={currentWorkoutsPlan?.workoutId}
            />
            {workouts?.map((item) => (
              <Picker.Item label={`${item.name}`} value={item.wid} key={item.wid} />
            ))}
          </Picker>
          <TouchableOpacity onPress={onIconPress}>
            <View style={themedStyles.iconContainer}>
              <MyIcon icon={ICON.PAD} size={RFValue(34)} color="white" />
            </View>
          </TouchableOpacity>
        </View>
      )}

      <View style={themedStyles.contentContainer}>
        <ScrollView style={themedStyles.scrollContainer}>
          <View style={themedStyles.gaugeContainer}>
            {sortedActiveMembers.map((activeMember) => {
              return (
                <ActiveMemberGauge
                  key={activeMember.userId}
                  activeMember={activeMember}
                  membersSortedByScore={membersSortedByScore}
                />
              );
            })}
          </View>
        </ScrollView>
        <View style={themedStyles.metricContainer}>
          <CustomScrollView>
            <LiveMembersMetric />
          </CustomScrollView>
        </View>
      </View>
      <StatisticsBarChart height={graphHeight} isSplitMode={false} />
    </Container>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  activeMembers: state.coachControls.activeMembers,
  user: state.user,
  workouts: state.workouts.workouts,
  currentWorkoutsPlan: state.activeTraining.plan,
  trainingStatus: state.activeTraining.status,
  userType: state.user.userType,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  initializeGroupTraining: (externalId: string, isItCoach: boolean) =>
    dispatch(TrainingDetailsCreators.initializeGroupTraining(externalId, isItCoach)),
  changeSessionWorkout: (workoutId: string) =>
    dispatch(CoachControlsCreators.changeSessionWorkout(workoutId)),
  finishTraining: () => dispatch(ActiveTrainingCreators.finishTraining()),
  getNotes: (notesId: string) => dispatch(WorkoutsCreators.getWorkoutNotes(notesId)),
  removeMember: (removeMemberInfo: RemoveMemberInfoInterface) =>
    dispatch(CoachControlsCreators.removeMember(removeMemberInfo)),
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationsCreators.addNotification(notification)),
});
export const TrainingControlsScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrainingControls);
