import React from "react";
import { View, TouchableOpacity, Linking, ScrollView } from "react-native";
import { TrainingPlansInterface } from "../../../redux/types";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";
import { WorkoutList } from "../WorkoutList";

interface PropsType {
  trainingPlan: TrainingPlansInterface | null;
  isVideoAccess: boolean;
}

const reversePlans = [8, 9];

export function TrainingPlansTab({ trainingPlan, isVideoAccess }: PropsType) {
  let workouts = null;
  const ebookLink = trainingPlan?.ebook_url;
  const ebookLinkHandler = () => {
    ebookLink &&
      Linking.canOpenURL(ebookLink).then((supported) => {
        if (supported) {
          Linking.openURL(ebookLink);
        } else {
          console.log("Don't know how to open URI: " + ebookLink);
        }
      });
  };
  if (trainingPlan) {
    workouts = trainingPlan?.workouts.map((item) => {
      return {
        id: `${item.id}`,
        name: item.workout_name,
        wid: `${item.cpx_workout_id}`,
        virtual_coach: item.has_video && isVideoAccess,
        category: `intensity: ${item.intensity}%`,
        supported_languages: item.supported_languages,
      };
    });
  }

  return (
    <View style={themedStyles.container}>
      <DefaultText style={themedStyles.tabTitle}>{trainingPlan?.training_plan_name}</DefaultText>
      <View style={themedStyles.contentContainer}>
        <View style={themedStyles.workoutsContainer}>
          <WorkoutList
            workouts={
              reversePlans.includes(trainingPlan?.id ?? 0) ? workouts?.reverse() ?? null : workouts
            }
            withoutPaddings
            withSoloVideoButtons
          />
        </View>
        <ScrollView style={themedStyles.descriptionContainer}>
          <DefaultText>{trainingPlan?.description}</DefaultText>
          {ebookLink && (
            <TouchableOpacity style={themedStyles.ebookLinkContainer} onPress={ebookLinkHandler}>
              <DefaultText style={themedStyles.linkText}>View E-Book</DefaultText>
            </TouchableOpacity>
          )}
        </ScrollView>
      </View>
    </View>
  );
}
