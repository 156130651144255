import React, { useCallback, useEffect } from "react";
import { Container, DefaultText } from "../../components";
import { useAuth0 } from "@auth0/auth0-react";
import { ImageBackground, View, TouchableOpacity, Linking } from "react-native";
import { IMG, SVG } from "../../assets";
import { themedStyles } from "./styles";
import { Event } from "../../services/googleAnalyticsTracking";

export function Login() {
  const { loginWithRedirect } = useAuth0();
  const registrationLink = "https://www.powerwatts.com/sign-up";
  const onLoginButtonPress = useCallback(() => {
    loginWithRedirect();
    Event("click", {
      page: "landing page",
      component: "Landing",
      target: "Login",
    });
  }, [loginWithRedirect]);

  const onSignUpButtonPress = useCallback(() => {
    Event("click", {
      page: "landing page",
      component: "Landing",
      target: "Sign Up",
    });
    Linking.canOpenURL(registrationLink).then((supported) => {
      if (supported) {
        Linking.openURL(registrationLink);
      } else {
        console.log("Don't know how to open URI: " + registrationLink);
      }
    });
  }, [registrationLink]);

  useEffect(() => {
    Event("page_view", { page_title: "Landing Page" });
  }, []);

  return (
    <Container>
      <View style={themedStyles.container}>
        <ImageBackground source={IMG.landingBackground} style={themedStyles.backgroundImg}>
          <View style={themedStyles.loginContainer}>
            <View style={themedStyles.logoContainer}>
              <img src={SVG.logoSvg} alt="CPX Logo" style={{ width: "200%" }} />
            </View>
            <View style={themedStyles.buttonContainer}>
              <TouchableOpacity onPress={onLoginButtonPress}>
                <View style={[themedStyles.button, themedStyles.loginButton]}>
                  <DefaultText>Login</DefaultText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={onSignUpButtonPress}>
                <View style={[themedStyles.button, themedStyles.signUpButton]}>
                  <DefaultText>Sign up</DefaultText>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </ImageBackground>
      </View>
    </Container>
  );
}
