import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  modalContainer: {
    alignItems: "center",
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
    padding: RFValue(24),
    borderRadius: RFValue(8),
    flexDirection: "row",
  },
  titleText: {
    fontWeight: "bold",
    fontSize: RFValue(20),
    lineHeight: RFValue(30),
    marginBottom: RFValue(24),
  },
  connectionStatusContainer: {
    paddingBottom: RFValue(24),
    alignItems: "center",
  },
  connectionStatusText: {
    fontSize: RFValue(16),
    lineHeight: RFValue(24),
  },
  gaugeStatusContainer: {
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    borderRadius: RFValue(8),
    overflow: "hidden",
  },
  gaugeContainer: {
    paddingHorizontal: RFValue(34),
    paddingVertical: RFValue(19),
  },
  connectionCheckMessageContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: RFValue(18),
  },
  connectionCheckMessageText: {
    fontSize: RFValue(20),
    lineHeight: RFValue(32),
  },
  bottomButtonContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: RFValue(24),
  },
  button: {
    width: "max-content",
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(8),
    borderRadius: RFValue(4),
  },
  buttonText: {
    fontWeight: "500",
    fontSize: RFValue(16),
    lineHeight: RFValue(24),
  },
  continueButton: {
    width: RFValue(120),
    alignItems: "center",
  },
  rightBlock: {
    height: "100%",
    padding: RFValue(20),
    alignItems: "center",
    justifyContent: "space-between",
  },
});
