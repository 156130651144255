export const SVG = {
  // Preloader
  loading: require("./svg/loading.svg"),
  stravaButton: require("./svg/stravaButton.svg"),
  logoSvg: require("./svg/logoSvg.svg"),
};

export const IMG = {
  // MainScreen
  logo: require("./png/logo.png"),
  cyclist: require("./png/cyclist.png"),
  nextArrows: require("./png/nextArrows.png"),
  landingBackground: require("./jpg/landingBackground.jpg"),
};

export const MP3 = {
  beep: require("./mp3/pizzicato.mp3"),
};
