import { DeviceDataPoint } from "../../redux/types";
import { GetFlagBit } from "./BleParserHelpers";


export class HeartRateMonitorParser {
  lastPacketTime: number;
  constructor() {
    this.lastPacketTime = new Date().getTime();
  }

  GetDataPoint(dataView: DataView) {
    let offset = 0;
    const flagBits = dataView.getUint8(offset);
    offset += 1;

    const isHeartRateValueFormat16Bits = GetFlagBit(0, flagBits);

    let heartRate: number | null = null;
    if (isHeartRateValueFormat16Bits) {
      heartRate = dataView.getUint16(offset++, true);
      //console.log('16-bit heart rate: ', heartRate)
    } else {
      heartRate = dataView.getUint8(offset++);
      //console.log('8-bit heart rate:', heartRate);
    }

    var now = new Date().getTime();
    this.lastPacketTime = now;

    let deviceDataPoint: DeviceDataPoint = {
      cadence: null,
      power: null,
      speed: null,
      powerBalance: null,
      heartRate: heartRate,
    };

    //console.log(deviceDataPoint);
    return deviceDataPoint;
  }
}
