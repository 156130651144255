import React from "react";
import { FlatList, TouchableOpacity, View, ViewStyle, Platform } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { themedStyles } from "./styles";
import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import icoMoonConfig from "../../../selection.json";
import { MyIcon, ToolTipWrapper } from "../../index";
import { DefaultText } from "../DefaultText";

export const Icon = createIconSetFromIcoMoon(icoMoonConfig, "Icomoon", "icomoon.ttf");

interface ButtonProps {
  onPress: () => void;

  icon?: string;
  text?: string;
  isActive?: boolean;
  color?: string;
  size?: number;
  isDisable?: boolean;
  tooltip?: string;
}

interface PropsType {
  oneButton?: ButtonProps;
  arrButton?: ButtonProps[];
  style?: ViewStyle;
  isDisable?: boolean;
  tooltip?: string;
}

const RenderButton = ({
  icon,
  text,
  onPress,
  color = MAIN_THEME.HEADER.BUTTON_ICON_COLOR,
  size = 21,
  isActive,
  isDisable,
  tooltip,
}: ButtonProps) => {
  const isTooltipEnabled = Platform.OS === "web" && tooltip;

  const Wrapper = isTooltipEnabled ? ToolTipWrapper : React.Fragment;
  return (
    <Wrapper
      {...(isTooltipEnabled && {
        text: tooltip,
      })}
    >
      <TouchableOpacity
        onPress={onPress}
        disabled={isDisable}
        style={[
          themedStyles.buttonContainer,
          isActive === true
            ? themedStyles.activeButton
            : isActive === false
            ? themedStyles.inactiveButton
            : {},
        ]}
      >
        {text && (
          <DefaultText
            style={[{ color: color, fontSize: size, lineHeight: size }, themedStyles.text]}
          >
            {text}
          </DefaultText>
        )}
        {icon && <MyIcon icon={icon} size={size} color={color} />}
      </TouchableOpacity>
    </Wrapper>
  );
};

export function IconButton({
  oneButton,
  arrButton,
  isDisable,
  style: propStyle = {},
  tooltip,
}: PropsType) {
  return (
    <View style={[themedStyles.container, propStyle]}>
      {oneButton && (
        <RenderButton
          icon={oneButton.icon}
          text={oneButton.text}
          onPress={oneButton.onPress}
          color={oneButton.color}
          size={oneButton.size}
          isActive={oneButton.isActive}
          isDisable={isDisable}
          tooltip={tooltip}
        />
      )}
      {arrButton && (
        <FlatList
          keyExtractor={(item) => item.icon || item.text || ""}
          data={arrButton}
          renderItem={({ item }) => (
            <RenderButton
              icon={item.icon}
              onPress={item.onPress}
              color={item.color}
              size={item.size}
              isActive={item.isActive}
              isDisable={isDisable}
              text={item.text}
              tooltip={item.tooltip}
            />
          )}
          numColumns={999}
        />
      )}
    </View>
  );
}
