import { RollingResistanceCoeff, WindResistanceCoefficient } from "./BleMeterCommon";

export function CreateWeightPacket(userWeight: number) {
  const bluetoothWeightUnits = 0.005; // Units is in kg with a resolution of 0.005
  const userWeightValue = userWeight / bluetoothWeightUnits;

  let roundedWeight = Math.round(userWeightValue);
  let weightPacket = new Uint8Array(2);
  let index = 0;
  weightPacket[index++] = roundedWeight & 0xff;
  weightPacket[index++] = roundedWeight >> 8;

  return weightPacket;
}

export function CreateWattBikeInitPacket(deviceInfo: number) {
  const commandAtomInit = 0x39;
  const initPayload0 = 0x70;
  const initPayload1 = 0x01;
  const packetNumber = 0; // If we do more WattBike communication in the future, this should auto-increment.

  let initPacket = new Uint8Array(5);
  let index = 0;
  initPacket[index++] = packetNumber;
  initPacket[index++] = deviceInfo;
  initPacket[index++] = commandAtomInit;
  initPacket[index++] = initPayload0;
  initPacket[index++] = initPayload1;

  return initPacket;
}

export function CreateWattBikeWeightPacket(deviceInfo: number, userWeight: number) {
  const commandSetWeight = 0xBB;
  const packetNumber = 1; // If we do more WattBike communication in the future, this should auto-increment.

  // Create Int8Array of a float32
  let weightArray = new Float32Array(1);
  weightArray[0] = userWeight;
  const weightByteArray = new Int8Array(weightArray.buffer);

  let weightPacket = new Uint8Array(7);
  let index = 0;
  weightPacket[index++] = packetNumber;
  weightPacket[index++] = deviceInfo;
  weightPacket[index++] = commandSetWeight;
  weightPacket[index++] = weightByteArray[0];
  weightPacket[index++] = weightByteArray[1];
  weightPacket[index++] = weightByteArray[2];
  weightPacket[index++] = weightByteArray[3];

  return weightPacket;
}


export function CreateHeightPacket(userHeight: number) {
  const bluetoothHeightUnits = 0.01; // Unit is in meters with a resolution of 0.01
  const userHeightValue = userHeight / bluetoothHeightUnits;

  let roundedHeight = Math.round(userHeightValue);
  let heightPacket = new Uint8Array(2);
  let index = 0;
  heightPacket[index++] = roundedHeight & 0xff;
  heightPacket[index++] = roundedHeight >> 8;

  return heightPacket;
}

export function CreateFtmsRequestControlPacket() {
  let packet = new Uint8Array(1);
  packet[0] = 0x00; // request control op code

  return packet;
}

export function CreateFtmsStartSessionPacket() {
  let packet = new Uint8Array(1);
  packet[0] = 0x07; // start session op code
  return packet;
}

export function CreateFtmsGradePacket(grade: number) {
  // set indoor bike simulation parameters
  const windSpeed = 0; // resolution 0.001 mps
  const gradeValue = grade * 100; // resolution 0.01 percent
  const coeffRollingResistance = RollingResistanceCoeff * 10000; // resolution 0.0001 (unitless)
  const coeffWindResistance = WindResistanceCoefficient * 100; // resolution 0.01 Kg/m
  const packet = new Uint8Array(7);
  let index = 0;

  //console.log("!values",windSpeed, grade, coeffRollingResistance, coeffWindResistance);
  packet[index++] = 0x11; // Set Indoor Bike Simulation parameters op-code
  packet[index++] = windSpeed & 0xff;
  packet[index++] = windSpeed >> 8;
  packet[index++] = gradeValue & 0xff;
  packet[index++] = gradeValue >> 8;
  packet[index++] = coeffRollingResistance;
  packet[index++] = coeffWindResistance;

  return packet;
}
