import React, { useEffect, /* useRef, */ useState } from "react";
import Modal from "modal-react-native-web";
import { connect, /* useDispatch */ } from "react-redux";
import { Dispatch } from "redux";
import { browserName } from "react-device-detect";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Container,
  SignUpModal,
  EditProfileModal,
  UnsupportedBrowserModal,
  MembershipModal,
  NotBookedModal,
  BetaModal,
  WelcomeSideBar,
  // ActiveSessionTab,
  VirtualCoachTab,
  WelcomeTrainingList,
  NewVersionModal,
  ToolTipProvider,
  CCExpireModal,
  AchievementInfoModal,
} from "../../components";
import TimerCreators from "../../redux/reducers/timerReducer";
import { StateInterface } from "../../redux/reducers";
import {
  PRICING_MODE,
  SessionInterface,
  USER_TYPES,
  WorkoutInterface,
  WORKOUT_CATEGORY,
} from "../../redux/types";
// import { useUpdateLiveTrainingsListCallback } from "../../redux/hooks";
// import { FirebaseSocketReactivePagination } from "../../services/socket";
// import { SocketCollectionPreset } from "../../services/socket.options";
import { themedStyles } from "./styles";
import { BROWSERS, ICON, LOCAL_STORAGE_ITEMS } from "../../constants";
import { Event } from "../../services/googleAnalyticsTracking";
import { WorkoutList } from "../../components/WelcomeTrainingList/WorkoutList";
import { TrainingPlansTab } from "../../components/WelcomeTrainingList/TrainingPlansTab";
import { WelcomeTabInterface } from ".";
import { StackParams } from "../../navigation/index.web";
import { getQueryParameters } from "../../utils/helpers";
import { WelcomeParams } from "../../navigation";
import { checkAccess } from "../../utils/sagasHelper";
import { useAuth0 } from "@auth0/auth0-react";

type RouteProps = RouteProp<StackParams, "Welcome">;
type NavigationProps = StackNavigationProp<StackParams, "Welcome">;

const Welcome = (props: CombinedProps) => {
  const { params } = useRoute<RouteProps>();
  const { setParams } = useNavigation<NavigationProps>();
  const { logout } = useAuth0();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSupportedBrowser, setIsSupportedBrowser] = useState(true);
  const [isMembershipModalVisible, setIsMembershipModalVisible] = useState(false);
  const isTrainingPeaksConnected = props.servicesConnected.training_peaks.connected;
  const [isBetaModalVisible, setIsBetaModalVisible] = useState(false);
  const [tabIndex, setTabIndex] = useState(5);
  const [subTabIndex, setSubTabIndex] = useState<number | null>(null);
  const [isCCExpireSoon, setIsCCExpireSoon] = useState(false);
  const [isAchievementInfoModal, setIsAchievementInfoModal] = useState(
    !localStorage.getItem(LOCAL_STORAGE_ITEMS.USER_SAW_ACHIEVEMENT_INFO_MODAL),
  );
  const { userAccessLvl } = props;

  const isNewVersionModal = !localStorage.getItem(LOCAL_STORAGE_ITEMS.USER_SAW_NEW_VERSION_MODAL);
  const isProfileModalOpen = !!params?.profile;
  Modal.setAppElement("body");

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "beta") {
      setIsBetaModalVisible(true);
    }
    props.startTimer();
    const initUrlParams = localStorage.getItem(LOCAL_STORAGE_ITEMS.INIT_PARAMS);
    if (initUrlParams && !isProfileModalOpen) {
      const query = getQueryParameters<WelcomeParams>(initUrlParams);
      if (query) {
        setParams({
          profile: query.profile,
          productId: query.productId,
          tabIndex: query.tabIndex,
        });
      }
    }
    localStorage.removeItem(LOCAL_STORAGE_ITEMS.INIT_PARAMS);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Event("page_view", { page_title: "home page" });
    if (browserName === BROWSERS.CHROME || browserName === BROWSERS.EDGE) {
      setIsSupportedBrowser(true);
    } else {
      setIsSupportedBrowser(false);
    }
  }, []);

  useEffect(() => {
    if (
      !props.hasActiveMembership &&
      !isModalVisible &&
      props.userType !== null &&
      !isProfileModalOpen
    ) {
      setIsMembershipModalVisible(true);
    } else {
      setIsMembershipModalVisible(false);
    }
  }, [props.hasActiveMembership, isModalVisible, props.userType, isProfileModalOpen]);

  useEffect(() => {
    if (props.userType === USER_TYPES.USER) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [props.userType]);

  useEffect(() => {
    if (props.monthsWhileCardValid !== null && props.monthsWhileCardValid < 1) {
      setIsCCExpireSoon(true);
    } else {
      setIsCCExpireSoon(false);
    }
  }, [props.monthsWhileCardValid]);

  // const dispatch = useDispatch();

  // const onUpdateLiveTrainingsList = useUpdateLiveTrainingsListCallback(dispatch);

  // const firebaseSocketConnection = useRef(
  //   new FirebaseSocketReactivePagination<SessionInterface>(
  //     SocketCollectionPreset.LiveTrainingsList,
  //   ),
  // );

  // useEffect(() => {
  //   const currentFBSocket = firebaseSocketConnection.current;
  //   if (props.isAuthenticated) {
  //     currentFBSocket.subscribe(onUpdateLiveTrainingsList);
  //   }

  //   return () => currentFBSocket.unsubscribe();
  // }, [onUpdateLiveTrainingsList, props.isAuthenticated]);

  const handleCloseCCExpireModal = () => {
    setIsCCExpireSoon(false);
  };

  const onBetaModalCancelPress = () => {
    setIsBetaModalVisible(false);
  };

  const handleSidebarItemPress = (index: number) => {
    setTabIndex(index);
  };

  const handleSidebarSubItemPress = (index: number | null) => {
    setSubTabIndex(index);
  };

  const handleCloseAchievementInfoModal = () => {
    setIsAchievementInfoModal(false);
  };

  const onLogoutPress = () => {
    logout({ returnTo: window.location.href });
    Event("page_view", { page_title: "Logout" });
  };

  const soloWorkouts = props.workouts?.map((item) => ({
    ...item,
    virtual_coach: false,
  }));
  const virtualCoachWorkouts = props.workouts?.filter((item) => item.virtual_coach === true);
  const activeSessions: SessionInterface[] = [];
  const willSoonSessions: SessionInterface[] = [];

  props.sessions?.map((session) => {
    const { workoutStartTimestamp } = session.trainingStatus
      ? session.trainingStatus
      : { workoutStartTimestamp: 0 };
    const timeBeforeTraining = Math.floor(props.dateTime) - workoutStartTimestamp;
    if (timeBeforeTraining < 0) {
      willSoonSessions.unshift(session);
    } else {
      activeSessions.push(session);
    }
  });

  const defaultCategory = props.isGBUser
    ? WORKOUT_CATEGORY.POWERWATTS_UK
    : WORKOUT_CATEGORY.ALL_CATEGORIES;

  const placeholderTPText = isTrainingPeaksConnected
    ? "You don't have any workouts"
    : "Training Peaks is not connected";

  const fixedTrainingPeaks: WorkoutInterface[] | null =
    props.trainingPeeksWorkouts?.map((workout) => ({
      id: workout.id,
      name: workout.name,
      wid: `${workout.wid}`,
      supported_languages: workout.supported_languages,
    })) || null;

  const tabsArr: WelcomeTabInterface[] = [
    {
      title: "Introductions workout",
      icon: ICON.LOCATION_2,
      iconColor: "#6495ED",
      component: <WorkoutList workouts={props.introductions} />,
      isAccess: checkAccess({ userAccessLvl, requiredPricingMode: PRICING_MODE.BASIC }),
    },
    null,
    {
      title: "Training plans",
      icon: ICON.PASTE,
      iconColor: "#6495ED",
      isAccess: checkAccess({ userAccessLvl, requiredPricingMode: PRICING_MODE.BASIC }),
      components: props.trainingPlans?.map((trainingPlan) => {
        const isVideoAccess =
          trainingPlan.id === 1
            ? true
            : checkAccess({ userAccessLvl, requiredPricingMode: PRICING_MODE.PREMIUM });

        const componentsArr = {
          title: trainingPlan.training_plan_name,
          icon: ICON.CLIPBOARD_3,
          iconColor: "#6495ED",
          component: <TrainingPlansTab trainingPlan={trainingPlan} isVideoAccess={isVideoAccess} />,
        };
        return componentsArr;
      }),
    },
    null,
    // {
    //   title: "LIVE Classes",
    //   icon: ICON.USERS_4,
    //   iconColor: null,
    //   isAccess: checkAccess({ userAccessLvl, requiredPricingMode: PRICING_MODE.BASIC }),
    //   component: (
    //     <ActiveSessionTab activeSessions={activeSessions} willSoonSessions={willSoonSessions} />
    //   ),
    // },
    // null,
    {
      title: "All Video Workouts",
      icon: ICON.FILM,
      iconColor: null,
      isAccess: checkAccess({ userAccessLvl, requiredPricingMode: PRICING_MODE.PREMIUM }),
      component: <VirtualCoachTab workouts={virtualCoachWorkouts} />,
    },
    {
      title: "All Solo Workouts",
      icon: ICON.USER_6,
      iconColor: null,
      isAccess: checkAccess({ userAccessLvl, requiredPricingMode: PRICING_MODE.BASIC }),
      component: <VirtualCoachTab workouts={soloWorkouts} defaultCategory={defaultCategory} />,
    },
    null,
    {
      title: "Training Peaks Workouts",
      icon: ICON.TARGET,
      iconColor: "#6495ED",
      isAccess: checkAccess({ userAccessLvl, requiredPricingMode: PRICING_MODE.PREMIUM }),
      component: <WorkoutList workouts={fixedTrainingPeaks} placeholder={placeholderTPText} />,
    },
  ];

  const renderModals = () => {
    return (
      <>
        <Modal animationType="fade" transparent={true} visible={isModalVisible}>
          <SignUpModal />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={!isSupportedBrowser}>
          <UnsupportedBrowserModal userBrowser={browserName} />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isProfileModalOpen}>
          <EditProfileModal />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isMembershipModalVisible}>
          <MembershipModal onLogoutPress={onLogoutPress} />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isCCExpireSoon}>
          <CCExpireModal handleCloseModal={handleCloseCCExpireModal} />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={props.isNotBookedModalVisible}>
          <NotBookedModal />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isBetaModalVisible}>
          <BetaModal onClosePress={onBetaModalCancelPress} />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isNewVersionModal}>
          <NewVersionModal />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isAchievementInfoModal}>
          <AchievementInfoModal closeModal={handleCloseAchievementInfoModal} />
        </Modal>
      </>
    );
  };

  return (
    <ToolTipProvider>
      <Container style={themedStyles.container}>
        {renderModals()}
        <WelcomeSideBar
          tabsArr={tabsArr}
          onItemPress={handleSidebarItemPress}
          tabIndex={tabIndex}
          isGBUser={props.isGBUser}
          subTabIndex={subTabIndex}
          onSubItemPress={handleSidebarSubItemPress}
        />
        <WelcomeTrainingList tabsArr={tabsArr} tabIndex={tabIndex} subTabIndex={subTabIndex} />
      </Container>
    </ToolTipProvider>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  workouts: state.workouts.workouts,
  sessions: state.sessions.sessions,
  introductions: state.workouts.introductions,
  userType: state.user.userType,
  hasActiveMembership: state.subscription.hasActiveMembership,
  isNotBookedModalVisible: state.subscription.isNotBookedModalVisible,
  trainingPlans: state.workouts.trainingPlans,
  trainingPeeksWorkouts: state.workouts.trainingPeeksWorkouts,
  servicesConnected: state.settings.servicesConnected,
  isAuthenticated: state.user.isAuthenticated,
  isGBUser: state.user.isGBUser,
  userAccessLvl: state.user.userAccessLvl,
  monthsWhileCardValid: state.subscription.monthsWhileCardValid,
  dateTime: state.timer.dateTime,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  startTimer: () => dispatch(TimerCreators.startTimer()),
});
export const WelcomeScreen = connect(mapStateToProps, mapDispatchToProps)(Welcome);
