import React from "react";
import { View } from "react-native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";
import { MAIN_THEME } from "../../../themes";

export const BottomGradient = () => {
  return (
    <View style={{ position: "absolute", bottom: 0, width: "100%" }}>
      <Svg height="50" width="100%">
        <Defs>
          <LinearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
            <Stop offset="0" stopColor="transparent" stopOpacity="0" />
            <Stop
              offset="1"
              stopColor={MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR}
              stopOpacity="0.9"
            />
          </LinearGradient>
        </Defs>
        <Rect x="0" y="0" width="100%" height="50" fill="url(#grad)" clipPath="url(#clip)" />
      </Svg>
    </View>
  );
};
