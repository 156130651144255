import React, { useState, useEffect } from "react";
import { themedStyles } from "./styles";
import { connect } from "react-redux";
import { StateInterface } from "../../redux/reducers";
import { View } from "react-native";
import YouTube, { Options } from "react-youtube";
import { TRAINING_STATUS_TYPES } from "../../redux/types";
import { getCurrentWorkoutTime } from "../../redux/selectors";

type PropsType = {
  virtualCoach: boolean;
  isFocus?: boolean;
};
type YoutubeEventType = {
  target: any;
  data: number;
};

const YoutubeVideoComponent = ({
  isWorkoutInitialized,
  videoId,
  videoStartSec,
  currentWorkoutTime,
  trainingStatus,
  virtualCoach,
}: CombinedProps) => {
  const [player, setPlayer] = useState(null);
  const [playerState, setPlayerState] = useState("");

  useEffect(() => {
    return () => {
      (player as any)?.pauseVideo();
    };
  }, [player]);

  const timeFromWorkoutStart = currentWorkoutTime;
  const onStateChange = (event: YoutubeEventType) => {
    let playerState = null;
    if (event.target.getPlayerState) {
      switch (event.target.getPlayerState()) {
        case 1:
          playerState = TRAINING_STATUS_TYPES.STARTED;
          break;
        case 2:
          playerState = TRAINING_STATUS_TYPES.PAUSED;
          break;
        default:
          playerState = TRAINING_STATUS_TYPES.PAUSED;
          break;
      }
      setPlayerState(playerState);
    }
  };

  const onReady = (event: YoutubeEventType) => {
    setPlayer(event.target);
    // access to player in all event handlers via event.target
    (player as any)?.pauseVideo();
  };

  if (!isWorkoutInitialized) {
    return <View style={themedStyles.container} />;
  }

  if (!videoId || !virtualCoach) {
    return <View />;
  }

  if (player) {
    let seekTo = (timeFromWorkoutStart || 0) + parseInt(videoStartSec || "0");
    let diff = Math.abs((player as any).getCurrentTime() - seekTo);

    if ((timeFromWorkoutStart || 0) > 0 && diff > 3) {
      (player as any)?.seekTo(seekTo);
      (player as any)?.pauseVideo();
      (player as any)?.playVideo();
    }
  }

  if (
    trainingStatus === TRAINING_STATUS_TYPES.STARTED &&
    playerState !== TRAINING_STATUS_TYPES.STARTED &&
    !!player
  ) {
    (player as any)?.playVideo();
  }

  if (
    trainingStatus !== TRAINING_STATUS_TYPES.STARTED &&
    playerState === TRAINING_STATUS_TYPES.STARTED &&
    !!player
  ) {
    (player as any)?.pauseVideo();
  }

  const opts: Options = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 0,
      controls: 0,
      modestbranding: 1,
      fs: 0,
      showinfo: 0,
      rel: 0,
    },
  };

  return (
    <View style={themedStyles.container}>
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={onReady}
        onStateChange={onStateChange}
        className={"youtube"}
        containerClassName={"youtubeContainer"}
      />
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & PropsType;

const mapStateToProps = (state: StateInterface) => ({
  currentWorkoutTime: getCurrentWorkoutTime(state),
  videoId: state.trainingDetails.detailsInfo?.videoId,
  videoStartSec: state.trainingDetails.detailsInfo?.videoStartSec,
  trainingStatus: state.activeTraining.status,
  isWorkoutInitialized: state.activeTraining.isInitialized,
});
export const YoutubeVideo = connect(mapStateToProps, {})(YoutubeVideoComponent);
