import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  text: { marginLeft: 10 },
  selectedContainer: { backgroundColor: MAIN_THEME.DEFAULT?.CONFORMING_BACKGROUND_COLOR },
});
