import { StyleSheet } from "react-native";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    overflow: "hidden",
  },
  paddings: { paddingHorizontal: RFValue(16), paddingVertical: RFValue(24) },
  scrollView: { flex: 1 },
  placeholder: {
    textAlign: "center",
  },
});
