import { TimeApi } from "../api/api";

class Time {
  timeDifference = 0;
  fetchTimeDifWithServer = async () => {
    const timestampBeforeFetch = Date.now();
    const serverTimeResponse = await TimeApi.getTime();
    const timestampAfterFetch = Date.now();
    const fetchDelay = timestampAfterFetch - timestampBeforeFetch;
    const unixServerTime = serverTimeResponse * 1000 - fetchDelay;
    const severTimeDif = Math.round(Date.now()) - unixServerTime;
    this.timeDifference = severTimeDif;
  };
  getTime = () => {
    return Date.now() - this.timeDifference;
  };
}

export default new Time();
