// To grab name of the color
// http://chir.ag/projects/name-that-color/

export const COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  GRAY: "#8D8D8D",
  GREEN: "#1FCF0A",
  PORCELAIN: "#F4F5F6",
  ATHENS_GRAY: "#F8F9FA",
  BLAZE_ORANGE: "#F46901",
  SHARK: "#1A1B1C",
  OUTER_SPACE: "#2C2D2D",
  TWILIGHT_BLUE: "#FEFFFF",
  HEAVY_METAL: "#212220",
  COD_GRAY: "#1B1B1B",
  STARSHIP: "#F2E14C",
  MINE_SHAFT: "#2A2A2A",
  APPLE: "#41C332",
  JAPANESE_LAUREL: "#12A800",
  VERDUN_GREEN: "#5C5300",
  SAN_FELIX: "#054A05",
  LOG_CABIN: "#263122",
  BIRCH: "#373524",
  ALTO: "#DDDDDD",
  EUCALYPTUS: "#29A645",
  AZURE_RADIANCE: "#007BFF",
  PICTON_BLUE: "#3595F0",
  THUNDERBIRD: "#C01818",
  LIMEADE: "#2F8F00",
  GOLD: "#FFD700",
  SILVER: "#C0C0C0",
  RAW_SIENNA: "#C67A44",
  PINK: "#ffc0cb",
};
