import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
  },
  numberParam: {
    color: MAIN_THEME.DEFAULT?.REVERSE_TEXT_COLOR,
    backgroundColor: MAIN_THEME.HEADER.BUTTON_BACKGROUND_COLOR,
    textAlign: "center",
  },
  memberGauge: {
    margin: 15,
    width: "max-content",
    justifyContent: "center",
    alignItems: "center",
  },
});
