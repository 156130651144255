import React from "react";
import { TextInput, View } from "react-native";
import { connect } from "react-redux";
import { StateInterface } from "../../redux/reducers";
import { ActiveMemberInterface } from "../../redux/types";
import { themedStyles } from "./styles";
import { DefaultText } from "../CommonElements";
import { UserMetricsGauge } from "../MetricsGauge";
import { getCpValueFromTextField, isGameInterval } from "../../utils/Statistics";
import { getPlaceNumberColor } from "../../utils/GameScoreCalculating";
import { Dispatch } from "redux";
import CoachControlsCreators from "../../redux/reducers/coachControlsReducer";
import { useState } from "react";

interface PropsType {
  activeMember: ActiveMemberInterface;
  membersSortedByScore: ActiveMemberInterface[];
}

const ActiveMemberGaugeComponent = ({
  activeMember,
  membersSortedByScore,
  currentStepType,
  changeUserCp20m,
}: CombinedProps) => {
  const [cp20m, setCp20m] = useState(activeMember.cp);
  const getUserPlace = (activeMember: ActiveMemberInterface) =>
    membersSortedByScore.findIndex((member) => member.userId === activeMember.userId) + 1;
  const userPlace = getUserPlace(activeMember);
  const isGameStep = isGameInterval(currentStepType);
  const scoreLineColor = getPlaceNumberColor(userPlace, isGameStep);
  const textUnderGauge =
    userPlace > 0 && userPlace <= 3 && isGameStep
      ? `#${userPlace} ${activeMember.stats.leaderboardScoreLine}`
      : `${activeMember.stats.leaderboardScoreLine}`;
  const onCp20mChange = (cp20m: string) => {
    const cpNumber = getCpValueFromTextField(cp20m);
    setCp20m(cpNumber);
  };
  const onCp20mBlur = () => {
    changeUserCp20m(activeMember.userId, cp20m);
  };
  return (
    <View style={themedStyles.memberGauge}>
      {!activeMember.isHide && (
        <>
          <DefaultText>{activeMember.nickName}</DefaultText>
          <TextInput
            value={`${cp20m}`}
            style={themedStyles.numberParam}
            keyboardType="number-pad"
            onChangeText={onCp20mChange}
            onBlur={onCp20mBlur}
          />
          <UserMetricsGauge
            width={150}
            height={150}
            deviceData={{
              cadence: activeMember.stats.cadence,
              power: activeMember.stats.power,
              powerBalance: activeMember.stats.powerBalance,
              speed: null,
              heartRate: activeMember.stats.heartRate,
            }}
            cp20m={activeMember.cp}
            avgValues={{
              powerAvg: activeMember.stats.powerAvg,
              cadenceAvg: activeMember.stats.cadenceAvg,
            }}
            coachView
          />
          <DefaultText style={{ color: scoreLineColor }}>{textUnderGauge}</DefaultText>
        </>
      )}
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PropsType;

const mapStateToProps = (state: StateInterface) => ({
  currentStepType: state.activeTraining.currentSteps.currentStep?.intervalType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeUserCp20m: (userEmail: string, cp20m: number) =>
    dispatch(CoachControlsCreators.changeUserCp20m(userEmail, cp20m)),
});

export const ActiveMemberGauge = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActiveMemberGaugeComponent);
