/* ------------- Initial State ------------- */
import { Device } from "react-native-ble-plx";
import { SmartTrainerBase } from "../../sensors/common/SmartTrainerBase";
import { SensorBase } from "../../sensors/common/SensorBase";
import { DeviceDataPoint } from "./metricsTypes";

export interface IDevicesState {
  bleSmartTrainerConnectionStatus: CONNECTION_STATUS_TYPES;
  blePowerMeterConnectionStatus: CONNECTION_STATUS_TYPES;
  bleHeartRateMeterConnectionStatus: CONNECTION_STATUS_TYPES;
  bleSpeedCadenceSensorConnectionStatus: CONNECTION_STATUS_TYPES;
  remark: BLE_REMARK_TYPES;
  data: DeviceDataPoint | null;
  bluetoothDevices: Array<any>;
  smartTrainerDevice: SmartTrainerBase | null;
  powerMeterDevice: SensorBase | null;
  heartRateMeterDevice: SensorBase | null;
  speedCadenceSensorDevice: SensorBase | null;
  cscSupportsCadence: boolean;
  cscSupportsSpeed: boolean;
  grade: number;
  isSearchingDevices: boolean;
  isNothingFounded: boolean;
}

/* ------------- Reducers Actions ------------- */

export interface ResetDevicesListAction {
  type: string;
}

export interface SetDeviceAction {
  type: string;
  device: Device;
}

export interface UpdateSpecificBluetoothSensorAction {
  type: string;
  device: SensorBase | null;
  deviceType: DeviceType;
}

export interface CreateBluetoothSensorAction {
  type: string;
  device: BluetoothDevice;
  deviceType: DeviceType;
}

export interface CreateBluetoothSensorMobileAction {
  type: string;
  device: Device;
  deviceType: DeviceType;
}

export interface ReconnectDeviceAction {
  type: string;
  bluetoothSensor: SensorBase;
  device: BluetoothDevice;
  deviceType: DeviceType;
}

export interface SearchForBleDeviceAction {
  type: string;
  deviceType: DeviceType;
}

export interface BleStatusChangedAction {
  type: string;
  deviceType: DeviceType;
  status: CONNECTION_STATUS_TYPES;
  remark: BLE_REMARK_TYPES;
  deviceName?: string;
}

export interface DeviceDataChangedAction {
  type: string;
  deviceData: DeviceDataPoint;
  deviceDataSource: DeviceType;
}

export interface StartDeviceSearchingTimerAction {
  type: string;
}

export interface FinishDeviceSearchingTimerAction {
  type: string;
  isNothingFounded: boolean;
}

export enum CONNECTION_STATUS_TYPES {
  CONNECTED = "connected",
  SEARCHING = "searching",
  CONNECTING_DEVICE = "connecting-device",
  CONNECTING_SERVER = "connecting-server",
  CONNECTING_SERVICE = "connecting-service",
  FAILED = "failed",
  NOT_CONNECTED = "No device connected",
}

export enum BLE_REMARK_TYPES {
  NOT_CONNECTED = "not connected",
  NO_BLE_SUPPORT = "No BLE Support",
  BLUETOOTH_IS_NOT_SUPPORTED = "Bluetooth is not supported",
  FOUND_DEVICE = "found device",
  FOUND_SERVER = "found server",
  FOUND_SERVICE = "found service",
  FOUND_CHARACTERISTIC = "found characteristic",
  CONNECTED = "connected",
  NO_CONNECTED_DEVICE = "no connected device",
  DISCONNECTED = "disconnected",
  DEVICE_DISCONNECTED = "device disconnected",
}

export enum DeviceType {
  UnknownDevice,
  PowerMeterDevice,
  SpeedCadenceSensor,
  HeartRateMeterDevice,
  SmartTrainer,
}
