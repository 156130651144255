import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { COMMON_STYLES } from "../../themes/commonStyles";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    borderRadius: RFValue(20),
    overflow: "hidden",
    ...COMMON_STYLES.SHADOW_BOX,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: RFValue(18),
    paddingVertical: RFValue(13),
    borderBottomWidth: RFValue(1),
  },
  stepsCountContainer: { flexDirection: "row" },
  workoutName: {
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: RFValue(18),
    lineHeight: RFValue(27),
  },

  allSteps: {
    fontStyle: "italic",
    fontSize: RFValue(18),
    lineHeight: RFValue(27),
  },
  currentStep: {
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: RFValue(18),
    lineHeight: RFValue(27),
    color: "green",
    paddingRight: RFValue(8),
  },
});
