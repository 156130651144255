import {
  ActiveMemberInterface,
  ActiveMemberMetrics,
  ActiveMemberStats,
  CoachTrainingStatusInterface,
  ProfileInterface,
  LiveSegmentsHistoryInterface,
  TRAINING_STATUS_TYPES,
} from "../redux/types";
import { db } from "./config";

export const streamLiveTrainingById = (liveTrainingId: string, observer: any) => {
  return db?.collection("liveTrainings")?.doc(liveTrainingId)?.onSnapshot(observer) ?? (() => {});
};

export const updateLiveTrainingStatus = (
  liveTrainingId: string,
  liveTrainingStatus: CoachTrainingStatusInterface,
) => {
  return db
    ?.collection("liveTrainings")
    ?.doc(liveTrainingId)
    ?.update({ trainingStatus: liveTrainingStatus });
};

export const updateUserCp20mValue = (liveTrainingId: string, cpxUserId: string, cp20m: number) => {
  return db
    ?.collection("liveTrainings")
    ?.doc(liveTrainingId)
    ?.collection("activeMembers")
    ?.doc(`${cpxUserId}`)
    ?.update({ cp: cp20m });
};

export const connectUserToLiveTraining = (
  liveTrainingId: string,
  user: ProfileInterface,
  cp: number,
  cpxNickname: string,
) => {
  const newActiveMember: ActiveMemberInterface = {
    stats: {
      cadence: 0,
      powerBalance: 0,
      heartRate: 0,
      power: 0,
      powerAvg: 0,
      cadenceAvg: 0,
      score: 0,
      leaderboardScoreLine: "",
      currentSegmentIndex: null,
      accuracyStatus: {
        powerStatus: null,
        cadenceStatus: null,
        powerStatusAvg: null,
        cadenceStatusAvg: null,
      },
    },

    metrics: [],
    segmentsHistory: [],
    userId: user.cpxUserId || "",
    userName: user.full_name,
    nickName: cpxNickname,
    email: user.email,
    cp: cp,
  };
  return db
    ?.collection("liveTrainings")
    ?.doc(liveTrainingId)
    ?.collection("activeMembers")
    ?.doc(`${user.cpxUserId}`)
    ?.set(newActiveMember);
};

export const removeUserFromLiveTraining = (liveTrainingId: string, userId: string) => {
  return db
    ?.collection("liveTrainings")
    ?.doc(liveTrainingId)
    ?.collection("activeMembers")
    ?.doc(userId)
    ?.delete();
};

export const updatePersonalStats = (
  cpxUserId: string,
  liveTrainingId: string,
  stats: ActiveMemberStats,
  metrics: ActiveMemberMetrics[],
  segmentsHistory: LiveSegmentsHistoryInterface[],
) => {
  return db
    ?.collection("liveTrainings")
    ?.doc(liveTrainingId)
    ?.collection("activeMembers")
    ?.doc(`${cpxUserId}`)
    ?.update({ stats: stats, metrics: metrics, segmentsHistory: segmentsHistory });
};

export const reloadLiveTraining = (liveTrainingId: string, timestamp: number) => {
  const trainingStatus: CoachTrainingStatusInterface = {
    pauseTimestamp: 0,
    stepStartTimestamp: 0,
    trainingStatus: TRAINING_STATUS_TYPES.PENDING_START,
    workoutCurrentStepIndex: -1,
    workoutStartTimestamp: timestamp + 300000,
    segmentCurrentStepIndex: null,
  };
  return db?.collection("liveTrainings").doc(liveTrainingId).update({ trainingStatus });
};
