import React, { useCallback, useEffect, useRef } from "react";
import CoachControlsCreators from "../../redux/reducers/coachControlsReducer";
import { ScrollView, View, CheckBox, Dimensions } from "react-native";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { DefaultText } from "../index";
import { strLimit } from "../../utils/resizeHelper";
import { getTimeFromStepStart } from "../../redux/selectors";
import { StepInfoBlock } from "./StepInfoBlock";
import { MetricInfoBlock } from "./MetricInfoBlock";
import { getMemberWithPlaceList } from "../../utils/GameScoreCalculating";
import { useScrollIntoView } from "react-native-scroll-into-view";
const { height } = Dimensions.get("window");
const scrollOption = { insets: { bottom: height / 6, top: 0 } };

const LiveMembersMetricComponent = ({
  trainingSteps,
  currentSteps,
  activeMembers,
  timeFromStepStart,
  toggleVisibleMember,
}: CombinedProps) => {
  const sortedActiveMembers = activeMembers.sort((a, b) => a.nickName.localeCompare(b.nickName));
  const refContainer = useRef<View>(null);
  const scrollIntoView = useScrollIntoView();

  const scrollToCurrentStep = useCallback(() => {
    if (refContainer.current) {
      scrollIntoView(refContainer.current, scrollOption);
    }
  }, [refContainer, scrollIntoView]);

  useEffect(() => {
    scrollToCurrentStep();
  }, [currentSteps.currentCourseStepIndex, scrollToCurrentStep, currentSteps.currentStepIndex]);

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.scrollHelper}>
        <View style={[themedStyles.listItemContainer, themedStyles.firstCell]}>
          <DefaultText>Steps</DefaultText>
        </View>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          scrollEnabled={false}
          contentContainerStyle={themedStyles.header}
        >
          {sortedActiveMembers.map((member) => {
            return (
              <View key={member.userId}>
                <View
                  style={[themedStyles.listItemContainer, !member.isHide && themedStyles.webCell]}
                >
                  {!member.isHide && (
                    <DefaultText style={themedStyles.text}>
                      {strLimit(member.nickName, 10)}
                    </DefaultText>
                  )}
                  <CheckBox
                    value={!member.isHide}
                    onValueChange={() => toggleVisibleMember(member.userId)}
                    style={themedStyles.checkBox}
                  />
                </View>
              </View>
            );
          })}
        </ScrollView>
      </View>
      <ScrollView>
        {trainingSteps && (
          <View style={themedStyles.metricsContainer}>
            <View style={themedStyles.stepsContainer}>
              {trainingSteps.map((step, index) => {
                const ref = index === currentSteps.currentStepIndex ? refContainer : null;
                return (
                  <View key={`interval-id-${step.shortText}-${index}`} ref={ref}>
                    <StepInfoBlock
                      step={step}
                      index={index}
                      currentStepIndex={currentSteps.currentStepIndex}
                    />
                    {step.courseSegments &&
                      step.courseSegments.map((segment, segmentIndex) => (
                        <StepInfoBlock
                          key={`segment-id-${segment.end}-${segmentIndex}`}
                          step={segment}
                          index={999}
                          currentStepIndex={currentSteps.currentCourseStepIndex}
                          isSegment
                        />
                      ))}
                  </View>
                );
              })}
            </View>
            <ScrollView horizontal>
              <View style={themedStyles.activeMembersContainer}>
                {sortedActiveMembers.map((member) => {
                  const membersWithPlaceList = getMemberWithPlaceList(sortedActiveMembers, member);

                  const memberWithPlace = membersWithPlaceList.find(
                    (item) => item.userId === member.userId,
                  );

                  return (
                    <View key={member.userId}>
                      {trainingSteps?.map((item, index) => {
                        return (
                          <View key={`interval-info-id-${item.shortText}-${index}`}>
                            <MetricInfoBlock
                              index={index}
                              member={memberWithPlace || member}
                              step={item}
                              currentStepIndex={currentSteps.currentStepIndex}
                              timeFromStepStart={timeFromStepStart}
                            />
                            {item.courseSegments &&
                              item.courseSegments.map((segment, segmentIndex) => {
                                const currentCourse = memberWithPlace?.segmentsHistory.find(
                                  (item) => item.courseId === segment.course,
                                );
                                const currentStepIndex = currentCourse
                                  ? currentCourse.currentSegmentIndex
                                  : null;
                                return (
                                  <MetricInfoBlock
                                    key={`segment-id-${segment.end}-${segmentIndex}`}
                                    index={segmentIndex}
                                    member={memberWithPlace || member}
                                    step={segment}
                                    currentStepIndex={currentStepIndex}
                                    timeFromStepStart={timeFromStepStart}
                                    isSegmentStep
                                    currentCourseId={currentCourse?.courseId}
                                  />
                                );
                              })}
                          </View>
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </ScrollView>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  trainingSteps: state.activeTraining.plan?.steps,
  currentSteps: state.activeTraining.currentSteps,
  activeMembers: state.coachControls.activeMembers,
  timeFromStepStart: getTimeFromStepStart(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleVisibleMember: (id: string) => dispatch(CoachControlsCreators.toggleVisibleMember(id)),
});
export const LiveMembersMetric = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveMembersMetricComponent);
