import { combineReducers } from "redux";
import {
  ActiveTrainingState,
  CoachControlsState,
  IDevicesState,
  MetricsState,
  SessionsState,
  SettingsState,
  SubscriptionState,
  TimerState,
  TrainingDetailsState,
  UserState,
  WorkoutsState,
  ToolTipState,
  NotificationState,
} from "../types";
import { activeTrainingReducer } from "./activeTrainingReducer";
import { devicesReducer } from "./devicesReducer";
import { sessionsReducer } from "./sessionsReducer";
import { settingsReducer } from "./settingsReducer";
import { timerReducer } from "./timerReducer";
import { trainingDetailsReducer } from "./trainingDetailsReducer";
import { userReducer } from "./userReducer";
import { workoutsReducer } from "./workoutsReducer";
import { metricsReducer } from "./metricsReducer";
import { coachControlsReducer } from "./coachControlsReducer";
import { subscriptionReducer } from "./subscriptionReducer";
import { toolTipReducer } from "./toolTipReducer";
import { notificationsReducer } from "./notificationsReducer";

export interface StateInterface {
  timer: TimerState;
  sessions: SessionsState;
  workouts: WorkoutsState;
  devices: IDevicesState;
  user: UserState;
  settings: SettingsState;
  trainingDetails: TrainingDetailsState;
  activeTraining: ActiveTrainingState;
  metrics: MetricsState;
  coachControls: CoachControlsState;
  subscription: SubscriptionState;
  toolTip: ToolTipState;
  notification: NotificationState;
}

const allReducers = combineReducers({
  timer: timerReducer,
  sessions: sessionsReducer,
  workouts: workoutsReducer,
  devices: devicesReducer,
  user: userReducer,
  settings: settingsReducer,
  trainingDetails: trainingDetailsReducer,
  activeTraining: activeTrainingReducer,
  metrics: metricsReducer,
  coachControls: coachControlsReducer,
  subscription: subscriptionReducer,
  toolTip: toolTipReducer,
  notification: notificationsReducer,
});

export default allReducers;
