import { isIphoneX } from "react-native-iphone-x-helper";
import { Platform, StatusBar, Dimensions } from "react-native";
import { DEFAULT_DISPLAY_HEIGHT } from "../constants";

export const RFValue = (fontSize: number, standardScreenHeight = DEFAULT_DISPLAY_HEIGHT) => {
  const { height, width } = Dimensions.get("window");
  const standardLength = width > height ? width : height;
  const offset = width > height ? 0 : Platform.OS === "ios" ? 78 : StatusBar.currentHeight; // iPhone X style SafeAreaView size in portrait

  const deviceHeight =
    isIphoneX() || Platform.OS === "android" ? standardLength - (offset || 0) : standardLength;

  const heightPercent = (fontSize * deviceHeight) / standardScreenHeight;
  return Math.round(heightPercent);
};

export const RFMinValue = (size: number) => {
  return RFValue(size) < size ? size : RFValue(size);
};

export const strLimit = (string: string, limit: number) => {
  return string.length > limit ? string.substring(0, limit - 3) + "..." : string;
};
