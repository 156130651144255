import React from "react";
import { View } from "react-native";
import { ACCOUNT_STATUS, SubscriptionInterface } from "../../../../../redux/types";
import { DefaultText } from "../../../../index";
import { themedStyles } from "./styles";

type PropsType = {
  subscriptions: SubscriptionInterface[] | null;
  accountStatus: ACCOUNT_STATUS | null;
};

export const AccountInfoBlock = ({ subscriptions, accountStatus }: PropsType) => {
  const accountStatusInfo =
    accountStatus === ACCOUNT_STATUS.TRIAL
      ? "Trial Account"
      : accountStatus === ACCOUNT_STATUS.MEMBERSHIP
      ? "Membership"
      : "You do not have an active PowerWatts Online Membership!";

  return (
    <View style={themedStyles.container}>
      <DefaultText style={themedStyles.accountStatus}>{accountStatusInfo}</DefaultText>
      {subscriptions &&
        subscriptions.map((subscription, index) => {
          return (
            <View style={themedStyles.subscriptionContainer} key={`subscription-${index}`}>
              <DefaultText>{subscription.name}</DefaultText>
              <DefaultText>{subscription.type}</DefaultText>
              <DefaultText>{`${subscription.period} ${
                subscription.is_trial ? "Trial" : ""
              }`}</DefaultText>
            </View>
          );
        })}
    </View>
  );
};
