import { call, put, select } from "redux-saga/effects";
import {
  reloadLiveTraining,
  removeUserFromLiveTraining,
  updateLiveTrainingStatus,
  updateUserCp20mValue,
} from "../../services/firestore";
import { StateInterface } from "../reducers";
import ActiveTrainingCreators from "../reducers/activeTrainingReducer";
import MetricsCreators from "../reducers/metricsReducer";
import TimerCreators from "../reducers/timerReducer";
import TrainingDetailsCreators from "../reducers/trainingDetailsReducer";
import {
  ChangeSessionWorkoutAction,
  CoachTrainingStatusInterface,
  NotificationLocation,
  RemoveMemberAction,
  TRAINING_STATUS_TYPES,
  TRAINING_TYPES,
  TypesOfNotification,
  ChangeUserCp20mAction,
  USER_TYPES,
} from "../types";
import { CoachApi } from "../../api/api";
import NotificationsCreators from "../reducers/notificationsReducer";

/*-------- Start --------*/

export function* startLiveTraining() {
  const trainingType: TRAINING_TYPES = yield select(
    (state: StateInterface) => state.trainingDetails.trainingType,
  );
  const userType: USER_TYPES | null = yield select((state: StateInterface) => state.user.userType);
  const coachId: string = yield select(
    (state: StateInterface) => state.user.coachProfile?.coach_id,
  );
  const trainingCoachId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.coachId,
  );

  if (
    trainingType === TRAINING_TYPES.LIVE_SESSION &&
    userType === USER_TYPES.COACH &&
    coachId === trainingCoachId
  ) {
    const currentStepIndex: number = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex || 0,
    );

    const liveTrainingId: string = yield select(
      (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
    );

    const trainingStatus: TRAINING_STATUS_TYPES = yield select(
      (state: StateInterface) => state.activeTraining.status,
    );

    const pauseTimestamp: number = yield select(
      (state: StateInterface) => state.timer.pauseTimestamp,
    );

    const currentTrainingStatus =
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED
        ? TRAINING_STATUS_TYPES.PAUSED
        : TRAINING_STATUS_TYPES.STARTED;

    const firstStepStartTimeStamp: number = yield select(
      (state: StateInterface) => state.timer.dateTime,
    );

    const isSegmentStep: boolean = yield select(
      (state: StateInterface) => !!state.activeTraining.currentSteps.currentStep?.courseSegments,
    );

    const coachTrainingStatus: CoachTrainingStatusInterface = {
      pauseTimestamp: pauseTimestamp,
      stepStartTimestamp: firstStepStartTimeStamp,
      trainingStatus: currentTrainingStatus,
      workoutCurrentStepIndex: currentStepIndex,
      workoutStartTimestamp: firstStepStartTimeStamp,
      segmentCurrentStepIndex: isSegmentStep ? 0 : null,
    };

    yield call(updateLiveTrainingStatus, liveTrainingId, coachTrainingStatus);
  }
}

/*-------- Next step --------*/

export function* nextLiveTrainingStep() {
  const trainingType: TRAINING_TYPES = yield select(
    (state: StateInterface) => state.trainingDetails.trainingType,
  );
  const userType: USER_TYPES | null = yield select((state: StateInterface) => state.user.userType);
  const coachId: string = yield select(
    (state: StateInterface) => state.user.coachProfile?.coach_id,
  );
  const trainingCoachId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.coachId,
  );
  if (
    trainingType === TRAINING_TYPES.LIVE_SESSION &&
    userType === USER_TYPES.COACH &&
    coachId === trainingCoachId
  ) {
    const trainingStatus: TRAINING_STATUS_TYPES = yield select(
      (state: StateInterface) => state.activeTraining.status,
    );

    if (
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START ||
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED
    ) {
      yield call(startLiveTraining);
    } else {
      const currentStepIndex: number = yield select(
        (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex,
      );
      const nextStepIndex = currentStepIndex + 1;
      const liveTrainingId: string = yield select(
        (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
      );

      const workoutStartTimestamp: number = yield select(
        (state: StateInterface) => state.timer.workoutStartTimestamp,
      );

      const currentStepStartTimeStamp: number = yield select(
        (state: StateInterface) => state.timer.dateTime,
      );
      const pauseTimestamp: number = yield select(
        (state: StateInterface) => state.timer.pauseTimestamp,
      );

      const isSegmentStep: boolean = yield select(
        (state: StateInterface) => !!state.activeTraining.currentSteps.nextStep?.courseSegments,
      );

      const coachTrainingStatus: CoachTrainingStatusInterface = {
        pauseTimestamp: pauseTimestamp,
        stepStartTimestamp: currentStepStartTimeStamp,
        trainingStatus: trainingStatus,
        workoutCurrentStepIndex: nextStepIndex,
        workoutStartTimestamp: workoutStartTimestamp,
        segmentCurrentStepIndex: isSegmentStep ? 0 : null,
      };

      yield call(updateLiveTrainingStatus, liveTrainingId, coachTrainingStatus);
    }
  }
}

/*-------- Pause --------*/

export function* pauseLiveTrainingTraining() {
  const trainingType: TRAINING_TYPES = yield select(
    (state: StateInterface) => state.trainingDetails.trainingType,
  );
  const userType: USER_TYPES | null = yield select((state: StateInterface) => state.user.userType);
  const coachId: string = yield select(
    (state: StateInterface) => state.user.coachProfile?.coach_id,
  );
  const trainingCoachId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.coachId,
  );
  if (
    trainingType === TRAINING_TYPES.LIVE_SESSION &&
    userType === USER_TYPES.COACH &&
    coachId === trainingCoachId
  ) {
    const trainingStatus: TRAINING_STATUS_TYPES = yield select(
      (state: StateInterface) => state.activeTraining.status,
    );
    const pauseTrainingTimestamp: number = yield select(
      (state: StateInterface) => state.timer.dateTime,
    );
    yield put(TimerCreators.pauseTimer());

    const stepStartTimestamp: number = yield select(
      (state: StateInterface) => state.timer.stepStartTimestamp,
    );

    const currentIndex: number = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex,
    );

    const workoutStartTimestamp: number = yield select(
      (state: StateInterface) => state.timer.workoutStartTimestamp,
    );

    const currentTrainingStatus =
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START
        ? TRAINING_STATUS_TYPES.PENDING_START_PAUSED
        : TRAINING_STATUS_TYPES.PAUSED;

    const liveTrainingId: string = yield select(
      (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
    );

    const isSegmentStep: boolean = yield select(
      (state: StateInterface) => !!state.activeTraining.currentSteps.currentStep?.courseSegments,
    );

    const coachTrainingStatus: CoachTrainingStatusInterface = {
      pauseTimestamp: pauseTrainingTimestamp,
      stepStartTimestamp: stepStartTimestamp,
      trainingStatus: currentTrainingStatus,
      workoutCurrentStepIndex: currentIndex,
      workoutStartTimestamp: workoutStartTimestamp,
      segmentCurrentStepIndex: isSegmentStep ? 0 : null,
    };

    yield call(updateLiveTrainingStatus, liveTrainingId, coachTrainingStatus);
  }
}

/*-------- Resume --------*/

export function* resumeLiveTraining() {
  const trainingType: TRAINING_TYPES = yield select(
    (state: StateInterface) => state.trainingDetails.trainingType,
  );
  const userType: USER_TYPES | null = yield select((state: StateInterface) => state.user.userType);
  const coachId: string = yield select(
    (state: StateInterface) => state.user.coachProfile?.coach_id,
  );
  const trainingCoachId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.coachId,
  );
  if (
    trainingType === TRAINING_TYPES.LIVE_SESSION &&
    userType === USER_TYPES.COACH &&
    coachId === trainingCoachId
  ) {
    const trainingStatus: TRAINING_STATUS_TYPES = yield select(
      (state: StateInterface) => state.activeTraining.status,
    );
    const currentStepIndex: number = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex || 0,
    );
    const pauseTimestamp: number = yield select(
      (state: StateInterface) => state.timer.pauseTimestamp,
    );
    const resumeTimestamp: number = yield select((state: StateInterface) => state.timer.dateTime);
    const pauseDuration = resumeTimestamp - pauseTimestamp;
    const workoutStartTimestamp: number = yield select(
      (state: StateInterface) => state.timer.workoutStartTimestamp,
    );
    const stepStartTimestamp: number = yield select(
      (state: StateInterface) => state.timer.stepStartTimestamp,
    );

    const workoutStartTimestampAfterResume =
      currentStepIndex === 0 && trainingStatus !== TRAINING_STATUS_TYPES.PENDING_START_PAUSED
        ? resumeTimestamp
        : workoutStartTimestamp + pauseDuration;

    const stepStartTimestampAfterResume =
      currentStepIndex === 0 && trainingStatus !== TRAINING_STATUS_TYPES.PENDING_START_PAUSED
        ? resumeTimestamp
        : stepStartTimestamp + pauseDuration;

    const currentTrainingStatus =
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED
        ? TRAINING_STATUS_TYPES.PENDING_START
        : TRAINING_STATUS_TYPES.STARTED;

    const currentIndex: number = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentStepIndex,
    );

    const liveTrainingId: string = yield select(
      (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
    );

    const isSegmentStep: boolean = yield select(
      (state: StateInterface) => !!state.activeTraining.currentSteps.currentStep?.courseSegments,
    );

    const coachTrainingStatus: CoachTrainingStatusInterface = {
      pauseTimestamp: workoutStartTimestamp,
      stepStartTimestamp: stepStartTimestampAfterResume,
      trainingStatus: currentTrainingStatus,
      workoutCurrentStepIndex: currentIndex,
      workoutStartTimestamp: workoutStartTimestampAfterResume,
      segmentCurrentStepIndex: isSegmentStep ? 0 : null,
    };

    yield call(updateLiveTrainingStatus, liveTrainingId, coachTrainingStatus);
  }
}

/*-------- Finish --------*/

export function* coachFinishLiveSession() {
  const trainingType: TRAINING_TYPES = yield select(
    (state: StateInterface) => state.trainingDetails.trainingType,
  );
  const userType: USER_TYPES | null = yield select((state: StateInterface) => state.user.userType);
  const coachId: string = yield select(
    (state: StateInterface) => state.user.coachProfile?.coach_id,
  );
  const trainingCoachId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.coachId,
  );
  if (
    trainingType === TRAINING_TYPES.LIVE_SESSION &&
    userType === USER_TYPES.COACH &&
    coachId === trainingCoachId
  ) {
    const liveTrainingId: string = yield select(
      (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
    );

    const workoutStartTimestamp: number = yield select(
      (state: StateInterface) => state.timer.workoutStartTimestamp,
    );

    const isSegmentStep: boolean = yield select(
      (state: StateInterface) => !!state.activeTraining.currentSteps.currentStep?.courseSegments,
    );

    const coachTrainingStatus: CoachTrainingStatusInterface = {
      pauseTimestamp: 0,
      stepStartTimestamp: 0,
      trainingStatus: TRAINING_STATUS_TYPES.FINISHED,
      workoutCurrentStepIndex: -1,
      workoutStartTimestamp: workoutStartTimestamp,
      segmentCurrentStepIndex: isSegmentStep ? 0 : null,
    };
    yield call(updateLiveTrainingStatus, liveTrainingId, coachTrainingStatus);
  }
}

/*-------- Change Session Workout --------*/

export function* changeSessionWorkout({ workoutId }: ChangeSessionWorkoutAction) {
  const trainingType: TRAINING_TYPES = yield select(
    (state: StateInterface) => state.trainingDetails.trainingType,
  );
  const userType: USER_TYPES | null = yield select((state: StateInterface) => state.user.userType);
  const coachId: string = yield select(
    (state: StateInterface) => state.user.coachProfile?.coach_id,
  );
  const trainingCoachId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.coachId,
  );
  if (
    trainingType === TRAINING_TYPES.LIVE_SESSION &&
    userType === USER_TYPES.COACH &&
    coachId === trainingCoachId
  ) {
    const externalId: string = yield select(
      (state: StateInterface) => state.trainingDetails.detailsInfo?.externalId,
    );
    try {
      yield CoachApi.changeSessionWorkout(workoutId, externalId);
    } catch (error) {
      yield put(
        NotificationsCreators.addNotification({
          location: NotificationLocation.CouchSaga,
          notificationType: TypesOfNotification.Error,
          description: `${error}`,
        }),
      );
    }
  }
}

/*-------- Remove Member --------*/

export function* removeMember({ removeMemberInfo }: RemoveMemberAction) {
  try {
    yield call(
      removeUserFromLiveTraining,
      removeMemberInfo.liveTrainingId,
      removeMemberInfo.userId,
    );
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.CouchSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}

/*-------- Restart Session --------*/

export function* restartSession() {
  const sessionId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
  );
  const timeDate: number = yield select((state: StateInterface) => state.timer.dateTime);
  try {
    yield put(TrainingDetailsCreators.resetTrainingDetails());
    yield put(ActiveTrainingCreators.resetActiveTraining());
    yield put(TimerCreators.resetTimer());
    yield put(MetricsCreators.resetMetrics());
    yield call(reloadLiveTraining, sessionId, timeDate);
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.CouchSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}

/*-------- Change cp20m --------*/

export function* changeUserCp20m({ cpxUserId, cp20m }: ChangeUserCp20mAction) {
  const liveTrainingId: string = yield select(
    (state: StateInterface) => state.trainingDetails.detailsInfo?.sessionId,
  );
  yield call(updateUserCp20mValue, liveTrainingId, cpxUserId, cp20m);
}
