import React from "react";
import { View, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { round } from "../../utils/Statistics";
import { DefaultText } from "../index";

import { GaugeBlock } from "./GaugeBlock";
import { COURSE_MOVEMENT_TYPE, TRAINING_TYPES } from "../../redux/types";
import { NewRecordsBlock } from "./NewRecordsBlock";
import { MyIcon } from "../CommonElements";
import { ICON } from "../../constants";

interface StatisticInfoProps {
  openAchievementInfoModal: () => void;
}

export function StatisticInfoComponent({
  workoutMetrics,
  deviceData,
  isHiddenStep,
  cp20m,
  newRecords,
  trainingType,
  courseDistance,
  courseMovementStatus,
  openAchievementInfoModal,
}: CombinedProps) {
  const isLiveSession = trainingType === TRAINING_TYPES.LIVE_SESSION;
  const isCourseStep = courseMovementStatus === COURSE_MOVEMENT_TYPE.MOVING;
  const distance = isCourseStep ? courseDistance.toFixed(2) : workoutMetrics.distance.toFixed(2);
  const distanceStyles = isCourseStep
    ? [themedStyles.paramValue, themedStyles.currentParamValue]
    : themedStyles.paramValue;

  return (
    <View
      style={
        isLiveSession
          ? [themedStyles.container, themedStyles.liveSessionContainer]
          : themedStyles.container
      }
    >
      <NewRecordsBlock newRecords={newRecords} />
      <View style={themedStyles.gaugeContainer}>
        <TouchableOpacity onPress={openAchievementInfoModal} style={themedStyles.questionContainer}>
          <MyIcon icon={ICON.QUESTION_4} />
        </TouchableOpacity>
        <GaugeBlock deviceData={deviceData} cp20m={cp20m} isLiveSession={isLiveSession} />
      </View>
      <View style={themedStyles.bottomParamsContainer}>
        <View style={themedStyles.paramContainer}>
          <DefaultText style={themedStyles.paramName}>Dist (Km)</DefaultText>
          <DefaultText style={distanceStyles}>{distance}</DefaultText>
        </View>
        <View style={themedStyles.paramContainer}>
          <DefaultText style={themedStyles.paramName}>Kph</DefaultText>
          <DefaultText style={themedStyles.paramValue}>
            {workoutMetrics.speed.toFixed(1)}
          </DefaultText>
        </View>
        <View style={themedStyles.paramContainer}>
          <DefaultText style={themedStyles.paramName}>AvgW</DefaultText>
          <DefaultText style={themedStyles.paramValue}>
            {round(workoutMetrics?.avg_watts, 0)}
          </DefaultText>
        </View>
        <View style={themedStyles.paramContainer}>
          <DefaultText style={themedStyles.paramName}>Kj</DefaultText>
          <DefaultText style={themedStyles.paramValue}>{round(workoutMetrics?.kj, 0)}</DefaultText>
        </View>
      </View>
      {isHiddenStep && <View style={themedStyles.overlay} />}
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  StatisticInfoProps;

const mapStateToProps = (state: StateInterface) => ({
  workoutMetrics: state.metrics.workoutMetrics,
  deviceData: state.devices.data,
  isHiddenStep:
    state.activeTraining.currentSteps.currentStep?.visualExperience?.display_rider_metrics ===
    false,
  cp20m: state.settings.criticalPower,
  trainingType: state.trainingDetails.trainingType,
  courseDistance: state.metrics.currentMetrics.distance + state.metrics.savedDistance,
  courseMovementStatus: state.activeTraining.currentSteps.currentStep?.courseMovementStatus,
  newRecords: state.metrics.newRecords,
});

const mapDispatchToProps = (_dispatch: Dispatch) => ({});

export const StatisticInfo = connect(mapStateToProps, mapDispatchToProps)(StatisticInfoComponent);
