import React from "react";
import { View, FlatList } from "react-native";
import { WorkoutInterface } from "../../../redux/types";
import { themedStyles } from "./styles";
import { TrainingItem } from "../../TrainingItem";
import { DefaultText } from "../../CommonElements/DefaultText";
import { ICON } from "../../../constants";

interface PropsType {
  workouts: WorkoutInterface[] | null;
  placeholder?: string;
  withoutPaddings?: boolean;
  withSoloVideoButtons?: boolean;
}

export function WorkoutList({
  workouts,
  placeholder,
  withoutPaddings,
  withSoloVideoButtons,
}: PropsType) {
  const emptyRender = () => {
    return <DefaultText style={themedStyles.placeholder}>{placeholder}</DefaultText>;
  };

  return (
    <View
      style={
        withoutPaddings
          ? themedStyles.contentContainer
          : [themedStyles.contentContainer, themedStyles.paddings]
      }
    >
      {workouts && (
        <FlatList
          style={themedStyles.scrollView}
          nestedScrollEnabled={true}
          keyExtractor={(item) => item.id}
          ListEmptyComponent={emptyRender}
          data={workouts}
          renderItem={({ item }) => (
            <TrainingItem
              icon={ICON.PAD}
              trainingName={item.name}
              category={item.category}
              virtualCoach={item.virtual_coach}
              wid={item.id}
              coachNoteId={item.wid}
              withSoloVideoButtons={withSoloVideoButtons}
              supportedLanguages={item.supported_languages}
            />
          )}
        />
      )}
    </View>
  );
}
