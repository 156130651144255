/* ------------- Initial State ------------- */

export interface TrainingDetailsState {
  trainingType: TRAINING_TYPES | null;
  detailsInfo: DetailsInfoInterface | null;
}

/* ------------- Reducers Actions ------------- */

export interface SetTrainingInfoAction {
  type: string;
  trainingType: TRAINING_TYPES;
  detailsInfo: DetailsInfoInterface;
}

export interface InitializeTrainingAction {
  type: string;
  trainingId: string;
  lang?: string;
  isItCoach?: boolean;
}

export interface RemoveMemberAction {
  type: string;
  removeMemberInfo: RemoveMemberInfoInterface;
}

/* ------------- State Elements ------------- */

export interface DetailsInfoInterface {
  autoStartAt: number;
  autoStartInSeconds: number;
  coachId: string | null;
  coachName: string | null;
  externalId: string | null;
  sessionId: string | null;
  videoId: string | null;
  videoStartSec: string | null;
  workoutUrl: string | null;
  zoomInfo: ZoomInfoInterface | null;
}

export enum TRAINING_TYPES {
  WORKOUT = "WORKOUT",
  VIRTUAL_COACH = "VIRTUAL_COACH",
  LIVE_SESSION = "LIVE_SESSION",
}

// export interface LiveTrainingDetailsInfo {
//   type: string;
//   workoutStartTimestamp: number;
// }

export interface RemoveMemberInfoInterface {
  liveTrainingId: string;
  userId: string;
}
export interface ZoomInfoInterface {
  api_key: string;
  display_name: string;
  signature: string;
  zoom_meeting_number: string;
  zoom_password: string;
  zoom_sdk_version: string;
}
export interface ResponseZoomConnection {
  success: boolean;
  zoom: ZoomInfoInterface;
}
