import React, { useMemo } from "react";
import { View } from "react-native";
import { themedStyles } from "./styles";
import { StepInfoLine, DefaultText } from "../../index";
import { WorkoutIntervalInterface } from "../../../redux/types";
import { isGameInterval } from "../../../utils/Statistics";
import { CourseSegment } from "../../CourseSegment";

type StepInfoBlockPropsType = {
  step: WorkoutIntervalInterface;
  index: number;
  currentStepIndex: number | null;
  isSegment?: boolean;
};

const _StepInfoBlock = ({ step, index, currentStepIndex, isSegment }: StepInfoBlockPropsType) => {
  const style = [
    themedStyles.listItemContainer,
    themedStyles.firstCell,
    index % 2 !== 0 && themedStyles.secondListItemContainer,
    currentStepIndex === index && themedStyles.activeCell,
    themedStyles.columnContainer,
  ];
  const isGameStep = useMemo(() => isGameInterval(step?.intervalType), [step]);
  return (
    <View style={style}>
      {isSegment ? <CourseSegment segment={step} /> : <StepInfoLine step={step} />}
      {isGameStep && <DefaultText style={themedStyles.scoreLineText}>Game step</DefaultText>}
    </View>
  );
};

export const StepInfoBlock = React.memo(_StepInfoBlock, (prevProps, nextProps) => {
  return prevProps.currentStepIndex === nextProps.currentStepIndex;
});
