import { StyleSheet } from "react-native";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  defaultViewStyle: {
    width: "max-content",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(8),
    borderRadius: RFValue(4),
  },
  defaultTextStyle: {
    fontWeight: "500",
    fontSize: RFValue(16),
    lineHeight: RFValue(24),
  },
  iconContainer: {
    paddingLeft: RFValue(14.5),
    paddingRight: RFValue(6.5),
  },
});
