import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
// import { getProcessedSnapshots } from "../../services/socket";
import { ISnapshot } from "../../utils/types";
import {
  ActiveMemberInterface,
  SetActiveMembersAction,
  CoachControlsState,
  CoachTrainingStatusInterface,
  RemoveMemberInfoInterface,
  ToggleVisibleMemberAction,
} from "../types";

interface ActionTypesInterface {
  SET_COACH_TRAINING_STATUS: "SET_COACH_TRAINING_STATUS";
  SET_ACTIVE_MEMBERS: "SET_ACTIVE_MEMBERS";
  CHANGE_SESSION_WORKOUT: "CHANGE_SESSION_WORKOUT";
  TOGGLE_VISIBLE_MEMBER: "TOGGLE_VISIBLE_MEMBER";
  REMOVE_MEMBER: "REMOVE_MEMBER";
  RESTART_SESSION: "RESTART_SESSION";
  COACH_FINISH_LIVE_SESSION: "COACH_FINISH_LIVE_SESSION";
  CHANGE_USER_CP20M: "CHANGE_USER_CP20M";
}

interface ActionCreatorsInterface extends ActionCreators {
  setCoachTrainingStatus: (coachTrainingStatus: CoachTrainingStatusInterface) => AnyAction;
  setActiveMembers: (activeMembersSnapshot: ISnapshot<ActiveMemberInterface>[]) => AnyAction;
  changeSessionWorkout: (workoutId: string) => AnyAction;
  toggleVisibleMember: (id: string) => AnyAction;
  removeMember: (removeMemberInfo: RemoveMemberInfoInterface) => AnyAction;
  restartSession: () => AnyAction;
  coachFinishLiveSession: () => AnyAction;
  changeUserCp20m: (cpxUserId: string, cp20m: number) => AnyAction;
}

type Handler<A> = (state: CoachControlsState, action: A) => CoachControlsState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  createLiveTraining: ["coach", "workout", "detailsInfo"],
  setCoachTrainingStatus: ["coachTrainingStatus"],
  setActiveMembers: ["activeMembersSnapshot"],
  changeSessionWorkout: ["workoutId"],
  toggleVisibleMember: ["id"],
  removeMember: ["removeMemberInfo"],
  restartSession: null,
  coachFinishLiveSession: null,
  changeUserCp20m: ["cpxUserId", "cp20m"],
});

export const CoachControlsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: CoachControlsState = {
  activeMembers: [],
};

/* ------------- Reducers ------------- */

const setActiveMembers: Handler<SetActiveMembersAction> = (state /*, { activeMembersSnapshot } */) => {
  // const activeMembersArr = getProcessedSnapshots(
  //   state.activeMembers,
  //   activeMembersSnapshot,
  //   "userId",
  // );

  // activeMembersArr.forEach((item) => {
  //   item.isHide = state.activeMembers.find((member) => member.userId === item.userId)?.isHide;
  // });

  return {
    ...state,
    activeMembers: [], // [...activeMembersArr],
  };
};

const toggleVisibleMember: Handler<ToggleVisibleMemberAction> = (state, { id }) => {
  const activeMembers = state.activeMembers.map((item) => {
    if (item.userId === id) {
      item.isHide = !item.isHide;
    }
    return item;
  });
  return {
    ...state,
    activeMembers: [...activeMembers],
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_ACTIVE_MEMBERS]: setActiveMembers,
  [Types.TOGGLE_VISIBLE_MEMBER]: toggleVisibleMember,
};

export const coachControlsReducer = createReducer(INITIAL_STATE, HANDLERS);
