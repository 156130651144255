import React, { useEffect, useState } from "react";
import { View, Platform, LayoutChangeEvent } from "react-native";
import { DefaultText, MyIcon } from "../../index";
import { RFValue } from "../../../utils/resizeHelper";
import { DEFAULT_DISPLAY_HEIGHT } from "../../../constants";
import { themedStyles } from "./styles";

interface PropsType {
  icon: string;
  score: string;
  color: string;
  isUpdateScoreLine: boolean;
  setLeaderboardScoreLine: (scoreLine: string) => void;
}

const _GameAccuracy = ({
  icon,
  score,
  color,
  isUpdateScoreLine,
  setLeaderboardScoreLine,
}: PropsType) => {
  useEffect(() => {
    if (isUpdateScoreLine) {
      setLeaderboardScoreLine(score);
    }
  }, [score, isUpdateScoreLine, setLeaderboardScoreLine]);

  const [acHeight, setAcHeight] = useState(0);

  const onLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setAcHeight(width);
  };

  const iconSize = Platform.OS === "web" ? RFValue(32, DEFAULT_DISPLAY_HEIGHT) : 26;

  return (
    <View
      style={[themedStyles.container, { backgroundColor: color, height: acHeight }]}
      onLayout={onLayout}
    >
      <MyIcon icon={icon} size={iconSize} />
      <DefaultText style={themedStyles.text}>{score}</DefaultText>
    </View>
  );
};

export const GameAccuracy = React.memo(_GameAccuracy, (prevProps, nextProps) => {
  return prevProps.score === nextProps.score;
});
