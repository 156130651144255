import { StyleSheet } from "react-native";
import { RFMinValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    position: "relative",
    width: RFMinValue(500),
    alignItems: "center",
    justifyContent: "center",
  },
  liveSessionContainer: { width: RFMinValue(420) },

  gaugeSubContainer: {
    position: "absolute",
    width: "100%",
    height: "25%",
    bottom: 0,
  },
});
