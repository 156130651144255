import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  stravaContainer: {
    marginBottom: 10,
  },
  saveButton: {
    padding: RFValue(10),
    borderRadius: RFValue(8),
    backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
  },
  stravaButton: {
    alignSelf: "center",
  },
  trainingPeaksButton: {
    alignSelf: "center",
    borderRadius: 3,
    backgroundColor: "#fff",
    paddingHorizontal: 33,
    paddingVertical: 2,
  },
  trainingPeaksImage: {
    height: 36,
    width: 120,
  },
});
