/* ------------- Initial State ------------- */

import { FETCHING_STATUS } from "../../constants";
import {
  IntroductionWorkoutInterface,
  WorkoutInterface,
  SessionInterface,
  TrainingPeaksWodInterface,
  ServicesConnectedInterface,
} from "./";

export interface UserState {
  isAuthenticated: boolean;
  isAuthorizedChecked: boolean;
  isFullProfileReceived: boolean;
  userType: USER_TYPES | null;
  token: string | null;
  profile: ProfileInterface | null;
  cpxProfile: CpxProfileInterface | null;
  coachProfile: CoachProfileInterface | null;
  isUpdateModalVisible: boolean;
  updateStatus: null | FETCHING_STATUS;
  isGBUser: boolean;
  userAccessLvl: number | null;
}

/* ------------- Reducers Actions ------------- */

export interface SetIsAuthorizedCheckedAction {
  type: string;
  isAuthorizedChecked: boolean;
}

export interface SetUserProfileAction {
  type: string;
  token: string;
  profile: ProfileInterface;
}

export interface SetUserAccessLvlAction {
  type: string;
  userAccessLvl: number;
}

export interface SetCoachProfileAction {
  type: string;
  coachProfile: CoachProfileInterface;
}

export interface SetCpxUserProfileAction {
  type: string;
  cpxProfile: CpxProfileInterface;
}

export interface SetUserTypeAction {
  type: string;
  userType: USER_TYPES;
}

export interface InitializeUserAction {
  type: string;
  token: string;
  user: Auth0UserInterface;
}

export interface UpdateUserProfileAction {
  type: string;
  updatedUserProfile: UpdatedUserProfileInterface;
}

export interface UpdateUserProfileSuccessAction {
  type: string;
  updatedUserProfile: UpdatedUserProfileInterface;
}

export interface UpdateUserProfileFailureAction {
  type: string;
}

export interface ToggleUpdateProfileModalAction {
  type: string;
  isVisible: boolean;
}

/* ------------- State Elements ------------- */

export interface ProfileInterface {
  first_name: string;
  last_name: string;
  nickname: string;
  full_name: string;
  picture: string;
  email: string;
  cpxUserId?: string | null;
}

export interface CpxProfileInterface {
  birthDate: string | null;
  age?: number | null;
  weight: number | null;
  height: number | null;
  isMale: boolean;
  country: string | null;
  primaryPowerWattsCenter: string | null;
  wPrime: number | null;
  wPrimeCP: number | null;
  cpxNickname: string | null;
  name: string | null;
  personalRecords: PersonalRecordInterface[];
}

export interface CoachProfileInterface {
  coach_id: string | null;
  first_name: string | null;
  last_name: string | null;
  center: string | null;
}

export enum USER_TYPES {
  USER = "USER",
  CPX_USER = "CPX_USER",
  COACH = "COACH",
}

export interface Auth0UserInterface {
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  locale?: string;
  name?: string;
  nickname?: string;
  picture?: string;
  sub?: string;
  updated_at?: string;
  "https://myapp.example.com/CPXUserId"?: string;
  roles?: "Client" | "Coach" | "Admin";
}

export interface GetCpxUserProfileResponse {
  Address: null | string;
  BirthDate: null | string;
  City: null | string;
  ConnectedToStrava: true;
  ConnectedToTrainingPeaks: false;
  Country: null | string;
  Cp1M: null | number;
  Cp2M: null | number;
  Cp3M: null | number;
  Cp5M: null | number;
  Cp5S: null | number;
  Cp10M: null | number;
  Cp10S: null | number;
  Cp20M: null | number;
  Cp20S: null | number;
  Cp30M: null | number;
  Cp30S: null | number;
  Cp45M: null | number;
  Cp45S: null | number;
  Cp60M: null | number;
  Email: string;
  Height: null | number;
  Id: string;
  Name: null | string;
  Nickname: null | string;
  Phone: null | string;
  PostalCode: null | string;
  PrimaryStudioIndex: null | number;
  ProfileCompleted: boolean;
  Referrer: null | string;
  Role: null | string;
  Sex: "male" | "female";
  State: null;
  WPrime: null | number;
  WPrimeCp: null | number;
  Weight: null | number;
  PersonalRecords: PersonalRecordInterface[];
}

export interface UpdatedUserProfileInterface {
  Name: string;
  Nickname: string;
  BirthDate: string;
  Sex: string;
  Height: number;
  Weight: number;
  Cp20M: number;
  Country: string;
  ProfileCompleted: boolean;
}

export interface PersonalRecordInterface {
  IntervalLength: number;
  Power: number;
  Rank: number;
  TimeFrame: number;
  Timestamp: string;
  Weight: number;
}

export interface GetFirebaseTokenResponse {
  data: string;
  status: number;
  statusText: string;
}

export interface FetchSessionsResponse {
  introductions: IntroductionWorkoutInterface[];
  sessions: SessionInterface[];
  success: boolean;
  workouts: WorkoutInterface[];
}

export interface FetchTrainingPeaksResponse {
  success: boolean;
  training_peaks_wods: TrainingPeaksWodInterface[];
}

export interface FetchConnectionsListResponse {
  success: boolean;
  connections: ServicesConnectedInterface;
}

export interface UpdateUserProfileResponse {
  status: number;
  statusText: string;
  data: GetCpxUserProfileResponse;
}
