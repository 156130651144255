import React, { useState } from "react";
import { View, Dimensions, Platform, LayoutChangeEvent } from "react-native";
import { UserMetricsGauge } from "../../index";
import { DeviceDataPoint } from "../../../redux/types";
import { AccuracyGauge } from "../../AccuracyGauge";
import { themedStyles } from "./styles";

const { width } = Dimensions.get("window");

type Props = {
  deviceData: DeviceDataPoint | null;
  cp20m: number;
  isLiveSession: boolean;
};

export function GaugeBlock({ deviceData, cp20m, isLiveSession }: Props) {
  const [gaugeTabletWidth, setGaugeTabletWidth] = useState(width);

  const onLayout = (e: LayoutChangeEvent) => {
    const { width, height } = e.nativeEvent.layout;
    Platform.OS === "web" ? setGaugeTabletWidth(width * 0.9) : setGaugeTabletWidth(height);
  };

  const getGaugeSize = () => {
    if (Platform.OS === "web") {
      if (isLiveSession) {
        return gaugeTabletWidth;
      } else return gaugeTabletWidth * 0.9;
    } else return gaugeTabletWidth;
  };

  const gaugeSize = getGaugeSize();
  return (
    <View
      onLayout={onLayout}
      style={
        isLiveSession
          ? [themedStyles.container, themedStyles.liveSessionContainer]
          : themedStyles.container
      }
    >
      <UserMetricsGauge
        width={gaugeSize}
        height={gaugeSize}
        cp20m={cp20m}
        deviceData={deviceData}
      />
      <View style={themedStyles.gaugeSubContainer}>
        <AccuracyGauge />
      </View>
    </View>
  );
}
