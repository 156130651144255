import React, { ReactNode } from "react";
import { View } from "react-native";
import { DefaultText } from "../../index";
import { themedStyles } from "./styles";
import { ViewProps } from "react-device-detect";
import { Button } from "../../CommonElements/Button";

interface DefaultModalProps extends ViewProps {
  title: string;
  children?: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirmButtonPress?: () => void;
  onCancelButtonPress?: () => void;
}

export const DefaultModal = ({
  title,
  children,
  confirmButtonText,
  cancelButtonText,
  onConfirmButtonPress,
  onCancelButtonPress,
  ...props
}: DefaultModalProps) => {
  return (
    <View style={themedStyles.container}>
      <View {...props} style={themedStyles.modalContainer}>
        <View style={themedStyles.titleContainer}>
          <DefaultText style={themedStyles.title}>{title}</DefaultText>
        </View>
        <View style={themedStyles.contentContainer}>{children}</View>
        <View style={themedStyles.buttonContainer}>
          {onCancelButtonPress && (
            <Button
              onPress={onCancelButtonPress}
              style={[themedStyles.button, themedStyles.cancelButton]}
            >
              {cancelButtonText || "cancel"}
            </Button>
          )}
          {onConfirmButtonPress && (
            <Button onPress={onConfirmButtonPress} style={themedStyles.button}>
              {confirmButtonText || "confirm"}
            </Button>
          )}
        </View>
      </View>
    </View>
  );
};
