import React, { useCallback } from "react";
import moment from "moment";
import Modal from "modal-react-native-web";
import SubscriptionCreators from "../../redux/reducers/subscriptionReducer";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StackParams } from "../../navigation";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { DefaultText, MyIcon } from "../index";
import { USER_TYPES } from "../../redux/types";
import { DEFAULT_DISPLAY_HEIGHT } from "../../constants";
import { Event } from "../../services/googleAnalyticsTracking";
import { RFValue } from "../../utils/resizeHelper";
import { Platform } from "react-native";

type NavigationProps = StackNavigationProp<StackParams, "Welcome">;
interface PropsType {
  id: string;
  icon: string;
  startAt?: number;
  externalId?: string;
  startTs?: number;
  endTs?: number;
  coachName?: string;
  trainingCenterName?: string;
  showNotBookedModal: (externalId: string) => void;
}

function LiveTrainingItemComponent({
  id,
  icon,
  startAt,
  externalId,
  dateTime,
  startTs,
  endTs,
  coachName,
  trainingCenterName,
  userType,
  bookingsList,
  showNotBookedModal,
}: CombinedProps) {
  const { replace } = useNavigation<NavigationProps>();

  const startTime = moment(startTs).format("HH:mm");
  const endTime = moment(endTs).format("HH:mm");
  const isBooked = !!bookingsList?.find((book) => book.id === id);
  const centerStatus = trainingCenterName !== "PowerWatts International" ? "Private" : "";

  Modal.setAppElement("body");

  const secondsToTime = (timestamp: number) => {
    const hours = Math.floor(timestamp / 60 / 60);
    const minutes = Math.floor(timestamp / 60) - hours * 60;
    const seconds = timestamp % 60;

    const formatted =
      hours !== 0
        ? [
            hours.toString().padStart(2, "0"),
            minutes.toString().padStart(2, "0"),
            seconds.toString().padStart(2, "0"),
          ].join(":")
        : [minutes.toString().padStart(2, "0"), seconds.toString().padStart(2, "0")].join(":");

    return formatted;
  };

  const secondsBeforeTraining = startAt ? Math.round((dateTime - startAt) / 1000) : dateTime;
  const firstSymbol = secondsBeforeTraining < 0 ? "-" : "+";
  const durationColor =
    secondsBeforeTraining < 0 && secondsBeforeTraining > -60
      ? "#BB2323"
      : secondsBeforeTraining < -60 && secondsBeforeTraining > -300
      ? "#F2E14C"
      : secondsBeforeTraining < -300
      ? "#59AE30"
      : "white";

  const timeBeforeTraining = secondsToTime(Math.abs(secondsBeforeTraining));

  const onIconPress = useCallback(() => {
    if (externalId && userType === USER_TYPES.COACH) {
      replace("TrainingControls", { externalId: externalId });
    }
  }, [externalId, userType, replace]);

  const onButtonPress = useCallback(() => {
    if (isBooked) {
      Event("click", {
        target: "live class",
        start_time: startTime,
        end_time: endTime,
        coach_name: coachName,
      });
      replace("LiveSession", { externalId: externalId });
    } else {
      Event("click", {
        target: "live class not booked",
        start_time: startTime,
        end_time: endTime,
        coach_name: coachName,
      });
      externalId && showNotBookedModal(externalId);
    }
  }, [replace, externalId, isBooked, startTime, endTime, coachName, showNotBookedModal]);

  return (
    <View
      style={
        isBooked ? [themedStyles.container, themedStyles.bookedContainer] : themedStyles.container
      }
    >
      {userType === USER_TYPES.COACH && Platform.OS === "web" ? (
        <TouchableOpacity onPress={onIconPress}>
          <View style={themedStyles.iconContainer}>
            <MyIcon icon={icon} size={RFValue(24, DEFAULT_DISPLAY_HEIGHT)} color="white" />
          </View>
        </TouchableOpacity>
      ) : (
        <View />
      )}

      <TouchableOpacity onPress={onButtonPress} style={{ flex: 1 }}>
        <View style={themedStyles.workoutContainer}>
          <DefaultText
            style={
              isBooked
                ? [themedStyles.trainingName, themedStyles.bookedTrainingName]
                : themedStyles.trainingName
            }
          >
            {`${startTime}-${endTime} with ${coachName}` +
              "\n" +
              `${trainingCenterName} (${centerStatus})`}
          </DefaultText>
          {startAt && (
            <View style={themedStyles.liveTimerContainer}>
              <DefaultText
                style={[themedStyles.duration, { color: durationColor }]}
              >{`${firstSymbol}${timeBeforeTraining}`}</DefaultText>
            </View>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> & PropsType;

const mapStateToProps = (state: StateInterface) => ({
  dateTime: state.timer.dateTime,
  userType: state.user.userType,
  bookingsList: state.subscription.bookingsList,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showNotBookedModal: (externalId: string) =>
    dispatch(SubscriptionCreators.toggleNotBookedModal(true, externalId)),
});

export const LiveTrainingItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveTrainingItemComponent);
