import React from "react";
import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackParams } from "../../../navigation/index.web";
import { DefaultModal } from "../DefaultModal";

type PropsType = {
  handleCloseModal: () => void;
};

type NavigationProps = StackNavigationProp<StackParams, "Welcome">;

export function CCExpireModal({ handleCloseModal }: PropsType) {
  const { setParams } = useNavigation<NavigationProps>();
  const confirmButtonHandler = () => {
    setParams({ profile: true, tabIndex: 1 });
    handleCloseModal();
  };
  return (
    <DefaultModal
      title="Your credit card is about to expire"
      confirmButtonText="Update Credit Card"
      onConfirmButtonPress={confirmButtonHandler}
      cancelButtonText="Close"
      onCancelButtonPress={handleCloseModal}
    />
  );
}
