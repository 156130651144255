import React, { useCallback, useEffect, useState } from "react";
import Modal from "modal-react-native-web";
import TimerCreators from "../../redux/reducers/timerReducer";
import { View } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  BleConnectionModal,
  Container,
  Header,
  HeaderTimers,
  StepsTable,
  StatisticInfo,
  StatisticsBarChart,
  YoutubeVideo,
  FinalTrainingModal,
  CourseImage,
  ToolTipProvider,
  AchievementInfoModal,
} from "../../components";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StackParams } from "../../navigation";
import TrainingDetailsCreators from "../../redux/reducers/trainingDetailsReducer";
import ActiveTrainingCreators from "../../redux/reducers/activeTrainingReducer";
import MetricsCreators from "../../redux/reducers/metricsReducer";
import DeviceCreators from "../../redux/reducers/devicesReducer";
import NotificationsCreators from "../../redux/reducers/notificationsReducer";
import {
  DeviceType,
  NotificationInterface,
  NotificationLocation,
  TRAINING_STATUS_TYPES,
  TypesOfNotification,
} from "../../redux/types";
import { useWakeLock } from "react-screen-wake-lock";
import { useBeforeunload } from "react-beforeunload";
import { Event } from "../../services/googleAnalyticsTracking";
import { determineDeviceType } from "../../sensors/common/BleMeterCommon";

type RouteProps = RouteProp<StackParams, "VirtualTraining">;

const VirtualTraining = (props: CombinedProps) => {
  const { params } = useRoute<RouteProps>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isAchievementInfoModal, setIsAchievementInfoModal] = useState(false);
  const [isFinalModalVisible, setIsFinalModalVisible] = useState(false);
  const handle = useFullScreenHandle();
  const isVirtualCoach = params?.virtualCoach === true || params?.virtualCoach === "true";
  const { addNotification } = props;
  Modal.setAppElement("body");
  // Automatically connect to previosuly paired bluetooth devices
  useEffect(() => {
    const connectToPreviouslyPairedBluetoothDevices = async () => {
      try {
        const devices = await navigator.bluetooth.getDevices();
        console.log("Found " + devices.length + " previously paired Bluetooth devices.");

        // These devices may not be powered on or in range, so scan for
        // advertisement packets from them before connecting.
        for (const device of devices) {
          addNotification({
            location: NotificationLocation.Components,
            notificationType: TypesOfNotification.Info,
            description: "Looking for device: " + device.name + "..",
          });
          scanAndConnectToBluetoothDevice(device);
        }
      } catch (error) {
        // Just send this message to the console, as the feature is in beta.
        console.log(
          "To auto-connect BLE devices enable flags #enable-web-bluetooth-new-permissions-backend and #enable-experimental-web-platform-features.",
        );
      }
    };

    const scanAndConnectToBluetoothDevice = async (device: BluetoothDevice) => {
      let isFired: boolean = false;
      device.addEventListener("advertisementreceived", async () => {
        if (!isFired) {
          isFired = true;
          addNotification({
            location: NotificationLocation.Components,
            notificationType: TypesOfNotification.Info,
            description: 'Found & connecting to device "' + device.name + '"...',
          });

          // Determine device type before trying to connect
          const server = await device?.gatt?.connect();
          const services = await server?.getPrimaryServices();
          if (services) {
            let deviceType = determineDeviceType(services);
            connectToBleDevice(device, deviceType);
          }
        }
      });

      await device.watchAdvertisements();
    };

    connectToPreviouslyPairedBluetoothDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    user,
    trainingStatus,
    currentStepIndex,
    visualExperience,
    currentCourseStepIndex,
    calculateMetricHistory,
    calculateSegmentsMetricHistory,
    initializeVirtualTraining,
    connectToBleDevice,
    finishTraining,
    pauseTimer,
    startTimer,
  } = props;
  const isCoursePendingStep = visualExperience?.display_course;
  const { request } = useWakeLock({
    onRequest: () => {},
    onError: () =>
      addNotification({
        location: NotificationLocation.Components,
        notificationType: TypesOfNotification.Warning,
        description: "Your computer can now go to sleep mode",
      }),
    onRelease: () => {
      isFullScreen && handle.exit();
    },
  });

  const onFullScreenPress = () => {
    if (isFullScreen) {
      handle.exit();
    } else {
      handle.enter();
      request();
    }
  };

  const onFullScreenChange = (fullScreeState: boolean) => {
    setIsFullScreen(fullScreeState);
  };

  useEffect(() => {
    Event("page_view", { page_title: "Workout Page" });
  }, []);

  useBeforeunload(() => {
    return "Are you sure?";
  });

  useEffect(() => {
    if ((currentStepIndex || 0) > 0) {
      calculateMetricHistory();
    }
  }, [currentStepIndex, calculateMetricHistory]);

  useEffect(() => {
    if ((currentCourseStepIndex || 0) > 0) {
      calculateSegmentsMetricHistory();
    }
  }, [currentCourseStepIndex, calculateSegmentsMetricHistory]);

  useEffect(() => {
    if (user.cpxProfile && trainingStatus === TRAINING_STATUS_TYPES.NOT_INITIALIZED) {
      try {
        if (params?.wid) {
          initializeVirtualTraining(params.wid, params.lang);
        }
      } catch (error) {
        addNotification({
          location: NotificationLocation.Components,
          notificationType: TypesOfNotification.Error,
          description: `${error}`,
        });
      }
    }
  }, [params, user.cpxProfile, trainingStatus, initializeVirtualTraining, addNotification]);

  useEffect(() => {
    return () => {
      finishTraining();
      handle.exit();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishTraining]);

  useEffect(() => {
    if (
      trainingStatus === TRAINING_STATUS_TYPES.PAUSED ||
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED
    ) {
      pauseTimer();
    } else {
      startTimer();
    }

    if (trainingStatus === TRAINING_STATUS_TYPES.FINISHED) {
      handle.exit();
      setIsFinalModalVisible(true);
    } else {
      setIsFinalModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingStatus, pauseTimer, startTimer]);

  const closeBleModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);
  const openBleModal = useCallback(() => {
    setIsModalVisible(true);
    handle.exit();
    request();
  }, [setIsModalVisible, request, handle]);
  const handleCloseAchievementInfoModal = () => {
    setIsAchievementInfoModal(false);
    handle.enter();
  };
  const handleOpenAchievementInfoModal = () => {
    setIsAchievementInfoModal(true);
    handle.exit();
  };

  const onConnectDevice = () => {
    handle.enter();
  };

  const renderModals = () => {
    return (
      <>
        <Modal animationType="fade" transparent={true} visible={isModalVisible}>
          <BleConnectionModal
            closeBleModal={closeBleModal}
            request={request}
            onConnectDevice={onConnectDevice}
          />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isFinalModalVisible}>
          <FinalTrainingModal />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isAchievementInfoModal}>
          <AchievementInfoModal closeModal={handleCloseAchievementInfoModal} />
        </Modal>
      </>
    );
  };

  return (
    <FullScreen handle={handle} onChange={onFullScreenChange}>
      {renderModals()}
      <ToolTipProvider>
        <Container style={themedStyles.container}>
          <Header
            onFullScreenPress={onFullScreenPress}
            onBleButtonPress={openBleModal}
            isFullScreen={isFullScreen}
          />
          <HeaderTimers />
          <View
            style={
              isVirtualCoach
                ? themedStyles.contentContainer
                : [themedStyles.contentContainer, themedStyles.soloContentContainer]
            }
          >
            {params?.wid && <YoutubeVideo virtualCoach={isVirtualCoach} />}
            <StatisticInfo openAchievementInfoModal={handleOpenAchievementInfoModal} />
            <StepsTable />
          </View>
          <View style={themedStyles.statisticsBarContainer}>
            {props.isDisplayCourse && (
              <View
                style={
                  isCoursePendingStep
                    ? themedStyles.courseContainer
                    : [themedStyles.courseContainer, themedStyles.courseMinimapContainer]
                }
              >
                <CourseImage />
              </View>
            )}

            <View
              style={
                props.isDisplayCourse
                  ? [themedStyles.barChartContainer, themedStyles.barChartWithCourseContainer]
                  : themedStyles.barChartContainer
              }
            >
              <StatisticsBarChart isSplitMode={props.isDisplayCourse} />
            </View>
          </View>
        </Container>
      </ToolTipProvider>
    </FullScreen>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  user: state.user,
  trainingStatus: state.activeTraining.status,
  currentStepIndex: state.activeTraining.currentSteps.currentStepIndex,
  currentCourseStepIndex: state.activeTraining.currentSteps.currentCourseStepIndex,
  isDisplayCourse: !!state.activeTraining.currentSteps.currentStep?.courseMovementStatus,
  courseMovementStatus: state.activeTraining.currentSteps.currentStep?.courseMovementStatus,
  visualExperience: state.activeTraining.currentSteps.currentStep?.visualExperience,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  initializeVirtualTraining: (wid: string, lang?: string) =>
    dispatch(TrainingDetailsCreators.initializeVirtualTraining(wid, lang)),
  finishTraining: () => dispatch(ActiveTrainingCreators.finishTraining()),
  startTimer: () => dispatch(TimerCreators.startTimer()),
  pauseTimer: () => dispatch(TimerCreators.pauseTimer()),
  calculateMetricHistory: () => dispatch(MetricsCreators.calculateMetricHistory()),
  calculateSegmentsMetricHistory: () => dispatch(MetricsCreators.calculateSegmentsMetricHistory()),
  connectToBleDevice: (device: BluetoothDevice, deviceType: DeviceType) =>
    dispatch(DeviceCreators.createBluetoothSensor(device, deviceType)),
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationsCreators.addNotification(notification)),
});
export const VirtualTrainingScreen = connect(mapStateToProps, mapDispatchToProps)(VirtualTraining);
