import moment from "moment";
import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import {
  SubscriptionState,
  SubscriptionsDataInterface,
  BookingInterface,
  ProductInterface,
  SetProductsAction,
  SetSelectedProductAction,
  SetSubscriptionsAction,
  SetBookingsAction,
  FailedAction,
  SuccessAction,
  BuyProductAction,
  PurchaseDetailsInterface,
  ACCOUNT_STATUS,
  ToggleNotSignedUpAction,
  UpdateCreditCardPayloadInterface,
} from "../types";

interface ActionTypesInterface {
  SET_BOOKINGS: "SET_BOOKINGS";
  SET_PRODUCTS: "SET_PRODUCTS";
  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS";
  SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT";
  FAILED_SUBSCRIPTIONS: "FAILED_SUBSCRIPTIONS";
  FAILED_BOOKINGS: "FAILED_SUBSCRIPTIONS";
  FAILED_PRODUCTS: "FAILED_PRODUCTS";
  BUY_PRODUCT: "BUY_PRODUCT";
  UPDATE_SUCCESS: "UPDATE_SUCCESS";
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS";
  PAYMENT_FAILED: "PAYMENT_FAILED";
  UPDATE_FAILED: "UPDATE_FAILED";
  TOGGLE_NOT_BOOKED_MODAL: "TOGGLE_NOT_BOOKED_MODAL";
  UPDATE_CREDIT_CARD_INFO: "UPDATE_CREDIT_CARD_INFO";
}

interface ActionCreatorsInterface extends ActionCreators {
  setBookings: (bookingsList: BookingInterface[]) => AnyAction;
  setProducts: (productsList: ProductInterface[]) => AnyAction;
  setSubscriptions: (subscriptionResponse: SubscriptionsDataInterface) => AnyAction;
  setSelectedProduct: (selectedProduct: ProductInterface | null) => AnyAction;
  failedSubscriptions: (error: string) => AnyAction;
  failedBookings: (error: string) => AnyAction;
  failedProducts: (error: string) => AnyAction;
  buyProduct: (purchaseInformation: PurchaseDetailsInterface) => AnyAction;
  updateSuccess: (message: string) => AnyAction;
  paymentSuccess: (message: string) => AnyAction;
  paymentFailed: (error: string) => AnyAction;
  updateFailed: (error: string) => AnyAction;
  toggleNotBookedModal: (isVisible: boolean, externalId?: string) => AnyAction;
  updateCreditCardInfo: (creditCardInformation: UpdateCreditCardPayloadInterface) => AnyAction;
}

type Handler<A> = (state: SubscriptionState, action: A) => SubscriptionState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  setBookings: ["bookingsList"],
  setProducts: ["productsList"],
  setSubscriptions: ["subscriptionResponse"],
  setSelectedProduct: ["selectedProduct"],
  failedSubscriptions: ["error"],
  failedBookings: ["error"],
  failedProducts: ["error"],
  buyProduct: ["purchaseInformation"],
  paymentSuccess: ["message"],
  updateSuccess: ["message"],
  paymentFailed: ["error"],
  updateFailed: ["error"],
  toggleNotBookedModal: ["isVisible", "externalId"],
  updateCreditCardInfo: ["creditCardInformation"],
});

export const SubscriptionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: SubscriptionState = {
  hasActiveMembership: false,
  isTrialAccount: false,
  isPaymentSuccess: false,
  isUpdateSuccess: false,
  isPaymentInProgress: false,
  isUpdateInProgress: false,
  productsList: null,
  bookingsList: null,
  subscriptions: null,
  selectedProduct: null,
  accountStatus: null,
  paymentErrorMessage: null,
  updateErrorMessage: null,
  paymentSuccessMessage: null,
  updateSuccessMessage: null,
  isNotBookedModalVisible: false,
  modalExternalId: "",
  ccExpirationDate: null,
  monthsWhileCardValid: null,
};

/* ------------- Reducers ------------- */
const setProducts: Handler<SetProductsAction> = (state, { productsList }) => {
  return {
    ...state,
    productsList,
  };
};

const setSelectedProduct: Handler<SetSelectedProductAction> = (state, { selectedProduct }) => {
  return {
    ...state,
    selectedProduct,
  };
};

const buyProduct: Handler<BuyProductAction> = (state) => {
  return {
    ...state,
    isPaymentInProgress: true,
  };
};

const updateCreditCardInfo: Handler<BuyProductAction> = (state) => {
  return {
    ...state,
    isUpdateInProgress: true,
  };
};

const paymentFailed: Handler<FailedAction> = (state, { error }) => {
  return {
    ...state,
    isPaymentInProgress: false,
    isPaymentSuccess: false,
    paymentErrorMessage: error,
  };
};

const paymentSuccess: Handler<SuccessAction> = (state, { message }) => {
  return {
    ...state,
    isPaymentInProgress: false,
    isPaymentSuccess: true,
    paymentSuccessMessage: message,
  };
};

const updateFailed: Handler<FailedAction> = (state, { error }) => {
  return {
    ...state,
    isUpdateInProgress: false,
    isUpdateSuccess: false,
    updateErrorMessage: error,
  };
};

const updateSuccess: Handler<SuccessAction> = (state, { message }) => {
  return {
    ...state,
    isUpdateInProgress: false,
    isUpdateSuccess: true,
    updateSuccessMessage: message,
  };
};

const setSubscriptions: Handler<SetSubscriptionsAction> = (state, { subscriptionResponse }) => {
  const status =
    !subscriptionResponse.is_trial_account && !subscriptionResponse.has_active_membership
      ? ACCOUNT_STATUS.COMMON
      : subscriptionResponse.is_trial_account && !subscriptionResponse.has_active_membership
      ? ACCOUNT_STATUS.TRIAL
      : ACCOUNT_STATUS.MEMBERSHIP;
  const todaysDate = moment(new Date());
  const expirationDate = moment(subscriptionResponse.cc_expiration_date);
  const monthsWhileCardValid = moment.duration(expirationDate.diff(todaysDate)).asMonths();
  return {
    ...state,
    hasActiveMembership: subscriptionResponse.has_active_membership,
    isTrialAccount: subscriptionResponse.is_trial_account,
    subscriptions: subscriptionResponse.subscriptions,
    ccExpirationDate: subscriptionResponse.cc_expiration_date,
    accountStatus: status,
    monthsWhileCardValid: monthsWhileCardValid,
  };
};

const setBookings: Handler<SetBookingsAction> = (state, { bookingsList }) => {
  return {
    ...state,
    bookingsList: bookingsList,
  };
};

const toggleNotBookedModal: Handler<ToggleNotSignedUpAction> = (
  state,
  { isVisible, externalId },
) => {
  return {
    ...state,
    isNotBookedModalVisible: isVisible,
    modalExternalId: externalId,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_PRODUCTS]: setProducts,
  [Types.SET_SELECTED_PRODUCT]: setSelectedProduct,
  [Types.SET_SUBSCRIPTIONS]: setSubscriptions,
  [Types.BUY_PRODUCT]: buyProduct,
  [Types.UPDATE_CREDIT_CARD_INFO]: updateCreditCardInfo,
  [Types.PAYMENT_FAILED]: paymentFailed,
  [Types.PAYMENT_SUCCESS]: paymentSuccess,
  [Types.UPDATE_FAILED]: updateFailed,
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.SET_BOOKINGS]: setBookings,
  [Types.TOGGLE_NOT_BOOKED_MODAL]: toggleNotBookedModal,
};

export const subscriptionReducer = createReducer(INITIAL_STATE, HANDLERS);
