import React, { useCallback } from "react";
import { Linking } from "react-native";
import { DefaultText } from "../../index";
import { DefaultModal } from "../DefaultModal";
import { themedStyles } from "./styles";

type PropsType = {
  userBrowser: string;
};

export function UnsupportedBrowserModal({ userBrowser }: PropsType) {
  const chromeDownloadLink = "https://www.google.com/chrome/";
  const edgeDownloadLink = "https://www.microsoft.com/edge/";

  const downloadChrome = useCallback(() => {
    Linking.canOpenURL(chromeDownloadLink).then((supported) => {
      if (supported) {
        Linking.openURL(chromeDownloadLink);
      } else {
        console.log("Don't know how to open URI: " + chromeDownloadLink);
      }
    });
  }, [chromeDownloadLink]);

  const downloadEdge = useCallback(() => {
    Linking.canOpenURL(edgeDownloadLink).then((supported) => {
      if (supported) {
        Linking.openURL(edgeDownloadLink);
      } else {
        console.log("Don't know how to open URI: " + edgeDownloadLink);
      }
    });
  }, [edgeDownloadLink]);

  return (
    <DefaultModal
      title="Your browser is not supported"
      cancelButtonText="Download Chrome"
      confirmButtonText="Download Edge"
      onCancelButtonPress={downloadChrome}
      onConfirmButtonPress={downloadEdge}
    >
      <DefaultText style={themedStyles.text}>
        This Web App requires a browser that support Bluetooth and your browser {userBrowser} does
        NOT support Bluetooth. Please use Google Chrome or Microsoft Edge instead
      </DefaultText>
    </DefaultModal>
  );
}
