import React, { useCallback } from "react";
import { Linking, TouchableOpacity, View } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";
import { useAuth0 } from "@auth0/auth0-react";
import { StateInterface } from "../../../redux/reducers";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  NotificationInterface,
  NotificationLocation,
  TypesOfNotification,
} from "../../../redux/types";
import NotificationsCreators from "../../../redux/reducers/notificationsReducer";

export function SignUpModalComponent({ addNotification }: CombinedProps) {
  const registrationLink = "https://booking.powerwatts.co.il/auth/pwhome_registration";

  const { logout } = useAuth0();

  const onSignUpButtonPress = useCallback(() => {
    Linking.canOpenURL(registrationLink).then((supported) => {
      if (supported) {
        Linking.openURL(registrationLink);
      } else {
        addNotification({
          location: NotificationLocation.Components,
          notificationType: TypesOfNotification.Info,
          description: "Don't know how to open URI: " + registrationLink,
        });
      }
    });
  }, [registrationLink, addNotification]);

  const onLogOutButtonPress = useCallback(() => {
    logout({ returnTo: window.location.href });
  }, [logout]);

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.modalContainer}>
        <DefaultText style={themedStyles.mainText}>
          Before starting workouts, you need to create account in the PowerWats system.
        </DefaultText>
        <View style={themedStyles.buttonContainer}>
          <TouchableOpacity
            onPress={onLogOutButtonPress}
            style={[themedStyles.button, themedStyles.logOutButton]}
          >
            <DefaultText style={themedStyles.buttonText}>logOut</DefaultText>
          </TouchableOpacity>
          <TouchableOpacity onPress={onSignUpButtonPress} style={themedStyles.button}>
            <DefaultText style={themedStyles.buttonText}>SignUp</DefaultText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (_state: StateInterface) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationsCreators.addNotification(notification)),
});
export const SignUpModal = connect(mapStateToProps, mapDispatchToProps)(SignUpModalComponent);
