import {
  DEFAULT_CADENCE_VALUE,
  DEFAULT_WINDOW_VALUE,
  getPower,
  MAX_CADENCE,
  MAX_LIMIT_INDICATOR,
  MAX_POWER,
  MIN_LIMIT_INDICATOR,
  round,
} from "./Statistics";
import {
  CurrentMetricsInterface,
  DeviceDataPoint,
  WorkoutIntervalInterface,
  INTERVAL_TYPE,
} from "../redux/types";

export enum VALUE_TYPE {
  POWER = "power",
  CADENCE = "cadece",
  POWER_PERCENT = "powerPercent",
  HEART_RATE = "heartRate",
  MAX_HEART_RATE = "maxHeartRate",
}

interface GetMetricsSubsetInterface {
  cp: number;
  isStarted: boolean;
  timeFromStepStart: number;
  timeFromWorkoutStart?: number;
  step: WorkoutIntervalInterface | null;
  nextStep?: WorkoutIntervalInterface | null;
  currentMetrics?: CurrentMetricsInterface;
  deviceData?: DeviceDataPoint | null;
}

export interface MetricsSubsetInterface {
  workout_ts: number;
  cp: number;
  watts: number;
  power: number;
  powerBalance: number | null;
  avgPower: number;
  avgWatts: number;
  powerLoad: number;
  nextPowerLoad: number;
  wattsLoad: number;
  wattsWindowLow: number;
  wattsWindowHigh: number;
  powerWindowLow: number;
  powerWindowHigh: number;
  nextPowerWindowLow: number;
  nextPowerWindowHigh: number;
  cadence: number;
  avgCadence: number;
  cadenceLoad: number;
  cadenceWindowLow: number;
  cadenceWindowHigh: number;
  powerWindowOriginal: number;
  cadenceWindowOriginal: number;
  speed: number;
  heartRate: number | null;
}

interface LoadInterface {
  power: number;
  cadence: number;
  nextPower: number | null;
  power_window_low: number;
  power_window_high: number;
  cadence_window_low: number;
  cadence_window_high: number;
  power_window_original: number;
  next_power_window_low: number;
  next_power_window_high: number;
  cadence_window_original: number;
}

export const getMetricsSubset = ({
  cp,
  step,
  nextStep,
  isStarted,
  deviceData,
  currentMetrics,
  timeFromStepStart,
  timeFromWorkoutStart,
}: GetMetricsSubsetInterface) => {
  const load = {
    power: getPower(step, timeFromStepStart),
    nextPower: !!nextStep ? getPower(nextStep, timeFromStepStart) : null,
    power_window_low:
      step?.intervalType === INTERVAL_TYPE.RECOVER
        ? MAX_LIMIT_INDICATOR
        : step?.powerWindowLow || DEFAULT_WINDOW_VALUE,
    power_window_high: step?.powerWindowHigh || DEFAULT_WINDOW_VALUE,
    cadence: step?.cadence || DEFAULT_CADENCE_VALUE,
    cadence_window_low: step?.cadenceWindowLow || DEFAULT_WINDOW_VALUE,
    cadence_window_high: step?.cadenceWindowHigh || DEFAULT_WINDOW_VALUE,
    next_power_window_low: nextStep?.powerWindowLow || DEFAULT_WINDOW_VALUE,
    next_power_window_high: nextStep?.powerWindowHigh || DEFAULT_WINDOW_VALUE,
    power_window_original: 0,
    cadence_window_original: 0,
  };

  const fixedLoad = fixSelfPacedWindow(load, cp);
  const metricsSubset: MetricsSubsetInterface = {
    workout_ts: !isStarted ? 0 : timeFromWorkoutStart || 0,
    cp: cp,
    watts: deviceData?.power || 0,
    power: round(((deviceData?.power || 0) / cp) * 100, 1),
    powerBalance: deviceData?.powerBalance || null,
    avgPower: !isStarted ? 0 : round(((currentMetrics?.power_avg || 0) / cp) * 100, 1),
    avgWatts: !isStarted ? 0 : round((currentMetrics?.power_avg || 0), 1),
    powerLoad: !isStarted ? 0 : round(fixedLoad.power, 1),
    nextPowerLoad: !isStarted ? 0 : round(fixedLoad.nextPower || 0, 1),
    wattsLoad: !isStarted ? 0 : round((fixedLoad.power / 100) * cp, 1),
    wattsWindowLow: !isStarted ? 0 : Math.round(fixedLoad.power_window_low!),
    wattsWindowHigh: !isStarted ? 0 : Math.round(fixedLoad.power_window_high!),
    powerWindowLow: !isStarted ? 0 : Math.round((fixedLoad.power_window_low / cp) * 100),
    powerWindowHigh: !isStarted ? 0 : Math.round((fixedLoad.power_window_high / cp) * 100),
    nextPowerWindowLow: !isStarted ? 0 : Math.round((fixedLoad.next_power_window_low / cp) * 100),
    nextPowerWindowHigh: !isStarted ? 0 : Math.round((fixedLoad.next_power_window_high / cp) * 100),
    cadence: Math.round(deviceData?.cadence || 0),
    avgCadence: !isStarted ? 0 : round(currentMetrics?.cadence_avg, 1),
    cadenceLoad: !isStarted ? 0 : Math.round(fixedLoad.cadence),
    cadenceWindowLow: !isStarted ? 0 : Math.round(fixedLoad.cadence_window_low),
    cadenceWindowHigh: !isStarted ? 0 : Math.round(fixedLoad.cadence_window_high),
    powerWindowOriginal: fixedLoad.power_window_original,
    cadenceWindowOriginal: fixedLoad.cadence_window_original,
    speed: deviceData?.speed || 0,
    heartRate: deviceData?.heartRate || null,
  };
  return metricsSubset;
};

const fixSelfPacedWindow = (load: LoadInterface, cp: number) => {
  switch (load.power_window_low) {
    case MAX_LIMIT_INDICATOR: // max limit
      load.power_window_original = MAX_LIMIT_INDICATOR;
      load.power_window_low = (load.power / 100) * cp;
      load.power_window_high = 0;
      break;
    case MIN_LIMIT_INDICATOR: //min limit
      load.power_window_original = MIN_LIMIT_INDICATOR;
      load.power_window_low = 0;
      load.power_window_high = ((MAX_POWER - load.power) / 100) * cp;
      break;
    default:
      break;
  }
  switch (load.next_power_window_low) {
    case MAX_LIMIT_INDICATOR: // max limit
      load.next_power_window_low = load.nextPower ? (load.nextPower / 100) * cp : 0;
      load.next_power_window_high = 0;
      break;
    case MIN_LIMIT_INDICATOR: //min limit
      load.next_power_window_low = 0;
      load.next_power_window_high = load.nextPower ? ((MAX_POWER - load.nextPower) / 100) * cp : 0;
      break;
    default:
      break;
  }
  if (load.cadence_window_low === MIN_LIMIT_INDICATOR) {
    load.cadence_window_original = MIN_LIMIT_INDICATOR;
    load.cadence_window_low = 0;
    load.cadence_window_high = MAX_CADENCE - load.cadence;
  }
  if (load.cadence_window_high === MAX_LIMIT_INDICATOR) {
    load.cadence_window_original = MAX_LIMIT_INDICATOR;
    load.cadence_window_low = load.cadence;
    load.cadence_window_high = 0;
  }
  return load;
};

interface GetMetricsStrByType {
  cp: number;
  type: VALUE_TYPE;
  hasMetrics: boolean;
  isCurrentStepIndex: boolean;
  avgCadence?: number;
  avgPower?: number;
  avgHeartRate?: number;
  cadence?: number;
  power?: number;
  heartRate?: number;
}

export const getMetricsStrByType = ({
  cp,
  type,
  hasMetrics,
  isCurrentStepIndex,
  avgCadence = 0,
  avgPower = 0,
  avgHeartRate = 0,
  cadence = 0,
  power = 0,
  heartRate = 0,
}: GetMetricsStrByType) => {
  switch (type) {
    case VALUE_TYPE.POWER:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "---W";
      } else if (isCurrentStepIndex) {
        return `${round(power, 0)}W`;
      } else {
        return `${avgPower.toFixed()}W`;
      }
    case VALUE_TYPE.CADENCE:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "--.-";
      } else if (isCurrentStepIndex) {
        return `${cadence.toFixed(1)}`;
      } else {
        return avgCadence.toFixed(1);
      }
    case VALUE_TYPE.POWER_PERCENT:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "--%";
      } else if (isCurrentStepIndex) {
        return `${round((power / cp) * 100, 0)}%`;
      } else {
        return `${((avgPower / cp) * 100).toFixed()}%`;
      }
    case VALUE_TYPE.HEART_RATE:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "";
      } else if (isCurrentStepIndex) {
        return `♥${heartRate.toFixed()}`;
      } else {
        return `♥${avgHeartRate.toFixed()}`;
      }
    case VALUE_TYPE.MAX_HEART_RATE:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "";
      } else if (isCurrentStepIndex) {
        return `(${heartRate.toFixed()})`;
      } else {
        return `(${avgHeartRate.toFixed()})`;
      }

    default:
      return "--";
  }
};
