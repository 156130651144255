import React from "react";
import { LOCAL_STORAGE_ITEMS } from "../../../constants";
import { openLink } from "../../../utils/helpers";
import { DefaultText } from "../../CommonElements/DefaultText";
import { DefaultModal } from "../DefaultModal";

export const NewVersionModal = () => {
  const appLink = "https://classic.app.powerwatts.com";

  const onCancelButtonPress = () => {
    openLink(appLink);
  };
  const onConfirmButtonPress = () => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.USER_SAW_NEW_VERSION_MODAL, "true");
  };
  return (
    <DefaultModal
      title="Welcome to the New PowerWatts Online!"
      cancelButtonText="Return to PowerWatts Classic"
      confirmButtonText="Try it out"
      onCancelButtonPress={onCancelButtonPress}
      onConfirmButtonPress={onConfirmButtonPress}
    >
      <DefaultText>
        You've accessed a new version of the site. Do you want to try it or switch to the old
        version of the site?
      </DefaultText>
    </DefaultModal>
  );
};
