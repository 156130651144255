import { AxiosError } from "axios";
import { put, call } from "redux-saga/effects";
import { SubscriptionApi } from "../../api/api";
import SubscriptionCreators from "../reducers/subscriptionReducer";
import {
  BuyContractPayloadInterface,
  BuyProductAction,
  BuyServicePayloadInterface,
  PRODUCT_TYPE,
  SubscriptionsDataResponse,
  UpdateCreditCardAction,
} from "../types";

function* refreshSubscriptions() {
  try {
    const { data: userSubscription }: SubscriptionsDataResponse =
      yield SubscriptionApi.getSubscriptions();

    if (userSubscription.success === true) {
      yield put(SubscriptionCreators.setSubscriptions(userSubscription));
    } else {
      yield put(SubscriptionCreators.failedSubscriptions("TODO: error response"));
    }
  } catch (error) {
    yield put(SubscriptionCreators.failedSubscriptions(`${error}`));
  }
}

export function* buyProduct({ purchaseInformation }: BuyProductAction) {
  switch (purchaseInformation.productType) {
    case PRODUCT_TYPE.CONTRACT:
      const contractPayload: BuyContractPayloadInterface = {
        contract_id: purchaseInformation.productId,
        payment_info: {
          credit_card_number: purchaseInformation.paymentInfo.creditCardNumber,
          exp_month: purchaseInformation.paymentInfo.expMonth,
          exp_year: purchaseInformation.paymentInfo.expYear,
          cvv: purchaseInformation.paymentInfo.cvv,
          billing_name: purchaseInformation.paymentInfo.billingName,
        },
      };

      try {
        const contractRes = yield SubscriptionApi.buyContract(JSON.stringify(contractPayload));
        const contractBody = contractRes.data;

        if (contractBody.success === true) {
          yield put(SubscriptionCreators.paymentSuccess(`${contractBody.contract}`));

          yield call(refreshSubscriptions);
        } else {
          yield put(SubscriptionCreators.paymentFailed(`${contractBody.error.detailed_error}`));
        }
      } catch (error) {
        const payloadError: AxiosError = error;
        yield put(
          SubscriptionCreators.paymentFailed(`${payloadError.response?.data.error.detailed_error}`),
        );
      }

      break;
    case PRODUCT_TYPE.SERVICE:
      const servicePayload: BuyServicePayloadInterface = {
        service_id: purchaseInformation.productId,
        payment_info: {
          credit_card_number: purchaseInformation.paymentInfo.creditCardNumber,
          exp_month: purchaseInformation.paymentInfo.expMonth,
          exp_year: purchaseInformation.paymentInfo.expYear,
          cvv: purchaseInformation.paymentInfo.cvv,
          billing_name: purchaseInformation.paymentInfo.billingName,
        },
      };

      try {
        const serviceRes = yield SubscriptionApi.buyService(JSON.stringify(servicePayload));
        const serviceBody = serviceRes.data;

        if (serviceBody.success === true) {
          yield put(SubscriptionCreators.paymentSuccess(`${serviceBody.service}`));

          yield call(refreshSubscriptions);
        } else {
          yield put(SubscriptionCreators.paymentFailed(`${serviceBody.error.detailed_error}`));
        }
      } catch (error) {
        const payloadError: AxiosError = error;
        yield put(
          SubscriptionCreators.paymentFailed(`${payloadError.response?.data.error.detailed_error}`),
        );
      }

      break;

    default:
      break;
  }
}

export function* updateCreditCardInfo({ creditCardInformation }: UpdateCreditCardAction) {
  try {
    const updateCCRes = yield SubscriptionApi.updateCard(JSON.stringify(creditCardInformation));
    const updateBody = updateCCRes.data;
    console.log("KKK", updateBody);
    if (updateBody.success === true) {
      yield put(SubscriptionCreators.updateSuccess(`${updateBody.result}`));
    } else {
      yield put(SubscriptionCreators.updateFailed(`${updateBody.error.detailed_error}`));
    }
  } catch (error) {
    const payloadError: AxiosError = error;
    yield put(
      SubscriptionCreators.updateFailed(`${payloadError.response?.data.error.detailed_error}`),
    );
  }
}
