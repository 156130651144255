import {
  ProfileInterface,
  DetailsInfoInterface,
  AdvancedStepMetricsInterface,
  IDevicesState,
  SampleInterface,
  finalStepMetricInterface,
} from "../redux/types";
import { normalizedPower, round } from "./Statistics";

export function generateCpxPData(
  user: ProfileInterface,
  session: DetailsInfoInterface,
  workoutId: string,
  intervals: AdvancedStepMetricsInterface[],
  device: IDevicesState,
  samples: SampleInterface[],
) {
  let totalWork = 0;
  let workoutStartTime: number = 0;
  let np = normalizedPower(samples);
  let avgp = samples.reduce((p, c) => p + (c.p != null ? c.p : 0), 0) / samples.length;
  let totTiz: number = 0,
    totTaz: number = 0,
    totTbz: number = 0;
  const steps: finalStepMetricInterface[] = intervals.map((interval, index) => {
    totalWork += interval.totalWork;
    if (workoutStartTime === 0 && !!interval.start_ts) {
      workoutStartTime = interval.start_ts;
    }
    let intervalDuration = (interval.end_ts - interval.start_ts) / 1000;
    let tizS = (interval.power_accuracy.tiz / 100) * intervalDuration;
    let tazS = (interval.power_accuracy.toz / 100) * intervalDuration;
    let tbzS = (interval.power_accuracy.tbz / 100) * intervalDuration;
    totTiz += tizS;
    totTaz += tazS;
    totTbz += tbzS;
    return {
      Step: index,
      ResultString: interval.shortText,
      AveragePower: interval.relative_power,
      WattsAvg: interval.average_power,
      WattsMax: interval.maxPower,
      WattsNorm: interval.normalizedPower,
      TargetPower: interval.targetPower,
      TargetPowerWindow: interval.targetPowerWindow,
      CadenceAvg: interval.average_cadence,
      CadenceMax: interval.minCadence,
      CadenceMin: interval.maxCadence,
      CPX: interval.criticalPower,
      IntervalTypeString: interval.intervalType,
      DistanceKMs: round(interval.distance, 3),
      Rank: null,
      // !isGameInterval(interval.intervalType) ||
      // userMetrics === null ||
      // typeof userMetrics === "undefined"
      //   ? null
      //   : userMetrics.rank,
      Points: interval.power_accuracy.grade,
      SpeedAvg: round(interval.avg_speed, 2),
      SpeedMax: round(interval.maxSpeed, 2),
      TargetCAD: interval.targetCadence,
      TargetCADWindow: interval.targetCadenceWindow,
      MilesTraveled: round(interval.distance * 0.621371, 3),
      NumberOfSecondsInTarget: round(tizS, 2),
      NumberOfSecondsInOrAboveTarget: round(tazS, 2),
      NumberOfSecondsInOrBelowTarget: round(tbzS, 2),
      WpKgInterval: null,
      WPrimePercentBalance_Start: null,
      WPrimePercentBalance_End: null,
      TimeInMinutesAtInterval_START: (interval.start_ts - workoutStartTime) / 60000,
      TimeInMinutesAtInterval_END: (interval.end_ts - workoutStartTime) / 60000,
    };
  });
  let startTime = workoutStartTime ? new Date(workoutStartTime) : null;
  let total = totTbz + totTiz + totTaz;
  let mainDeviceName = "unknown";
  if (device.smartTrainerDevice != null) {
    mainDeviceName = device.smartTrainerDevice.name;
  } else if (device.powerMeterDevice != null) {
    mainDeviceName = device.powerMeterDevice.name;
  }

  return {
    ActualStartTimeOfWorkout: startTime && startTime.toISOString(),
    User_ID: user.cpxUserId,
    WorkoutId: workoutId,
    cpXUserIdentifier: session?.coachId,
    TotalWork: Math.round(totalWork),
    NormalizedPower: Math.round(np),
    AveragePower: Math.round(avgp),
    PowerAccuracy: {
      tbz: round(total > 0 ? (totTbz / total) * 100 : 0, 2),
      tiz: round(total > 0 ? (totTiz / total) * 100 : 0, 2),
      toz: round(total > 0 ? (totTaz / total) * 100 : 0, 2),
    },
    SessionGUID: session?.sessionId,
    LocationID: null, // session.locationId, TODO
    VersionID: 1.0,
    BleDevice: mainDeviceName,
    MasterDataList: samples,
    ListOfWorkoutStepData: steps,
    ListOfPersonalRecords: [],
  };
}
