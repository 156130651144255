import {
    AntPageControlTrackResistance,
    AntPageUserConfiguration,
    AntPageWindResistance
} from "./BleParserHelpers";
import { CyclingPowerMeasurementParser } from "./CyclingPowerMeasurementParser";
import { TacxDataParser } from "./TacxDataParser";
import { FtmsDataParser } from "./FtmsDataParser";
import {
    CreateFtmsGradePacket,
    CreateFtmsRequestControlPacket,
    CreateFtmsStartSessionPacket,
    CreateHeightPacket,
    CreateWattBikeInitPacket,
    CreateWattBikeWeightPacket,
    CreateWeightPacket
} from "./FtmsTrainerControl";
import {
    CreateTacxGradePacket,
    CreateTacxRequestDataPagePacket,
    CreateTacxUserConfigurationPacket,
    CreateTacxWindResistancePacket
} from "./TacxTrainerControl";
import {
    CreateWahooGradePacket,
    CreateWahooRiderParametersPacket,
    CreateWahooWheelCircumferencePacket,
    CreateWahooWindSpeedPacket
} from "./WahooLegacy";
import { BleDeviceBase } from "./BleDeviceBase";
import { SensorBase } from "./SensorBase";
import {
    MaxGradeDefault,
    delay,
    WheelCircumference,
    SERVICE_UUID_USER_DATA,
    CHARACTERISTIC_UUID_WEIGHT,
    CHARACTERISTIC_UUID_HEIGHT,
    SERVICE_UUID_WATTBIKE_ATOM_PROPRIETARY,
    CHARACTERISTIC_UUID_WATTBIKE_ATOM_WRITE,
    SERVICE_UUID_WATTBIKE_ATOMX_PROPRIETARY,
    CHARACTERISTIC_UUID_WATTBIKE_ATOMX_WRITE
} from "./BleMeterCommon";
import { SmartTrainerType } from "./SmartTrainerType";
import { DebugApi } from "../../api/api";

export abstract class SmartTrainerBase extends SensorBase {
    smartTrainerType: SmartTrainerType;
    userWeight: number;
    userHeight: number;
    maxGrade: number;
    isActive: boolean;

    // grade needs to respect maxGrade, so don't allow direct writes
    private grade: number;

    constructor(
        bleDevice: BleDeviceBase | null,
        deviceName: string,
        userWeight: number,
        userHeight: number
    ) {
        super(deviceName, bleDevice);

        this.userWeight = userWeight;
        this.userHeight = userHeight;
        this.grade = 0;
        this.maxGrade = MaxGradeDefault;
        this.isActive = true;
        this.smartTrainerType = SmartTrainerType.Unknown;
    }

    getGrade() {
        return this.grade;
    }

    setGrade(grade: number) {
        if (grade <= this.maxGrade) {
            this.grade = grade;
        } else
            this.grade = this.maxGrade;
    }

    abstract isConnected(): boolean;

    async updateGradeLoop() {
        while (this.isActive) {
            try {
                await this.sendGrade();
            }
            catch (error) {
                if (this.isConnected() === false) {
                    console.log("Exiting Update Grade Loop, device disconnected.");
                    DebugApi.log({
                        function: "updateGradeLoop",
                        message: "Exiting Update Grade Loop, device disconnected.",
                    });
                    if (this.bleDevice?.OnUnexpectedDisconnect) this.bleDevice?.OnUnexpectedDisconnect();
                    break;
                }
                console.log("Error -  Update Grade: ", error);
                DebugApi.log({
                    function: "updateGradeLoop",
                    message: "Error -  Update Grade: " + JSON.stringify(error),
                });
            }

            await delay(500);
        }
    }

    increaseGrade() {
        this.setGrade(this.grade + 0.5);
        console.log("increaseGrade", this.grade);
    }

    decreaseGrade() {
        this.setGrade(this.grade - 0.5);
        console.log("decreaseGrade", this.grade);
    }

    async initializeControlCharacteristicTacx() {
        if (this.bleDevice != null) {
            console.log("Tacx Trainer Control");
            DebugApi.log("Tacx Trainer Control");
            this.smartTrainerType = SmartTrainerType.Tacx;
            this.bleDevice.parser = new TacxDataParser(this.userWeight);

            let packet = CreateTacxUserConfigurationPacket(this.userWeight);
            await this.WriteControlCharacteristic(packet);

            packet = CreateTacxWindResistancePacket(0);
            await this.WriteControlCharacteristic(packet);

            packet = CreateTacxRequestDataPagePacket(AntPageWindResistance);
            await this.WriteControlCharacteristic(packet);

            packet = CreateTacxRequestDataPagePacket(AntPageControlTrackResistance);
            await this.WriteControlCharacteristic(packet);

            packet = CreateTacxRequestDataPagePacket(AntPageUserConfiguration);
            await this.WriteControlCharacteristic(packet);
        }
    }

    async initializeControlCharacteristicWahooLegacy() {
        if (this.bleDevice != null) {
            console.log("Wahoo Legacy Control");
            DebugApi.log("Wahoo Legacy Control");
            this.smartTrainerType = SmartTrainerType.WahooLegacy;
            this.bleDevice.parser = new CyclingPowerMeasurementParser(
                this.userWeight,
                WheelCircumference
            );

            const riderParametersPacket = CreateWahooRiderParametersPacket(this.userWeight);
            await this.WriteControlCharacteristic(riderParametersPacket);

            const windSpeedPacket = CreateWahooWindSpeedPacket(0);
            await this.WriteControlCharacteristic(windSpeedPacket);

            // Set wheel circumference
            const wheelCircumferencePacket = CreateWahooWheelCircumferencePacket(WheelCircumference);
            await this.WriteControlCharacteristic(wheelCircumferencePacket);
        }
    }

    async initializeControlCharacteristicFtms() {
        if (this.bleDevice != null) {
            console.log("FTMS Control");
            DebugApi.log("FTMS Control");
            this.smartTrainerType = SmartTrainerType.Ftms;
            this.bleDevice.parser = new FtmsDataParser(this.userWeight);

            // Send weight through User Data Service, if available
            const weightPacket = CreateWeightPacket(this.userWeight);
            const isWeightSet = await this.WriteCharacteristic(
                SERVICE_UUID_USER_DATA,
                CHARACTERISTIC_UUID_WEIGHT,
                weightPacket
            );

            // Send height through User Data Service, if available
            const heightPacket = CreateHeightPacket(this.userHeight);
            const isHeightSet = await this.WriteCharacteristic(
                SERVICE_UUID_USER_DATA,
                CHARACTERISTIC_UUID_HEIGHT,
                heightPacket
            );

            console.log("User Data Service: Weight: ", isWeightSet, "Height", isHeightSet);
            DebugApi.log(`User Data Service: Weight: ${isWeightSet}. Height: ${isHeightSet}`);

            // Request Control
            const controlPacket = CreateFtmsRequestControlPacket();
            await this.WriteControlCharacteristic(controlPacket);

            // CreateFtmsStartSessionPacket
            const startPacket = CreateFtmsStartSessionPacket();
            await this.WriteControlCharacteristic(startPacket);

            // Create WattBike packets
            const wattBikeAtomDeviceInfo = 0x03;
            const wattBikeAtomXDeviceInfo = 0x06;
            const wattBikeAtomInitPacket = CreateWattBikeInitPacket(wattBikeAtomDeviceInfo);
            const wattBikeAtomWeightPacket = CreateWattBikeWeightPacket(wattBikeAtomDeviceInfo, this.userWeight);
            const wattBikeAtomXInitPacket = CreateWattBikeInitPacket(wattBikeAtomXDeviceInfo);
            const wattBikeAtomXWeightPacket = CreateWattBikeWeightPacket(wattBikeAtomXDeviceInfo, this.userWeight);

            // Initialize WattBike Atom, if WattBike service is available
            const isWattBikeAtomInitSuccess = await this.WriteCharacteristic(
                SERVICE_UUID_WATTBIKE_ATOM_PROPRIETARY,
                CHARACTERISTIC_UUID_WATTBIKE_ATOM_WRITE,
                wattBikeAtomInitPacket
            );

            // Set WattBike Atom Weight, if service is available
            const isWattBikeAtomWeightSet = await this.WriteCharacteristic(
                SERVICE_UUID_WATTBIKE_ATOM_PROPRIETARY,
                CHARACTERISTIC_UUID_WATTBIKE_ATOM_WRITE,
                wattBikeAtomWeightPacket
            );

            console.log("WattBike Atom: Init: ", isWattBikeAtomInitSuccess, "Weight", isWattBikeAtomWeightSet);
            if (isWattBikeAtomInitSuccess || isWattBikeAtomWeightSet) {
                DebugApi.log(`WattBike Atom: Init: ${isWattBikeAtomInitSuccess}. Weight: ${isWattBikeAtomWeightSet}`);
            }

            // Initialize WattBike AtomX, if WattBike service is available
            const isWattBikeAtomXInitSuccess = await this.WriteCharacteristic(
                SERVICE_UUID_WATTBIKE_ATOMX_PROPRIETARY,
                CHARACTERISTIC_UUID_WATTBIKE_ATOMX_WRITE,
                wattBikeAtomXInitPacket
            );

            // Set WattBike AtomX Weight, if service is available
            const isWattBikeAtomXWeightSet = await this.WriteCharacteristic(
                SERVICE_UUID_WATTBIKE_ATOMX_PROPRIETARY,
                CHARACTERISTIC_UUID_WATTBIKE_ATOMX_WRITE,
                wattBikeAtomXWeightPacket
            );

            console.log("WattBike AtomX: Init: ", isWattBikeAtomXInitSuccess, "Weight", isWattBikeAtomXWeightSet);
            if (isWattBikeAtomXInitSuccess || isWattBikeAtomXWeightSet) {
                DebugApi.log(`WattBike Atom: Init: ${isWattBikeAtomXInitSuccess}. Weight: ${isWattBikeAtomXWeightSet}`);
            }
        }
    }

    // This function will just send the current grade out to the trainer.
    async sendGrade() {
        console.log("send grade ", this.grade);
        if (this.smartTrainerType === SmartTrainerType.Tacx) {
            const tacxGradePacket = CreateTacxGradePacket(this.grade);
            await this.WriteControlCharacteristic(tacxGradePacket);
        } else if (this.smartTrainerType === SmartTrainerType.Ftms) {
            const ftmsGradePacket = CreateFtmsGradePacket(this.grade);
            await this.WriteControlCharacteristic(ftmsGradePacket);
        } else if (this.smartTrainerType === SmartTrainerType.WahooLegacy) {
            const wahooGradePacket = CreateWahooGradePacket(this.grade);
            await this.WriteControlCharacteristic(wahooGradePacket);
        }
        else {
            console.log("Error: BLE Device not set (sendGrade)");
        }
    }

    abstract WriteControlCharacteristic(fullPacket: Uint8Array): Promise<void>;
    abstract WriteCharacteristic(
        serviceUuid: string,
        characteristicUuid: string,
        data: Uint8Array
    ): Promise<boolean>;
}
