/* ------------- Initial State ------------- */

export interface WorkoutsState {
  workouts: WorkoutInterface[] | null;
  introductions: WorkoutInterface[] | null;
  trainingPlans: TrainingPlansInterface[] | null;
  notes: WorkoutNotesInterface | null;
  trainingPeeksWorkouts: TrainingPeaksWodInterface[] | null;
}

/* ------------- Reducers Actions ------------- */

export interface SetWorkoutsListAction {
  type: string;
  workouts: WorkoutInterface[];
}

export interface SetIntroductionsListAction {
  type: string;
  introductions: WorkoutInterface[];
}

export interface SetTrainingPlansAction {
  type: string;
  trainingPlans: TrainingPlansInterface[];
}

export interface GetWorkoutNotesAction {
  type: string;
  notesId: string;
}

export interface SetWorkoutNotesAction {
  type: string;
  notes: WorkoutNotesInterface;
}

export interface SetTrainingPeeksWorkoutsAction {
  type: string;
  workouts: TrainingPeaksWodInterface[];
}

export interface ClearNotesAction {
  type: string;
}

/* ------------- State Elements ------------- */

export interface WorkoutInterface {
  id: string;
  name: string;
  wid: string;
  virtual_coach?: boolean;
  category?: WORKOUT_CATEGORY | string;
  intensity?: number;
  supported_languages?: string[];
}

export interface WorkoutNotesInterface {
  notesId: string;
  html: string;
}

export enum WORKOUT_CATEGORY {
  ALL_CATEGORIES = "All categories",
  TECHNIQUE = "Technique",
  INTRODUCTION = "Introduction",
  ENDURANCE = "Endurance",
  BLEND = "Blend",
  ANAEROBIC = "Anaerobic",
  THRESHOLD = "Threshold",
  POWERWATTS_UK = "Powerwatts Uk",
  POWERWATTS_UK_ANAEROBIC = "Powerwatts Uk. Anaerobic",
  POWERWATTS_UK_THRESHOLD = "Powerwatts Uk. Threshold",
  TRAINING_PEEKS = "Training Peeks",
  EVENT = "Event",
  SPRINT = "Sprint",
  PRO_WORKOUTS = "Pro Workouts",
  RACE_PREP = "Race Prep",
  ACTIVE_RECOVERY = "Active Recovery",
  HILLS = "Hills",
}

export interface GetTrainingPlansResponse {
  success: boolean;
  training_plans: TrainingPlansInterface[];
}

export interface TrainingPlansInterface {
  description: string;
  ebook_url: string;
  id: number;
  introduction_video_id: string | null;
  logo_url: string | null;
  training_plan_name: string;
  video_id: string | null;
  workouts: TrainingPlanWorkoutInterface[];
}

export interface TrainingPlanWorkoutInterface {
  cpx_workout_id: string;
  has_video: boolean;
  id: number;
  intensity: number;
  notes: string | null;
  s3_key: string;
  sort_order: number;
  workout_name: string;
  supported_languages?: string[];
}

export interface IntroductionWorkoutInterface {
  id: string;
  name: string;
  wid: string;
}

export interface TrainingPeaksWodInterface {
  completed: boolean;
  id: string;
  name: string;
  start_time: string | null;
  total_time_planed: string | null;
  tp_url: string;
  wid: number;
  workout_date: string;
  supported_languages?: string[];
}
