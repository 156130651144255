import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
// import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    backgroundColor: MAIN_THEME.DEFAULT?.INFO_BACKGROUND_COLOR,
  },
});
