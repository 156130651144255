import React from "react";
import { View, Picker } from "react-native";
import { DefaultText } from "../../index";
import { themedStyles } from "./styles";

interface PickerItemProps {
  itemText: string;
  pickerOptions: { label: string; value: string }[];
  placeholder: { label: string; value: string };
  itemName: string;
  handleChange: (name: string) => any;
  isValid: boolean;
}

export const PickerItem = ({
  itemText,
  pickerOptions,
  itemName,
  placeholder,
  handleChange,
  isValid,
}: PickerItemProps) => {
  return (
    <View style={themedStyles.formItemContainer}>
      <DefaultText style={themedStyles.formItemText}>{itemText}</DefaultText>
      <Picker
        style={[themedStyles.formTextInputMobile, !isValid && themedStyles.errBorder]}
        onValueChange={handleChange(itemName)}
      >
        <Picker.Item label={placeholder?.label || ""} value={placeholder?.value} />
        {pickerOptions?.map((item) => (
          <Picker.Item label={`${item.label}`} value={item.value} key={item.label} />
        ))}
      </Picker>
    </View>
  );
};
