import { DeviceType } from "../redux/types";
import {
  UnknownDeviceString,
  SERVICE_UUID_CYCLING_POWER,
  SERVICE_UUID_TACX_TRAINER_CONTROL,
  CHARACTERISTIC_UUID_CYCLING_POWER_MEASUREMENT,
  SERVICE_UUID_FTMS,
  CHARACTERISTIC_UUID_TACX_TRAINER_CONTROL_RX,
  CHARACTERISTIC_UUID_WAHOO_LEGACY_CONTROL,
  CHARACTERISTIC_UUID_TACX_TRAINER_CONTROL_TX,
  CHARACTERISTIC_UUID_INDOOR_BIKE_DATA,
  CHARACTERISTIC_UUID_FTMS_CONTROL_POINT,
  isKickrDevice
} from "./common/BleMeterCommon";
import { SmartTrainerBase } from "./common/SmartTrainerBase";
import { BleMeter } from "./BleMeter.web";

export class SmartTrainer extends SmartTrainerBase {
  server: BluetoothRemoteGATTServer | undefined;
  services: BluetoothRemoteGATTService[];
  constructor(
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer | undefined,
    services: BluetoothRemoteGATTService[],
    userWeight: number,
    userHeight: number
  ) {
    const deviceName = device.name || UnknownDeviceString;
    const bleDevice = new BleMeter(
      DeviceType.SmartTrainer,
      null,
      device,
      server,
      userWeight
    );
    super(bleDevice, deviceName, userWeight, userHeight);

    this.server = server;
    this.services = services;
  }

  isConnected(): boolean {
    const bleMeter = this.bleDevice as BleMeter;
    return bleMeter.device.gatt?.connected || false;
  }

  async SetupCharacteristics() {
    const tacxTrainerControlService = this.services.find(
      (o) => o.uuid === SERVICE_UUID_TACX_TRAINER_CONTROL
    );
    const wahooLegacyService = this.services.find((o) => o.uuid === SERVICE_UUID_CYCLING_POWER);
    const ftmsService = this.services.find((o) => o.uuid === SERVICE_UUID_FTMS);
    const bleMeter = this.bleDevice as BleMeter;

    if (tacxTrainerControlService) {
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("Try Tacx...");

      const characteristics = await tacxTrainerControlService.getCharacteristics();
      const notifyCharacteristic = characteristics.find(
        (o) => o.uuid === CHARACTERISTIC_UUID_TACX_TRAINER_CONTROL_RX
      );
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("notify characteristic: " + notifyCharacteristic?.uuid);

      const controlCharacteristic = characteristics.find(
        (o) => o.uuid === CHARACTERISTIC_UUID_TACX_TRAINER_CONTROL_TX
      );
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("control characteristic: " + controlCharacteristic?.uuid);

      if (notifyCharacteristic && controlCharacteristic) {
        bleMeter.notifyCharacteristic = notifyCharacteristic;
        bleMeter.controlCharacteristic = controlCharacteristic;

        bleMeter.listen();
        await this.initializeControlCharacteristicTacx();
        this.updateGradeLoop();
        return;
      }
    }

    if (ftmsService) {
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("Try FTMS...");

      const characteristics = await ftmsService.getCharacteristics();
      const notifyCharacteristic = characteristics.find(
        (o) => o.uuid === CHARACTERISTIC_UUID_INDOOR_BIKE_DATA
      );
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("notify characteristic: " + notifyCharacteristic?.uuid);

      const controlCharacteristic = characteristics.find(
        (o) => o.uuid === CHARACTERISTIC_UUID_FTMS_CONTROL_POINT
      );
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("control characteristic: " + controlCharacteristic?.uuid);

      if (notifyCharacteristic && controlCharacteristic) {
        bleMeter.notifyCharacteristic = notifyCharacteristic;
        bleMeter.controlCharacteristic = controlCharacteristic;

        bleMeter.listen();
        await this.initializeControlCharacteristicFtms(); // Make sure that listen() is called before this
        this.updateGradeLoop();
        return;
      }
    }

    if (wahooLegacyService && isKickrDevice(this.name)) {
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("Try Wahoo...");

      const characteristics = await wahooLegacyService.getCharacteristics();
      const notifyCharacteristic = characteristics.find(
        (o) => o.uuid === CHARACTERISTIC_UUID_CYCLING_POWER_MEASUREMENT
      );
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("notify characteristic: " + notifyCharacteristic?.uuid);

      const controlCharacteristic = characteristics.find(
        (o) => o.uuid === CHARACTERISTIC_UUID_WAHOO_LEGACY_CONTROL
      );
      if (this.bleDevice?.OnDebug) this.bleDevice.OnDebug("control characteristic: " + controlCharacteristic?.uuid);

      if (notifyCharacteristic && controlCharacteristic) {
        bleMeter.notifyCharacteristic = notifyCharacteristic;
        bleMeter.controlCharacteristic = controlCharacteristic;

        bleMeter.listen();
        await this.initializeControlCharacteristicWahooLegacy();
        this.updateGradeLoop();
        return;
      }
    }
  }

  async WriteControlCharacteristic(fullPacket: Uint8Array) {
    const bleMeterWeb = this.bleDevice as BleMeter;
    if (bleMeterWeb && bleMeterWeb.controlCharacteristic) {
      // Send packet to control characteristic
      await bleMeterWeb.controlCharacteristic.writeValue(fullPacket);
    } else {
      if (this.bleDevice?.OnError) this.bleDevice.OnError("No Control Characteristic Set");
    }
  }

  async WriteCharacteristic(serviceUuid: string, characteristicUuid: string, data: Uint8Array) {
    try {
      if (!this.server)
        return false;

      const service = await this.server.getPrimaryService(serviceUuid);
      if (!service)
        return false;

      const characteristic = await service.getCharacteristic(characteristicUuid);
      if (!characteristic)
        return false;

      await characteristic.writeValue(data);
      return true;
    } catch {
      return false;
    }
  }
}
