import React from "react";
import { View } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";
import { ActiveMemberInterface, WorkoutIntervalInterface } from "../../../redux/types";
import {
  getMetricsStrByType,
  getMetricsSubset,
  VALUE_TYPE,
} from "../../../utils/MetricsCalculating";
import { getGaugeTextColor } from "../../../utils/SvgHelpers";
import { MAIN_THEME } from "../../../themes";
import { isGameInterval } from "../../../utils/Statistics";
import { getPlaceNumberColor } from "../../../utils/GameScoreCalculating";

type MetricInfoBlockPropsType = {
  member: ActiveMemberInterface;
  index: number;
  step: WorkoutIntervalInterface;
  currentStepIndex: number | null;
  timeFromStepStart: number;
  isSegmentStep?: boolean;
  currentCourseId?: string;
};
const _MetricInfoBlock = ({
  member,
  index,
  step,
  currentStepIndex,
  timeFromStepStart,
  isSegmentStep,
  currentCourseId,
}: MetricInfoBlockPropsType) => {
  const segmentMetrics =
    member.segmentsHistory?.find((item) => item.courseId === currentCourseId)?.segmentsMetric || [];

  const stepMetrics = member.metrics;
  const currentMetric = isSegmentStep ? segmentMetrics : stepMetrics;
  const hasMetrics = !!currentMetric[index];

  const isCurrentStepIndex = index === currentStepIndex;
  const currentPower = member?.stats?.powerAvg;
  const historyPower = currentMetric[index]?.avgPowerPerStep;
  const currentCadence = member?.stats?.cadenceAvg;
  const historyCadence = currentMetric[index]?.avgCadencePerStep;
  const isGameStep = isGameInterval(step?.intervalType);
  const currentScoreLine = currentMetric[index]?.gameAvgScoreLine || "---";
  const placeInLeaderboard = currentMetric[index]?.place || 0;

  const isPrizePlace =
    placeInLeaderboard !== null && placeInLeaderboard >= 1 && placeInLeaderboard <= 3;

  const style = [
    themedStyles.listItemContainer,
    !member.isHide && themedStyles.webCell,
    index % 2 !== 0 && themedStyles.secondListItemContainer,
    currentStepIndex === index && themedStyles.activeCell,
    member.isHide && themedStyles.webCellHided,
    member.isHide && isGameStep && themedStyles.webGameCellHided,
  ];
  if (member.isHide) {
    return <View style={style} />;
  }
  const placeColor = getPlaceNumberColor(placeInLeaderboard, isGameStep);
  const powerPercent = getMetricsStrByType({
    type: VALUE_TYPE.POWER_PERCENT,
    cp: member.cp,
    hasMetrics,
    isCurrentStepIndex,
    power: currentPower,
    avgPower: historyPower,
  });

  const cadence = getMetricsStrByType({
    type: VALUE_TYPE.CADENCE,
    cp: member.cp,
    hasMetrics,
    isCurrentStepIndex,
    cadence: currentCadence,
    avgCadence: historyCadence,
  });
  const load = getMetricsSubset({
    cp: member.cp,
    step: step,
    isStarted: true,
    timeFromStepStart,
  });
  const currentPowerColor = getGaugeTextColor({
    value: currentPower,
    min: load.wattsLoad - load.wattsWindowLow,
    max: load.wattsLoad + load.wattsWindowHigh,
  });

  const historyPowerColor = getGaugeTextColor({
    value: historyPower,
    min: load.wattsLoad - load.wattsWindowLow,
    max: load.wattsLoad + load.wattsWindowHigh,
  });

  const currentCadenceColor = getGaugeTextColor({
    value: currentCadence,
    min: load.cadenceLoad - load.cadenceWindowLow,
    max: load.cadenceLoad + load.cadenceWindowHigh,
  });

  const historyCadenceColor = getGaugeTextColor({
    value: historyCadence,
    min: load.cadenceLoad - load.cadenceWindowLow,
    max: load.cadenceLoad + load.cadenceWindowHigh,
  });

  const powerColor = isCurrentStepIndex
    ? currentPowerColor
    : hasMetrics
    ? historyPowerColor
    : MAIN_THEME.GAUGE.INACTIVE_ZONE_COLOR;

  const cadenceColor = isCurrentStepIndex
    ? currentCadenceColor
    : hasMetrics
    ? historyCadenceColor
    : MAIN_THEME.GAUGE.INACTIVE_ZONE_COLOR;
  return (
    <View style={style}>
      <View>
        <View style={themedStyles.cellContainer}>
          <DefaultText style={[themedStyles.stepMetricsText, { color: powerColor }]}>
            {`${powerPercent} `}
          </DefaultText>
          <DefaultText style={[themedStyles.stepMetricsText, { color: cadenceColor }]}>
            {cadence}
          </DefaultText>
        </View>
        {isGameStep && (
          <DefaultText style={[themedStyles.scoreLineText, { color: placeColor }]}>
            {isPrizePlace && hasMetrics
              ? `#${placeInLeaderboard} ${currentScoreLine}`
              : currentScoreLine}
          </DefaultText>
        )}
      </View>
    </View>
  );
};

export const MetricInfoBlock = React.memo(_MetricInfoBlock, (prevProps, nextProps) => {
  return (
    nextProps.index !== nextProps.currentStepIndex &&
    nextProps.index !== (nextProps.currentStepIndex || 0) - 1 &&
    prevProps.member.metrics.length === nextProps.member.metrics.length &&
    prevProps.member.isHide === nextProps.member.isHide
  );
});
