import { StyleSheet, Dimensions } from "react-native";
import { MAIN_THEME } from "../../themes";

const { height } = Dimensions.get("window");

export const themedStyles = StyleSheet.create({
  contentContainer: {
    flex: 2,
    height: "65vh",
    padding: 20,
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
  },
  scrollContainer: {
    flex: 1.5,
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    overflow: "hidden",
    borderRadius: 20,
    marginBottom: 20,
  },

  gaugeContainer: { flexDirection: "row", flexWrap: "wrap" },
  memberGauge: {
    margin: 15,
    width: "max-content",
    justifyContent: "center",
    alignItems: "center",
  },
  workoutPickerLine: {
    alignSelf: "center",
    width: 300,
    marginTop: 12,
    paddingVertical: 8,
    paddingHorizontal: 12,
    color: MAIN_THEME.DEFAULT?.TEXT_COLOR,
    fontSize: 18,
    lineHeight: 29,
    borderWidth: 1,
    borderColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
    borderRadius: 4,
    backgroundColor: MAIN_THEME.WELCOME_SCREEN.TRAINING_ITEM_BACKGROUND_COLOR,
  },
  iconContainer: { padding: 5, marginTop: 5 },
  pickerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  metricContainer: {
    height: height * 0.35,
  },
  numberParam: {
    color: MAIN_THEME.DEFAULT?.REVERSE_TEXT_COLOR,
    backgroundColor: MAIN_THEME.HEADER.BUTTON_BACKGROUND_COLOR,
    textAlign: "center",
  },
});
