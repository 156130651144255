import { takeLatest, all, takeEvery } from "redux-saga/effects";
import { ActiveTrainingTypes } from "../reducers/activeTrainingReducer";
import { DeviceActionTypes } from "../reducers/devicesReducer";
import { TrainingDetailsTypes } from "../reducers/trainingDetailsReducer";
import { UserTypes } from "../reducers/userReducer";
import { searchForBleDevices, createBluetoothSensor, reconnectDevice } from "./deviceSagas.web";
import { connectMockBle } from "./mockBle";
import { timerSaga } from "./timerSagas";
import { CoachControlsTypes } from "../reducers/coachControlsReducer";
import { MetricsTypes } from "../reducers/metricsReducer";
import { SettingsTypes } from "../reducers/settingsReducer";
import { WorkoutsTypes } from "../reducers/workoutsReducer";
import {
  finishTraining,
  finishLiveTraining,
  nextStep,
  pauseTraining,
  pendingStartTraining,
  resumeTraining,
  startTraining,
  nextCourseStep,
} from "./trainingControlSagas";
import {
  getWorkoutNotes,
  initializeLiveTraining,
  initializeTraining,
} from "./trainingDetailsSagas";
import {
  getServicesConnections,
  initializeUser,
  updateCriticalPower,
  updateUserProfile,
} from "./userSagas";
import {
  calculateMetricHistory,
  calculateSegmentMetricHistory,
  deviceDataChanged,
  streamStats,
} from "./trainingMetricsSagas";
import {
  changeSessionWorkout,
  nextLiveTrainingStep,
  pauseLiveTrainingTraining,
  removeMember,
  restartSession,
  resumeLiveTraining,
  startLiveTraining,
  coachFinishLiveSession,
  changeUserCp20m,
} from "./coachControlsSagas";
import { SubscriptionTypes } from "../reducers/subscriptionReducer";
import { buyProduct, updateCreditCardInfo } from "./subscriptionSagas";

export default function* rootSaga() {
  yield all([
    /*------------ Global sagas -------------*/
    timerSaga(),

    /*------------ User sagas -------------*/
    takeLatest<any>(UserTypes.INITIALIZE_USER, initializeUser),
    takeLatest<any>(SettingsTypes.GET_SERVICES_CONNECTED, getServicesConnections),
    takeLatest<any>(SettingsTypes.UPDATE_CRITICAL_POWER, updateCriticalPower),
    takeLatest<any>(UserTypes.UPDATE_USER_PROFILE, updateUserProfile),

    /*------------ Device sagas -------------*/
    takeLatest<any>(DeviceActionTypes.SEARCH_FOR_BLE_DEVICES, searchForBleDevices),
    takeEvery<any>(DeviceActionTypes.CREATE_BLUETOOTH_SENSOR, createBluetoothSensor),
    takeEvery<any>(DeviceActionTypes.RECONNECT_DEVICE, reconnectDevice),
    takeLatest<any>(DeviceActionTypes.CONNECT_MOCK_BLE, connectMockBle),

    /*------------ Training Details sagas -------------*/
    takeLatest<any>(TrainingDetailsTypes.INITIALIZE_GROUP_TRAINING, initializeLiveTraining),
    takeLatest<any>(TrainingDetailsTypes.INITIALIZE_VIRTUAL_TRAINING, initializeTraining),
    takeLatest<any>(WorkoutsTypes.GET_WORKOUT_NOTES, getWorkoutNotes),

    /*------------ Training Control sagas -------------*/
    takeLatest<any>(ActiveTrainingTypes.PENDING_START_TRAINING, pendingStartTraining),
    takeLatest<any>(ActiveTrainingTypes.START_TRAINING, startTraining),
    takeLatest<any>(ActiveTrainingTypes.NEXT_STEP, nextStep),
    takeLatest<any>(ActiveTrainingTypes.NEXT_COURSE_STEP, nextCourseStep),
    takeLatest<any>(ActiveTrainingTypes.FINISH_TRAINING, finishTraining),
    takeLatest<any>(ActiveTrainingTypes.FINISH_LIVE_TRAINING, finishLiveTraining),
    takeLatest<any>(ActiveTrainingTypes.PAUSE_TRAINING, pauseTraining),
    takeLatest<any>(ActiveTrainingTypes.RESUME_TRAINING, resumeTraining),

    /*------------ Training Metrics sagas -------------*/
    takeLatest<any>(DeviceActionTypes.DEVICE_DATA_CHANGED, deviceDataChanged),
    takeLatest<any>(MetricsTypes.STREAM_STATS, streamStats),
    takeLatest<any>(MetricsTypes.CALCULATE_METRIC_HISTORY, calculateMetricHistory),
    takeLatest<any>(MetricsTypes.CALCULATE_SEGMENTS_METRIC_HISTORY, calculateSegmentMetricHistory),

    /*------------ Subscription sagas -------------*/
    takeLatest<any>(SubscriptionTypes.BUY_PRODUCT, buyProduct),
    takeLatest<any>(SubscriptionTypes.UPDATE_CREDIT_CARD_INFO, updateCreditCardInfo),

    /*------------ Coach Controls sagas -------------*/
    takeLatest<any>(ActiveTrainingTypes.START_TRAINING, startLiveTraining),
    takeLatest<any>(ActiveTrainingTypes.NEXT_STEP, nextLiveTrainingStep),
    takeLatest<any>(ActiveTrainingTypes.PAUSE_TRAINING, pauseLiveTrainingTraining),
    takeLatest<any>(ActiveTrainingTypes.RESUME_TRAINING, resumeLiveTraining),
    takeLatest<any>(CoachControlsTypes.CHANGE_SESSION_WORKOUT, changeSessionWorkout),
    takeLatest<any>(CoachControlsTypes.REMOVE_MEMBER, removeMember),
    takeLatest<any>(CoachControlsTypes.COACH_FINISH_LIVE_SESSION, coachFinishLiveSession),
    takeLatest<any>(CoachControlsTypes.RESTART_SESSION, restartSession),
    takeLatest<any>(CoachControlsTypes.CHANGE_USER_CP20M, changeUserCp20m),
  ]);
}
