import { Linking, Platform } from "react-native";

export const openLink = (url: string) => {
  Linking.canOpenURL(url).then((supported) => {
    if (supported) {
      if (Platform.OS === "web") {
        window.open(url, "_blank");
      } else {
        Linking.openURL(url);
      }
    } else {
      console.log("Don't know how to open URI: " + url);
    }
  });
};

export function getQueryParameters<T>(queryString: string): T {
  const sliceQueryString = queryString.slice(1);
  const params: T = {} as T;

  sliceQueryString.replace(/([^=]*)=([^&]*)&*/g, (_s, key: keyof T, value) => {
    params[key] = value;
    return "";
  });

  return params;
}

function pipe<T>(...fns: Array<(arg: T) => T>): (arg: T) => T {
  return (initialValue: T): T => {
    return fns.reduce((acc, fn) => fn(acc), initialValue);
  };
}

function unicodeToNormal(string: string) {
  return string.replace(/\\u[\dA-F]{4}/gi, (match) =>
    String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16)),
  );
}

function removeCrLf(string: string) {
  return string.replaceAll("\\n", "").replaceAll("\\r", "");
}

function removeMultipleSpaces(string: string) {
  return string.replace(/\s+/g, " ");
}

function correctCss(string: string) {
  return string.replace("\\\\0000a0", "\\0000a0");
}

function trimDoubleQuotesFromEnds(string: string) {
  return string.slice(0, string.lastIndexOf(`"`)).replace(`"`, "");
}

export function unicodeStringToHtml(string?: string) {
  if (string === undefined) {
    return "";
  }
  return pipe(
    unicodeToNormal,
    removeCrLf,
    removeMultipleSpaces,
    correctCss,
    trimDoubleQuotesFromEnds,
  )(string);
}
