import React, { useMemo } from "react";
import { View } from "react-native";
import { MAIN_THEME } from "../../themes";
import {
  CurrentMetricsInterface,
  TRAINING_TYPES,
  WorkoutIntervalInterface,
  INTERVAL_TYPE,
} from "../../redux/types";
import { DefaultText } from "../index";
import { themedStyles } from "./styles";
import { StepInfoLine } from "../StepInfoLine";
import { getGaugeTextColor } from "../../utils/SvgHelpers";
import { getMetricsSubset, VALUE_TYPE, getMetricsStrByType } from "../../utils/MetricsCalculating";
import { getGameInfoStrByType } from "../../utils/GameScoreCalculating";
import { ICON } from "../../constants";
import { MyIcon } from "../CommonElements/MyIcon";
import { CourseSegment } from "../CourseSegment";

type PropsType = {
  cp: number;
  timeFromStepStart: number;
  step: WorkoutIntervalInterface;
  isCurrentStepIndex: boolean;
  userCurrentMetrics: CurrentMetricsInterface;
  userStepsMetricHistory: CurrentMetricsInterface;
  trainingType: TRAINING_TYPES | null;
  currentStepIndex: number | null;
  stepIndex: number;
  isSegment?: boolean;
};

interface GameInfoProps {
  gameInfo: string | null;
  stepType: INTERVAL_TYPE;
  isSegment?: boolean;
}

const _StepBlock = ({
  cp,
  step,
  isSegment,
  trainingType,
  timeFromStepStart,
  isCurrentStepIndex,
  userCurrentMetrics,
  userStepsMetricHistory,
}: PropsType) => {
  const hasMetrics = !!userStepsMetricHistory;
  const additionalStyle = isCurrentStepIndex ? themedStyles.currentStepContainer : {};
  const userPower = userCurrentMetrics.power_avg;
  const avgPower = userStepsMetricHistory?.power_avg;
  const userCadence = userCurrentMetrics.cadence_avg;
  const avgCadence = userStepsMetricHistory?.cadence_avg;
  const userHeartRate = userCurrentMetrics?.heartRate_avg;
  const avgHeartRate = userStepsMetricHistory?.heartRate_avg;
  const userMaxHeartRate = userCurrentMetrics?.heartRate_max;
  const historyMaxHeartRate = userStepsMetricHistory?.heartRate_max;
  const gameInfo = getGameInfoStrByType({
    type: step.intervalType,
    hasMetrics,
    isCurrentStepIndex,
    currentMetrics: userCurrentMetrics,
    historyMetrics: userStepsMetricHistory,
    isSegment,
  });

  const powerPercent = getMetricsStrByType({
    type: VALUE_TYPE.POWER_PERCENT,
    cp,
    hasMetrics,
    isCurrentStepIndex,
    power: userPower,
    avgPower,
  });

  const power = getMetricsStrByType({
    type: VALUE_TYPE.POWER,
    cp,
    hasMetrics,
    isCurrentStepIndex,
    power: userPower,
    avgPower,
  });
  const cadence = getMetricsStrByType({
    type: VALUE_TYPE.CADENCE,
    cp,
    hasMetrics,
    isCurrentStepIndex,
    cadence: userCadence,
    avgCadence,
  });
  const heartRate = getMetricsStrByType({
    type: VALUE_TYPE.HEART_RATE,
    cp,
    hasMetrics,
    isCurrentStepIndex,
    heartRate: userHeartRate,
    avgHeartRate,
  });
  const maxHeartRate = getMetricsStrByType({
    type: VALUE_TYPE.MAX_HEART_RATE,
    cp,
    hasMetrics,
    isCurrentStepIndex,
    heartRate: userMaxHeartRate,
    avgHeartRate: historyMaxHeartRate,
  });

  const load = getMetricsSubset({
    cp,
    step,
    isStarted: true,
    timeFromStepStart,
  });

  const currentPowerColor = getGaugeTextColor({
    value: userCurrentMetrics.power_avg,
    min: load.wattsLoad - load.wattsWindowLow,
    max: load.wattsLoad + load.wattsWindowHigh,
  });
  const historyPowerColor = useMemo(
    () =>
      getGaugeTextColor({
        value: avgPower,
        min: load.wattsLoad - load.wattsWindowLow,
        max: load.wattsLoad + load.wattsWindowHigh,
      }),
    [avgPower, load],
  );

  const currentCadenceColor = getGaugeTextColor({
    value: userCurrentMetrics.cadence,
    min: load.cadenceLoad - load.cadenceWindowLow,
    max: load.cadenceLoad + load.cadenceWindowHigh,
  });

  const historyCadenceColor = useMemo(
    () =>
      getGaugeTextColor({
        value: avgCadence,
        min: load.cadenceLoad - load.cadenceWindowLow,
        max: load.cadenceLoad + load.cadenceWindowHigh,
      }),
    [avgCadence, load],
  );

  const powerColor = isCurrentStepIndex
    ? currentPowerColor
    : hasMetrics
    ? historyPowerColor
    : MAIN_THEME.GAUGE.INACTIVE_ZONE_COLOR;

  const cadenceColor = isCurrentStepIndex
    ? currentCadenceColor
    : hasMetrics
    ? historyCadenceColor
    : MAIN_THEME.GAUGE.INACTIVE_ZONE_COLOR;

  return (
    <View
      style={[
        themedStyles.stepMetricsContainer,
        additionalStyle,
        trainingType === TRAINING_TYPES.LIVE_SESSION && themedStyles.horizontalView,
      ]}
    >
      {isSegment ? <CourseSegment segment={step} /> : <StepInfoLine step={step} />}
      <View>
        <View style={themedStyles.secondLineInfoContainer}>
          <DefaultText
            style={[
              themedStyles.secondLineInfoText,
              themedStyles.powerPercentText,
              { color: powerColor },
            ]}
            numberOfLines={1}
          >
            {powerPercent}
          </DefaultText>
          <DefaultText
            style={[themedStyles.secondLineInfoText, themedStyles.powerText, { color: powerColor }]}
            numberOfLines={1}
          >
            {power}
          </DefaultText>
          <DefaultText
            style={[
              themedStyles.secondLineInfoText,
              themedStyles.cadenceText,
              { color: cadenceColor },
            ]}
            numberOfLines={1}
          >
            {cadence}
          </DefaultText>
          <DefaultText
            style={[themedStyles.secondLineInfoText, themedStyles.heartRateText]}
            numberOfLines={1}
          >
            {heartRate}
            {maxHeartRate}
          </DefaultText>
        </View>
        <GameInfo gameInfo={gameInfo} stepType={step.intervalType} />
      </View>
    </View>
  );
};

export const StepBlock = React.memo(_StepBlock, (_prevProps, nextProps) => {
  return (
    nextProps.stepIndex !== nextProps.currentStepIndex &&
    nextProps.stepIndex + 1 !== nextProps.currentStepIndex &&
    nextProps.stepIndex - 1 !== nextProps.currentStepIndex
  );
});

const GameInfo = ({ gameInfo, stepType }: GameInfoProps) => {
  switch (stepType) {
    case INTERVAL_TYPE.TAR:
      return (
        <View style={themedStyles.gameInfoContainer}>
          <MyIcon icon={ICON.TARGET} />
          <DefaultText style={themedStyles.gameInfoText}>{gameInfo}</DefaultText>
        </View>
      );
    case INTERVAL_TYPE.TIZ:
      return (
        <View style={themedStyles.gameInfoContainer}>
          <MyIcon icon={ICON.STOPWATCH} />
          <DefaultText style={themedStyles.gameInfoText}>{gameInfo}</DefaultText>
        </View>
      );
    case INTERVAL_TYPE.AGR:
      return (
        <View style={themedStyles.gameInfoContainer}>
          <MyIcon icon={ICON.FIRE} />
          <DefaultText style={themedStyles.gameInfoText}>{gameInfo}</DefaultText>
        </View>
      );
    case INTERVAL_TYPE.SPR:
      return (
        <View style={themedStyles.gameInfoContainer}>
          <MyIcon icon={ICON.ROCKET} />
          <DefaultText style={themedStyles.gameInfoText}>{gameInfo}</DefaultText>
        </View>
      );
    case INTERVAL_TYPE.KOD:
      return (
        <View style={themedStyles.gameInfoContainer}>
          <MyIcon icon={ICON.KOD} />
          <DefaultText style={themedStyles.gameInfoText}>{gameInfo}</DefaultText>
        </View>
      );
    case INTERVAL_TYPE.KOM:
      return (
        <View style={themedStyles.gameInfoContainer}>
          <MyIcon icon={ICON.KOM} />
          <DefaultText style={themedStyles.gameInfoText}>{gameInfo}</DefaultText>
        </View>
      );
    default:
      return <View />;
  }
};
