import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Sound } from "../../utils/sound";
import ActiveTrainingCreators from "../../redux/reducers/activeTrainingReducer";
import CoachControlsCreators from "../../redux/reducers/coachControlsReducer";
import { Platform, View } from "react-native";
import { themedStyles } from "./styles";
import { StepInfoLine } from "../index";
import { DefaultText } from "../CommonElements/DefaultText";
import { StateInterface } from "../../redux/reducers";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { TRAINING_STATUS_TYPES } from "../../redux/types";

import Svg, { LinearGradient, Defs, Stop, Rect } from "react-native-svg";
import { Event } from "../../services/googleAnalyticsTracking";
import { MP3 } from "../../assets";

const HeaderTimersComponent = ({
  workoutStartTimestamp,
  stepStartTimestamp,
  workoutSteps,
  dateTime,
  trainingStatus,
  currentSteps,
  nextStep,
  startTraining,
  changeTrainingStatus,
  coachFinishLiveSession,
}: CombinedProps) => {
  const [switchStepDelay, setSwitchStepDelay] = useState(0);
  const currentStepIndex = currentSteps.currentStepIndex || 0;
  const currentStepDurationInMs = () => {
    if (
      workoutSteps &&
      trainingStatus !== TRAINING_STATUS_TYPES.PENDING_START &&
      trainingStatus !== TRAINING_STATUS_TYPES.PENDING_START_PAUSED
    ) {
      return workoutSteps[currentStepIndex]?.durationInSeconds * 1000;
    } else {
      return workoutStartTimestamp - stepStartTimestamp;
    }
  };

  const stepEndTimestamp = stepStartTimestamp + currentStepDurationInMs() - switchStepDelay;

  const duration =
    workoutSteps?.slice(currentStepIndex).reduce((durationSum, step) => {
      return durationSum + step.durationInSeconds;
    }, 0) || 0;

  const workoutEndTimestamp = dateTime + duration * 1000 - (dateTime - stepStartTimestamp);

  const timestampFromStart =
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START ||
    trainingStatus === TRAINING_STATUS_TYPES.NOT_INITIALIZED ||
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED ||
    trainingStatus === TRAINING_STATUS_TYPES.FINISHED ||
    trainingStatus === TRAINING_STATUS_TYPES.FINISHED_AND_SAVED
      ? 0
      : moment(dateTime).diff(workoutStartTimestamp);

  const timeFromStart = moment(timestampFromStart).utc(false).format("H:mm:ss");

  const timestampToEnd =
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START ||
    trainingStatus === TRAINING_STATUS_TYPES.NOT_INITIALIZED ||
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED ||
    trainingStatus === TRAINING_STATUS_TYPES.FINISHED ||
    trainingStatus === TRAINING_STATUS_TYPES.FINISHED_AND_SAVED
      ? 0
      : moment(workoutEndTimestamp).diff(dateTime);

  const timeToEnd = moment(timestampToEnd).utc(false).format("H:mm:ss");

  const timeToStepEnd =
    trainingStatus === TRAINING_STATUS_TYPES.NOT_INITIALIZED ||
    trainingStatus === TRAINING_STATUS_TYPES.FINISHED ||
    trainingStatus === TRAINING_STATUS_TYPES.FINISHED_AND_SAVED ||
    stepEndTimestamp < dateTime
      ? 0
      : moment(stepEndTimestamp).diff(dateTime);

  const intervalTimer =
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START ||
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED
      ? moment(timeToStepEnd).utc(false).format("-mm:ss")
      : moment(timeToStepEnd).utc(false).format("mm:ss");

  const isStepToggle =
    stepEndTimestamp <= dateTime && trainingStatus === TRAINING_STATUS_TYPES.STARTED;

  const sound = useMemo(
    () =>
      Platform.select({
        web: new Sound(MP3.beep),
        default: new Sound("pizzicato.mp3", Sound.MAIN_BUNDLE),
      }),
    [],
  );

  const isTimeToBeep = timeToStepEnd < 4000 && trainingStatus === TRAINING_STATUS_TYPES.STARTED;

  useEffect(() => {
    if (isTimeToBeep) {
      sound.play();
    }
  }, [isTimeToBeep, sound]);

  useEffect(() => {
    if (isStepToggle) {
      setSwitchStepDelay(dateTime - stepEndTimestamp);
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStepToggle, setSwitchStepDelay, nextStep]);

  const isStartTraining =
    workoutStartTimestamp <= dateTime &&
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START &&
    workoutStartTimestamp !== 0;

  useEffect(() => {
    if (isStartTraining) {
      startTraining();
    }
  }, [isStartTraining, startTraining]);

  const isFinishedTraining =
    !currentStepDurationInMs() && trainingStatus === TRAINING_STATUS_TYPES.STARTED;

  useEffect(() => {
    if (isFinishedTraining) {
      Event("end_workout", { duration_is_sec: timestampFromStart });

      changeTrainingStatus(TRAINING_STATUS_TYPES.FINISHED);
      coachFinishLiveSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishedTraining, Event, changeTrainingStatus, coachFinishLiveSession]);

  const renderGradientOverlay = () => (
    <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
      <Svg height="100%" width="100%">
        <Defs>
          <LinearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
            <Stop offset="0" stopColor="#41C332" stopOpacity="1" />
            <Stop offset="1" stopColor="#12A800" stopOpacity="1" />
          </LinearGradient>
        </Defs>
        <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
      </Svg>
    </View>
  );

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.timerContainer}>
        <DefaultText style={themedStyles.timer}>{timeFromStart}</DefaultText>
        <View style={themedStyles.stepInfoContainer}>
          <StepInfoLine step={currentSteps.currentStep} type="current" />
        </View>
      </View>
      <View style={themedStyles.centralTimerBlock}>
        {renderGradientOverlay()}
        <DefaultText style={themedStyles.centralTimer}>{intervalTimer}</DefaultText>
      </View>
      <View style={themedStyles.timerContainer}>
        <View style={themedStyles.stepInfoContainer}>
          <StepInfoLine step={currentSteps.nextStep} type="next" />
        </View>
        <DefaultText style={themedStyles.timer}>{timeToEnd}</DefaultText>
      </View>
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  workoutStartTimestamp: state.timer.workoutStartTimestamp,
  stepStartTimestamp: state.timer.stepStartTimestamp,
  workoutSteps: state.activeTraining.plan?.steps,
  dateTime: state.timer.dateTime,
  trainingStatus: state.activeTraining.status,
  currentSteps: state.activeTraining.currentSteps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  nextStep: () => dispatch(ActiveTrainingCreators.nextStep()),
  startTraining: () => dispatch(ActiveTrainingCreators.startTraining()),
  changeTrainingStatus: (status: TRAINING_STATUS_TYPES) =>
    dispatch(ActiveTrainingCreators.changeTrainingStatus(status)),
  coachFinishLiveSession: () => dispatch(CoachControlsCreators.coachFinishLiveSession()),
});

export const HeaderTimers = connect(mapStateToProps, mapDispatchToProps)(HeaderTimersComponent);
