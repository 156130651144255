import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../../themes";
import { RFValue } from "../../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  formContainer: {
    flex: 1,
    width: "70%",
    padding: RFValue(24),
  },
  formContentContainer: {
    paddingBottom: RFValue(40),
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: RFValue(20),
    width: "100%",
  },
  buttonTouchable: {
    flex: 1,
    marginHorizontal: "5%",
  },
  cancelButton: {
    flex: 1,
    marginHorizontal: "5%",
    backgroundColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
  },
  saveButton: { backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR },
  successMessage: {
    alignSelf: "center",
    color: "green",
  },
  failMessage: {
    alignSelf: "center",
    color: "red",
  },
  erMessage: {
    fontSize: RFValue(10),
    lineHeight: RFValue(12),
    color: "red",
    alignSelf: "flex-end",
    marginBottom: RFValue(10),
  },
});
