import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../../../themes";
import { COMMON_STYLES } from "../../../../../themes/commonStyles";
import { RFValue } from "../../../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {},
  productContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemContainer: { flex: 1, maxWidth: "49%", minWidth: "49%", marginBottom: RFValue(18) },
  productCardContainer: {
    minHeight: RFValue(300),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: MAIN_THEME.DEFAULT?.PLACEHOLDER_COLOR,
    ...COMMON_STYLES.BOTTOM_SHADOW,
  },
  selectedCard: {
    backgroundColor: MAIN_THEME.DEFAULT?.CONFORMING_BACKGROUND_COLOR,
  },
  productName: {
    fontSize: RFValue(42),
    textAlign: "center",
    color: MAIN_THEME.WELCOME_SCREEN.ACTIVE_TEXT_COLOR,
  },
  productPrice: {
    fontSize: RFValue(32),
    color: MAIN_THEME.WELCOME_SCREEN.ACTIVE_TEXT_COLOR,
  },
  productTotalPrice: { fontSize: RFValue(21) },
  paymentDescription: {
    fontSize: RFValue(18),
    color: MAIN_THEME.WELCOME_SCREEN.REMARK_TEXT_COLOR,
  },
  title: {
    fontSize: RFValue(24),
    paddingTop: RFValue(24),
    alignSelf: "center",
  },
});
