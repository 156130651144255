import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    paddingVertical: RFValue(100),
  },
  modalContainer: {
    flex: 1,
    overflow: "scroll",
    width: "70%",
    backgroundColor: "white",
    padding: RFValue(24),
    borderRadius: RFValue(8),
  },
  button: {
    alignSelf: "flex-end",
    width: "max-content",
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(8),
    borderRadius: RFValue(4),
    backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
  },
  buttonText: {
    fontWeight: "500",
    fontSize: RFValue(16),
    lineHeight: RFValue(24),
  },
  scroll: {},
  closeIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "transparent",
    zIndex: 999,
  },
});
