import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../../../themes";
import { RFValue } from "../../../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    flex: 1,
    padding: RFValue(24),
    width: "100%",
  },
  fieldsContainer: {
    marginVertical: RFValue(24),
  },
  doubleLineContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  payButtonContainer: {
    flexShrink: 1,
    padding: RFValue(12),
    backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
    borderRadius: RFValue(8),
    justifyContent: "center",
    alignItems: "center",
  },
  payButtonInactive: {
    backgroundColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  successMessage: {
    textAlign: "center",
    color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR,
  },
});
