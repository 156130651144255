import {
  CourseInterface,
  INTERVAL_TYPE,
  PRICING_MODE,
  TrainingPlanInterface,
  WorkoutIntervalInterface,
  WorkoutSetInterface,
} from "../redux/types";

interface GetCurrentCourseStepIndexProps {
  currentCourseStepIndex: number | null;
  currentSteps: WorkoutIntervalInterface[];
}

interface CheckAccessProps {
  userAccessLvl: number | null;
  requiredPricingMode: PRICING_MODE;
}

export const getTrainingPlan = (workoutData: any) => {
  let workoutDuration = 0;
  const workoutSteps: WorkoutIntervalInterface[] = [];
  const workoutSets: WorkoutSetInterface[] = workoutData.sets;
  const workoutsCourses: CourseInterface[] =
    typeof workoutData.courses !== "undefined" ? workoutData.courses : [];

  workoutSets.forEach((set, setIndex) => {
    set.intervals.forEach((interval, intervalIndex) => {
      if (interval.durationInSeconds > 0 && interval.intervalType !== INTERVAL_TYPE.SEGMENT_BASED) {
        workoutSteps.push({
          ...interval,
          intervalId: `${setIndex}-${intervalIndex}`,
        });
        workoutDuration += interval.durationInSeconds;
      } else if (
        interval.durationInSeconds > 0 &&
        interval.intervalType === INTERVAL_TYPE.SEGMENT_BASED
      ) {
        workoutSteps.push({
          ...interval,
          intervalId: `${setIndex}-${intervalIndex}`,
        });
        workoutDuration += interval.durationInSeconds;
      }
    });
  });

  const trainingPlan: TrainingPlanInterface = {
    description: workoutData.description,
    duration: workoutDuration,
    intensityFactor: workoutData.intensityFactor,
    sets: workoutSets,
    steps: workoutSteps,
    courses: workoutsCourses,
    workoutId: workoutData.workoutId,
    workoutName: workoutData.workoutName,
    workoutPlanImage: workoutData.workoutPlanImage,
  };
  return trainingPlan;
};

export const getCurrentCourseStepIndex = ({
  currentCourseStepIndex,
  currentSteps,
}: GetCurrentCourseStepIndexProps) => {
  if (currentCourseStepIndex !== null && !!currentSteps[0]?.courseSegments) {
    return currentCourseStepIndex;
  } else if (!!currentSteps[0]?.courseSegments) {
    return 0;
  } else return null;
};

export const getUserAccessLvl = (pricingMode: PRICING_MODE) => {
  switch (pricingMode) {
    case PRICING_MODE.BASIC:
      return 0;
    case PRICING_MODE.PREMIUM:
      return 1;

    default:
      return 0;
  }
};

export const checkAccess = ({ userAccessLvl, requiredPricingMode }: CheckAccessProps) => {
  if (userAccessLvl === null) return false;
  const requiredAccessLvl = getUserAccessLvl(requiredPricingMode);
  if (userAccessLvl >= requiredAccessLvl) {
    return true;
  } else return false;
};
