import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "max-content",
  },
  altContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "max-content",
  },
  stepMetricsInfoText: {
    fontSize: RFValue(25),
  },
  currentStepMetricsInfoText: {
    fontWeight: "500",
    fontSize: RFValue(48),
    color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR,
    marginHorizontal: RFValue(5),
  },
  nextStepMetricsInfoText: {
    fontWeight: "500",
    fontSize: RFValue(34),

    color: MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR,
  },
  iconContainer: {
    marginHorizontal: RFValue(1),
  },
  iconPlus: {
    marginHorizontal: RFValue(5),
    bottom: RFValue(1),
  },
  lightingContainer: { marginHorizontal: RFValue(-5) },
});

export const getResponsiveStyle = (type?: "current" | "next") => {
  let containerStyle = {};
  let iconColor: string | undefined = "";
  let lineStyle = {};
  let iconSize = 0;
  let lightingSize = 0;
  let targetSize = 0;
  let eyeSize = 0;
  let stopwatchSize = 0;
  let fireSize = 0;
  let plusSize = 0;
  let rocketSize = 0;

  switch (type) {
    case "next":
      containerStyle = themedStyles.altContainer;
      iconColor = MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR;
      lineStyle = themedStyles.nextStepMetricsInfoText;
      iconSize = RFValue(20);
      lightingSize = RFValue(25);
      targetSize = RFValue(18);
      eyeSize = RFValue(19);
      stopwatchSize = RFValue(22);
      fireSize = RFValue(20);
      plusSize = RFValue(20);
      rocketSize = RFValue(18);
      break;
    case "current":
      containerStyle = themedStyles.container;
      iconColor = MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR;
      lineStyle = themedStyles.currentStepMetricsInfoText;
      iconSize = RFValue(38);
      lightingSize = RFValue(41);
      targetSize = RFValue(34);
      eyeSize = RFValue(33);
      stopwatchSize = RFValue(35);
      fireSize = RFValue(34);
      plusSize = RFValue(34);
      rocketSize = RFValue(31);
      break;
    default:
      containerStyle = themedStyles.altContainer;
      iconColor = MAIN_THEME.DEFAULT?.TEXT_COLOR;
      lineStyle = themedStyles.stepMetricsInfoText;
      iconSize = RFValue(23);
      lightingSize = RFValue(27);
      targetSize = RFValue(23);
      eyeSize = RFValue(20);
      stopwatchSize = RFValue(22);
      fireSize = RFValue(21);
      plusSize = RFValue(21);
      rocketSize = RFValue(19);
  }
  return {
    containerStyle,
    iconColor,
    lineStyle,
    iconSize,
    lightingSize,
    targetSize,
    eyeSize,
    stopwatchSize,
    fireSize,
    plusSize,
    rocketSize,
  };
};
