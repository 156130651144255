import Poppins from "../fonts/Poppins-Regular.ttf";
import { Platform } from "react-native";

const FontsCSS = `
@font-face {
  src: url(${Poppins});
  font-family: Poppins;
}

body {
  font: 14px Poppins;
  margin: 0;
}
`;

if (Platform.OS === "web") {
  const style: any = document.createElement("style");
  style.type = "text/css";
  if (style.styleSheet) style.styleSheet.cssText = FontsCSS;
  else style.appendChild(document.createTextNode(FontsCSS));

  document.head.appendChild(style);
}
