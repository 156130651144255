/* ------------- Initial State ------------- */

export interface SettingsState {
  criticalPower: number;
  unitSystem: UNIT_SYSTEM_TYPE | null;
  cp: CpInterface | null;
  permissions: PermissionsInterface | null;
  servicesConnected: ServicesConnectedInterface;
}

/* ------------- Reducers Actions ------------- */

export interface SetCriticalPowerAction {
  type: string;
  criticalPower: number;
  cp: CpInterface;
}

export interface SetPermissionsAction {
  type: string;
  permissions: PermissionsInterface;
}

export interface SetUnitSystemAction {
  type: string;
  unitSystem: UNIT_SYSTEM_TYPE;
}

export interface SetServicesConnectedAction {
  type: string;
  servicesConnected: ServicesConnectedInterface;
}

export interface UpdateCriticalPowerAction {
  type: string;
  criticalPower: number;
}

/* ------------- State Elements ------------- */

export interface CpInterface {
  cp5s: number | null;
  cp10s: number | null;
  cp20s: number | null;
  cp45s: number | null;
  cp1m: number | null;
  cp2m: number | null;
  cp3m: number | null;
  cp5m: number | null;
  cp10m: number | null;
  cp20m: number | null;
  cp30m: number | null;
  cp45m: number | null;
  cp60m: number | null;
}

export enum UNIT_SYSTEM_TYPE {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
}

export interface PermissionsInterface {}

export interface ServicesConnectedInterface {
  strava: {
    authorization_url: null | string;
    connected: null | boolean;
  };
  training_peaks: {
    authorization_url: null | string;
    connected: null | boolean;
  };
}
