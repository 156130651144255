import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { DefaultText } from "../../../..";
import { PaymentFormInterface, UpdateCreditCardPayloadInterface } from "../../../../../redux/types";
import { PaymentForm } from "../PaymentForm";
import { themedStyles } from "./styles";

type PropsType = {
  isUpdateInProgress: boolean;
  isUpdateSuccess: boolean;
  updateErrorMessage: string | null;
  updateSuccessMessage: string | null;
  updateCreditCardInfo: (creditCardInformation: UpdateCreditCardPayloadInterface) => void;
};

export const UpdateCreditCardBlock = ({
  isUpdateInProgress,
  isUpdateSuccess,
  updateErrorMessage,
  updateSuccessMessage,
  updateCreditCardInfo,
}: PropsType) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (isCollapsed) {
    return (
      <View style={themedStyles.container}>
        <TouchableOpacity onPress={handleCollapseClick} style={themedStyles.buttonContainer}>
          <DefaultText>Update Credit Card info</DefaultText>
        </TouchableOpacity>
      </View>
    );
  }
  const onHandleSubmit = (purchaseDetails: PaymentFormInterface) => {
    updateCreditCardInfo({
      payment_info: {
        billing_name: purchaseDetails.billingName,
        credit_card_number: purchaseDetails.creditCardNumber,
        cvv: purchaseDetails.cvv,
        exp_month: purchaseDetails.expMonth,
        exp_year: purchaseDetails.expYear,
      },
    });
  };
  return (
    <View style={themedStyles.container}>
      <TouchableOpacity onPress={handleCollapseClick} style={themedStyles.buttonContainer}>
        <DefaultText>Collapse</DefaultText>
      </TouchableOpacity>
      <PaymentForm
        isPaymentInProgress={isUpdateInProgress}
        isPaymentSuccess={isUpdateSuccess}
        onHandleSubmit={onHandleSubmit}
        paymentErrorMessage={updateErrorMessage}
        paymentSuccessMessage={updateSuccessMessage}
        buttonText="Update"
      />
    </View>
  );
};
