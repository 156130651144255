import { DeviceDataPoint } from "../../redux/types";
import { GetFlagBit, differenceBetweenTwoUInt16Values, WheelTimeFractionOfSecondCSC, SecondsToMinutes, MinutesToHours, MetersToKilometers, WheelTimeoutMilliseconds, CrankTimeFractionOfSecond, CadenceTimeoutMilliseconds } from "./BleParserHelpers";


export class SpeedCadenceSensorParser {
  isCrankDataInitialized: boolean;
  lastCrankRevolutions: number;
  lastCrankEventTime: number;
  lastCrankUpdateTime: number;
  isWheelDataInitialized: boolean;
  lastWheelRevolutions: number;
  lastWheelEventTime: number;
  lastWheelUpdateTime: number;
  lastPacketTime: number;
  wheelCircumference: number;
  constructor(wheelCircumference: number) {
    this.wheelCircumference = wheelCircumference;

    // Initialize cadence variables
    this.isCrankDataInitialized = false;
    this.lastCrankRevolutions = 0;
    this.lastCrankEventTime = 0;
    this.lastCrankUpdateTime = new Date().getTime();

    // Initialize wheel (for speed calculation) variables
    this.isWheelDataInitialized = false;
    this.lastWheelRevolutions = 0;
    this.lastWheelEventTime = 0;
    this.lastWheelUpdateTime = new Date().getTime();

    this.lastPacketTime = new Date().getTime();
  }

  GetDataPoint(dataView: DataView) {
    let offset = 0;
    const flagBits = dataView.getUint8(offset);
    offset += 1;

    const isSpeedDataPresent = GetFlagBit(0, flagBits);
    const isCadenceDataPresent = GetFlagBit(1, flagBits);

    let deviceDataPoint: DeviceDataPoint = {
      cadence: null,
      power: null,
      speed: null,
      powerBalance: null,
      heartRate: null,
    };

    if (isSpeedDataPresent) {
      const wheelRevolutions = dataView.getUint32(offset, true);
      offset += 4;
      const wheelEventTime = dataView.getUint16(offset, true);
      offset += 2;

      if (this.isWheelDataInitialized) {
        const wheelRevolutionsDifference = differenceBetweenTwoUInt16Values(
          wheelRevolutions,
          this.lastWheelRevolutions
        );
        const wheelTimeDifference = differenceBetweenTwoUInt16Values(
          wheelEventTime,
          this.lastWheelEventTime
        );

        // Update the speed if we have a valid duration and atleast one revolution
        if (wheelRevolutionsDifference > 0 && wheelTimeDifference > 0) {
          const wheelDuration = wheelTimeDifference / WheelTimeFractionOfSecondCSC;
          const wheelRpm = (wheelRevolutionsDifference / wheelDuration) * SecondsToMinutes;
          deviceDataPoint.speed =
            (wheelRpm * this.wheelCircumference * MinutesToHours) / MetersToKilometers;
          this.lastWheelUpdateTime = new Date().getTime();
        }

        // Calculate the amount of time since speed has been updated and send update if over timeout threshold
        else {
          let numMillisecondsSinceUpdate = new Date().getTime() - this.lastWheelUpdateTime;

          if (numMillisecondsSinceUpdate > WheelTimeoutMilliseconds) {
            deviceDataPoint.speed = 0;

            this.lastWheelUpdateTime = new Date().getTime();
          }
        }
      }

      this.lastWheelRevolutions = wheelRevolutions;
      this.lastWheelEventTime = wheelEventTime;
      this.isWheelDataInitialized = true;
    }

    if (isCadenceDataPresent) {
      const crankRevolutions = dataView.getUint16(offset, true);
      offset += 2;
      const crankEventTime = dataView.getUint16(offset, true);
      offset += 2;

      if (this.isCrankDataInitialized) {
        const crankRevolutionsDifference = differenceBetweenTwoUInt16Values(
          crankRevolutions,
          this.lastCrankRevolutions
        );
        const crankTimeDifference = differenceBetweenTwoUInt16Values(
          crankEventTime,
          this.lastCrankEventTime
        );

        if (crankRevolutionsDifference > 0 && crankTimeDifference > 0) {
          const crankEventSecondsElapsed = crankTimeDifference / CrankTimeFractionOfSecond;
          const crankRpm = (crankRevolutionsDifference / crankEventSecondsElapsed) * SecondsToMinutes;

          deviceDataPoint.cadence = crankRpm;
          this.lastCrankUpdateTime = new Date().getTime();
        }

        // Calculate the amount of time since cadence has been updated and send update if over timeout threshold
        else {
          let numMillisecondsSinceUpdate = new Date().getTime() - this.lastCrankUpdateTime;

          if (numMillisecondsSinceUpdate > CadenceTimeoutMilliseconds) {
            deviceDataPoint.cadence = 0;

            this.lastCrankUpdateTime = new Date().getTime();
          }
        }
      }

      this.lastCrankRevolutions = crankRevolutions;
      this.lastCrankEventTime = crankEventTime;
      this.isCrankDataInitialized = true;
    }

    var now = new Date().getTime();
    this.lastPacketTime = now;

    //console.log('CSC:', deviceDataPoint);
    return deviceDataPoint;
  }
}
