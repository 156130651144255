import { put } from "redux-saga/effects";
import NotificationsCreators from "../reducers/notificationsReducer";
import { NotificationLocation, TypesOfNotification } from "../types";

export function* logOut() {
  yield put(
    NotificationsCreators.addNotification({
      location: NotificationLocation.UserSaga,
      notificationType: TypesOfNotification.Error,
      description: `Log out and log in again, please`,
    }),
  );
}
