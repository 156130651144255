import React, { useState } from "react";
import { Platform, View } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import CheckBox from "@react-native-community/checkbox";
import { ICON, LOCAL_STORAGE_ITEMS } from "../../../constants";
import { COLORS } from "../../../themes/colors";
import { RFValue } from "../../../utils/resizeHelper";
import { MyIcon } from "../../CommonElements";
import { DefaultText, Button } from "../../index";
import { themedStyles } from "./styles";
import { MAIN_THEME } from "../../../themes";

interface Achievement {
  icon: string;
  color: string;
  title: string;
  subtitle: string;
}

const TITLE = {
  BEST_1: "BEST",
  BEST_2: "2ND BEST",
  BEST_3: "3RD  BEST",
};
const SUBTITLE = {
  ALL_TIME: "ALL TIME",
  LAST_365_DAYS: "LAST 365 DAYS",
  LAST_50_DAYS: "LAST 50 DAYS",
};

const achievementArr: Achievement[] = [
  { color: COLORS.GOLD, icon: ICON.TROPHY, title: TITLE.BEST_1, subtitle: SUBTITLE.ALL_TIME },
  { color: COLORS.GOLD, icon: ICON.MEDAL_2, title: TITLE.BEST_1, subtitle: SUBTITLE.LAST_365_DAYS },
  { color: COLORS.GOLD, icon: ICON.MEDAL, title: TITLE.BEST_1, subtitle: SUBTITLE.LAST_50_DAYS },
  { color: COLORS.SILVER, icon: ICON.TROPHY, title: TITLE.BEST_2, subtitle: SUBTITLE.ALL_TIME },
  {
    color: COLORS.SILVER,
    icon: ICON.MEDAL_2,
    title: TITLE.BEST_2,
    subtitle: SUBTITLE.LAST_365_DAYS,
  },
  { color: COLORS.SILVER, icon: ICON.MEDAL, title: TITLE.BEST_2, subtitle: SUBTITLE.LAST_50_DAYS },
  { color: COLORS.RAW_SIENNA, icon: ICON.TROPHY, title: TITLE.BEST_3, subtitle: SUBTITLE.ALL_TIME },
  {
    color: COLORS.RAW_SIENNA,
    icon: ICON.MEDAL_2,
    title: TITLE.BEST_3,
    subtitle: SUBTITLE.LAST_365_DAYS,
  },
  {
    color: COLORS.RAW_SIENNA,
    icon: ICON.MEDAL,
    title: TITLE.BEST_3,
    subtitle: SUBTITLE.LAST_50_DAYS,
  },
];

type PropsType = {
  closeModal: () => void;
};

export const AchievementInfoModal = ({ closeModal }: PropsType) => {
  const [isNeverShow, setIsNeverShow] = useState(false);
  const onCheckBoxPress = () => {
    setIsNeverShow(!isNeverShow);
  };
  const setCheckInLocalStorage = () => {
    if (Platform.OS === "web") {
      localStorage.setItem(LOCAL_STORAGE_ITEMS.USER_SAW_ACHIEVEMENT_INFO_MODAL, "true");
    } else {
      AsyncStorage.setItem(LOCAL_STORAGE_ITEMS.USER_SAW_ACHIEVEMENT_INFO_MODAL, "true");
    }
  };
  const onOkPress = () => {
    isNeverShow && setCheckInLocalStorage();
    closeModal();
  };
  const TestCheckbox =
    Platform.OS === "web" ? (
      <input type="checkbox" checked={isNeverShow} onChange={onCheckBoxPress} />
    ) : (
      <CheckBox
        value={isNeverShow}
        onValueChange={onCheckBoxPress}
        style={themedStyles.checkbox}
        boxType="square"
        onCheckColor={MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR}
        onTintColor={MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR}
      />
    );

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.modalContainer}>
        <View style={themedStyles.titleContainer}>
          <DefaultText style={themedStyles.title}>REAL-TIME AWARDS</DefaultText>
          <DefaultText>
            If you set a Personal Best power output during one your PowerWatts workouts you'll
            receive an award above your Gauge. There are 9 different awards.
          </DefaultText>
        </View>
        <View style={themedStyles.contentContainer}>
          {achievementArr.map((achievement) => {
            return (
              <View
                key={`${achievement.color}-${achievement.icon}`}
                style={themedStyles.achievementContainer}
              >
                <MyIcon icon={achievement.icon} color={achievement.color} size={RFValue(36)} />
                <View style={themedStyles.achievementTextContainer}>
                  <DefaultText>{achievement.title}</DefaultText>
                  <DefaultText>{achievement.subtitle}</DefaultText>
                </View>
              </View>
            );
          })}
        </View>
        <View style={themedStyles.buttonContainer}>
          <View style={themedStyles.checkboxContainer}>
            {TestCheckbox}
            <DefaultText style={themedStyles.checkboxText}>Do not show me this again</DefaultText>
          </View>
          <Button onPress={onOkPress} style={themedStyles.button}>
            <DefaultText style={themedStyles.buttonText}>OK</DefaultText>
          </Button>
        </View>
      </View>
    </View>
  );
};
