import { BleDeviceBase } from "./BleDeviceBase";


export abstract class SensorBase {
    name: string;
    bleDevice: BleDeviceBase | null;

    constructor(name: string, bleDevice: BleDeviceBase | null) {
        this.name = name;
        this.bleDevice = bleDevice;
    }

    abstract SetupCharacteristics(): Promise<void>;

    disconnect() {
        if (this.bleDevice) {
            this.bleDevice.disconnect();
        }
    }
}
