import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import {
  TimerState,
  TimerTickAction,
  SetWorkoutTimestampsAction,
  SetStepTimestampsAction,
  ResetTimerAction,
  SetPauseTimestampAction,
  SetCoachTrainingStatusAction,
  SetCourseTimestampAction,
  SetSegmentTimestampAction,
  SetUserConnectionTimestampAction,
} from "../types";
import { CoachControlsTypes } from "./coachControlsReducer";

interface ActionTypesInterface {
  TIMER_TICK: "TIMER_TICK";
  RESET_TIMER: "RESET_TIMER";
  START_TIMER: "START_TIMER";
  PAUSE_TIMER: "PAUSE_TIMER";
  SET_WORKOUT_TIMESTAMPS: "SET_WORKOUT_TIMESTAMPS";
  SET_STEP_TIMESTAMPS: "SET_STEP_TIMESTAMPS";
  CHANGE_WORKOUT_END_TIMESTAMP: "CHANGE_WORKOUT_END_TIMESTAMP";
  ADD_TIMING_ACTION: "ADD_TIMING_ACTION";
  SET_PAUSE_TIMESTAMP: "SET_PAUSE_TIMESTAMP";
  SET_COURSE_TIMESTAMP: "SET_COURSE_TIMESTAMP";
  SET_SEGMENT_TIMESTAMP: "SET_SEGMENT_TIMESTAMP";
  SET_USER_CONNECTION_TIMESTAMP: "SET_USER_CONNECTION_TIMESTAMP";
}

interface ActionCreatorsInterface extends ActionCreators {
  timerTick: (dateTime: number) => AnyAction;
  resetTimer: () => AnyAction;
  startTimer: () => AnyAction;
  pauseTimer: () => AnyAction;
  setWorkoutTimestamps: (workoutStartTimestamp: number) => AnyAction;
  setStepTimestamp: (stepStartTimestamp: number) => AnyAction;
  setPauseTimestamp: (pauseTimestamp: number) => AnyAction;
  setCourseTimestamp: (courseTimestamp: number) => AnyAction;
  setSegmentTimestamp: (segmentTimestamp: number) => AnyAction;
  setUserConnectionTimestamp: (userConnectionTimestamp: number) => AnyAction;
}

type Handler<A> = (state: TimerState, action: A) => TimerState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  timerTick: ["dateTime"],
  resetTimer: null,
  startTimer: null,
  pauseTimer: null,
  setWorkoutTimestamps: ["workoutStartTimestamp"],
  setStepTimestamps: ["stepStartTimestamp"],
  setPauseTimestamp: ["pauseTimestamp"],
  setCourseTimestamp: ["courseTimestamp"],
  setSegmentTimestamp: ["segmentTimestamp"],
  setUserConnectionTimestamp: ["userConnectionTimestamp"],
});

export const TimerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: TimerState = {
  dateTime: 0,
  stepStartTimestamp: 0,
  workoutStartTimestamp: 0,
  userConnectionTimestamp: 0,
  pauseTimestamp: 0,
  courseStartTimestamp: 0,
  segmentStartTimestamp: 0,
};

/* ------------- Reducers ------------- */

const timerTick: Handler<TimerTickAction> = (state, { dateTime }) => {
  return {
    ...state,
    dateTime: dateTime,
  };
};

const resetTimer: Handler<ResetTimerAction> = () => {
  return {
    ...INITIAL_STATE,
  };
};

const setWorkoutTimestamps: Handler<SetWorkoutTimestampsAction> = (
  state,
  { workoutStartTimestamp },
) => {
  return {
    ...state,
    workoutStartTimestamp: workoutStartTimestamp,
    userConnectionTimestamp: workoutStartTimestamp,
  };
};

const setStepTimestamps: Handler<SetStepTimestampsAction> = (state, { stepStartTimestamp }) => {
  return {
    ...state,
    stepStartTimestamp: stepStartTimestamp,
  };
};

const setPauseTimestamp: Handler<SetPauseTimestampAction> = (state, { pauseTimestamp }) => {
  return {
    ...state,
    pauseTimestamp: pauseTimestamp,
  };
};

const setCourseTimestamp: Handler<SetCourseTimestampAction> = (state, { courseTimestamp }) => {
  return {
    ...state,
    courseStartTimestamp: courseTimestamp,
  };
};

const setSegmentTimestamp: Handler<SetSegmentTimestampAction> = (state, { segmentTimestamp }) => {
  return {
    ...state,
    segmentStartTimestamp: segmentTimestamp,
  };
};

const setUserConnectionTimestamp: Handler<SetUserConnectionTimestampAction> = (
  state,
  { userConnectionTimestamp },
) => {
  return {
    ...state,
    userConnectionTimestamp: userConnectionTimestamp,
  };
};

const setCoachTrainingStatus: Handler<SetCoachTrainingStatusAction> = (
  state,
  { coachTrainingStatus },
) => {
  return {
    ...state,
    pauseTimestamp: coachTrainingStatus.pauseTimestamp,
    stepStartTimestamp: coachTrainingStatus.stepStartTimestamp,
    workoutStartTimestamp: coachTrainingStatus.workoutStartTimestamp,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.TIMER_TICK]: timerTick,
  [Types.SET_WORKOUT_TIMESTAMPS]: setWorkoutTimestamps,
  [Types.SET_STEP_TIMESTAMPS]: setStepTimestamps,
  [Types.RESET_TIMER]: resetTimer,
  [Types.SET_PAUSE_TIMESTAMP]: setPauseTimestamp,
  [Types.SET_COURSE_TIMESTAMP]: setCourseTimestamp,
  [Types.SET_SEGMENT_TIMESTAMP]: setSegmentTimestamp,
  [Types.SET_USER_CONNECTION_TIMESTAMP]: setUserConnectionTimestamp,
  [CoachControlsTypes.SET_COACH_TRAINING_STATUS]: setCoachTrainingStatus,
};

export const timerReducer = createReducer(INITIAL_STATE, HANDLERS);
