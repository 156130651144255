import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingVertical: RFValue(24),
    width: "100%",
    height: "100%",
    paddingHorizontal: RFValue(24),
  },

  title: {
    fontSize: RFValue(20),
    lineHeight: RFValue(30),
    marginBottom: RFValue(16),
  },
  trainWithCoach: {
    flex: 1,
  },
  remark: {
    fontSize: RFValue(14),
    lineHeight: RFValue(24),
    fontStyle: "italic",
    color: MAIN_THEME.WELCOME_SCREEN.REMARK_TEXT_COLOR,
    marginBottom: RFValue(8),
  },
  scrollView: { flex: 1 },
  searchContainer: {
    flexDirection: "row",
    marginBottom: RFValue(16),
  },
  searchInput: {
    flex: 1,
    paddingVertical: RFValue(8),
    paddingHorizontal: RFValue(12),
    color: MAIN_THEME.DEFAULT?.TEXT_COLOR,
    fontSize: RFValue(18),
    lineHeight: RFValue(29),
    borderWidth: RFValue(1),
    borderColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
    borderRadius: RFValue(4),
    backgroundColor: MAIN_THEME.WELCOME_SCREEN.TRAINING_ITEM_BACKGROUND_COLOR,
  },
  pikerLine: { marginLeft: RFValue(24) },
});
