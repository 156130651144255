import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  userScoreContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: RFValue(8),
    paddingHorizontal: RFValue(8),
    borderBottomWidth: RFValue(1),
  },
  currentUserContainer: {
    backgroundColor: MAIN_THEME.HOME_SCREEN.CURRENT_STEP_BACKGROUND_COLOR,
  },
  nameContainer: {
    flexDirection: "row",
  },
  placeNumberText: {
    marginRight: RFValue(15),
    color: MAIN_THEME.DEFAULT?.PLACEHOLDER_COLOR,
  },
  listText: { fontSize: RFValue(17), lineHeight: RFValue(24) },
  scoreLineContainer: { flexDirection: "row" },
});
