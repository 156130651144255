import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import { SessionInterface, SessionsState, SetSessionsListAction } from "../types";

interface ActionTypesInterface {
  SET_SESSIONS_LIST: "SET_SESSIONS_LIST";
}

interface ActionCreatorsInterface extends ActionCreators {
  setSessionsList: (sessions: SessionInterface[]) => AnyAction;
}

type Handler<A> = (state: SessionsState, action: A) => SessionsState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  setSessionsList: ["sessions"],
});

export const SessionsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: SessionsState = {
  sessions: null,
};

/* ------------- Reducers ------------- */

const setSessionsList: Handler<SetSessionsListAction> = (state, { sessions }) => {
  return {
    ...state,
    sessions: sessions,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_SESSIONS_LIST]: setSessionsList,
};

export const sessionsReducer = createReducer(INITIAL_STATE, HANDLERS);
