import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    paddingVertical: RFValue(100),
  },
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
    padding: RFValue(24),
    borderRadius: RFValue(8),
  },
  title: {
    fontSize: RFValue(32),
    lineHeight: RFValue(38),
    color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR,
  },
  finalMetricsContainer: {
    flex: 1,
    borderRadius: RFValue(8),
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    marginVertical: RFValue(30),
    paddingVertical: RFValue(25),
    paddingHorizontal: RFValue(15),
  },
  metricLineContainer: {
    flexDirection: "row",
    paddingBottom: RFValue(10),
  },
  metricText: {
    fontSize: RFValue(24),
    lineHeight: RFValue(30),
    paddingRight: RFValue(10),
  },
  metricValue: { color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: RFValue(20),
    width: "100%",
  },
  buttonTouchable: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "20%",
    padding: RFValue(20),
    borderRadius: RFValue(8),
    backgroundColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
  },
  saveButton: { backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR },
});
