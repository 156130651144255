import "react-notifications/lib/notifications.css";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StateInterface } from "../../redux/reducers";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { TypesOfNotification } from "../../redux/types";

export const NotificationComponent = ({
  notificationList,
  currentNotificationIndex,
}: CombinedProps) => {
  useEffect(() => {
    if (notificationList.length > 0 && currentNotificationIndex !== null) {
      switch (notificationList[currentNotificationIndex].notificationType) {
        case TypesOfNotification.Info:
          NotificationManager.info(notificationList[currentNotificationIndex].description);
          break;
        case TypesOfNotification.Success:
          NotificationManager.success(notificationList[currentNotificationIndex].description);
          break;
        case TypesOfNotification.Warning:
          NotificationManager.warning(notificationList[currentNotificationIndex].description);
          break;
        case TypesOfNotification.Error:
          NotificationManager.error(notificationList[currentNotificationIndex].description);
          break;
      }
    }
    // eslint-disable-next-line
  }, [notificationList.length]);

  return <NotificationContainer />;
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  notificationList: state.notification.notificationsList,
  currentNotificationIndex: state.notification.currentNotificationIndex,
});
const mapDispatchToProps = (_dispatch: Dispatch) => ({});

export const Notification = connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
