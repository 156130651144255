import React from "react";
import { useNavigation } from "@react-navigation/native";
import SubscriptionCreators from "../../../redux/reducers/subscriptionReducer";
import { View } from "react-native";
import { Dispatch } from "redux";
import { DefaultText } from "../../index";
import { themedStyles } from "./styles";
import { StateInterface } from "../../../redux/reducers";
import { StackParams } from "../../../navigation";
import { StackNavigationProp } from "@react-navigation/stack";
import { connect } from "react-redux";
import { DefaultModal } from "../DefaultModal";

type PropsType = {};

type NavigationProps = StackNavigationProp<StackParams, "Welcome">;

export const NotBookedModalComponent = ({ toggleNotBookedModal, externalId }: CombinedProps) => {
  const { replace } = useNavigation<NavigationProps>();

  const onEnterClassPress = () => {
    replace("LiveSession", { externalId: externalId });
    toggleNotBookedModal();
  };

  return (
    <DefaultModal
      title="You are not registered for this class!"
      cancelButtonText="Go Back to Main Screen"
      confirmButtonText="Enter Class"
      onCancelButtonPress={toggleNotBookedModal}
      onConfirmButtonPress={onEnterClassPress}
    >
      <View style={themedStyles.contentContainer}>
        <DefaultText>If you click Enter Class, you will be able to join the class and</DefaultText>
        <DefaultText>a LIVE class credit will be deducted from you account.</DefaultText>
      </View>
    </DefaultModal>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PropsType;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleNotBookedModal: () => dispatch(SubscriptionCreators.toggleNotBookedModal(false)),
});

const mapStateToProps = (state: StateInterface) => ({
  userName: state.user.cpxProfile?.name || state.user.profile?.full_name,
  externalId: state.subscription.modalExternalId,
});
export const NotBookedModal = connect(mapStateToProps, mapDispatchToProps)(NotBookedModalComponent);
