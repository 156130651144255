import {
  DraftingFactor,
  RollingResistanceCoeff,
  WheelDiameter,
  WindResistanceCoefficient,
} from "./BleMeterCommon";
import {
  AntPageControlTrackResistance,
  AntPageRequestData,
  AntPageUserConfiguration,
  AntPageWindResistance,
} from "./BleParserHelpers";

export function CreateAntPacket(message: Uint8Array) {
  const numWrapperBytes = 5;
  const fullPacket = new Uint8Array(message.length + numWrapperBytes);
  let fullPacketIndex = 0;

  fullPacket[fullPacketIndex++] = 0xa4; // Sync
  fullPacket[fullPacketIndex++] = message.length + 1; // length
  fullPacket[fullPacketIndex++] = 0x4f; // Acknowledge message type
  fullPacket[fullPacketIndex++] = 0x05; // Channel

  // Add message content
  for (let dataIndex = 0; dataIndex < message.length; dataIndex++) {
    fullPacket[fullPacketIndex++] = message[dataIndex];
  }

  // Checksum
  // The Checksum (in the ANT+ spec) is the XOR of all previous bytes.
  // Note that this implementation works with TACX, but TACX also seems to accept any value for the checksum byte.
  // If issues arise in the future it may be worth looking at the checksum.
  let checksum = 0;
  for (let checksumIndex = 0; checksumIndex < fullPacket.length - 1; checksumIndex++) {
    checksum = checksum ^ fullPacket[checksumIndex];
  }
  fullPacket[fullPacketIndex] = checksum;

  return fullPacket;
}

export function CreateTacxGradePacket(grade: number) {
  const coeffRollResistance = RollingResistanceCoeff * 20000;

  // Keep the gradient percentage within +/- 200%
  if (grade < -200) grade = -200;
  else if (grade > 200) grade = 200;

  // Convert to a 16 bit value where 0 is -200% grade
  let shortGradient = grade * 100 + 20000;

  let data = new Uint8Array(8);
  data[0] = AntPageControlTrackResistance;
  data[1] = 0xff;
  data[2] = 0xff;
  data[3] = 0xff;
  data[4] = 0xff;
  data[5] = shortGradient;
  data[6] = shortGradient >> 8;
  data[7] = coeffRollResistance;

  const fullPacket = CreateAntPacket(data);
  return fullPacket;
}

export function CreateTacxWindResistancePacket(windSpeed: number) {
  let data = new Uint8Array(8);
  data[0] = AntPageWindResistance;
  data[1] = 0xff; // reserved
  data[2] = 0xff; // reserved
  data[3] = 0xff; // reserved
  data[4] = 0xff; // reserved
  data[5] = Math.round(WindResistanceCoefficient * 100);
  data[6] = windSpeed + 127;
  data[7] = DraftingFactor * 100;

  const fullPacket = CreateAntPacket(data);
  return fullPacket;
}

export function CreateTacxRequestDataPagePacket(page: number) {
  let data = new Uint8Array(8);
  data[0] = AntPageRequestData;
  data[1] = 0xff;
  data[2] = 0xff;
  data[3] = 0xff;
  data[4] = 0xff;
  data[5] = 0x80; // requested transmission response
  data[6] = page;
  data[7] = 0x01; // command type

  const fullPacket = CreateAntPacket(data);
  return fullPacket;
}

export function CreateTacxUserConfigurationPacket(userWeight: number) {
  const userWeight10Grams = userWeight * 100; // convert from kg to 10 gram units
  const bikeWeightKg = 0; // set bike weight to 0kg
  const bikeWeight50Grams = bikeWeightKg * 20; // convert from kg to 50 gram units
  const wheelDiameterCm = Math.round(Math.round(WheelDiameter) / 10);
  const wheelDiameterLeftoverMm = Math.round(WheelDiameter) - wheelDiameterCm * 10; // calculate wheel diameter remaining mm for the offset parameter

  let data = new Uint8Array(8);
  data[0] = AntPageUserConfiguration;
  data[1] = userWeight10Grams & 0xff;
  data[2] = userWeight10Grams >> 8;
  data[3] = 255; // reserved
  data[4] = wheelDiameterLeftoverMm + ((bikeWeight50Grams & 0xf) << 4); // wheel diameter offset, LSN of bike weight
  data[5] = bikeWeight50Grams >> 4;
  data[6] = wheelDiameterCm;
  data[7] = 0x00; // gear ratio, invalid

  const fullPacket = CreateAntPacket(data);
  return fullPacket;
}
