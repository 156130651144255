import React from "react";
import { View, Image, ActivityIndicator } from "react-native";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { StackNavigationProp } from "@react-navigation/stack";

import { IMG } from "../../assets";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { SideBarItem } from "./SideBarItem";
import { ICON, SUPPORT_EMAIL } from "../../constants";
import { Event } from "../../services/googleAnalyticsTracking";
import { DefaultText } from "../CommonElements";
import { openLink } from "../../utils/helpers";
import { WelcomeTabInterface } from "../../screens";
import { SideBarTabItem } from "./SideBarTabItem";
import { StackParams } from "../../navigation/index.web";
import { useNavigation } from "@react-navigation/core";
import {
  NotificationInterface,
  NotificationLocation,
  TypesOfNotification,
} from "../../redux/types";
import NotificationCreators from "../../redux/reducers/notificationsReducer";
import { MAIN_THEME } from "../../themes";

interface PropsType {
  tabsArr: WelcomeTabInterface[];
  tabIndex: number;
  onItemPress: (index: number) => void;
  isGBUser: boolean;
  subTabIndex: number | null;
  onSubItemPress: (index: number | null) => void;
}
type NavigationProps = StackNavigationProp<StackParams, "Welcome">;

const WelcomeSideBarComponent = ({
  tabsArr,
  userProfile,
  onItemPress,
  tabIndex,
  isGBUser,
  subTabIndex,
  isFullProfileReceived,
  onSubItemPress,
  addNotification,
}: CombinedProps) => {
  const { logout } = useAuth0();
  const { setParams } = useNavigation<NavigationProps>();
  const onLogoutPress = () => {
    logout({ returnTo: window.location.href });
    Event("page_view", { page_title: "Logout" });
  };
  const onProfilePressHandler = () => {
    setParams({ profile: true });
  };

  const statsUrl = "https://my.powerwatts.com/";

  const onStatsPress = () => {
    openLink(statsUrl);
  };

  const onSupportPress = () => {
    navigator.clipboard.writeText(SUPPORT_EMAIL);
    addNotification({
      location: NotificationLocation.Components,
      notificationType: TypesOfNotification.Info,
      description: "support@powerwatts.com has been copied to the clipboard",
    });
  };

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.logoContainer}>
        <Image source={IMG.logo} style={themedStyles.logoImg} />
      </View>
      {userProfile?.cpxNickname ? (
        <DefaultText
          style={themedStyles.userNickText}
        >{`Welcome ${userProfile.cpxNickname}`}</DefaultText>
      ) : (
        <DefaultText style={themedStyles.userNickText}>{`Loading CPX profile...`}</DefaultText>
      )}

      <SideBarItem
        icon={ICON.PROFILE}
        text="Your Profile"
        onPress={onProfilePressHandler}
        style={{ container: themedStyles.portfolioItem }}
      />
      <View style={themedStyles.tabsContainer}>
        {isFullProfileReceived ? (
          tabsArr.map((tab, index) => (
            <SideBarTabItem
              key={`${tab?.title}-${index}`}
              tab={tab}
              tabIndex={tabIndex}
              isGBUser={isGBUser}
              subTabIndex={subTabIndex}
              itemIndex={index}
              onItemPress={onItemPress}
              onSubItemPress={onSubItemPress}
            />
          ))
        ) : (
          <ActivityIndicator color={MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR} size="large" />
        )}
      </View>
      <View style={themedStyles.bottomContainer}>
        <SideBarItem
          icon={ICON.STATS_DOTS}
          iconColor="orange"
          text="View Stats on My PowerWatts"
          onPress={onStatsPress}
        />
        <SideBarItem icon={ICON.EMAIL} iconColor="orange" text="Support" onPress={onSupportPress} />
        <SideBarItem
          icon={ICON.LOGOUT}
          iconColor="orange"
          text="Not you? Log Out"
          onPress={onLogoutPress}
        />
      </View>
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PropsType;

const mapStateToProps = (state: StateInterface) => ({
  userProfile: state.user.cpxProfile,
  isFullProfileReceived: state.user.isFullProfileReceived,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationCreators.addNotification(notification)),
});
export const WelcomeSideBar = connect(mapStateToProps, mapDispatchToProps)(WelcomeSideBarComponent);
