const isPlainObject = (obj: unknown) =>
  obj !== undefined && obj !== null && Object.prototype.toString.call(obj) === "[object Object]";

const toPascalCase = (string: string) => `${string[0].toUpperCase()}${string.slice(1)}`;
const toCamelCase = (string: string) => `${string[0].toLowerCase()}${string.slice(1)}`;

const addPascalAndCamelKeys = <T extends object>(obj: T) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (isPlainObject(value)) {
      addPascalAndCamelKeys(value);
    }
    if (Array.isArray(value)) {
      value.forEach(addPascalAndCamelKeys);
    }
    obj[toPascalCase(key) as keyof T] = value;
    obj[toCamelCase(key) as keyof T] = value;
  });
};

const pascalAndCamelKeys = <T extends object>(obj: T) => {
  addPascalAndCamelKeys(obj);
  return obj;
}

export { pascalAndCamelKeys };
