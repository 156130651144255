import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";

export const themedStyles = StyleSheet.create({
  center: {
    flex: 1,
    minHeight: "100vh",
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
  },
});
