import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { BEST_EFFORTS_INTERVALS } from "../../../constants";
import { RecordObjectType, RecordType } from "../../../redux/types";
import { Achievement } from "../Achievement";
import { themedStyles } from "./styles";

type Props = {
  newRecords: RecordType;
};
interface IRecords {
  [key: string]: RecordObjectType;
}
const getTopRecords = (newRecords: RecordType) => {
  const mainRecordArr: RecordType = [];
  for (let i = 0; i < BEST_EFFORTS_INTERVALS.length; i++) {
    const recordInInterval = newRecords
      .filter((record) => record.intervalDuration === BEST_EFFORTS_INTERVALS[i])
      .sort((a, b) => a.timeFrame - b.timeFrame || a.rank - b.rank)[0];
    recordInInterval && mainRecordArr.push(recordInInterval);
  }
  return mainRecordArr;
};
export function NewRecordsBlock({ newRecords }: Props) {
  const [recordsObj, setRecordsObj] = useState<IRecords>({});
  useEffect(() => {
    const topRecords = getTopRecords(newRecords);
    let bucketRecordsObj = { ...recordsObj };
    topRecords.forEach((record) => {
      const recordObjKey = `${record.intervalDuration}`;

      if (bucketRecordsObj[recordObjKey]) {
        const isNewTimeFrameBetter = record.timeFrame < bucketRecordsObj[recordObjKey].timeFrame;
        const isNewTimeFrameSame = record.timeFrame === bucketRecordsObj[recordObjKey].timeFrame;
        const isNewPowerBetter = record.power > bucketRecordsObj[recordObjKey].power;
        const isNewRankBetter = record.rank < bucketRecordsObj[recordObjKey].rank;

        if (
          isNewTimeFrameBetter ||
          (isNewPowerBetter && isNewTimeFrameSame) ||
          (isNewRankBetter && isNewTimeFrameSame)
        ) {
          bucketRecordsObj = { ...bucketRecordsObj, [recordObjKey]: record };
        }
      } else {
        bucketRecordsObj = { ...bucketRecordsObj, [recordObjKey]: record };
      }
    });
    setRecordsObj(bucketRecordsObj);
    // eslint-disable-next-line
  }, [newRecords]);

  const objKeyArr = Object.keys(recordsObj);
  return (
    <View style={themedStyles.container}>
      {objKeyArr.map((objKey) => {
        return (
          <Achievement
            key={`record-key-${objKey}`}
            record={recordsObj[objKey]}
            achievementCount={objKeyArr.length}
          />
        );
      })}
    </View>
  );
}
