import { StateInterface } from "./reducers";
import { TRAINING_STATUS_TYPES } from "./types";

export const getTimeFromWorkoutStart = (state: StateInterface) => {
  const dateTime = state.timer.dateTime;
  const workoutStartTimestamp = state.timer.workoutStartTimestamp;
  const timestampFromStart = dateTime - workoutStartTimestamp;
  return timestampFromStart >= 0 ? timestampFromStart / 1000 : 0;
};

export const getTimeFromStepStart = (state: StateInterface) => {
  const dateTime = state.timer.dateTime;
  const stepStartTimestamp = state.timer.stepStartTimestamp;
  const timestampFromStepStart = dateTime - stepStartTimestamp;
  return timestampFromStepStart >= 0 ? timestampFromStepStart / 1000 : 0;
};

export const getTimeFromSegmentStart = (state: StateInterface) => {
  const dateTime = state.timer.dateTime;
  const segmentStartTimestamp = state.timer.segmentStartTimestamp;
  const timestampFromSegmentStart = dateTime - segmentStartTimestamp;
  return timestampFromSegmentStart >= 0 ? timestampFromSegmentStart / 1000 : 0;
};

export const getTimeFromUserConnection = (state: StateInterface) => {
  const dateTime = state.timer.dateTime;
  const userConnectionTimeStamp = state.timer.userConnectionTimestamp;
  const timestampFromUserConnection = dateTime - userConnectionTimeStamp;
  return timestampFromUserConnection >= 0 ? timestampFromUserConnection / 1000 : 0;
};

export const getMyCp20m = (state: StateInterface) => {
  const activeMembers = state.coachControls.activeMembers;
  const myCpxId = state.user.profile?.cpxUserId;
  const myCurrentCp20m = activeMembers.find((member) => member.userId === myCpxId)?.cp;

  return myCurrentCp20m;
};

export const getCurrentWorkoutTime = (state: StateInterface) => {
  const workoutSteps = state.activeTraining.plan?.steps;
  const currentStepIndex = state.activeTraining.currentSteps.currentStepIndex || 0;
  const takenStepsTime =
    workoutSteps?.slice(0, currentStepIndex).reduce((durationSum, step) => {
      return durationSum + step.durationInSeconds;
    }, 0) || 0;
  const trainingStatus = state.activeTraining.status;
  return trainingStatus === TRAINING_STATUS_TYPES.STARTED
    ? takenStepsTime + getTimeFromStepStart(state)
    : 0;
};
