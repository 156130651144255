import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100vh",
  },
  backgroundImg: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loginContainer: { justifyContent: "center", alignItems: "center" },
  logoContainer: { justifyContent: "center", alignItems: "center" },
  button: {
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    marginBottom: 20,
    borderRadius: 8,
  },
  buttonContainer: { width: 150 },
  loginButton: { backgroundColor: MAIN_THEME.LOGIN_SCREEN.LOGIN_BUTTON_COLOR },
  signUpButton: {
    backgroundColor: MAIN_THEME.LOGIN_SCREEN.SIGN_UP_BUTTON_COLOR,
  },
});
