import React from "react";
import { View, Image, KeyboardAvoidingView, Platform } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import UserCreators from "../../../redux/reducers/userReducer";
import SubscriptionCreators from "../../../redux/reducers/subscriptionReducer";
import {
  ProductInterface,
  PurchaseDetailsInterface,
  UpdateCreditCardPayloadInterface,
  UpdatedUserProfileInterface,
} from "../../../redux/types";
import { StateInterface } from "../../../redux/reducers";
import { themedStyles } from "./styles";
import { DefaultText, IconButton, ServiceButton, ServiceButtonType } from "../../index";
import UserProfile from "./UserProfileForm";
import { AccountTab } from "./AccountTab";
import { MAIN_THEME } from "../../../themes";
import { ICON } from "../../../constants";
import { TabsSelectComponent } from "../../TabsComponent/TabsSelectComponent";
import { StackParams } from "../../../navigation/index.web";
import { BottomGradient } from "../BottomGradient";

const isWeb = Platform.OS === "web";
type NavigationProps = StackNavigationProp<StackParams, "Welcome">;
type RouteProps = RouteProp<StackParams, "Welcome">;

function EditProfileModalComponent({
  user,
  cp20m,
  buyProduct,
  updateUserProfile,
  hideUpdateProfileModal,
  setSelectedProduct,
  updateCreditCardInfo,
  subscription,
  updateStatus,
  servicesConnected,
  isFullProfileReceived,
}: CombinedProps) {
  const { setParams } = useNavigation<NavigationProps>();
  const { params } = useRoute<RouteProps>();
  const tabIndex = params?.tabIndex ? +params.tabIndex : 0;
  const onCloseModal = () => {
    if (isWeb) {
      document.body.style.overflow = "unset";
    }
    setParams({ profile: undefined, productId: undefined, tabIndex: undefined });
    hideUpdateProfileModal();
  };

  const setActiveTabIndex = (index: number) => {
    setParams({ tabIndex: index });
  };

  const tabsComponentArr = [
    {
      title: "User Profile",
      component: (
        <UserProfile
          cp20m={cp20m}
          hideUpdateProfileModal={onCloseModal}
          updateStatus={updateStatus}
          updateUserProfile={updateUserProfile}
          user={user}
        />
      ),
    },
    {
      title: "Account",
      component: (
        <AccountTab
          subscription={subscription}
          buyProduct={buyProduct}
          setSelectedProduct={setSelectedProduct}
          updateCreditCardInfo={updateCreditCardInfo}
        />
      ),
    },
  ];

  const renderMessage = () => {
    const isStravaConnected = servicesConnected.strava.connected;
    const isTrainingPeaksConnected = servicesConnected.training_peaks.connected;
    if (isStravaConnected && isTrainingPeaksConnected) {
      return null;
    }
    let text = "";
    switch (true) {
      case !isStravaConnected && !isTrainingPeaksConnected:
        text =
          "Connect With Training Peaks and Strava buttons - All Existing PW riders must RELINK to Training Peaks and Strava";
        break;
      case !isStravaConnected:
        text = "Connect With Strava button - All Existing PW riders must RELINK to Strava";
        break;
      case !isTrainingPeaksConnected:
        text =
          "Connect With Training Peaks button - All Existing PW riders must RELINK to Training Peaks";
        break;
    }
    return <DefaultText style={{ textAlign: "center" }}>{text}</DefaultText>;
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={themedStyles.container}
    >
      <View style={themedStyles.modalContainer}>
        <IconButton
          oneButton={{
            icon: ICON.CROSS,
            onPress: onCloseModal,
            color: MAIN_THEME.HEADER.ALTERNATIVE_ICON_COLOR,
            size: 24,
          }}
          style={themedStyles.closeIcon}
        />
        <View style={themedStyles.userInfoContainer}>
          <Image source={{ uri: user.profile?.picture }} style={themedStyles.userPortfolioImg} />
          <DefaultText style={themedStyles.name}>
            {user.cpxProfile?.name || user.profile?.full_name}
          </DefaultText>
        </View>
        {renderMessage()}
        <View style={themedStyles.buttonPanel}>
          <ServiceButton type={ServiceButtonType.TrainingPeaks} />
          <ServiceButton type={ServiceButtonType.Strava} />
        </View>
        <TabsSelectComponent
          tabsComponentArr={tabsComponentArr}
          style={{ container: themedStyles.tabsContainer }}
          tabIndex={tabIndex}
          setTabIndex={setActiveTabIndex}
          isLoading={!isFullProfileReceived}
        />
        <BottomGradient />
      </View>
    </KeyboardAvoidingView>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  user: state.user,
  isFullProfileReceived: state.user.isFullProfileReceived,
  cp20m: state.settings.criticalPower,
  updateStatus: state.user.updateStatus,
  subscription: state.subscription,
  servicesConnected: state.settings.servicesConnected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUserProfile: (updatedUserProfile: UpdatedUserProfileInterface) =>
    dispatch(UserCreators.updateUserProfile(updatedUserProfile)),
  hideUpdateProfileModal: () => dispatch(UserCreators.toggleUpdateProfileModal(false)),
  setSelectedProduct: (selectedProduct: ProductInterface | null) =>
    dispatch(SubscriptionCreators.setSelectedProduct(selectedProduct)),
  buyProduct: (purchaseDetails: PurchaseDetailsInterface) =>
    dispatch(SubscriptionCreators.buyProduct(purchaseDetails)),
  updateCreditCardInfo: (creditCardInformation: UpdateCreditCardPayloadInterface) =>
    dispatch(SubscriptionCreators.updateCreditCardInfo(creditCardInformation)),
});

export const EditProfileModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileModalComponent);
