import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import {
  DetailsInfoInterface,
  TrainingDetailsState,
  SetTrainingInfoAction,
  TRAINING_TYPES,
} from "../types";

interface ActionTypesInterface {
  RESET_TRAINING_DETAILS: "RESET_TRAINING_DETAILS";
  INITIALIZE_VIRTUAL_TRAINING: "INITIALIZE_VIRTUAL_TRAINING";
  INITIALIZE_GROUP_TRAINING: "INITIALIZE_GROUP_TRAINING";
  GET_TRAINING_DETAILS: "GET_TRAINING_DETAILS";
  SET_TRAINING_DETAILS: "SET_TRAINING_DETAILS";
}

interface ActionCreatorsInterface extends ActionCreators {
  resetTrainingDetails: () => AnyAction;
  initializeVirtualTraining: (trainingId: string, lang?: string) => AnyAction;
  initializeGroupTraining: (trainingId: string, isItCoach?: boolean) => AnyAction;
  getTrainingDetails: () => AnyAction;
  setTrainingDetails: (
    trainingType: TRAINING_TYPES,
    detailsInfo: DetailsInfoInterface,
  ) => AnyAction;
}

type Handler<A> = (state: TrainingDetailsState, action: A) => TrainingDetailsState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  resetTrainingDetails: null,
  initializeVirtualTraining: ["trainingId", "lang"],
  initializeGroupTraining: ["trainingId", "isItCoach"],
  getTrainingDetails: null,
  setTrainingDetails: ["trainingType", "detailsInfo"],
});

export const TrainingDetailsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: TrainingDetailsState = {
  trainingType: null,
  detailsInfo: null,
};

/* ------------- Reducers ------------- */
const resetTrainingDetails = () => {
  return { ...INITIAL_STATE };
};

const setTrainingDetails: Handler<SetTrainingInfoAction> = (
  state,
  { trainingType, detailsInfo },
) => {
  return {
    ...state,
    trainingType: trainingType,
    detailsInfo: detailsInfo,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_TRAINING_DETAILS]: setTrainingDetails,
  [Types.RESET_TRAINING_DETAILS]: resetTrainingDetails,
};

export const trainingDetailsReducer = createReducer(INITIAL_STATE, HANDLERS);
