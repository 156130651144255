import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import {
  WorkoutInterface,
  WorkoutsState,
  SetWorkoutsListAction,
  SetIntroductionsListAction,
  SetTrainingPlansAction,
  WorkoutNotesInterface,
  SetWorkoutNotesAction,
  ClearNotesAction,
  TrainingPlansInterface,
  SetTrainingPeeksWorkoutsAction,
  WORKOUT_CATEGORY,
  TrainingPeaksWodInterface,
} from "../types";

interface ActionTypesInterface {
  SET_WORKOUTS_LIST: "SET_WORKOUTS_LIST";
  SET_INTRODUCTIONS_LIST: "SET_INTRODUCTIONS_LIST";
  SET_TRAINING_PLANS: "SET_TRAINING_PLANS";
  GET_WORKOUT_NOTES: "GET_WORKOUT_NOTES";
  SET_WORKOUT_NOTES: "SET_WORKOUT_NOTES";
  SET_TRAINING_PEEKS_WORKOUTS: "SET_TRAINING_PEEKS_WORKOUTS";
  CLEAR_NOTES: "CLEAR_NOTES";
}

interface ActionCreatorsInterface extends ActionCreators {
  setWorkoutsList: (workouts: WorkoutInterface[]) => AnyAction;
  setIntroductionsList: (introductions: WorkoutInterface[]) => AnyAction;
  setTrainingPlans: (trainingPlans: TrainingPlansInterface[]) => AnyAction;
  getWorkoutNotes: (notesId: string) => AnyAction;
  setWorkoutNotes: (notes: WorkoutNotesInterface) => AnyAction;
  setTrainingPeeksWorkouts: (workouts: TrainingPeaksWodInterface[]) => AnyAction;
  clearNotes: () => AnyAction;
}

type Handler<A> = (state: WorkoutsState, action: A) => WorkoutsState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  setWorkoutsList: ["workouts"],
  setIntroductionsList: ["introductions"],
  getWorkoutNotes: ["notesId"],
  setWorkoutNotes: ["notes"],
  setTrainingPlans: ["trainingPlans"],
  setTrainingPeeksWorkouts: ["workouts"],
  clearNotes: null,
});

export const WorkoutsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: WorkoutsState = {
  workouts: null,
  introductions: null,
  trainingPlans: null,
  notes: null,
  trainingPeeksWorkouts: null,
};

/* ------------- Reducers ------------- */

const setWorkoutsList: Handler<SetWorkoutsListAction> = (state, { workouts }) => {
  return {
    ...state,
    workouts: workouts,
  };
};

const setIntroductionsList: Handler<SetIntroductionsListAction> = (state, { introductions }) => {
  const handledIntroductions = introductions?.map((item) => ({
    ...item,
    category: WORKOUT_CATEGORY.INTRODUCTION,
    virtual_coach: true,
  }));
  return {
    ...state,
    introductions: handledIntroductions,
  };
};

const setWorkoutNotes: Handler<SetWorkoutNotesAction> = (state, { notes }) => {
  return {
    ...state,
    notes: notes,
  };
};

const clearNotes: Handler<ClearNotesAction> = (state) => {
  return {
    ...state,
    notes: null,
  };
};

const setTrainingPlans: Handler<SetTrainingPlansAction> = (state, { trainingPlans }) => {
  return {
    ...state,
    trainingPlans: trainingPlans,
  };
};

const setTrainingPeeksWorkouts: Handler<SetTrainingPeeksWorkoutsAction> = (state, { workouts }) => {
  const trainingPeeksWorkouts = workouts?.map((item) => ({
    ...item,
    category: WORKOUT_CATEGORY.TRAINING_PEEKS,
    virtual_coach: false,
  }));
  return {
    ...state,
    trainingPeeksWorkouts: trainingPeeksWorkouts,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_WORKOUTS_LIST]: setWorkoutsList,
  [Types.SET_INTRODUCTIONS_LIST]: setIntroductionsList,
  [Types.SET_WORKOUT_NOTES]: setWorkoutNotes,
  [Types.CLEAR_NOTES]: clearNotes,
  [Types.SET_TRAINING_PLANS]: setTrainingPlans,
  [Types.SET_TRAINING_PEEKS_WORKOUTS]: setTrainingPeeksWorkouts,
};

export const workoutsReducer = createReducer(INITIAL_STATE, HANDLERS);
