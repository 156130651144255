import { call, delay, put, select } from "redux-saga/effects";
import { Event } from "../../services/googleAnalyticsTracking";
import { SmartTrainerBase } from "../../sensors/common/SmartTrainerBase";
import { EstimateSpeed } from "../../utils/SpeedCalculator";
import { StateInterface } from "../reducers";
import DeviceCreators from "../reducers/devicesReducer";
import {
  BLE_REMARK_TYPES,
  CONNECTION_STATUS_TYPES,
  CourseSegmentStepInterface,
  DeviceDataPoint,
  DeviceType,
  WorkoutIntervalInterface,
} from "../types";
import { DebugApi } from "../../api/api";

export function* connectMockBle() {
  const userWeight: number = yield select(
    (state: StateInterface) => state.user.cpxProfile?.weight || 80,
  );

  const mockTrainer = new MockSmartTrainer(userWeight);

  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.SmartTrainer,
      CONNECTION_STATUS_TYPES.NOT_CONNECTED,
      BLE_REMARK_TYPES.NOT_CONNECTED,
    ),
  );
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.HeartRateMeterDevice,
      CONNECTION_STATUS_TYPES.NOT_CONNECTED,
      BLE_REMARK_TYPES.NOT_CONNECTED,
    ),
  );
  yield delay(500);
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.SmartTrainer,
      CONNECTION_STATUS_TYPES.CONNECTING_SERVER,
      BLE_REMARK_TYPES.FOUND_SERVER,
    ),
  );
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.HeartRateMeterDevice,
      CONNECTION_STATUS_TYPES.CONNECTING_SERVER,
      BLE_REMARK_TYPES.FOUND_SERVER,
    ),
  );
  yield delay(1000);
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.SmartTrainer,
      CONNECTION_STATUS_TYPES.CONNECTING_SERVICE,
      BLE_REMARK_TYPES.FOUND_SERVICE,
    ),
  );
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.HeartRateMeterDevice,
      CONNECTION_STATUS_TYPES.CONNECTING_SERVICE,
      BLE_REMARK_TYPES.FOUND_SERVICE,
    ),
  );
  yield delay(1500);
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.SmartTrainer,
      CONNECTION_STATUS_TYPES.CONNECTED,
      BLE_REMARK_TYPES.FOUND_CHARACTERISTIC,
    ),
  );
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.HeartRateMeterDevice,
      CONNECTION_STATUS_TYPES.CONNECTED,
      BLE_REMARK_TYPES.FOUND_CHARACTERISTIC,
    ),
  );
  yield delay(2000);
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.SmartTrainer,
      CONNECTION_STATUS_TYPES.CONNECTED,
      BLE_REMARK_TYPES.CONNECTED,
      mockTrainer.name,
    ),
  );
  yield put(
    DeviceCreators.bleStatusChanged(
      DeviceType.HeartRateMeterDevice,
      CONNECTION_STATUS_TYPES.CONNECTED,
      BLE_REMARK_TYPES.CONNECTED,
      "Mock HRM",
    ),
  );

  yield put(DeviceCreators.updateSpecificBluetoothSensor(mockTrainer, DeviceType.SmartTrainer));
  Event("use_simulator", {});
  Event("ble_connection", { status: "connected", trainer_type: "mock_ble" });
  DebugApi.log({
    function: "connectMockBle",
    message: "Connect Mock BLE",
  });
  yield call(virtualRider);
}

class MockSmartTrainer extends SmartTrainerBase {
  constructor(userWeight: number) {
    super(null, "Mock Smart Trainer", userWeight, 0);
  }

  isConnected(): boolean {
    return true;
  }

  async SetupCharacteristics() { }

  async SetControlCharacteristic(_uuid: string) { }

  async WriteControlCharacteristic(_fullPacket: Uint8Array) { }

  async WriteCharacteristic(_serviceUuid: string, _characteristicUuid: string, _data: Uint8Array) {
    return true;
  }
}

export function* virtualRider() {
  let deviceDataPoint: DeviceDataPoint = {
    cadence: null,
    power: null,
    speed: null,
    powerBalance: null,
    heartRate: null,
  };

  const connectionStatus: CONNECTION_STATUS_TYPES = yield select(
    (state: StateInterface) => state.devices.bleSmartTrainerConnectionStatus,
  );

  const deviceName: string = yield select(
    (state: StateInterface) => state.devices.smartTrainerDevice?.name,
  );

  const userCp: number = yield select((state: StateInterface) => state.settings.criticalPower);
  const userWeight: number = yield select(
    (state: StateInterface) => state.user.cpxProfile?.weight || 80,
  );
  const powAccuracy = 0.05;
  const cadAccuracy = 0.05;
  const powerBalanceAccuracy = 0.1;
  const heartRateAccruacy = 0.1;

  let metricIndex = 0;
  while (
    connectionStatus === CONNECTION_STATUS_TYPES.CONNECTED &&
    deviceName === "Mock Smart Trainer"
  ) {
    const offset = Math.ceil(Math.random() - 0.5);
    const currentStep: WorkoutIntervalInterface | null = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentStep,
    );
    const currentSegment: CourseSegmentStepInterface | null = yield select(
      (state: StateInterface) => state.activeTraining.currentSteps.currentCourseStep,
    );
    const currentPower = currentSegment?.relativePower || currentStep?.relativePower || 0;
    const currentCadence = currentSegment?.cadence || currentStep?.cadence || 85;

    const power =
      offset +
      Math.round(
        (((1 - powAccuracy + 2 * powAccuracy * Math.random()) * currentPower) / 100) * userCp,
      );

    const cadence = Math.round(
      (1 - cadAccuracy + 2 * cadAccuracy * Math.random()) * currentCadence,
    );
    const speed: number = yield call(EstimateSpeed, power, userWeight, 0);
    const powerBalance = Math.round((1 - powerBalanceAccuracy + 2 * powerBalanceAccuracy * Math.random()) * 50);
    const heartRate = Math.round((1 - heartRateAccruacy + 2 * heartRateAccruacy * Math.random()) * 140);

    deviceDataPoint.power = null;
    deviceDataPoint.powerBalance = null;
    deviceDataPoint.cadence = null;
    deviceDataPoint.speed = null;
    deviceDataPoint.heartRate = null;

    if (metricIndex === 0) {
      deviceDataPoint.power = power;
      deviceDataPoint.powerBalance = powerBalance;
    } else if (metricIndex === 1) {
      deviceDataPoint.cadence = cadence;
      deviceDataPoint.speed = speed;
    } else if (metricIndex === 2) {
      deviceDataPoint.heartRate = heartRate;
    }
    metricIndex++;
    if (metricIndex >= 3) metricIndex = 0;

    yield put(DeviceCreators.deviceDataChanged(deviceDataPoint, DeviceType.SmartTrainer));

    yield delay(150);
  }

  deviceDataPoint.power = 0;
  deviceDataPoint.cadence = 0;
  yield put(DeviceCreators.deviceDataChanged(deviceDataPoint, DeviceType.SmartTrainer));
}
