import React, { ReactNode } from "react";
import { View, ViewStyle } from "react-native";
import { themedStyles } from "./styles";

type Props = {
  children: ReactNode | ReactNode[];
  testID?: string;
  style?: ViewStyle;
};

export function Container({ children, testID, style: propsStyle }: Props) {
  return (
    <View style={[themedStyles.center, propsStyle]} testID={testID}>
      {children}
    </View>
  );
}
