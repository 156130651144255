import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import ToolTipCreators from "../../../redux/reducers/toolTipReducer";
import { RFValue } from "../../../utils/resizeHelper";

interface PropsType {
  children?: JSX.Element;
  text?: string;
  showToolTip?: (x: number, y: number, text: string) => void;
  hideTootTip?: () => void;
}

const ToolTipWrapperComponent = ({ children, showToolTip, hideTootTip, text }: PropsType) => {
  const show = (e: any) => {
    const x = e.pageX + RFValue(10);
    const y = e.pageY + RFValue(10);
    showToolTip && text && showToolTip(x, y, text);
  };

  const hide = () => {
    hideTootTip?.();
  };

  return (
    <div onMouseEnter={show} onMouseLeave={hide}>
      {children}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showToolTip: (x: number, y: number, text: string) => dispatch(ToolTipCreators.show(x, y, text)),
  hideTootTip: () => dispatch(ToolTipCreators.hide()),
});

export const ToolTipWrapper = connect(null, mapDispatchToProps)(ToolTipWrapperComponent);
