import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import {
  CpInterface,
  PermissionsInterface,
  ServicesConnectedInterface,
  SetCriticalPowerAction,
  SetPermissionsAction,
  SettingsState,
  SetUnitSystemAction,
  SetServicesConnectedAction,
  UNIT_SYSTEM_TYPE,
  UpdateUserProfileAction,
} from "../types";
import { UserTypes } from "./userReducer";

interface ActionTypesInterface {
  GET_SERVICES_CONNECTED: "GET_SERVICES_CONNECTED";
  SET_SERVICES_CONNECTED: "SET_SERVICES_CONNECTED";
  UPDATE_CRITICAL_POWER: "UPDATE_CRITICAL_POWER";
  SET_CRITICAL_POWER: "SET_CRITICAL_POWER";
  SET_PERMISSIONS: "SET_PERMISSIONS";
  SET_UNIT_SYSTEM: "SET_UNIT_SYSTEM";
}

interface ActionCreatorsInterface extends ActionCreators {
  getServicesConnected: () => AnyAction;
  setServicesConnected: (servicesConnected: ServicesConnectedInterface) => AnyAction;
  updateCriticalPower: (criticalPower: number) => AnyAction;
  setCriticalPower: (criticalPower: number, cp?: CpInterface) => AnyAction;
  setPermissions: (permissions: PermissionsInterface) => AnyAction;
  setUnitSystem: (unitSystem: UNIT_SYSTEM_TYPE) => AnyAction;
}

type Handler<A> = (state: SettingsState, action: A) => SettingsState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  getServicesConnected: null,
  setServicesConnected: ["servicesConnected"],
  updateCriticalPower: ["criticalPower"],
  setCriticalPower: ["criticalPower", "cp"],
  setPermissions: ["permissions"],
  setUnitSystem: ["unitSystem"],
});

export const SettingsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: SettingsState = {
  criticalPower: 200,
  unitSystem: null,
  cp: null,
  permissions: null,
  servicesConnected: {
    strava: {
      authorization_url: null,
      connected: false,
    },
    training_peaks: {
      authorization_url: null,
      connected: false,
    },
  },
};

/* ------------- Reducers ------------- */

const setCriticalPower: Handler<SetCriticalPowerAction> = (state, { criticalPower, cp }) => {
  return {
    ...state,
    criticalPower: criticalPower,
    cp: {
      ...state.cp,
      ...cp,
    },
  };
};

const setPermissions: Handler<SetPermissionsAction> = (state, { permissions }) => {
  return {
    ...state,
    permissions: permissions,
  };
};

const setUnitSystem: Handler<SetUnitSystemAction> = (state, { unitSystem }) => {
  return {
    ...state,
    unitSystem: unitSystem,
  };
};

const UpdateCriticalPower: Handler<UpdateUserProfileAction> = (state, { updatedUserProfile }) => {
  return {
    ...state,
    criticalPower: updatedUserProfile.Cp20M,
  };
};

const setServicesConnected: Handler<SetServicesConnectedAction> = (
  state,
  { servicesConnected },
) => {
  return {
    ...state,
    servicesConnected: servicesConnected,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_CRITICAL_POWER]: setCriticalPower,
  [Types.SET_PERMISSIONS]: setPermissions,
  [Types.SET_UNIT_SYSTEM]: setUnitSystem,
  [Types.SET_SERVICES_CONNECTED]: setServicesConnected,
  [UserTypes.UPDATE_USER_PROFILE_SUCCESS]: UpdateCriticalPower,
};

export const settingsReducer = createReducer(INITIAL_STATE, HANDLERS);
