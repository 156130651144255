import React from "react";
import { View } from "react-native";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { StateInterface } from "../../../redux/reducers";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";

const ToolTipProviderComponent = ({ children, x, y, isVisible, text }: CombinedProps) => {
  return (
    <View>
      {children}
      {isVisible && (
        <View style={[themedStyles.container, { top: y, left: x }]}>
          <DefaultText style={themedStyles.text}>{text}</DefaultText>
        </View>
      )}
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { children: JSX.Element };

const mapStateToProps = (state: StateInterface) => ({
  x: state.toolTip.x,
  y: state.toolTip.y,
  isVisible: state.toolTip.isVisible,
  text: state.toolTip.text,
});
const mapDispatchToProps = (_dispatch: Dispatch) => ({});
export const ToolTipProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToolTipProviderComponent);
