import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { round } from "../../utils/Statistics";
import { COURSE_MOVEMENT_TYPE } from "../../redux/types";
import { StateInterface } from "../../redux/reducers";

import { CourseInterface, CoursePointsInterface, CourseSegmentInterface } from "../../redux/types";
import { themedStyles } from "./styles";
import { View } from "react-native";
import { DefaultText } from "../CommonElements/DefaultText";
import ActiveTrainingCreators from "../../redux/reducers/activeTrainingReducer";
import { FinalSegmentStats } from "../FinalSegmentStats";

interface LocalStateInterface {}

interface LocalStylesInterface {
  width: number;
  height: number;
  titleHeight: number;
  colors?: string[];
  segmentColors?: number;
  miniMapWidth: number;
  miniMapHeight: number;
  dotsRadius: number;
}

interface SegmentPropsType {
  styles: LocalStylesInterface;
  segments: CourseSegmentInterface[];
  segmentColors: Record<string, string>;
}
interface PointsPropsType {
  styles: LocalStylesInterface;
  color: string;
  points: CoursePointsInterface[];
  titleText?: string;
}

interface GetPathDataProps {
  styles: LocalStylesInterface;
  aerialProgress: number;
  ascendProgress?: number;
  titleHeight?: number;
}
class CourseImageComponent extends Component<CombinedProps, LocalStateInterface> {
  static defaultProps = {
    colors: {
      primaryColor: "#ffffff",
      yellow: "#FFFF15",
      green: "#2F8F00",
    },
    segmentColors: {
      tar: "#696969",
      tiz: "#7f007f",
      spr: "#026902",
      agr: "#ff0000",
      toz: "#f05512",
      kom: "#ff00be",
      kod: "#007ffc",
    },
    width: 512,
    miniMapWidth: 350,
    miniMapHeight: 100,
    height: 100,
    margin: 0,
    dotsRadius: 1,
  };
  currentPoint: number;
  currentSegment: number;
  constructor(props: CombinedProps) {
    super(props);
    this.currentPoint = 0;
    this.currentSegment = 0;
  }

  getPathData = ({ styles, aerialProgress, ascendProgress, titleHeight }: GetPathDataProps) => {
    const pathData =
      "M" +
      Math.round(aerialProgress * styles.width) +
      " " +
      (titleHeight || styles.titleHeight) +
      " L" +
      Math.round(aerialProgress * styles.width) +
      " " +
      (ascendProgress
        ? Math.round(ascendProgress * styles.height + styles.titleHeight)
        : styles.height + styles.titleHeight);

    return pathData;
  };

  render() {
    const { course, progress } = this.props;
    if (!this.props.displayCourse || !course) {
      return null;
    }
    const { segments, points } = course;
    const styles = this.getStyles();

    const progressVector = this.progressVectors(points, progress);
    const { x: aerialProgress, y: ascendProgress } = progressVector;

    const currentPathData = this.getPathData({ styles, aerialProgress });
    if (this.props.visualExperience?.display_mini_map) {
      return this.renderMiniMap(styles, course, points, segments, aerialProgress, ascendProgress);
    } else if (this.props.visualExperience?.display_course) {
      return this.renderFullCourse(styles, course, points, segments, currentPathData);
    } else return <FinalSegmentStats />;
  }

  componentWillUpdate(nextProps: CombinedProps) {
    if (!this.props.displayCourse || !this.props.course || !nextProps.course) {
      return null;
    }

    if (this.props.course.courseId !== nextProps.course.courseId) {
      this.currentPoint = 0;
      this.currentSegment = 0;
    }
  }

  componentDidUpdate(prevProps: CombinedProps) {
    if (!this.props.displayCourse || prevProps.course?.points.length === 0 || !prevProps.course) {
      return null;
    }

    const {
      progress,
      smartTrainer,
      nextCourseStep,
      finishCourseStep,
      currentSteps,
      courseMovementStatus,
    } = this.props;

    if (progress === 0 && smartTrainer !== null) {
      smartTrainer.setGrade(0);
    }

    if (
      currentSteps.currentCourseStep?.end &&
      progress > currentSteps.currentCourseStep.end * 1000
    ) {
      nextCourseStep();
    }

    if (progress > prevProps.course.points[this.currentPoint].distance) {
      const nextPoint = this.getNextPointIndex(this.currentPoint);
      const pointsCount = prevProps.course.points.length;
      const slope = prevProps.course.points[nextPoint].grade;

      if (smartTrainer !== null) {
        smartTrainer.setGrade(slope);
      }
      if (nextPoint < pointsCount && courseMovementStatus === COURSE_MOVEMENT_TYPE.MOVING) {
        this.currentPoint = nextPoint;
      } else if (nextPoint >= pointsCount) {
        finishCourseStep();
      }
    }
  }

  getNextPointIndex(current: number) {
    if (this.props.course) {
      return (current + 1) % this.props.course.points.length;
    } else return 0;
  }

  progressVectors(points: CoursePointsInterface[], progress: number) {
    if (points.length === 0 || !this.props.course) {
      return { x: 0, y: 0 };
    }
    const { distance: totalDistance, heightDiff } = this.props.course;
    if (this.currentPoint < 1) {
      return { x: points[0].x, y: points[0].y };
    }

    const previous = this.currentPoint > 0 ? points[this.currentPoint - 1] : points[0];
    const lastPointEnd =
      this.currentPoint > 0 ? points[this.currentPoint - 1].distance : points[0].distance;
    const current = points[this.currentPoint];
    const angle = Math.atan(current.grade / 100);

    return {
      x: previous.x + ((progress - lastPointEnd) * Math.cos(angle)) / totalDistance,
      y: previous.y - ((progress - lastPointEnd) * Math.sin(angle)) / heightDiff,
    };
  }

  getStyles() {
    const styles: LocalStylesInterface = {
      width: this.props.width,
      height: this.props.height,
      titleHeight: 45,

      miniMapWidth: this.props.miniMapWidth,
      miniMapHeight: this.props.miniMapHeight,
      dotsRadius: this.props.dotsRadius,
    };
    return styles;
  }

  renderMiniMap(
    styles: LocalStylesInterface,
    course: CourseInterface,
    points: CoursePointsInterface[],
    segments: CourseSegmentInterface[],
    aerialProgress: number,
    ascendProgress: number,
  ) {
    const showDistance = 300; //show distance in Meters
    const cursorHeight = 40;
    const zoom = 1.5;
    const stepSize = styles.miniMapWidth / course.distance;
    const width = showDistance * stepSize;
    const height = styles.titleHeight + styles.height;
    const minX = aerialProgress * styles.width - 100 * stepSize - showDistance * stepSize;
    const minY = styles.titleHeight - cursorHeight + ascendProgress * styles.height;

    const viewBox =
      round(minX, 2) + " " + round(minY, 2) + " " + round(width, 2) + " " + round(height / zoom, 2);

    const currentGrade = points[this.currentPoint].grade;
    const nextGrade = points[this.getNextPointIndex(this.currentPoint)].grade;
    const currentPathData = this.getPathData({
      styles,
      aerialProgress,
      ascendProgress: 0,
      titleHeight: 1,
    });

    return (
      <View style={themedStyles.minMapContainer}>
        <View style={themedStyles.minMapTextContainer}>
          <DefaultText style={[themedStyles.minimapGradeText, themedStyles.minMapCurrenGradeText]}>
            {currentGrade > 0 && "+"}
            {currentGrade.toFixed(1)}%
          </DefaultText>

          <DefaultText style={[themedStyles.minimapGradeText, themedStyles.minMapNextGradeText]}>
            {nextGrade > 0 && "+"}
            {nextGrade.toFixed(1)}%
          </DefaultText>
        </View>
        <svg viewBox={viewBox}>
          <SegmentsDrawing
            segments={segments}
            styles={styles}
            segmentColors={this.props.segmentColors}
          />
          <CourseDrawing
            points={points}
            titleText={course.description}
            color={this.props.colors.primaryColor}
            styles={styles}
          />
          <CourseDots points={points} color={this.props.colors.primaryColor} styles={styles} />
          <path d={currentPathData} stroke={this.props.colors.yellow} strokeWidth="1" />
        </svg>
      </View>
    );
  }

  renderFullCourse(
    styles: LocalStylesInterface,
    course: CourseInterface,
    points: CoursePointsInterface[],
    segments: CourseSegmentInterface[],
    currentPathData: string,
  ) {
    const viewBox = "0 0 " + styles.width + " " + (styles.height + styles.titleHeight);
    return (
      <svg viewBox={viewBox}>
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          x={styles.width / 2}
          y={styles.titleHeight / 2}
          fill={this.props.colors.primaryColor}
          fontSize="1rem"
        >
          {course.description}
        </text>
        <SegmentsDrawing
          segments={segments}
          styles={styles}
          segmentColors={this.props.segmentColors}
        />
        <CourseDrawing
          points={points}
          titleText={course.description}
          styles={styles}
          color={this.props.colors.primaryColor}
        />

        <path d={currentPathData} stroke={this.props.colors.yellow} strokeWidth="3" />
      </svg>
    );
  }
}

const CourseDots = (props: PointsPropsType) => {
  let color = props.color;
  let points = props.points;
  let styles = props.styles;
  if (points.length === 0) return null;

  return (
    <g id="course-dots" stroke={color} strokeWidth="1">
      {points.map((point, key) => {
        let x = Math.round(point.x * styles.width);
        let y = styles.titleHeight + Math.round(point.y * styles.height);
        return <circle cx={x} cy={y} r={styles.dotsRadius} key={key} />;
      })}
    </g>
  );
};

const CourseDrawing = ({ color, styles, points }: PointsPropsType) => {
  if (points.length === 0) return null;

  return (
    <g id="course-path" stroke={color} fillOpacity="0" strokeWidth="1" strokeLinejoin="round">
      <path
        d={
          "M" +
          points[0].x * styles.width +
          " " +
          (styles.titleHeight + Math.round(points[0].y * styles.height)) +
          points.map((point) => {
            let x = Math.round(point.x * styles.width);
            let y = styles.titleHeight + Math.round(point.y * styles.height);
            return "L " + x + " " + y + " ";
          })
        }
      />
    </g>
  );
};

const SegmentsDrawing = ({ styles, segments, segmentColors }: SegmentPropsType) => {
  if (segments.length === 0) return null;

  return (
    <g id="segments" strokeWidth="1">
      {segments.map((segment) => {
        const x = Math.round(segment.start * styles.width);
        const y = styles.titleHeight;
        const width = Math.round((segment.end - segment.start) * styles.width);
        const height = styles.height;
        const color = segmentColors[segment.type];
        return (
          <rect
            key={`start-segment-point-${segment.start}`}
            x={x}
            y={y}
            width={width}
            height={height}
            stroke={color}
            fill={color}
            fillOpacity="0.5"
          />
        );
      })}
    </g>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  typeof CourseImageComponent.defaultProps;

const mapStateToProps = (state: StateInterface) => ({
  progress: (state.metrics.currentMetrics.distance + state.metrics.savedDistance) * 1000,
  course: state.activeTraining.currentSteps.course,
  smartTrainer: state.devices.smartTrainerDevice,
  displayCourse: !!state.activeTraining.currentSteps.currentStep?.courseMovementStatus,
  courseMovementStatus: state.activeTraining.currentSteps.currentStep?.courseMovementStatus,
  visualExperience: state.activeTraining.currentSteps.currentStep?.visualExperience,
  currentSteps: state.activeTraining.currentSteps,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  nextCourseStep: () => dispatch(ActiveTrainingCreators.nextCourseStep()),
  finishCourseStep: () => dispatch(ActiveTrainingCreators.finishCourseStep()),
});
export const CourseImage = connect(mapStateToProps, mapDispatchToProps)(CourseImageComponent);
