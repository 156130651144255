import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StateInterface } from "../../redux/reducers";
import {
  getTimeFromSegmentStart,
  getTimeFromStepStart,
  getTimeFromWorkoutStart,
} from "../../redux/selectors";
import { DeviceDataPoint, INTERVAL_TYPE, TRAINING_STATUS_TYPES } from "../../redux/types";
import { getMetricsSubset } from "../../utils/MetricsCalculating";

import { roundStr, toRelative } from "../../utils/Statistics";
import { toPath, toRotate, toArcPath, getGaugeTextColor } from "../../utils/SvgHelpers";

interface GaugeMetricsInterface {
  avgCadence: number;
  avgPower: number;
  avgWatts: number;
  cadence: number;
  cadenceLoad: number;
  cadenceWindowHigh: number;
  cadenceWindowLow: number;
  cadenceWindowOriginal: number | null;
  cp: number;
  nextPowerLoad: number;
  nextPowerWindowHigh: number;
  nextPowerWindowLow: number;
  power: number;
  powerLoad: number;
  powerWindowHigh: number;
  powerWindowLow: number;
  powerWindowOriginal: number | null;
  speed: number;
  watts: number | null;
  wattsLoad: number;
  wattsWindowHigh: number;
  wattsWindowLow: number;
  workout_ts: number;
  tbz?: number;
  toz?: number;
}

interface LocalStateInterface {
  coachViewMarginValue: number;
}

interface PropsType {
  deviceData: DeviceDataPoint | null;
  cp20m: number;
  coachView?: boolean;
  avgValues?: {
    cadenceAvg: number;
    powerAvg: number;
  };
}

const POWER_START_ANGLE = 180;

class MetricsGauge extends Component<CombinedProps, LocalStateInterface> {
  metrics: GaugeMetricsInterface | null;
  static defaultProps = {
    colors: {
      primaryColor: "#ffffff",
      yellow: "#FFFF15",
      blue: "#3595F0",
      green: "#2F8F00",
      regularValue: "#bbbbbb",

      red: "#C01818",
      grey: "#888888",
    },
    primaryColor: "#ffffff",
    gradient: [],
    width: 450,
    height: 450,
    margin: 10,
    powerMin: 0,
    powerMax: 250,
    cadenceMin: 0,
    cadenceMax: 270,
  };
  constructor(props: CombinedProps) {
    super(props);
    this.state = {
      coachViewMarginValue: props.coachView ? 7 : 0,
    };
    this.metrics = null;
  }

  getStyles(metrics: GaugeMetricsInterface) {
    let { width, height, margin, powerMin, powerMax, cadenceMax, cadenceMin } = this.props;
    const avgPowerProps = this.props.avgValues?.powerAvg
      ? Math.round((this.props.avgValues.powerAvg / this.props.cp20m) * 100)
      : metrics.avgPower;
    const avgCadeceProps = this.props.avgValues?.cadenceAvg || metrics.avgCadence;

    let powerRadius = Math.round((width - 2 * margin) / 2);

    let powerCenterX = Math.round(width / 2);
    let powerCenterY = Math.round(powerRadius + margin);
    let innerPowerRadius = powerRadius * 0.7;
    let needleWidth = (powerRadius - innerPowerRadius) * 0.5;
    let rectDim = Math.round(this.props.width / 20);

    let smallGaugeRadius = Math.round(powerRadius / 2.5);
    let smallGaugeInnerRadius = smallGaugeRadius * 0.6;
    let smallNeedleWidth = (smallGaugeRadius - smallGaugeInnerRadius) * 0.5;
    let smallRectDim = Math.round(this.props.width / 30);

    let cadenceCenterX = margin + smallGaugeRadius;
    let cadenceCenterY = height - margin - smallGaugeRadius;

    let accuracyCenterX = width - 1.5 * margin - smallGaugeRadius;
    let accuracyCenterY = height - smallGaugeRadius;

    let power = toRelative(metrics.power, powerMin, powerMax);
    let avgPower = toRelative(avgPowerProps, powerMin, powerMax);
    let powerLoad = toRelative(metrics.powerLoad, powerMin, powerMax);
    let nextPowerLoad = toRelative(metrics.nextPowerLoad, powerMin, powerMax);
    let powerWindowLow = toRelative(metrics.powerWindowLow, powerMin, powerMax);
    let powerWindowHigh = toRelative(metrics.powerWindowHigh, powerMin, powerMax);

    let nextPowerWindowLow = toRelative(metrics.nextPowerWindowLow, powerMin, powerMax);
    let nextPowerWindowHigh = toRelative(metrics.nextPowerWindowHigh, powerMin, powerMax);

    let powerColor = getGaugeTextColor({
      value: metrics.power,
      min: metrics.powerLoad - metrics.powerWindowLow,
      max: metrics.powerLoad + metrics.powerWindowHigh,
    });

    let powerAvgColor = getGaugeTextColor({
      value: avgPowerProps,
      min: metrics.powerLoad - metrics.powerWindowLow,
      max: metrics.powerLoad + metrics.powerWindowHigh,
    });
    let cadence = toRelative(metrics.cadence, cadenceMin, cadenceMax);
    let avgCadence = toRelative(avgCadeceProps, cadenceMin, cadenceMax);
    let cadenceLoad = toRelative(metrics.cadenceLoad, cadenceMin, cadenceMax);
    let cadenceWindowLow = toRelative(metrics.cadenceWindowLow, cadenceMin, cadenceMax);
    let cadenceWindowHigh = toRelative(metrics.cadenceWindowHigh, cadenceMin, cadenceMax);

    let cadenceColor = getGaugeTextColor({
      value: metrics.cadence,
      min: metrics.cadenceLoad - metrics.cadenceWindowLow,
      max: metrics.cadenceLoad + metrics.cadenceWindowHigh,
    });
    let cadenceAvgColor = getGaugeTextColor({
      value: avgCadeceProps,
      min: metrics.cadenceLoad - metrics.cadenceWindowLow,
      max: metrics.cadenceLoad + metrics.cadenceWindowHigh,
    });

    let tbz = toRelative(metrics.tbz || 0, 0, 100);
    let toz = toRelative(100 - (metrics.toz || 0), 0, 100);
    return {
      colors: this.props.colors,
      powerColor: powerColor,
      avgPowerColor: powerAvgColor,
      cadenceColor: cadenceColor,
      avgCadenceColor: cadenceAvgColor,
      width: width,
      height: height,
      margin: margin,
      fonts: {
        tiny: Math.round(0.03 * height) + "px",
        small: Math.round(0.05 * height) + "px",
        smallMedium: Math.round(0.077 * height) + "px",
        medium: Math.round(0.07 * height) + "px",
        large: Math.round(0.122 * height) + "px",
      },
      powerGauge: {
        centerX: powerCenterX,
        centerY: powerCenterY,
        radios: powerRadius,
        rectangleDim: rectDim,
        innerRadius: innerPowerRadius,
        needleRadius: innerPowerRadius + 1.3 * needleWidth,
        arcAngle: power,
        needleWidth: needleWidth,
        startAngle: POWER_START_ANGLE,
      },
      cadenceGauge: {
        centerX: cadenceCenterX,
        centerY: cadenceCenterY,
        radios: smallGaugeRadius,
        rectangleDim: smallRectDim,
        needleRadius: smallGaugeInnerRadius + +1.3 * smallNeedleWidth,
        innerRadius: smallGaugeInnerRadius,
        needleWidth: smallNeedleWidth,
        arcAngle: cadence,
        startAngle: 0,
      },

      accuracyGauge: {
        centerX: accuracyCenterX,
        centerY: accuracyCenterY,
        radios: smallGaugeRadius,
        innerRadius: smallGaugeInnerRadius,
        tbzRotation: tbz,
        tozRotation: toz,
      },

      powerAvg: {
        cx: powerCenterX - (innerPowerRadius + rectDim / 2) * Math.cos((avgPower / 180) * Math.PI),
        cy: powerCenterY - (innerPowerRadius + rectDim / 2) * Math.sin((avgPower / 180) * Math.PI),
        r: avgPower - 45,
      },
      cadenceAvg: {
        cx:
          cadenceCenterX -
          (smallGaugeInnerRadius + smallRectDim / 2) * Math.cos((avgCadence / 180) * Math.PI),
        cy:
          cadenceCenterY -
          (smallGaugeInnerRadius + smallRectDim / 2) * Math.sin((avgCadence / 180) * Math.PI),
        r: avgCadence - 45,
      },
      powerTarget: {
        load: {
          mx: Math.round(powerCenterX - innerPowerRadius * Math.cos((powerLoad / 180) * Math.PI)),
          my: Math.round(powerCenterY - innerPowerRadius * Math.sin((powerLoad / 180) * Math.PI)),
          lx: Math.round(powerCenterX - powerRadius * Math.cos((powerLoad / 180) * Math.PI)),
          ly: Math.round(powerCenterY - powerRadius * Math.sin((powerLoad / 180) * Math.PI)),
        },
        min: {
          mx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth - 7) *
                Math.cos(((powerLoad - powerWindowLow) / 180) * Math.PI),
          ),
          my: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth - 7) *
                Math.sin(((powerLoad - powerWindowLow) / 180) * Math.PI),
          ),
          lx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth + 1) *
                Math.cos(((powerLoad - powerWindowLow) / 180) * Math.PI),
          ),
          ly: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth + 1) *
                Math.sin(((powerLoad - powerWindowLow) / 180) * Math.PI),
          ),
        },
        max: {
          mx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth - 7) *
                Math.cos(((powerLoad + powerWindowHigh) / 180) * Math.PI),
          ),
          my: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth - 7) *
                Math.sin(((powerLoad + powerWindowHigh) / 180) * Math.PI),
          ),
          lx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth + 1) *
                Math.cos(((powerLoad + powerWindowHigh) / 180) * Math.PI),
          ),
          ly: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth + 1) *
                Math.sin(((powerLoad + powerWindowHigh) / 180) * Math.PI),
          ),
        },
        arc: {
          startAngle: POWER_START_ANGLE + powerLoad - powerWindowLow,
          arcAngle: powerWindowHigh + powerWindowLow,
          radius: innerPowerRadius + needleWidth,
        },
      },
      nextPowerTarget: {
        min: {
          mx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth + 3) *
                Math.cos(((nextPowerLoad - nextPowerWindowLow) / 180) * Math.PI),
          ),
          my: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth + 3) *
                Math.sin(((nextPowerLoad - nextPowerWindowLow) / 180) * Math.PI),
          ),
          lx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth + 14) *
                Math.cos(((nextPowerLoad - nextPowerWindowLow) / 180) * Math.PI),
          ),
          ly: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth + 14) *
                Math.sin(((nextPowerLoad - nextPowerWindowLow) / 180) * Math.PI),
          ),
        },
        max: {
          mx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth + 3) *
                Math.cos(((nextPowerLoad + nextPowerWindowHigh) / 180) * Math.PI),
          ),
          my: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth + 3) *
                Math.sin(((nextPowerLoad + nextPowerWindowHigh) / 180) * Math.PI),
          ),
          lx: Math.round(
            powerCenterX -
              (innerPowerRadius + needleWidth + 14) *
                Math.cos(((nextPowerLoad + nextPowerWindowHigh) / 180) * Math.PI),
          ),
          ly: Math.round(
            powerCenterY -
              (innerPowerRadius + needleWidth + 14) *
                Math.sin(((nextPowerLoad + nextPowerWindowHigh) / 180) * Math.PI),
          ),
        },
        arc: {
          startAngle: POWER_START_ANGLE + nextPowerLoad - nextPowerWindowLow,
          arcAngle: nextPowerWindowHigh + nextPowerWindowLow,
          radius: innerPowerRadius + needleWidth + 13,
        },
      },

      cadenceTarget: {
        load: {
          mx: Math.round(
            cadenceCenterX - smallGaugeInnerRadius * Math.cos((cadenceLoad / 180) * Math.PI),
          ),
          my: Math.round(
            cadenceCenterY - smallGaugeInnerRadius * Math.sin((cadenceLoad / 180) * Math.PI),
          ),
          lx: Math.round(
            cadenceCenterX - smallGaugeRadius * Math.cos((cadenceLoad / 180) * Math.PI),
          ),
          ly: Math.round(
            cadenceCenterY - smallGaugeRadius * Math.sin((cadenceLoad / 180) * Math.PI),
          ),
        },
        min: {
          mx: Math.round(
            cadenceCenterX -
              (smallGaugeInnerRadius + smallNeedleWidth - 7) *
                Math.cos(((cadenceLoad - cadenceWindowLow) / 180) * Math.PI),
          ),
          my: Math.round(
            cadenceCenterY -
              (smallGaugeInnerRadius + smallNeedleWidth - 7) *
                Math.sin(((cadenceLoad - cadenceWindowLow) / 180) * Math.PI),
          ),
          lx: Math.round(
            cadenceCenterX -
              (smallGaugeInnerRadius + smallNeedleWidth) *
                Math.cos(((cadenceLoad - cadenceWindowLow) / 180) * Math.PI),
          ),
          ly: Math.round(
            cadenceCenterY -
              (smallGaugeInnerRadius + smallNeedleWidth) *
                Math.sin(((cadenceLoad - cadenceWindowLow) / 180) * Math.PI),
          ),
        },
        max: {
          mx: Math.round(
            cadenceCenterX -
              (smallGaugeInnerRadius + smallNeedleWidth - 7) *
                Math.cos(((cadenceLoad + cadenceWindowHigh) / 180) * Math.PI),
          ),
          my: Math.round(
            cadenceCenterY -
              (smallGaugeInnerRadius + smallNeedleWidth - 7) *
                Math.sin(((cadenceLoad + cadenceWindowHigh) / 180) * Math.PI),
          ),
          lx: Math.round(
            cadenceCenterX -
              (smallGaugeInnerRadius + smallNeedleWidth + 1) *
                Math.cos(((cadenceLoad + cadenceWindowHigh) / 180) * Math.PI),
          ),
          ly: Math.round(
            cadenceCenterY -
              (smallGaugeInnerRadius + smallNeedleWidth + 1) *
                Math.sin(((cadenceLoad + cadenceWindowHigh) / 180) * Math.PI),
          ),
        },
        arc: {
          startAngle: POWER_START_ANGLE + cadenceLoad - cadenceWindowLow,
          arcAngle: cadenceWindowHigh + cadenceWindowLow,
          radius: smallGaugeInnerRadius + smallNeedleWidth,
        },
      },
    };
  }

  render() {
    const isSegmentBasedStep = this.props.interval?.intervalType === INTERVAL_TYPE.SEGMENT_BASED;
    const currentMetrics = isSegmentBasedStep
      ? this.props.metrics.currentSegmentMetrics
      : this.props.metrics.currentMetrics;
    const nextStep = isSegmentBasedStep ? this.props.nextCourseStep : this.props.nextInterval;
    const step = isSegmentBasedStep ? this.props.currentCourseStep : this.props.interval;
    const timeFromStepStart = isSegmentBasedStep
      ? this.props.timeFromSegmentStart
      : this.props.timeFromStepStart;

    const metrics = getMetricsSubset({
      cp: this.props.cp20m,
      currentMetrics: currentMetrics,
      deviceData: this.props.deviceData,
      isStarted: this.props.status === TRAINING_STATUS_TYPES.STARTED,
      nextStep: nextStep,
      step: step,
      timeFromStepStart: timeFromStepStart,
      timeFromWorkoutStart: this.props.timeFromWorkoutStart,
    });

    let showNextPower =
      this.props.timeFromStepStart >= (this.props.interval?.durationInSeconds || 999) - 4;

    const styles = this.getStyles(metrics);

    const viewBox = "0 0 " + styles.width + " " + styles.height;
    return (
      <svg viewBox={viewBox} width={this.props.width}>
        <g fill="none" fillOpacity="1">
          <g id="powerGaugeFrame" stroke={styles.colors.primaryColor} strokeWidth="2">
            <path
              d={toArcPath(
                styles.powerGauge.centerX,
                styles.powerGauge.centerY,
                styles.powerGauge.radios,
                POWER_START_ANGLE,
                270,
                0,
              )}
              id="power-gauge"
            />
            <path
              d={toArcPath(
                styles.powerGauge.centerX,
                styles.powerGauge.centerY,
                styles.powerGauge.innerRadius,
                POWER_START_ANGLE,
                270,
                0,
              )}
              id="power-gauge-inner"
            />
            <path
              d={toPath(
                styles.powerGauge.centerX - styles.powerGauge.innerRadius,
                styles.powerGauge.centerY,
                styles.powerGauge.centerX - styles.powerGauge.radios,
                styles.powerGauge.centerY,
              )}
              id="power-gauge-start"
            />
            <path
              d={toPath(
                styles.powerGauge.centerX,
                styles.powerGauge.centerY + styles.powerGauge.innerRadius,
                styles.powerGauge.centerX,
                styles.powerGauge.centerY + styles.powerGauge.radios,
              )}
              id="power-gauge-end"
            />
          </g>

          <g id="power-metrics" strokeWidth="3" fillOpacity="0.7">
            <path
              d={toArcPath(
                styles.powerGauge.centerX,
                styles.powerGauge.centerY,
                styles.powerGauge.needleRadius,
                styles.powerGauge.startAngle,
                styles.powerGauge.arcAngle,
                0,
              )}
              id="power-gauge-needle"
              stroke={styles.powerColor}
              strokeWidth={styles.powerGauge.needleWidth}
            />

            <path
              d={toArcPath(
                styles.powerGauge.centerX,
                styles.powerGauge.centerY,
                styles.powerTarget.arc.radius,
                styles.powerTarget.arc.startAngle,
                styles.powerTarget.arc.arcAngle,
                0,
              )}
              id="power-target-arc"
              stroke={styles.colors.primaryColor}
              strokeWidth="3"
            />

            <path
              stroke={styles.colors.primaryColor}
              strokeWidth="1"
              d={toPath(
                styles.powerTarget.load.mx,
                styles.powerTarget.load.my,
                styles.powerTarget.load.lx,
                styles.powerTarget.load.ly,
              )}
              id="power-target"
            />

            <path
              stroke={styles.colors.primaryColor}
              d={toPath(
                styles.powerTarget.min.mx,
                styles.powerTarget.min.my,
                styles.powerTarget.min.lx,
                styles.powerTarget.min.ly,
              )}
              id="power-low-bound"
            />

            <path
              stroke={styles.colors.primaryColor}
              d={toPath(
                styles.powerTarget.max.mx,
                styles.powerTarget.max.my,
                styles.powerTarget.max.lx,
                styles.powerTarget.max.ly,
              )}
              id="power-high-bound"
            />

            {!this.props.coachView && (
              <rect
                stroke={styles.colors.primaryColor}
                fill={styles.avgPowerColor}
                strokeWidth="2"
                transform={toRotate(styles.powerAvg.r, styles.powerAvg.cx, styles.powerAvg.cy)}
                id="power-average-box"
                width={styles.powerGauge.rectangleDim}
                height={styles.powerGauge.rectangleDim}
                x={styles.powerAvg.cx}
                y={styles.powerAvg.cy}
              />
            )}
          </g>

          {showNextPower ? (
            <g id="next-power-metrics" strokeWidth="3" fillOpacity="0.7">
              <path
                stroke={styles.colors.yellow}
                d={toPath(
                  styles.nextPowerTarget.min.mx,
                  styles.nextPowerTarget.min.my,
                  styles.nextPowerTarget.min.lx,
                  styles.nextPowerTarget.min.ly,
                )}
                id="next-power-low-bound"
              />

              <path
                stroke={styles.colors.yellow}
                d={toPath(
                  styles.nextPowerTarget.max.mx,
                  styles.nextPowerTarget.max.my,
                  styles.nextPowerTarget.max.lx,
                  styles.nextPowerTarget.max.ly,
                )}
                id="next-power-high-bound"
              />
              <path
                d={toArcPath(
                  styles.powerGauge.centerX,
                  styles.powerGauge.centerY,
                  styles.nextPowerTarget.arc.radius,
                  styles.nextPowerTarget.arc.startAngle,
                  styles.nextPowerTarget.arc.arcAngle,
                  0,
                )}
                id="next-power-target-arc"
                stroke={styles.colors.yellow}
                strokeWidth="3"
              />
            </g>
          ) : (
            ""
          )}

          <g id="cadenceGaugeFrame" stroke={styles.colors.primaryColor} strokeWidth="2">
            <path
              d={toArcPath(
                styles.cadenceGauge.centerX,
                styles.cadenceGauge.centerY,
                styles.cadenceGauge.radios,
                POWER_START_ANGLE,
                270,
                0,
              )}
              id="cadence-gauge"
            />

            <path
              d={toArcPath(
                styles.cadenceGauge.centerX,
                styles.cadenceGauge.centerY,
                styles.cadenceGauge.innerRadius,
                POWER_START_ANGLE,
                270,
                0,
              )}
              id="cadence-inner-gauge"
            />

            <path
              d={toPath(
                styles.cadenceGauge.centerX - styles.cadenceGauge.innerRadius,
                styles.cadenceGauge.centerY,
                styles.cadenceGauge.centerX - styles.cadenceGauge.radios,
                styles.cadenceGauge.centerY,
              )}
              id="cadence-gauge-start"
            />
            <path
              d={toPath(
                styles.cadenceGauge.centerX,
                styles.cadenceGauge.centerY + styles.cadenceGauge.innerRadius,
                styles.cadenceGauge.centerX,
                styles.cadenceGauge.centerY + styles.cadenceGauge.radios,
              )}
              id="cadence-gauge-end"
            />
          </g>

          <g id="cadence-metrics" strokeWidth="3" fillOpacity="0.7">
            <path
              d={toArcPath(
                styles.cadenceGauge.centerX,
                styles.cadenceGauge.centerY,
                styles.cadenceGauge.needleRadius,
                styles.cadenceGauge.startAngle,
                styles.cadenceGauge.arcAngle,
                180,
              )}
              id="cadence-gauge-needle"
              stroke={styles.cadenceColor}
              strokeWidth={styles.cadenceGauge.needleWidth}
            />

            <path
              stroke={styles.colors.primaryColor}
              d={toPath(
                styles.cadenceTarget.load.mx,
                styles.cadenceTarget.load.my,
                styles.cadenceTarget.load.lx,
                styles.cadenceTarget.load.ly,
              )}
              id="cadence-target"
              strokeWidth="1"
            />
            <path
              stroke={styles.colors.primaryColor}
              d={toPath(
                styles.cadenceTarget.min.mx,
                styles.cadenceTarget.min.my,
                styles.cadenceTarget.min.lx,
                styles.cadenceTarget.min.ly,
              )}
              id="cadence-target-low-bound"
            />
            <path
              stroke={styles.colors.primaryColor}
              d={toPath(
                styles.cadenceTarget.max.mx,
                styles.cadenceTarget.max.my,
                styles.cadenceTarget.max.lx,
                styles.cadenceTarget.max.ly,
              )}
              id="cadence-target-high-bound"
            />

            <path
              d={toArcPath(
                styles.cadenceGauge.centerX,
                styles.cadenceGauge.centerY,
                styles.cadenceTarget.arc.radius,
                styles.cadenceTarget.arc.startAngle,
                styles.cadenceTarget.arc.arcAngle,
                0,
              )}
              id="power-target-arc"
              stroke={styles.colors.primaryColor}
              strokeWidth="3"
            />

            {!this.props.coachView && (
              <rect
                stroke={styles.colors.primaryColor}
                id="cadence-average-box"
                fill={styles.avgCadenceColor}
                strokeWidth="2"
                transform={toRotate(
                  styles.cadenceAvg.r,
                  styles.cadenceAvg.cx,
                  styles.cadenceAvg.cy,
                )}
                width={styles.cadenceGauge.rectangleDim}
                height={styles.cadenceGauge.rectangleDim}
                x={styles.cadenceAvg.cx}
                y={styles.cadenceAvg.cy}
              />
            )}
          </g>

          <g fill="none" fillOpacity="1" stroke="#000000" strokeWidth="2"></g>
        </g>

        <g id="texts-power-value" textAnchor="start">
          {!this.props.coachView && (
            <text
              x={styles.powerGauge.centerX + styles.powerGauge.innerRadius * 0.0665}
              y={styles.powerGauge.centerY - styles.powerGauge.innerRadius * 0.5}
              fill={styles.colors.grey}
              fontSize={styles.fonts.smallMedium}
            >
              {roundStr(metrics.wattsLoad, 1)}
            </text>
          )}
          <text
            x={styles.powerGauge.centerX + styles.powerGauge.innerRadius * 0.0465}
            y={styles.powerGauge.centerY * 0.94 - this.state.coachViewMarginValue}
            fill={styles.powerColor}
            fontSize={styles.fonts.large}
          >
            {Math.round(metrics.watts)}
          </text>
          <text
            x={styles.powerGauge.centerX + styles.powerGauge.innerRadius * 0.0665}
            y={
              styles.powerGauge.centerY +
              styles.powerGauge.innerRadius * 0.23 -
              this.state.coachViewMarginValue
            }
            fill={styles.avgPowerColor}
            fontSize={styles.fonts.smallMedium}
          >
            {this.props.avgValues
              ? roundStr(this.props.avgValues.powerAvg, 1)
              : roundStr(metrics.avgWatts, 1)}
          </text>
        </g>

        <g id="texts-power-percentage" textAnchor="end">
          {!this.props.coachView && (
            <>
              <text
                x={styles.powerGauge.centerX - styles.powerGauge.innerRadius * 0.166}
                y={styles.powerGauge.centerY - styles.powerGauge.innerRadius * 0.5}
                fill={styles.colors.grey}
                fontSize={styles.fonts.smallMedium}
              >
                {roundStr(metrics.powerLoad, 1)}
              </text>
              <text
                x={styles.powerGauge.centerX - styles.powerGauge.innerRadius * 0.0332}
                y={styles.powerGauge.centerY - styles.powerGauge.innerRadius * 0.5}
                fill={styles.colors.grey}
                fontSize={styles.fonts.small}
              >
                %
              </text>
            </>
          )}
          <text
            x={styles.powerGauge.centerX - styles.powerGauge.innerRadius * 0.166}
            y={styles.powerGauge.centerY * 0.94 - this.state.coachViewMarginValue}
            fill={styles.powerColor}
            fontSize={styles.fonts.large}
          >
            {Math.round(metrics.power)}
          </text>
          <text
            x={styles.powerGauge.centerX - styles.powerGauge.innerRadius * 0.0332}
            y={styles.powerGauge.centerY * 0.94 - this.state.coachViewMarginValue}
            fill={styles.powerColor}
            fontSize={styles.fonts.small}
          >
            %
          </text>
          <text
            x={styles.powerGauge.centerX - styles.powerGauge.innerRadius * 0.166}
            y={
              styles.powerGauge.centerY +
              styles.powerGauge.innerRadius * 0.23 -
              this.state.coachViewMarginValue
            }
            fill={styles.avgPowerColor}
            fontSize={styles.fonts.smallMedium}
          >
            {this.props.avgValues
              ? roundStr((this.props.avgValues.powerAvg / this.props.cp20m) * 100, 1)
              : roundStr(metrics.avgPower, 1)}
          </text>
          <text
            x={styles.powerGauge.centerX - styles.powerGauge.innerRadius * 0.0332}
            y={
              styles.powerGauge.centerY +
              styles.powerGauge.innerRadius * 0.23 -
              this.state.coachViewMarginValue
            }
            fill={styles.avgPowerColor}
            fontSize={styles.fonts.small}
          >
            %
          </text>
        </g>

        <g id="texts-power-balance">
          {metrics.powerBalance && (
            <>
              <text
                x={styles.powerGauge.centerX - 5}
                y={
                  styles.powerGauge.centerY +
                  styles.powerGauge.innerRadius * 0.6 -
                  this.state.coachViewMarginValue
                }
                fill={styles.colors.regularValue}
                fontSize={styles.fonts.medium}
                textAnchor="end"
              >
                {metrics.powerBalance}
              </text>
              <text
                x={styles.powerGauge.centerX}
                y={
                  styles.powerGauge.centerY +
                  styles.powerGauge.innerRadius * 0.6 -
                  this.state.coachViewMarginValue
                }
                fill={styles.colors.regularValue}
                fontSize={styles.fonts.medium}
                textAnchor="middle"
              >
                {"|"}
              </text>
              <text
                x={styles.powerGauge.centerX + 5}
                y={
                  styles.powerGauge.centerY +
                  styles.powerGauge.innerRadius * 0.6 -
                  this.state.coachViewMarginValue
                }
                fill={styles.colors.regularValue}
                fontSize={styles.fonts.medium}
                textAnchor="start"
              >
                {100 - metrics.powerBalance}
              </text>
            </>
          )}
        </g>

        <g id="texts-heart-rate">
          {metrics.heartRate && (
            <>
              <text
                x={styles.powerGauge.centerX}
                y={
                  styles.powerGauge.centerY +
                  styles.powerGauge.innerRadius * 0.9 -
                  this.state.coachViewMarginValue
                }
                fill={styles.colors.regularValue}
                fontSize={styles.fonts.medium}
                textAnchor="middle"
              >
                {"♥"}
                {metrics.heartRate}
              </text>
            </>
          )}
        </g>

        <g id="texts-cadence-value" textAnchor="middle">
          <text
            x={styles.cadenceGauge.centerX}
            y={styles.cadenceGauge.centerY}
            fill={styles.cadenceColor}
            fontSize={styles.fonts.smallMedium}
          >
            {Math.round(metrics.cadence)}
          </text>
          <text
            x={styles.cadenceGauge.centerX}
            y={styles.cadenceGauge.centerY + styles.cadenceGauge.innerRadius * 0.6}
            fill={styles.avgCadenceColor}
            fontSize={styles.fonts.medium}
          >
            {this.props.avgValues
              ? roundStr(this.props.avgValues.cadenceAvg, 1)
              : roundStr(metrics.avgCadence, 1)}
          </text>
        </g>
      </svg>
    );
  }
}

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  typeof MetricsGauge.defaultProps &
  PropsType;

const mapStateToProps = (state: StateInterface) => ({
  status: state.activeTraining.status,
  userWeight: state.user.cpxProfile?.weight,
  timeFromStepStart: getTimeFromStepStart(state),
  timeFromWorkoutStart: getTimeFromWorkoutStart(state),
  timeFromSegmentStart: getTimeFromSegmentStart(state),
  metrics: state.metrics,
  interval: state.activeTraining.currentSteps.currentStep,
  currentCourseStep: state.activeTraining.currentSteps.currentCourseStep,
  nextInterval: state.activeTraining.currentSteps.nextStep,
  nextCourseStep: state.activeTraining.currentSteps.nextCourseStep,
});
const mapDispatchToProps = (_dispatch: Dispatch) => ({
  metricsChanged: (metrics: GaugeMetricsInterface) => metrics,
});
export const UserMetricsGauge = connect(mapStateToProps, mapDispatchToProps)(MetricsGauge);
