import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";
import { strLimit } from "../../../utils/resizeHelper";
import {
  ActiveMemberInterface,
  WorkoutIntervalInterface,
  INTERVAL_TYPE,
} from "../../../redux/types";
import { LEADERBOARD_TITLE } from "..";
import { getAccuracyColor } from "../../../utils/Statistics";
import { getPlaceNumberColor } from "../../../utils/GameScoreCalculating";

type PropsType = {
  item: ActiveMemberInterface;
  place: number;
  userId?: string | null;
  currentStepIndex: number | null;
  currentStep: WorkoutIntervalInterface | null;
  timeFromStepStart: number;
  isPreviousStepRecover: boolean;
  isGameStep: boolean;
  isPreviousStepGame: boolean;
  setTitle: (title: LEADERBOARD_TITLE) => void;
};

export const LeaderboardScoreLine = ({
  item,
  place,
  userId,
  currentStep,
  currentStepIndex,
  timeFromStepStart,
  isPreviousStepRecover,
  isPreviousStepGame,
  isGameStep,
  setTitle,
}: PropsType) => {
  const [scoreLine, setScoreLine] = useState("0% 0.0");
  const [userPlace, setUserPlace] = useState(place);
  const [powerColor, setPowerColor] = useState(getAccuracyColor(null));
  const [cadenceColor, setCadenceColor] = useState(getAccuracyColor(null));
  const containerStyle =
    userId === item.userId
      ? [themedStyles.userScoreContainer, themedStyles.currentUserContainer]
      : themedStyles.userScoreContainer;

  const isUserPlaceVisible =
    isGameStep ||
    (isPreviousStepGame && (currentStep?.durationInSeconds || 0) > 15 && timeFromStepStart < 15);

  const leaderBoardMod = () => {
    if (currentStep?.intervalType !== INTERVAL_TYPE.RECOVER) {
      setUserPlace(place);
      setScoreLine(item.stats.leaderboardScoreLine);
      setPowerColor(
        getAccuracyColor(isUserPlaceVisible ? null : item.stats.accuracyStatus.powerStatusAvg),
      );
      setCadenceColor(
        getAccuracyColor(isUserPlaceVisible ? null : item.stats.accuracyStatus.cadenceStatusAvg),
      );
      setTitle(LEADERBOARD_TITLE.LEADERBOARD);
    } else {
      const avgScoreLine =
        (currentStepIndex && item.metrics[currentStepIndex - 1]?.gameAvgScoreLine) || "";
      typeof avgScoreLine === "string" && setScoreLine(avgScoreLine);
      setUserPlace(place);
      setTitle(LEADERBOARD_TITLE.RESULTS_SUMMARY);
    }
  };

  useEffect(() => {
    if (currentStep?.durationInSeconds) {
      if (currentStepIndex === 0) {
        leaderBoardMod();
      } else if (isPreviousStepRecover) {
        leaderBoardMod();
      } else if (currentStep.durationInSeconds > 15 && timeFromStepStart > 15) {
        leaderBoardMod();
      } else if (isGameStep) {
        leaderBoardMod();
      } else if (currentStep.durationInSeconds < 15) {
        leaderBoardMod();
      } else {
        const avgScoreLine =
          currentStepIndex && currentStepIndex > 0
            ? item.metrics[currentStepIndex - 1]?.gameAvgScoreLine
            : null;
        typeof avgScoreLine === "string" && setScoreLine(avgScoreLine);
        setUserPlace(place);
        setTitle(LEADERBOARD_TITLE.RESULTS_SUMMARY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFromStepStart]);

  const placeNumberColor = getPlaceNumberColor(place, isGameStep);
  const splitScoreLine = [
    scoreLine.split(" ", 1).toString(),
    scoreLine.split(" ").slice(1).join(" "),
  ];
  return (
    <View style={containerStyle}>
      <View style={themedStyles.nameContainer}>
        <DefaultText
          style={
            placeNumberColor
              ? [themedStyles.listText, themedStyles.placeNumberText, { color: placeNumberColor }]
              : [themedStyles.listText, themedStyles.placeNumberText]
          }
        >
          {isUserPlaceVisible && `#${userPlace}`}
        </DefaultText>
        <DefaultText style={themedStyles.listText}>{strLimit(item.nickName, 10)}</DefaultText>
      </View>
      {!isGameStep && splitScoreLine.length > 1 ? (
        <View style={themedStyles.scoreLineContainer}>
          <DefaultText style={[themedStyles.listText, { color: powerColor }]}>
            {`${splitScoreLine[0]} `}
          </DefaultText>
          <DefaultText style={[themedStyles.listText, { color: cadenceColor }]}>
            {splitScoreLine[1]}
          </DefaultText>
        </View>
      ) : (
        <View>
          <DefaultText style={themedStyles.listText}>{scoreLine}</DefaultText>
        </View>
      )}
    </View>
  );
};
