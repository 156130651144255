interface ForcesType {
  gravity: number;
  rolling: number;
  drag: number;
}

// Constants Assumed - works well with the CompuTrainer
const FRONTAL_AREA = 0.509;
const C_DRAG = 0.63;
const C_ROLLING_RESISTANCE = 0.0055;
const AIR_DENSITY = 1.226;
const DRIVETRAIN_LOSS = 0.04;
const G_CONSTANT = 9.8067;
const MPS_TO_KPH = 3.6;

/**
 * Estimate a rider's speed given certain parameter
 * @param {float} power - power in watts
 * @param {flat} kg - rider weight in kg
 * @param {integer} grade - slope of the grade the rider is on
 * @return {float} - speed in km/h
 */
export const EstimateSpeed = (power: number, kg: number, grade: number) => {
  let speed = 0.0;
  speed = CalculateVelocity(power, kg, grade) * MPS_TO_KPH;
  return speed;
};

/**
 * Calculates the force components needed to achieve the given velocity, kg, grade
 * @param {float} velocity - in mps
 * @param {float} kg - rider weight in kg
 * @param {integer} grade - slope of the grade the rider is on
 * @return {object} - gravitation force, rolling force and drag forces
 */
const CalculateForces = (velocity: number, kg: number, grade: number) => {
  let forces: ForcesType = { drag: 0, gravity: 0, rolling: 0 };
  forces.gravity = G_CONSTANT * kg * Math.sin(Math.atan(grade / 100.0));
  forces.rolling = G_CONSTANT * kg * Math.cos(Math.atan(grade / 100.0)) * C_ROLLING_RESISTANCE;
  forces.drag = 0.5 * FRONTAL_AREA * C_DRAG * AIR_DENSITY * velocity * velocity;
  return forces;
};

/**
 * Calculates the power needed to achieve the given velocity, kg, grade
 * @param {float} velocity - in mps
 * @param {float} kg - rider weight in kg
 * @param {integer} grade - slope of the grade the rider is on
 * @return {float} - power required
 */
const CalculatePower = (velocity: number, kg: number, grade: number) => {
  const forces: ForcesType = CalculateForces(velocity, kg, grade);
  const totalforce = forces?.gravity + forces?.rolling + forces?.drag;
  const wheelpower = totalforce * velocity;
  const legpower = wheelpower / (1.0 - DRIVETRAIN_LOSS);
  return legpower;
};

/**
 * Calculates the velocity obtained from a given power, kg, grade
 * Runs a simple midpoint search, using CalculatePower(). Returns velocity, in km/h.
 * @param {float} power - in watts
 * @param {float} kg - rider weight in kg
 * @param {integer} grade - slope of the grade the rider is on
 * @return {float} - velocity in mps
 */
const CalculateVelocity = (power: number, kg: number, grade: number) => {
  // How close to get before finishing
  const epsilon = 0.001;

  // Set starting points for midpoints search
  let lowervel = -1000.0;
  let uppervel = 1000.0;
  let midvel = 0.0;

  let midpow = CalculatePower(midvel, kg, grade);

  /* Iterate until either
   * (1) 100 loops are completed or
   * (2) the calculated power is within epsilon of the given power
   */
  let i = 0;
  do {
    if (Math.abs(midpow - power) < epsilon) {
      break;
    }

    if (midpow > power) {
      uppervel = midvel;
    } else {
      lowervel = midvel;
    }

    midvel = (uppervel + lowervel) / 2.0;
    midpow = CalculatePower(midvel, kg, grade);

    i = i + 1;
  } while (i < 100);

  return midvel;
};
