import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  defaultTextStyle: {
    width: "100%",
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    borderWidth: RFValue(1),
    borderRadius: RFValue(8),
    marginBottom: RFValue(24),
    overflow: "hidden",
  },
});
