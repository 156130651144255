import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: MAIN_THEME.HEADER.BACKGROUND_COLOR,
    padding: RFValue(12),
    maxHeight: "6vh",
  },
  userInfoContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  userPortfolioImg: {
    width: RFValue(40),
    height: RFValue(40),
    marginRight: RFValue(16),
    borderRadius: RFValue(5),
  },
  logoutButton: { backgroundColor: "transparent" },
  logoContainer: {
    flex: 1,
    alignItems: "center",
  },
  logoImg: {
    width: RFValue(320),
    height: RFValue(35),
  },
  controlMenuContainer: {
    flex: 1,
    flexDirection: "row",
  },
  processControlContainer: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
  },
  cp: {
    fontWeight: "bold",
    fontSize: RFValue(29),
  },
  numberParam: {
    fontWeight: "bold",
    fontSize: RFValue(20),
    lineHeight: RFValue(29),
    paddingVertical: RFValue(8),
    color: MAIN_THEME.DEFAULT?.REVERSE_TEXT_COLOR,
    backgroundColor: MAIN_THEME.HEADER.BUTTON_BACKGROUND_COLOR,
    borderRadius: RFValue(5),
    marginLeft: RFValue(16),
    width: RFValue(50),
    textAlign: "center",
  },
  authContainer: { flexDirection: "row", alignItems: "center" },
  userName: { fontSize: RFValue(29) },
  setMaxGradeContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    marginLeft: RFValue(16),
    backgroundColor: MAIN_THEME.HEADER.BUTTON_BACKGROUND_COLOR,
    borderRadius: RFValue(5),
  },
  maxGradeButtonContainer: { marginLeft: 0, paddingVertical: RFValue(4) },
});
