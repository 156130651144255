import { DeviceType } from "../redux/types";
import {
    WheelCircumference,
    UnknownDeviceString,
    SERVICE_UUID_CYCLING_SPEED_AND_CADENCE,
    CHARACTERISTIC_UUID_CSC_MEASUREMENT
} from "./common/BleMeterCommon";
import { SensorBase } from "./common/SensorBase";
import { SpeedCadenceSensorParser } from "./common/SpeedCadenceSensorParser";
import { BleMeter } from "./BleMeter.web";
import { DebugApi } from "../api/api";

export class BleSpeedCadenceSensor extends SensorBase {
    services: BluetoothRemoteGATTService[];
    constructor(
        device: BluetoothDevice,
        server: BluetoothRemoteGATTServer | undefined,
        services: BluetoothRemoteGATTService[]
    ) {
        const parser = new SpeedCadenceSensorParser(WheelCircumference);
        const bleDevice = new BleMeter(
            DeviceType.SpeedCadenceSensor,
            parser,
            device,
            server,
            0
        );
        const deviceName = device.name || UnknownDeviceString;

        super(deviceName, bleDevice);
        this.services = services;
    }

    async SetupCharacteristics() {
        const service = this.services.find(
            (o) => o.uuid === SERVICE_UUID_CYCLING_SPEED_AND_CADENCE);
        const characteristic = await service?.getCharacteristic(CHARACTERISTIC_UUID_CSC_MEASUREMENT);

        if (characteristic) {
            const bleMeterWeb = this.bleDevice as BleMeter;
            bleMeterWeb.notifyCharacteristic = characteristic;
            bleMeterWeb.listen();
        } else {
            console.log("Error: Unable to find characteristic");
            DebugApi.log({
                function: "bleSpeedCadenceSensor SetupCharacteristic",
                message: "Error: Unable to find characteristic",
            });
        }
    }
}
