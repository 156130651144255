import React, { useMemo } from "react";
import { connect } from "react-redux";
import { StateInterface } from "../../redux/reducers";
import { getCurrentWorkoutTime } from "../../redux/selectors";
import { WorkoutIntervalInterface, WorkoutSetInterface } from "../../redux/types";
import { toRelative } from "../../utils/Statistics";
import { getWorkoutImgSegmentColor, getDoteDrawingValue } from "../../utils/SvgHelpers";
import { DefaultWorkoutSvgType, DrawingType } from "../../utils/types";

interface DrawComponentProps {
  sets?: WorkoutSetInterface[];
  step_size: number;
  styles: DefaultWorkoutSvgType;
  max_load: number;
  scale: number;
  drawingObject?: DrawingType;
}

interface SpecialIntervalsDrawingProps {
  step: WorkoutIntervalInterface;
  stepSize: number;
  styles: DefaultWorkoutSvgType;
  x: number;
}

interface PropsType {
  isSplitMode: boolean;
}

const StatisticsBarChartComponent = (props: CombinedProps) => {
  const {
    isTrainingInitialized,
    workoutDuration,
    workoutSteps,
    timeFromWorkoutStart,
    workoutSets,
    dateTime,
    isSplitMode,
  } = props;
  const width = isSplitMode ? 512 : 1024;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoTimeFromWorkoutStart = useMemo(() => timeFromWorkoutStart, [dateTime]);

  const getStyles = () => {
    return {
      width: width,
      height: props.height,
      colors: props.colors,
    };
  };
  if (!isTrainingInitialized) {
    return null;
  }

  const styles = getStyles();
  const viewBox = "0 -15 " + styles.width + " " + (styles.height + 15);
  const secStepSize = styles.width / (workoutDuration || 3600);

  let maxLoad = 0;

  workoutSteps?.map((step) => {
    let power = 0;
    if (!!step.rampUp) {
      power = Math.max(step.rampUp[0], step.rampUp[1]);
    } else {
      power = step.relativePower || 0;
    }

    if (power > maxLoad) {
      maxLoad = power;
    }
    return null;
  });

  const cp20Height = styles.height - toRelative(100, 0, maxLoad, styles.height - 10);
  const pathData = "M0 " + cp20Height + " L" + styles.width + " " + cp20Height;

  const currentPathData =
    "M" +
    memoTimeFromWorkoutStart * secStepSize +
    " 0 L" +
    memoTimeFromWorkoutStart * secStepSize +
    " " +
    styles.height;
  let currentTime = 0;

  return (
    <svg viewBox={viewBox}>
      <path
        d={pathData}
        stroke={styles.colors.defaultWattsLine}
        strokeWidth="1"
        strokeDasharray="4,1"
      />

      <SetsDrawing
        sets={workoutSets}
        step_size={secStepSize}
        styles={styles}
        max_load={maxLoad}
        scale={styles.height - 10}
      />

      {workoutSteps?.map((step) => {
        const x = Math.round(currentTime * secStepSize);
        currentTime += step.durationInSeconds;
        return (
          <SpecialIntervalsDrawing
            key={step.intervalId}
            step={step}
            stepSize={secStepSize}
            styles={styles}
            x={x}
          />
        );
      })}

      <DoteDrawing
        sets={workoutSets}
        step_size={secStepSize}
        styles={styles}
        max_load={maxLoad}
        scale={styles.height - 10}
        drawingObject={DrawingType.Cadence}
      />
      <DoteDrawing
        sets={workoutSets}
        step_size={secStepSize}
        styles={styles}
        max_load={maxLoad}
        scale={styles.height - 10}
        drawingObject={DrawingType.Slope}
      />
      <path d={currentPathData} stroke={styles.colors.yellow} strokeWidth="1" />
    </svg>
  );
};

StatisticsBarChartComponent.defaultProps = {
  colors: {
    primaryColor: "#ffffff",
    yellow: "#FFFF15",
    blue: "#00007f",
    red: "#C01818",
    purple: "#eb0eeb",
    tar: "#696969",
    tiz: "#7f007f",
    spr: "#026902",
    agr: "#ff0000",
    toz: "#f05512",
    kod: "#0080FF",
    kom: "#ffc0cb",
    course: "#b97957",
    hidden: "#9900ff",
    cadenceColor: "#FF00FF",
    defaultWattsLine: "#888",
  },
  gradient: [],

  height: 130,
  margin: 0,
};

const SpecialIntervalsDrawing = (props: SpecialIntervalsDrawingProps) => {
  const { stepSize, styles, step, x } = props;
  const isCourseStep = !!step.courseMovementStatus;

  const width = Math.round(step.durationInSeconds * stepSize);
  const height = isCourseStep ? styles.height / 5 : styles.height;
  const y = isCourseStep ? `${styles.height - height}` : "1";
  const fillOpacity = isCourseStep ? "1" : "0.25";

  const color = getWorkoutImgSegmentColor({ step, styles, isCourseStep });
  if (!color) {
    return null;
  }

  return (
    <rect
      stroke={color}
      strokeWidth="1"
      strokeOpacity="0.6"
      fill={color}
      fillOpacity={fillOpacity}
      width={width}
      height={height}
      rx="1"
      ry="1"
      x={x}
      y={y}
    />
  );
};
const DoteDrawing = (props: DrawComponentProps) => {
  const { sets, step_size: stepSize, styles, max_load: maxLoad, scale, drawingObject } = props;
  const color =
    drawingObject === DrawingType.Cadence ? styles.colors.cadenceColor : styles.colors.red;
  let currentTime = 0;
  return (
    <polygon
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeDasharray="2,1"
      points={
        "0," +
        styles.height +
        " " +
        sets?.map((set) => {
          return set.intervals.map((interval) => {
            const drawingValue = getDoteDrawingValue({ interval, drawingObject });

            let x1 = Math.round(currentTime * stepSize);
            let y1 = styles.height - toRelative(drawingValue, 0, maxLoad, scale);

            currentTime = currentTime + interval.durationInSeconds;
            let x2 = Math.round(currentTime * stepSize);
            let y2 = styles.height - toRelative(drawingValue, 0, maxLoad, scale);

            return x1 + "," + y1 + " " + x2 + "," + y2 + " ";
          });
        }) +
        " " +
        styles.width +
        "," +
        styles.height
      }
    />
  );
};

const SetsDrawing = (props: DrawComponentProps) => {
  const { sets, step_size: stepSize, styles, max_load: maxLoad, scale } = props;

  let currentTime = 0;
  return (
    <polygon
      fill={styles.colors.blue}
      stroke={styles.colors.primaryColor}
      strokeWidth="1"
      points={
        "0," +
        styles.height +
        " " +
        sets?.map((set) => {
          return set.intervals.map((interval) => {
            const x1 = Math.round(currentTime * stepSize);
            const y1 =
              styles.height -
              toRelative(
                !!interval.rampUp
                  ? parseFloat(`${interval.rampUp[0]}`)
                  : parseFloat(`${interval.relativePower}`),
                0,
                maxLoad,
                scale,
              );

            currentTime = currentTime + interval.durationInSeconds;
            const x2 = Math.round(currentTime * stepSize);
            const y2 =
              styles.height -
              toRelative(
                !!interval.rampUp
                  ? parseFloat(`${interval.rampUp[1]}`)
                  : parseFloat(`${interval.relativePower}`),
                0,
                maxLoad,
                scale,
              );

            return x1 + "," + y1 + " " + x2 + "," + y2 + " ";
          });
        }) +
        " " +
        styles.width +
        "," +
        styles.height
      }
    />
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & DefaultWorkoutSvgType & PropsType;
const mapStateToProps = (state: StateInterface) => ({
  workoutDuration: state.activeTraining.plan?.duration,
  isTrainingInitialized: state.activeTraining.isInitialized,
  workoutSteps: state.activeTraining.plan?.steps,
  timeFromWorkoutStart: getCurrentWorkoutTime(state),
  workoutSets: state.activeTraining.plan?.sets,
  dateTime: state.timer.dateTime,
});
export const StatisticsBarChart = connect(mapStateToProps, {})(StatisticsBarChartComponent);
