import React from "react";
import { View } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../index";
import { CourseSegmentStepInterface, INTERVAL_TYPE } from "../../redux/types";
import { MyIcon } from "../CommonElements";
import { ICON } from "../../constants";

export interface CourseSegmentProps {
  segment: CourseSegmentStepInterface;
}

const ICON_SIZE = 16;

export const CourseSegment = ({ segment }: CourseSegmentProps) => {
  const isTar = segment.intervalType === INTERVAL_TYPE.TAR;

  const segmentText =
    segment.shortText.length > 1
      ? `${segment.description} ${segment.start?.toFixed(2)} - ${segment.end?.toFixed(2)}`
      : `${segment.start?.toFixed(2)} - ${segment.end?.toFixed(2)}`;
  return (
    <View style={themedStyles.container}>
      <DefaultText style={themedStyles.text}>{segmentText}</DefaultText>
      {isTar && (
        <View style={themedStyles.container}>
          <MyIcon icon={ICON.LIGHTING} size={ICON_SIZE} />
          <DefaultText style={themedStyles.text}>{segment.relativePower}%</DefaultText>
          <MyIcon icon={ICON.SETTINGS} size={ICON_SIZE} />
          <DefaultText style={themedStyles.text}>{segment.cadence}</DefaultText>
        </View>
      )}
    </View>
  );
};
