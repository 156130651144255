import { DeviceDataPoint } from "../../redux/types";

export const AntPageWindResistance = 50;
export const AntPageControlTrackResistance = 51;
export const AntPageUserConfiguration = 55;
export const AntPageRequestData = 70;

export const CadenceTimeoutMilliseconds = 3000;
export const CrankTimeFractionOfSecond = 1024;

export const WheelTimeoutMilliseconds = 3000;
export const WheelTimeFractionOfSecond = 2048;
export const WheelTimeFractionOfSecondCSC = 1024;

export const SecondsToMinutes = 60;
export const MinutesToHours = 60;
export const MetersToKilometers = 1000;

export const differenceBetweenTwoUInt16Values = (newValue: number, oldValue: number) => {
  let difference = 0;
  if (newValue >= oldValue) {
    // Counter has not rolled over
    difference = newValue - oldValue;
  } else {
    // Counter has rolled over
    difference = 65536 - oldValue + newValue;
  }

  return difference;
};

export const GetFlagBit = (bitIndex: number, data: number) => {
  const mask = 2 ** bitIndex;
  if ((data & mask) === mask) {
    return true;
  }
  return false;
};

export interface IParser {
  GetDataPoint(dataview: DataView): DeviceDataPoint;
}
