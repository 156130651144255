import "firebase/firestore";
import "firebase/auth";
import "./styles/icons";
import "./styles/fonts";
import "./styles/youtube";
import React, { useEffect } from "react";
import { RootNavigation } from "./navigation/RootNavigation.web";
import store from "./redux";
import time from "./utils/Time";
import { AppRegistry, Platform } from "react-native";
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH } from "./constants";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Notification } from "./components";

const isDev = process.env.NODE_ENV === "development";
if (!isDev) {
  Sentry.init({
    dsn: "https://6788882f97824f7e8cefeda76b87cdcb@o484593.ingest.sentry.io/5764785",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export function App() {
  useEffect(() => {
    time.fetchTimeDifWithServer();
  });
  return (
    <Provider store={store}>
      <Auth0Provider
        domain={AUTH.DOMAIN}
        clientId={AUTH.CLIENT_ID}
        redirectUri={window.location.origin}
        audience={AUTH.AUDIENCE}
        scope={AUTH.SCOPE}
        useRefreshTokens={true}
      >
        <RootNavigation />
        <Notification />
      </Auth0Provider>
    </Provider>
  );
}

AppRegistry.registerComponent("PowerWatts", () => App);
if (Platform.OS === "web") {
  AppRegistry.runApplication("PowerWatts", {
    rootTag: document.getElementById("root"),
  });
}
