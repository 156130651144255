/* ------------- Initial State ------------- */

export interface NotificationState {
  notificationsList: NotificationInterface[];
  currentNotificationIndex: number | null;
}

/* ------------- Reducers Actions ------------- */

export interface AddNotificationAction {
  type: string;
  notification: NotificationInterface;
}

/* ------------- State Elements ------------- */

export interface NotificationInterface {
  location: NotificationLocation;
  notificationType: TypesOfNotification;
  description: string;
}

export enum NotificationLocation {
  Auth,
  Device,
  Components,
  CouchSaga,
  TrainingSaga,
  UserSaga,
}

export enum TypesOfNotification {
  Error,
  Info,
  Warning,
  Success,
}
