import { LIMIT_VALUES } from "../constants";
import {
  AdvancedStepMetricsInterface,
  CurrentMetricsInterface,
  SampleInterface,
  SettingsState,
  WorkoutIntervalInterface,
  ACCURACY_STATUS,
  INTERVAL_TYPE,
} from "../redux/types";

interface WorkoutType {
  duration: number;
  sets: {
    index: number;
    intervals: {
      durationInSeconds: number;
      index: number;
      intervalId: number;
      intervalType: string;
      powerWindowLow: number | null;
      powerWindowHigh: number | null;
      cadenceWindowLow: number;
    }[];
  }[];
}

export const DEFAULT_POWER_WINDOW = 30;
export const DEFAULT_CADENCE_WINDOW = 15;
export const DEFAULT_WINDOW_VALUE = 30;
export const DEFAULT_CADENCE_VALUE = 85;
export const MAX_RELATIVE_POWER = 330;
export const MAX_POWER = 250;
export const MAX_CADENCE = 270;
export const MAX_LIMIT_INDICATOR = -1;
export const MIN_LIMIT_INDICATOR = -2;

export const isEmpty = (value: any) => {
  if (typeof value === "undefined") return true;
  switch (value) {
    case "":
    case 0:
    case "0":
    case null:
    case false:
      return true;
    default:
      return false;
  }
};

export function uniqueId() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

export function round(number: any, digits: number) {
  if (typeof number === "string") {
    return parseFloat(parseFloat(number).toFixed(digits));
  } else if (typeof number === "number") {
    return parseFloat(number.toFixed(digits));
  } else {
    return 0;
  }
}

export function roundStr(number: number, digits: number) {
  if (typeof number === "undefined") {
    number = 0;
  }
  return number.toFixed(digits);
}

export function isGameInterval(type: INTERVAL_TYPE | undefined) {
  switch (type) {
    case INTERVAL_TYPE.TIZ:
    case INTERVAL_TYPE.TAZ:
    case INTERVAL_TYPE.TOZ:
    case INTERVAL_TYPE.TAR:
    case INTERVAL_TYPE.AGR:
    case INTERVAL_TYPE.KOM:
    case INTERVAL_TYPE.KOD:
    case INTERVAL_TYPE.SPR:
      return true;
    default:
      return false;
  }
}
export function toSetInterval(workout: WorkoutType, second: number) {
  let accTime = 0;
  let data = {
    setId: null,
    intervalIndex: null,
    intervalId: null,
    secondInInterval: null,
    intervalType: null,
  };
  if (second > workout.duration) {
    return data;
  }
  if (second < 0) {
    return {
      setId: 0,
      intervalIndex: 0,
      intervalId: 0,
      secondInInterval: second,
      intervalType: null,
    };
  }

  for (let set of workout.sets) {
    for (let interval of set.intervals) {
      if (second - accTime < interval.durationInSeconds) {
        return {
          setId: set.index,
          intervalIndex: interval.index,
          intervalId: interval.intervalId,
          secondInInterval: second - accTime,
          intervalType: interval.intervalType,
          powerWindow: interval.powerWindowLow,
          cadenceWindow: interval.cadenceWindowLow,
        };
      }
      accTime += interval.durationInSeconds;
    }
  }
}

export function toRelative(value: number, min: number, max: number, scale = 270) {
  return Math.round(((value - min) / (max - min)) * scale);
}

export function getIntervalCriticalPower(
  userSettings: SettingsState,
  interval: WorkoutIntervalInterface,
) {
  let cp = userSettings.criticalPower;
  // TODO: handle here more cp times !!!!!!!!!
  //let criticalPowerTime = interval.criticalPowerTime ? interval.criticalPowerTime : 20;
  if (cp < 0) {
    console.log(interval);
  }

  return cp;
}

export function convertStepInfoToLoad(
  userSettings: SettingsState,
  stepInfo: WorkoutIntervalInterface,
) {
  let minPower: number = 0;
  let maxPower: number = 0;
  let power: number = 0;
  let cp = userSettings.criticalPower; // getIntervalCriticalPower(userSettings, stepInfo);

  if (stepInfo?.relativePower) {
    power = minPower = maxPower = (stepInfo.relativePower * cp) / 100;
  }
  if (stepInfo.rampUp) {
    power = (((stepInfo.rampUp[0] + stepInfo.rampUp[1]) / 2) * cp) / 100;
    minPower = (stepInfo.rampUp[0] * cp) / 100;
    maxPower =
      ((!isEmpty(stepInfo.rampUp[1]) ? stepInfo.rampUp[1] : MAX_RELATIVE_POWER) * cp) / 100;
  }

  switch (stepInfo.powerWindowLow) {
    case -1:
      minPower = 0;
      maxPower = (stepInfo.relativePower! * cp) / 100;
      break;
    case -2:
      minPower = (stepInfo.relativePower! * cp) / 100;
      maxPower = (MAX_RELATIVE_POWER * cp) / 100;
      break;
    default:
      minPower -= stepInfo.powerWindowLow ? stepInfo.powerWindowLow : DEFAULT_POWER_WINDOW;
      maxPower += stepInfo.powerWindowHigh ? stepInfo.powerWindowHigh : DEFAULT_POWER_WINDOW;
      break;
  }

  let cadence = stepInfo.cadence ? stepInfo.cadence : DEFAULT_CADENCE_VALUE;
  let minCadence = stepInfo.cadenceWindowLow
    ? cadence - stepInfo.cadenceWindowLow
    : cadence - DEFAULT_CADENCE_WINDOW;
  let maxCadence = stepInfo.cadenceWindowHigh
    ? cadence + stepInfo.cadenceWindowHigh
    : cadence + DEFAULT_CADENCE_WINDOW;
  return {
    critical_power: cp,
    power: Math.round(power),
    power_window_low: Math.round(minPower),
    power_window_high: Math.round(maxPower),
    cadence: Math.round(cadence),
    cadence_window_low: Math.round(minCadence),
    cadence_window_high: Math.round(maxCadence),
  };
}

export const accuracyClass = (status: string) => {
  switch (status) {
    case ACCURACY_STATUS.HIGH:
      return "AboveTargetColor";
    case ACCURACY_STATUS.IN_ZONE:
      return "OnTargetColor";
    case ACCURACY_STATUS.LOW:
      return "BelowTargetColor";
    default:
      return "NextTargetColor";
  }
};

export const getAccuracyColor = (status: ACCURACY_STATUS | null) => {
  switch (status) {
    case ACCURACY_STATUS.HIGH:
      return "#C01818";
    case ACCURACY_STATUS.IN_ZONE:
      return "#2F8F00";
    case ACCURACY_STATUS.LOW:
      return "#3595F0";
    default:
      return "#DDDDDD";
  }
};

export const normalizedPower = (samples: SampleInterface[]) => {
  let chunkAvg: number = 0;
  let normalizedPower: number = 0;
  let chunksCount: number = 0;
  let count: number = 0;
  let startTs: number = 0;

  samples.forEach((sample) => {
    if (sample.p !== null) {
      if (startTs === 0) {
        startTs = sample.ts;
      }
      if (sample.ts - startTs < 30000) {
        count++;
        chunkAvg = chunkAvg + (sample.p - chunkAvg) / count;
      } else {
        normalizedPower += Math.pow(chunkAvg, 4);
        chunksCount++;
        count = chunkAvg = 0;
        startTs = 0;
      }
    }
  });
  if (chunkAvg > 0) {
    normalizedPower += Math.pow(chunkAvg, 4);
    chunksCount++;
  }
  normalizedPower = Math.pow(normalizedPower / chunksCount, 0.25);
  return normalizedPower;
};

export const secondsToTime = (timestamp: number) => {
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = timestamp % 60;

  const formatted =
    hours !== 0
      ? [
          hours.toString().padStart(2, "0"),
          minutes.toString().padStart(2, "0"),
          seconds.toString().padStart(2, "0"),
        ].join(":")
      : [minutes.toString().padStart(2, "0"), seconds.toString().padStart(2, "0")].join(":");

  return formatted;
};

export const createAdvancedStepMetric = (
  stepMetric: CurrentMetricsInterface,
  samples: SampleInterface[],
  workoutStepInfo: WorkoutIntervalInterface,
  userSettings: SettingsState,
) => {
  let ptbz: number = 0,
    ptiz: number = 0,
    ptoz: number = 0,
    ctbz: number = 0,
    ctiz: number = 0,
    ctoz: number = 0,
    maxPower: number = 0,
    minPower: number = 0,
    maxCadence: number = 0,
    minCadence: number = 0,
    maxSpeed: number = 0;
  const load = convertStepInfoToLoad(userSettings, workoutStepInfo);

  let np = normalizedPower(samples);
  samples.map((item) => {
    if (item.s !== null && item.s > maxSpeed) {
      maxSpeed = item.s;
    }

    if (item.p !== null) {
      if (maxPower === 0 || item.p > maxPower) {
        maxPower = item.p;
      }
      if (minPower === 0 || item.p < minPower) {
        minPower = item.p;
      }
      if (item.p < load.power_window_low) {
        ptbz++;
      } else if (item.p > load.power_window_high) {
        ptoz++;
      } else {
        ptiz++;
      }
    }

    if (item.c !== null) {
      if (maxCadence === 0 || item.c > maxCadence) {
        maxCadence = item.c;
      }
      if (minCadence === 0 || item.c < minCadence) {
        minCadence = item.c;
      }
      if (item.c < load.cadence_window_low) {
        ctbz++;
      } else if (item.c > load.cadence_window_high) {
        ctoz++;
      } else {
        ctiz++;
      }
    }

    return null;
  });

  let powerStatus = null;
  if (stepMetric.power_avg > load.power_window_high) {
    powerStatus = ACCURACY_STATUS.HIGH;
  } else if (stepMetric.power_avg < load.power_window_low) {
    powerStatus = ACCURACY_STATUS.LOW;
  } else {
    powerStatus = ACCURACY_STATUS.IN_ZONE;
  }
  let cadenceStatus = null;
  if (stepMetric.power_avg > load.power_window_high) {
    cadenceStatus = ACCURACY_STATUS.HIGH;
  } else if (stepMetric.power_avg < load.power_window_low) {
    cadenceStatus = ACCURACY_STATUS.LOW;
  } else {
    cadenceStatus = ACCURACY_STATUS.IN_ZONE;
  }

  const samplesCount = samples.length || 1;
  const powerSamplesCount = samples.filter((sample) => sample.p !== null).length || 1;
  const cadenceSamplesCount = samples.filter((sample) => sample.c !== null).length || 1;
  let pInZonePerc = samplesCount === 0 ? 0 : ptiz / samplesCount;

  let pAvgAcc = 1 - Math.abs(stepMetric.power_avg - load.power) / load.power;
  let cAvgAcc = 1 - Math.abs(stepMetric.cadence_avg - load.cadence) / load.cadence;

  let grade = 0;
  let showGrade = false;
  switch (workoutStepInfo.intervalType) {
    case INTERVAL_TYPE.WARM_UP:
    case INTERVAL_TYPE.RECOVER:
      grade = 0;
      break;
    case INTERVAL_TYPE.ACTIVE_RECOVERY:
    case INTERVAL_TYPE.POWER:
    case INTERVAL_TYPE.POWER_SLOPE:
      grade = pAvgAcc * 100;
      break;
    case INTERVAL_TYPE.TAR:
    case INTERVAL_TYPE.POWER_CADENCE:
      showGrade = true;
      grade = (100 * (pAvgAcc + cAvgAcc)) / 2;
      break;
    case INTERVAL_TYPE.AGR:
      showGrade = true;
      grade = (100 * stepMetric.power_avg) / load.critical_power;
      break;
    case INTERVAL_TYPE.TIZ:
      showGrade = true;
      grade = pInZonePerc * 100 - Math.abs(stepMetric.power_avg - load.power) / 100;
      break;
    default:
      break;
  }
  const advancedStepMetric: AdvancedStepMetricsInterface = {
    start_ts: stepMetric.startTimestamp,
    end_ts: stepMetric.endTimestamp,
    duration: workoutStepInfo.durationInSeconds,
    setId: workoutStepInfo.setId || 0,
    intervalId: workoutStepInfo.intervalId,
    shortText: workoutStepInfo.shortText,
    intervalType: workoutStepInfo.intervalType,
    average_power: round(stepMetric.power_avg, 1),
    relative_power: round((stepMetric.power_avg / load.critical_power) * 100, 1),
    targetPower: load.power,
    targetPowerWindow: Math.abs(load.power - load.power_window_low),
    maxPower: maxPower,
    minPower: minPower,
    minCadence: minCadence,
    maxCadence: maxCadence,
    criticalPower: load.critical_power,
    normalizedPower: np,
    power_accuracy: {
      diff: round(stepMetric.power_avg - load.power, 1),
      tbz: samplesCount === 0 ? 0 : round((ptbz / powerSamplesCount) * 100, 1),
      tiz: samplesCount === 0 ? 0 : round((ptiz / powerSamplesCount) * 100, 1),
      toz: samplesCount === 0 ? 0 : round((ptoz / powerSamplesCount) * 100, 1),
      tot: samplesCount,
      grade: showGrade ? round(grade, 3) : null,
    },
    totalWork: stepMetric.kj,
    average_cadence: round(stepMetric.cadence_avg, 1),
    targetCadence: load.cadence,
    targetCadenceWindow: Math.abs(load.cadence - load.cadence_window_low),
    cadence_accuracy: {
      diff: round(stepMetric.cadence_avg - load.cadence, 1),
      tbz: samplesCount === 0 ? 0 : round((ctbz / cadenceSamplesCount) * 100, 1),
      tiz: samplesCount === 0 ? 0 : round((ctiz / cadenceSamplesCount) * 100, 1),
      toz: samplesCount === 0 ? 0 : round((ctoz / cadenceSamplesCount) * 100, 1),
      tot: samplesCount,
    },
    power_status: powerStatus,
    cadence_status: cadenceStatus,
    grade: round(grade, 3),
    avg_speed: stepMetric.speed_avg,
    distance: stepMetric.distance,
    maxSpeed: maxSpeed,
  };

  return advancedStepMetric;
};

export const getPower = (interval: WorkoutIntervalInterface | null, timeFromStepStart: number) => {
  if (!interval) {
    return 0;
  }
  if (!!interval.rampUp) {
    const startPower = interval.rampUp[0];
    const endPower = interval.rampUp[1];
    const duration = interval.durationInSeconds;
    const timeFromStart = timeFromStepStart;
    return Math.round(startPower + (timeFromStart * (endPower - startPower)) / duration);
  } else {
    return interval.relativePower || 0;
  }
};

export const getCpValueFromTextField = (cpValue: string) => {
  let value = parseInt(cpValue);
  if (!value) {
    value = 0;
  }
  if (value < +LIMIT_VALUES.MIN_CP20M) {
    value = +LIMIT_VALUES.MIN_CP20M;
  }
  if (value > +LIMIT_VALUES.MAX_CP20M) {
    value = +LIMIT_VALUES.MAX_CP20M;
  }
  return +value.toFixed();
};
