import { StyleSheet } from "react-native";
import { DEFAULT_DISPLAY_HEIGHT } from "../../../constants";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "31%",
    borderRadius: 999,
    position: "absolute",
    right: RFValue(4),
    bottom: RFValue(-59),
    overflow: "hidden",
  },
  text: {
    fontSize: RFValue(30, DEFAULT_DISPLAY_HEIGHT),
    marginBottom: 10,
  },
});
