import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  formItemContainer: {
    flexShrink: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: RFValue(12),
  },
  formItemText: { flex: 1, fontSize: RFValue(20) },
  datePicker: { flex: 2 },
  dateText: {
    fontSize: RFValue(20),
    lineHeight: RFValue(20),
    color: MAIN_THEME.DEFAULT?.TEXT_COLOR,
    paddingHorizontal: RFValue(8),
    paddingVertical: RFValue(12),
  },
  dateInput: {
    alignItems: "flex-start",
    borderWidth: RFValue(1),
    borderRadius: RFValue(4),
    color: MAIN_THEME.DEFAULT?.TEXT_COLOR,
    borderColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
    backgroundColor: MAIN_THEME.WELCOME_SCREEN.TRAINING_ITEM_BACKGROUND_COLOR,
    height: RFValue(48),
  },
  errBorder: {
    borderColor: "red",
  },
});
