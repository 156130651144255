import { StyleSheet } from "react-native";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  workoutsListContainer: { flex: 5 },
});
