import { DeviceType } from "../redux/types";
import {
  UnknownDeviceString,
} from "./common/BleMeterCommon";
import { BleDeviceBase } from "./common/BleDeviceBase";
import { IParser } from "./common/BleParserHelpers";

export class BleMeter extends BleDeviceBase {
  device: BluetoothDevice;
  server: BluetoothRemoteGATTServer | undefined;
  service: BluetoothRemoteGATTService | undefined;
  notifyCharacteristic: BluetoothRemoteGATTCharacteristic | undefined;
  controlCharacteristic: BluetoothRemoteGATTCharacteristic | undefined;
  constructor(
    deviceType: DeviceType,
    parser: IParser | null,
    device: BluetoothDevice,
    server: BluetoothRemoteGATTServer | undefined,
    userWeight: number,
  ) {
    const deviceName = device.name || UnknownDeviceString;
    super(deviceName, deviceType, parser, userWeight);

    this.device = device;
    this.server = server;
  }

  public disconnect() {
    if (this.device.gatt?.connected) {
      this.device.gatt?.disconnect();
      return true;
    }
    return false;
  }

  listen() {
    if (!this.isListening) {
      this.notifyCharacteristic?.addEventListener("characteristicvaluechanged", (event: any) => {
        if (this.parser != null) {
          const deviceDataPoint = this.parser.GetDataPoint(event?.target?.value);
          this.dispatch(deviceDataPoint, this.deviceType);
        }
      });
      this.notifyCharacteristic?.startNotifications();

      // If the control characteristic requires responses on write, listen for the events and start notifications.
      if (this.controlCharacteristic?.properties.writeWithoutResponse === false) {
        this.controlCharacteristic?.addEventListener("characteristicvaluechanged", () => {
          // TODO: Check for errors
          //console.log('control update: ', event);
        });
        this.controlCharacteristic?.startNotifications();
      }

      this.isListening = true;
    }
  }
}