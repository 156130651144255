import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { COMMON_STYLES } from "../../themes/commonStyles";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1.5,
    flexDirection: "row",
    minWidth: 250,
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    borderRadius: RFValue(20),
    ...COMMON_STYLES.SHADOW_BOX,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: RFValue(20),
    borderBottomWidth: RFValue(1),
  },
  headerTextContainer: {
    flexDirection: "row",
  },
  columnStep: {
    color: MAIN_THEME.HOME_SCREEN.STEPS_COUNT_TEXT_COLOR,
    marginLeft: RFValue(5),
    fontWeight: "bold",
    fontStyle: "italic",
  },
  headerButtonsContainer: { flexDirection: "row" },
  metricsContainer: {
    flex: 1,
    overflow: "hidden",
    height: "100%",
    borderRadius: RFValue(8),
  },
  leaderboardContainer: {
    flex: 1,
    overflow: "hidden",
    height: "100%",
    borderRadius: RFValue(8),
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(13),
    borderBottomWidth: RFValue(1),
    borderRightWidth: RFValue(1),
  },
  stepsCountContainer: { flexDirection: "row" },
  workoutName: {
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: RFValue(20),
  },

  allSteps: {
    fontStyle: "italic",
    fontSize: RFValue(20),
  },
  currentStep: {
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: RFValue(20),
    color: "green",
    paddingRight: RFValue(8),
  },
});
