import { DebugApi } from "../../api/api";
import { DeviceDataPoint } from "../../redux/types";
import { EstimateSpeed } from "../../utils/SpeedCalculator";
import { GetFlagBit, differenceBetweenTwoUInt16Values, WheelTimeFractionOfSecond, SecondsToMinutes, MinutesToHours, MetersToKilometers, WheelTimeoutMilliseconds, CrankTimeFractionOfSecond, CadenceTimeoutMilliseconds } from "./BleParserHelpers";


export class CyclingPowerMeasurementParser {
  isCrankDataInitialized: boolean;
  lastCrankRevolutions: number;
  lastCrankTime: number;
  lastCrankUpdateTime: number;
  isWheelDataInitialized: boolean;
  lastWheelRevolutions: number;
  lastWheelTime: number;
  lastWheelUpdateTime: number;
  lastPacketTime: number;
  userWeight: number;
  wheelCircumference: number;
  isFirstData: boolean;
  constructor(userWeight: number, wheelCircumference: number) {
    this.userWeight = userWeight;
    this.wheelCircumference = wheelCircumference;

    // Initialize cadence variables
    this.isCrankDataInitialized = false;
    this.lastCrankRevolutions = 0;
    this.lastCrankTime = 0;
    this.lastCrankUpdateTime = new Date().getTime();

    // Initialize wheel (for speed calculation) variables
    this.isWheelDataInitialized = false;
    this.lastWheelRevolutions = 0;
    this.lastWheelTime = 0;
    this.lastWheelUpdateTime = new Date().getTime();

    this.isFirstData = true;
    this.lastPacketTime = new Date().getTime();
  }

  ResetData() {
    // Initialize cadence variables
    this.isCrankDataInitialized = false;
    this.lastCrankRevolutions = 0;
    this.lastCrankTime = 0;
    this.lastCrankUpdateTime = new Date().getTime();

    // Initialize wheel (for speed calculation) variables
    this.isWheelDataInitialized = false;
    this.lastWheelRevolutions = 0;
    this.lastWheelTime = 0;
    this.lastWheelUpdateTime = new Date().getTime();

    this.lastPacketTime = new Date().getTime();
  }

  GetDataPoint(dataview: DataView) {
    let offset = 0;
    const flagBits = dataview.getUint16(offset, true);
    offset += 2;

    const bitPedalPowerBalancePresent = GetFlagBit(0, flagBits);
    const bitPedalPowerBalanceReference = GetFlagBit(1, flagBits);
    const bitAccumulatedTorquePresent = GetFlagBit(2, flagBits);
    const bitAccumulatedTorqueSource = GetFlagBit(3, flagBits);
    const bitWheelRevolutionDataPresent = GetFlagBit(4, flagBits);
    const bitCrankRevolutionDataPresent = GetFlagBit(5, flagBits);

    if (this.isFirstData) {
      this.isFirstData = false;
      let infoText = "Power Meter Information:\r\n";
      infoText += "Pedal Power Balance Present: " + bitPedalPowerBalancePresent + "\r\n";
      infoText +=
        "Pedal Power Balance Reference: " +
        (bitPedalPowerBalanceReference ? "Left" : "Unknown") +
        "\r\n";
      infoText += "Accumulated Torque Present: " + bitAccumulatedTorquePresent + "\r\n";
      infoText +=
        "Accumulated Torque Source: " + (bitAccumulatedTorqueSource ? "crank" : "wheel") + "\r\n";
      infoText += "Wheel Revolution Data Present: " + bitWheelRevolutionDataPresent + "\r\n";
      infoText += "Crank Revolution Data Present: " + bitCrankRevolutionDataPresent + "\r\n";
      console.log(infoText);
      DebugApi.log({
        function: "CyclingPowerMeasurementParser GetDataPoint",
        message: infoText,
      });
    }

    let deviceDataPoint: DeviceDataPoint = {
      cadence: null,
      power: null,
      speed: null,
      powerBalance: null,
      heartRate: null,
    };

    // Power
    const power = dataview.getInt16(offset, true);
    offset += 2;
    deviceDataPoint.power = power;

    // Pedal Power Balance
    if (bitPedalPowerBalancePresent) {
      const pedalPowerBalance = dataview.getUint8(offset);
      offset += 1;
      deviceDataPoint.powerBalance = pedalPowerBalance / 2;
    }

    // Accumulated Torque
    if (bitAccumulatedTorquePresent) {
      //const accumulatedTorque = dataview.getUint16(offset, true) / 32;
      offset += 2;
      //console.log('accumulatedTorque: ', accumulatedTorque);
    }

    // Speed Calculation
    if (bitWheelRevolutionDataPresent) {
      const cumulativeWheelRevolutions = dataview.getUint32(offset, true);
      offset += 4;
      const wheelEventTime = dataview.getUint16(offset, true);
      offset += 2;

      if (this.isWheelDataInitialized) {
        const wheelRevolutions = differenceBetweenTwoUInt16Values(
          cumulativeWheelRevolutions,
          this.lastWheelRevolutions
        );
        const wheelDuration = differenceBetweenTwoUInt16Values(wheelEventTime, this.lastWheelTime) /
          WheelTimeFractionOfSecond;

        // Update the speed if we have a valid duration and atleast one revolution
        if (wheelDuration > 0 && wheelRevolutions > 0) {
          let wheelRpm = (wheelRevolutions / wheelDuration) * SecondsToMinutes;
          deviceDataPoint.speed =
            (wheelRpm * this.wheelCircumference * MinutesToHours) / MetersToKilometers;
          this.lastWheelUpdateTime = new Date().getTime();
        }

        // Calculate the amount of time since speed has been updated and send update if over timeout threshold
        else {
          const numMillisecondsSinceUpdate = new Date().getTime() - this.lastWheelUpdateTime;

          if (numMillisecondsSinceUpdate > WheelTimeoutMilliseconds) {
            deviceDataPoint.speed = 0;

            this.lastWheelUpdateTime = new Date().getTime();
          }
        }
      }

      this.lastWheelRevolutions = cumulativeWheelRevolutions;
      this.lastWheelTime = wheelEventTime;
      this.isWheelDataInitialized = true;
    }

    // Cadence Calculation
    if (bitCrankRevolutionDataPresent) {
      const cumulativeCrankRevolutions = dataview.getUint16(offset, true);
      offset += 2;
      const crankEventTime = dataview.getUint16(offset, true);
      offset += 2;

      if (this.isCrankDataInitialized) {
        const crankRevolutions = differenceBetweenTwoUInt16Values(
          cumulativeCrankRevolutions,
          this.lastCrankRevolutions
        );
        const crankDuration = differenceBetweenTwoUInt16Values(crankEventTime, this.lastCrankTime) /
          CrankTimeFractionOfSecond;

        // Update the cadence if we have a valid duration and atleast one revolution
        if (crankDuration > 0 && crankRevolutions > 0) {
          let rpm = (crankRevolutions / crankDuration) * 60;
          deviceDataPoint.cadence = rpm;

          this.lastCrankUpdateTime = new Date().getTime();
        }

        // Calculate the amount of time since cadence has been updated and send update if over timeout threshold
        else {
          let numMillisecondsSinceUpdate = new Date().getTime() - this.lastCrankUpdateTime;

          if (numMillisecondsSinceUpdate > CadenceTimeoutMilliseconds) {
            deviceDataPoint.cadence = 0;

            this.lastCrankUpdateTime = new Date().getTime();
          }
        }
      }

      this.lastCrankRevolutions = cumulativeCrankRevolutions;
      this.lastCrankTime = crankEventTime;
      this.isCrankDataInitialized = true;
    }

    // Estimate speed based on power if there is no speed data on this device
    if (!this.isWheelDataInitialized) {
      let speed = EstimateSpeed(power, this.userWeight, 0);
      deviceDataPoint.speed = speed;
    }

    var now = new Date().getTime();
    this.lastPacketTime = now;

    //console.log(deviceDataPoint);
    return deviceDataPoint;
  }
}
