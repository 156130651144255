import React, { useCallback, useState, useRef } from "react";
import WebModal from "modal-react-native-web";
import WorkoutCreators from "../../redux/reducers/workoutsReducer";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { TouchableOpacity, View, Modal as RNModal, Platform } from "react-native";
import { connect } from "react-redux";
import { StackParams } from "../../navigation";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { DefaultText, NotesModal } from "../index";
import { Event } from "../../services/googleAnalyticsTracking";

import { Dispatch } from "redux";
import { useHover } from "react-native-web-hooks";
import { MyIcon } from "../CommonElements";
import { TrainingItemButton } from "./TraininigItemButton";
import { ICON } from "../../constants";
import { RFValue } from "../../utils/resizeHelper";

type NavigationProps = StackNavigationProp<StackParams, "Welcome">;
interface PropsType {
  icon: string;
  trainingName: string;
  category?: string;
  virtualCoach?: boolean;
  coachNoteId: string;
  wid?: string;
  withSoloVideoButtons?: boolean;
  supportedLanguages?: string[];
}

const ICON_SIZE = RFValue(20);

function TrainingItemComponent({
  icon,
  trainingName,
  category,
  virtualCoach,
  wid,
  coachNoteId,
  withSoloVideoButtons,
  supportedLanguages,
  getNotes,
}: CombinedProps) {
  const { replace } = useNavigation<NavigationProps>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const workoutRef = useRef<View>(null);
  const isWorkoutHovered = useHover(workoutRef);
  const workoutStyles = isWorkoutHovered
    ? [themedStyles.workoutContainer, themedStyles.hoveredContainer]
    : themedStyles.workoutContainer;

  const Modal = Platform.OS === "web" ? WebModal : RNModal;
  Platform.OS === "web" && Modal.setAppElement("body");

  const onIconPress = useCallback(() => {
    getNotes(coachNoteId);
    setIsModalVisible(true);
    Platform.OS === "web" && Event("view_notes", { wid: coachNoteId, workout_name: trainingName });
  }, [getNotes, coachNoteId, trainingName]);

  const onButtonPress = useCallback(() => {
    replace("VirtualTraining", {
      wid: wid,
      virtualCoach: virtualCoach,
    });
    if (Platform.OS === "web") {
      if (category === "Introduction") {
        Event("click", {
          page: "home",
          component: "IntroductionMessage",
          target: "introduction workout",
          workout_name: trainingName,
        });
      }
      if (virtualCoach) {
        Event("click", {
          target: "coached workout",
          workout_name: trainingName,
        });
      } else {
        Event("click", { target: "solo workout", workout_name: trainingName });
      }
    }
  }, [replace, wid, virtualCoach, category, trainingName]);

  const onSoloPress = useCallback(() => {
    replace("VirtualTraining", {
      wid: wid,
      virtualCoach: false,
    });
  }, [replace, wid]);

  const onLangPress = useCallback(
    (lang: string) => {
      replace("VirtualTraining", {
        wid: wid,
        virtualCoach: true,
        lang,
      });
    },
    [replace, wid],
  );

  const closeNotesModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const renderModals = () => {
    return (
      <>
        {isModalVisible && (
          <Modal animationType="fade" transparent={true} visible={isModalVisible}>
            <NotesModal closeNotesModal={closeNotesModal} />
          </Modal>
        )}
      </>
    );
  };

  return (
    <View style={themedStyles.container}>
      {renderModals()}
      <TouchableOpacity onPress={onButtonPress} style={{ flex: 1 }}>
        <View ref={workoutRef} style={workoutStyles}>
          <TrainingItemButton onPress={onIconPress} tooltip="Notes">
            <MyIcon icon={icon} size={ICON_SIZE} color="white" />
          </TrainingItemButton>
          {withSoloVideoButtons && (
            <View style={themedStyles.soloVideoButtonsContainer}>
              <TrainingItemButton onPress={onSoloPress} tooltip="Ride Solo">
                <MyIcon icon={ICON.METER} size={ICON_SIZE} color="white" />
              </TrainingItemButton>
            </View>
          )}
          {supportedLanguages &&
            virtualCoach &&
            supportedLanguages.sort().map((lang) => (
              <TrainingItemButton
                key={`language-${lang}`}
                onPress={() => onLangPress(lang)}
                tooltip="Ride with Video Coach"
              >
                <DefaultText style={{ lineHeight: ICON_SIZE, fontSize: ICON_SIZE }}>
                  {lang}
                </DefaultText>
              </TrainingItemButton>
            ))}
          <DefaultText style={themedStyles.trainingName} numberOfLines={1}>
            {trainingName}
          </DefaultText>
          <DefaultText style={themedStyles.duration}>{category}</DefaultText>
        </View>
      </TouchableOpacity>
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  PropsType;

const mapStateToProps = (_state: StateInterface) => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getNotes: (notesId: string) => dispatch(WorkoutCreators.getWorkoutNotes(notesId)),
});
export const TrainingItem = connect(mapStateToProps, mapDispatchToProps)(TrainingItemComponent);
