import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    paddingVertical: RFValue(50),
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: RFValue(20),
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
    paddingTop: RFValue(24),
    borderRadius: RFValue(8),
    width: "70%",
  },
  userInfoContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  userPortfolioImg: {
    width: RFValue(40),
    height: RFValue(40),
    marginRight: RFValue(8),
    borderRadius: RFValue(5),
  },
  name: { fontSize: RFValue(24) },
  closeIcon: {
    position: "absolute",
    top: RFValue(10),
    right: RFValue(10),
    backgroundColor: "transparent",
  },
  buttonPanel: {
    flexDirection: "row",
    width: "60%",
    alignSelf: "center",
    justifyContent: "space-between",
    alignItems: "center",
    height: 40,
    marginTop: 10,
  },
  tabsContainer: { height: "85%", paddingBottom: RFValue(5) },
});
