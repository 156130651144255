import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
// import logger from "redux-logger";
import allReducers from "./reducers";
import rootSaga from "./sagas";

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(allReducers, initialState, compose(applyMiddleware(...middleware)));

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export default store;
