import { ITheme } from "./interface";
import { THEME_KEYS } from "../constants";
import { COLORS } from "./colors";

const COMMON_THEME: ITheme = {
  DEFAULT: {
    PRIMARY_BACKGROUND_COLOR: COLORS.COD_GRAY,
    SECONDARY_BACKGROUND_COLOR: COLORS.HEAVY_METAL,
    TEXT_COLOR: COLORS.ALTO,
    REVERSE_TEXT_COLOR: COLORS.COD_GRAY,
    ACTIVE_BUTTON_COLOR: COLORS.JAPANESE_LAUREL,
    INACTIVE_BUTTON_COLOR: COLORS.GRAY,
    CONFORMING_TEXT_COLOR: COLORS.GREEN,
    REJECTING_TEXT_COLOR: COLORS.STARSHIP,
    CONFORMING_BACKGROUND_COLOR: COLORS.SAN_FELIX,
    REJECTING_BACKGROUND_COLOR: COLORS.VERDUN_GREEN,
    PLACEHOLDER_COLOR: COLORS.GRAY,
    INFO_BACKGROUND_COLOR: COLORS.OUTER_SPACE,
  },
  HOME_SCREEN: {
    CENTRAL_TIMER_BACKGROUND_COLOR: COLORS.BLAZE_ORANGE,
    STEPS_COUNT_TEXT_COLOR: COLORS.BLAZE_ORANGE,
    PARAMS_NAME_TEXT_COLOR: COLORS.GRAY,
    CURRENT_STEP_BACKGROUND_COLOR: COLORS.LOG_CABIN,
    NEXT_STEP_BACKGROUND_COLOR: COLORS.BIRCH,
  },
  HEADER: {
    BACKGROUND_COLOR: COLORS.COD_GRAY,
    BUTTON_BACKGROUND_COLOR: COLORS.WHITE,
    BUTTON_ICON_COLOR: COLORS.COD_GRAY,
    ACTIVE_BUTTON_ICON_COLOR: COLORS.WHITE,
    ACTIVE_BUTTON_BACKGROUND_COLOR: COLORS.JAPANESE_LAUREL,
    INACTIVE_BUTTON_BACKGROUND_COLOR: COLORS.GRAY,
    ALTERNATIVE_ICON_COLOR: COLORS.GRAY,
  },
  WELCOME_SCREEN: {
    ACTIVE_TEXT_COLOR: COLORS.GREEN,
    BLE_CONNECTION_INFO_TEXT: COLORS.STARSHIP,
    REMARK_TEXT_COLOR: COLORS.GRAY,
    TRAINING_ITEM_BACKGROUND_COLOR: COLORS.MINE_SHAFT,
  },
  LOGIN_SCREEN: {
    LOGIN_BUTTON_COLOR: COLORS.EUCALYPTUS,
    SIGN_UP_BUTTON_COLOR: COLORS.AZURE_RADIANCE,
  },
  GAUGE: {
    IN_ZONE_COLOR: COLORS.LIMEADE,
    ABOVE_ZONE_COLOR: COLORS.THUNDERBIRD,
    BELOW_ZONE_COLOR: COLORS.PICTON_BLUE,
    INACTIVE_ZONE_COLOR: COLORS.GRAY,
  },
};

export const DARK_THEME: ITheme = {
  _KEY: THEME_KEYS.DARK_THEME,
  DEFAULT: {
    ...COMMON_THEME.DEFAULT,
  },
  HOME_SCREEN: {
    ...COMMON_THEME.HOME_SCREEN,
  },
  HEADER: {
    ...COMMON_THEME.HEADER,
  },
  WELCOME_SCREEN: {
    ...COMMON_THEME.WELCOME_SCREEN,
  },
  LOGIN_SCREEN: { ...COMMON_THEME.LOGIN_SCREEN },
  GAUGE: { ...COMMON_THEME.GAUGE },
};

export const LIGHT_THEME: ITheme = {
  _KEY: THEME_KEYS.DARK_THEME,
  DEFAULT: {
    ...COMMON_THEME.DEFAULT,
  },
  HOME_SCREEN: {
    ...COMMON_THEME.HOME_SCREEN,
  },
  HEADER: {
    ...COMMON_THEME.HEADER,
  },
  WELCOME_SCREEN: {
    ...COMMON_THEME.WELCOME_SCREEN,
  },
  LOGIN_SCREEN: { ...COMMON_THEME.LOGIN_SCREEN },
  GAUGE: { ...COMMON_THEME.GAUGE },
};

export const THEMES = [LIGHT_THEME, DARK_THEME];

export const MAIN_THEME = DARK_THEME;
