import { TThemeKey } from "../themes/interface";

export const API_KEY = "AIzaSyBq9E9yjSjFDVcziorlfwxLRpNVeCcvkSw";

export const AUTH_MOBILE = {
  DOMAIN: "login.powerwatts.com",
  CLIENT_ID: "1xIhvvHlmXy6mWe26Ye4DEWGuyL0xUSl",
};

export const THEME_KEY = "_themeKey";
export const ID_TOKEN_KEY = "@id_token";
export const ACCESS_TOKEN_KEY = "@access_token";
export const REFRESH_TOKEN_KEY = "@refresh_token";

export const THEME_KEYS: { [keyName: string]: TThemeKey } = {
  LIGHT_THEME: "LIGHT_THEME",
  DARK_THEME: "DARK_THEME",
};

export const AUTH = {
  DOMAIN: "login.powerwatts.com",
  CLIENT_ID: "RtQu03CshRAwJzZ7g7MhVkNwdsUn3zx1",
  AUDIENCE: "https://cpx.auth0.com/api/v2/",
  SCOPE: "read:current_user update:current_user_metadata",
};

export const FIREBASE = {
  API_KEY: "AIzaSyDQBtbKX1CtFxiRmw_a9Pd_mSfjvcG2Ih4",
  AUTH_DOMAIN: "powerwatts-36e9a.firebaseapp.com",
  PROJECT_ID: "powerwatts-36e9a",
  APP_ID: "1:540275368814:web:e73978de49776896e9177f",
};

export const CONNECTION_STATUS = {
  CONNECTED: "connected",
  SEARCHING: "searching",
  CONNECTING_DEVICE: "connecting-device",
  CONNECTING_SERVER: "connecting-server",
  CONNECTING_SERVICE: "connecting-service",
  FAILED: "failed",
  NOT_CONNECTED: "No device connected",
};

export const BLE_CONNECTION_REMARK = {
  NOT_CONNECTED: "not connected",
  NO_BLE_SUPPORT: "No BLE Support",
  BLUETOOTH_IS_NOT_SUPPORTED: "Bluetooth is not supported",
  FOUND_DEVICE: "found device",
  FOUND_SERVER: "found server",
  FOUND_SERVICE: "found service",
  FOUND_CHARACTERISTIC: "found characteristic",
  CONNECTED: "connected",
  NO_CONNECTED_DEVICE: "no connected device",
  DISCONNECTED: "disconnected",
  DEVICE_DISCONNECTED: "device disconnected",
};

export const TIMER_STATUS = {
  STARTED: "started",
  STOPPED: "stopped",
  PAUSED: "paused",
  AUTO_PAUSED: "auto-paused",
};

export const BUTTON_TEXT = {
  NOTE: "NOTES",
};

export const ICON = {
  PAUSE: "pause",
  STOP: "stop",
  BLUETOOTH: "bluetooth",
  LIGHTING: "lighting",
  PAD: "pad",
  PLAY: "play",
  SKIP: "skip",
  BIKE: "bike",
  CAST: "cast",
  LOGOUT: "logout",
  SETTINGS: "settings",
  EYE: "eye-slash",
  PLUS: "plus-square",
  STOPWATCH: "stopwatch",
  TARGET: "target",
  ROCKET: "rocket",
  FIRE: "fire",
  LEVEL_DOWN: "level-down",
  LEVEL_UP: "level-up",
  DISPLAY: "display",
  CROSS: "cross",
  RELOAD: "reload",
  STATS_DOTS: "stats-dots",
  KOD: "kod",
  KOM: "kom",
  EMAIL: "email",
  METER: "meter",
  FILM: "film",
  TROPHY: "trophy",
  MEDAL: "medal",
  MEDAL_2: "medal2",
  PROFILE: "profile",
  PASTE: "paste",
  LOCATION_2: "location2",
  HISTORY: "history",
  USER_6: "user6",
  USERS_4: "users4",
  CLIPBOARD_3: "clipboard3",
  QUESTION_4: "question4",
};

export enum BROWSERS {
  CHROME = "Chrome",
  EDGE = "Edge",
}

export enum FETCHING_STATUS {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  PROCESSED = "PROCESSED",
}

export const MAX_IDLE_SECONDS = 60;

export const DEFAULT_DISPLAY_HEIGHT = 1700;

export const LIMIT_VALUES = {
  MIN_HEIGHT: "1.00",
  MAX_HEIGHT: "2.40",
  MIN_WEIGHT: "0.0",
  MAX_WEIGHT: "135.0",
  MIN_CP20M: "0",
  MAX_CP20M: "500",
  MIN_GRADE_MAX: "0",
  MAX_GRADE_MAX: "20",
};

export const INITIAL_METRICS_VALUES = {
  startTimestamp: 0,
  endTimestamp: 0,
  cadence: 0,
  cadence_avg: 0,
  distance: 0,
  kj: 0,
  power: 0,
  power_avg: 0,
  speed: 0,
  speed_avg: 0,
  score: 0,
  loadValues: null,
  currentStepSamples: [],
  accuracyStatus: {
    powerStatus: null,
    cadenceStatus: null,
    powerStatusAvg: null,
    cadenceStatusAvg: null,
  },
  gameAvgScoreLine: null,
  heartRate: 0,
  heartRate_avg: 0,
  heartRate_max: 0,
};

export const INITIAL_AVG_VALUES = {
  powerSum: 0,
  powerCount: 0,
  cadenceSum: 0,
  cadenceCount: 0,
  speedSum: 0,
  speedCount: 0,
  hearRateCount: 0,
  heartRateSum: 0,
};

export const POWER_WATTS_CLASSIC_URL = "https://classic.app.powerwatts.com";
export const SUPPORT_EMAIL = "support@powerwatts.com";

export const LOCAL_STORAGE_ITEMS = {
  USER_SAW_NEW_VERSION_MODAL: "USER_SAW_NEW_VERSION_MODAL",
  INIT_PARAMS: "INIT_PARAMS",
  USER_SAW_ACHIEVEMENT_INFO_MODAL: "USER_SAW_ACHIEVEMENT_INFO_MODAL",
};

export const BEST_EFFORTS_INTERVALS = [5, 10, 20, 30, 60, 120, 180, 300, 600, 1200, 1800, 3600];
