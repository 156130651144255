import React, { useCallback, useEffect, useState } from "react";
import { Linking, Platform, TouchableOpacity, View, Image } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import UserCreators from "../../redux/reducers/userReducer";
import { StackParams } from "../../navigation";
import { StateInterface } from "../../redux/reducers";
import SettingsCreators from "../../redux/reducers/settingsReducer";
import { themedStyles } from "./styles";
import { DefaultText } from "../CommonElements/DefaultText";
import StravaButtonSVG from "../../assets/svg/stravaButton.svg";
import { SVG } from "../../assets";
import {
  NotificationInterface,
  NotificationLocation,
  TypesOfNotification,
} from "../../redux/types";
import NotificationsCreators from "../../redux/reducers/notificationsReducer";

type NavigationProps = StackNavigationProp<StackParams, "Welcome">;

export enum ServiceButtonType {
  Strava,
  TrainingPeaks,
}

type ServiceButtonProps = {
  type: ServiceButtonType;
};

export function ServiceButtonComponent({
  getServicesConnected,
  servicesConnected,
  userToken,
  cp,
  hideUpdateProfileModal,
  type,
  addNotification,
}: CombinedProps) {
  const { replace } = useNavigation<NavigationProps>();
  let isServiceConnected: boolean | null;
  let serviceUrl: string = "";
  let name: string;

  switch (type) {
    case ServiceButtonType.Strava:
      isServiceConnected = servicesConnected.strava.connected;
      serviceUrl = servicesConnected.strava.authorization_url || "";
      name = "Strava";
      break;
    case ServiceButtonType.TrainingPeaks:
      isServiceConnected = servicesConnected.training_peaks.connected;
      serviceUrl = servicesConnected.training_peaks.authorization_url || "";
      name = "Training Peaks";
      break;
  }

  useEffect(() => {
    userToken && getServicesConnected();
  }, [userToken, getServicesConnected]);

  const [criticalPower, setCriticalPower] = useState(cp);

  useEffect(() => {
    if (cp !== criticalPower) {
      setCriticalPower(cp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cp]);

  const onServiceButtonPress = useCallback(() => {
    if (serviceUrl) {
      if (Platform.OS === "web") {
        Linking.openURL(serviceUrl);
        Linking.canOpenURL(serviceUrl).then((supported) => {
          if (supported) {
            Linking.openURL(serviceUrl);
          } else {
            addNotification({
              location: NotificationLocation.Components,
              notificationType: TypesOfNotification.Info,
              description: "Don't know how to open URI: " + serviceUrl,
            });
          }
        });
      } else {
        hideUpdateProfileModal();
        replace("WebView", { url: serviceUrl });
      }
    }
  }, [serviceUrl, hideUpdateProfileModal, replace, addNotification]);

  const renderMobileButton = () => {
    switch (type) {
      case ServiceButtonType.Strava:
        return (
          <TouchableOpacity onPress={onServiceButtonPress} style={themedStyles.stravaButton}>
            <StravaButtonSVG />
          </TouchableOpacity>
        );
      case ServiceButtonType.TrainingPeaks:
        return (
          <TouchableOpacity onPress={onServiceButtonPress} style={themedStyles.trainingPeaksButton}>
            <Image
              source={require("../../assets/jpg/trainingpeaksConnect.jpg")}
              style={themedStyles.trainingPeaksImage}
              resizeMode="contain"
            />
          </TouchableOpacity>
        );
    }
  };

  const renderWebButton = () => {
    switch (type) {
      case ServiceButtonType.Strava:
        return (
          <TouchableOpacity onPress={onServiceButtonPress} style={themedStyles.stravaButton}>
            <img src={SVG.stravaButton} alt="stravaButton" />
          </TouchableOpacity>
        );
      case ServiceButtonType.TrainingPeaks:
        return (
          <TouchableOpacity onPress={onServiceButtonPress} style={themedStyles.trainingPeaksButton}>
            <Image
              source={require("../../assets/jpg/trainingpeaksConnect.jpg")}
              style={themedStyles.trainingPeaksImage}
              resizeMode="contain"
            />
          </TouchableOpacity>
        );
    }
  };

  return (
    <View style={themedStyles.stravaContainer}>
      {isServiceConnected ? (
        <DefaultText style={{ textAlign: "center" }}>{name} already connected</DefaultText>
      ) : (
        <View>{Platform.OS === "web" ? renderWebButton() : renderMobileButton()}</View>
      )}
    </View>
  );
}
type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ServiceButtonProps;

const mapStateToProps = (state: StateInterface) => ({
  userToken: state.user.token,
  servicesConnected: state.settings.servicesConnected,
  cp: state.settings.criticalPower,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getServicesConnected: () => dispatch(SettingsCreators.getServicesConnected()),
  updateCriticalPower: (criticalPower: number) =>
    dispatch(SettingsCreators.updateCriticalPower(criticalPower)),
  hideUpdateProfileModal: () => dispatch(UserCreators.toggleUpdateProfileModal(false)),
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationsCreators.addNotification(notification)),
});
export const ServiceButton = connect(mapStateToProps, mapDispatchToProps)(ServiceButtonComponent);
