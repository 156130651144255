import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";

export interface ISnapshot<T = Object> {
  id: string;
  type: FirebaseFirestoreTypes.DocumentChangeType;
  data: T;
}

export enum SnapshotType {
  Added = "added",
  Modified = "modified",
  Removed = "removed",
}

export type DefaultWorkoutSvgType = {
  colors: {
    primaryColor: string;
    yellow: string;
    blue: string;
    red: string;
    purple: string;
    tar: string;
    tiz: string;
    spr: string;
    agr: string;
    kod: string;
    kom: string;
    course: string;
    hidden: string;
    cadenceColor: string;
    defaultWattsLine: string;
  };
  width: number;
  height: number;
  margin?: number;
  x?: number;
  y?: number;
};

export enum DrawingType {
  Cadence,
  Slope,
}
