import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    marginLeft: RFValue(16),
    backgroundColor: MAIN_THEME.HEADER.BUTTON_BACKGROUND_COLOR,
    borderRadius: RFValue(5),
  },
  buttonContainer: {
    padding: RFValue(10),
  },
  activeButton: {
    backgroundColor: MAIN_THEME.HEADER.ACTIVE_BUTTON_BACKGROUND_COLOR,
  },
  inactiveButton: {
    backgroundColor: MAIN_THEME.HEADER.INACTIVE_BUTTON_BACKGROUND_COLOR,
  },
  buttonContent: {
    color: "white",
  },
  text: {},
});
