import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import { DebugApi } from "../../api/api";

import { NotificationInterface, NotificationState, AddNotificationAction } from "../types";

interface ActionTypesInterface {
  ADD_NOTIFICATION: "ADD_NOTIFICATION";
}

interface ActionCreatorsInterface extends ActionCreators {
  addNotification: (notification: NotificationInterface) => AnyAction;
}

type Handler<A> = (state: NotificationState, action: A) => NotificationState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  addNotification: ["notification"],
});

export const ErrorsHandlingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: NotificationState = {
  notificationsList: [],
  currentNotificationIndex: null,
};

/* ------------- Reducers ------------- */

const addError: Handler<AddNotificationAction> = (state, { notification }) => {
  console.log("!notification", notification);
  DebugApi.log({
    function: "notificationReducers.tsx addError",
    message: notification,
  });
  return {
    ...state,
    notificationsList: [...state.notificationsList, notification],
    currentNotificationIndex:
      state.currentNotificationIndex !== null ? state.currentNotificationIndex + 1 : 0,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.ADD_NOTIFICATION]: addError,
};

export const notificationsReducer = createReducer(INITIAL_STATE, HANDLERS);
