import React from "react";
import { TextInput, View, KeyboardType } from "react-native";
import { DefaultText } from "../../index";
import { themedStyles } from "./styles";
import {
  TextInputMask,
  TextInputMaskOptionProp,
  TextInputMaskTypeProp,
} from "react-native-masked-text";

interface InputItemProps {
  itemText?: string;
  itemValue: string;
  itemName: string;
  handleChange: (name: string) => any;
  handleBlur: (name: string) => any;
  handleFocus?: () => any;
  isValid?: boolean;
  maskType?: TextInputMaskTypeProp;
  maskOptions?: TextInputMaskOptionProp;
  placeholder?: string;
  keyboardType?: KeyboardType;
}

export const InputItem = ({
  itemText,
  itemValue,
  itemName,
  handleChange,
  handleBlur,
  handleFocus,
  isValid,
  maskType,
  maskOptions,
  placeholder,
  ...props
}: InputItemProps) => {
  return (
    <View style={themedStyles.formItemContainer}>
      {itemText && <DefaultText style={themedStyles.formItemText}>{itemText}</DefaultText>}

      <View style={themedStyles.inputHelperBlock}>
        {!maskType || !maskOptions ? (
          <TextInput
            style={[themedStyles.formTextInput, !isValid && themedStyles.errBorder]}
            onChangeText={handleChange(itemName)}
            onBlur={handleBlur(itemName)}
            onFocus={handleFocus}
            value={itemValue}
            placeholder={placeholder}
            {...props}
          />
        ) : (
          <TextInputMask
            type={maskType}
            options={maskOptions}
            style={[themedStyles.formTextInput, !isValid && themedStyles.errBorder]}
            onChangeText={handleChange(itemName)}
            onBlur={handleBlur(itemName)}
            onFocus={handleFocus}
            value={itemValue}
            placeholder={placeholder}
            {...props}
          />
        )}
      </View>
    </View>
  );
};
