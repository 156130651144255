import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: RFValue(16),
    backgroundColor: MAIN_THEME.WELCOME_SCREEN.TRAINING_ITEM_BACKGROUND_COLOR,
    borderRadius: RFValue(8),
    marginBottom: RFValue(8),
  },
  bookedContainer: {
    borderColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
    borderWidth: RFValue(1),
  },
  leftSide: { flexDirection: "row" },
  iconContainer: { marginRight: RFValue(20) },
  trainingName: {
    lineHeight: RFValue(24),
    color: MAIN_THEME.DEFAULT?.PLACEHOLDER_COLOR,
  },
  bookedTrainingName: { color: MAIN_THEME.DEFAULT?.TEXT_COLOR },
  duration: {
    fontSize: RFValue(12),
    lineHeight: RFValue(24),
    color: MAIN_THEME.WELCOME_SCREEN.REMARK_TEXT_COLOR,
  },
  workoutContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  liveTimerContainer: {
    width: RFValue(60),
    alignItems: "flex-end",
  },
});
