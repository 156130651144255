import axios, { AxiosRequestConfig } from "axios";

const instance = axios.create({
  baseURL: `https://booking.powerwatts.co.il/`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const cpxApi = axios.create({
  baseURL: `https://api.powerwatts.com/`,
  headers: {
    "Content-Type": "application/json",
  },
});

const unixTimeApi = axios.create({
  baseURL: `https://worldtimeapi.org/api/ip`,
  headers: {
    "Content-Type": "application/json",
  },
});

const messageApi = axios.create({
  baseURL:
    `https://script.google.com/macros/s/AKfycbyXpPz2hgg98vQnxT5MEFNjfGdzOR2KSO-PbwFJo7UZe7m05QNPaQL-R6NMldFFLgHo/exec` +
    `?key=350e64d0a6b84694b6566972d110fba9`,
  headers: {
    "Content-Type": "text/plain",
  },
});

export const SessionApi = {
  joinSession(wid: string, lang?: string) {
    const language = lang ? "&language=" + lang : "";
    try {
      return instance
        .get(`workout/join?external_id=${wid}&localtime=${Date.now()}&coach_mode=0${language}`)
        .then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
  getConnectionsList() {
    try {
      return instance.get(`workout/connections`).then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
  joinGroupSession(externalId: string) {
    try {
      return instance
        .get(`workout/join?external_id=${externalId}`)
        .then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
  joinZoomSession(externalId: string) {
    try {
      return instance
        .get(`workout/zoomSettings?external_id=${externalId}`)
        .then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
  fetchSession() {
    try {
      return instance
        .get(`workout/sessions?offset=${new Date().getTimezoneOffset()}`)
        .then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
  fetchTrainingPeaksSession() {
    try {
      return instance
        .get(`workout/trainingpeaks/sessions?offset=${new Date().getTimezoneOffset()}`)
        .then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
  getWorkoutByUrl(url: string) {
    try {
      return axios.get(url);
    } catch (error) {
      return error;
    }
  },
  getWorkoutNotes(notesId: string) {
    try {
      return instance.get(`workout/notes?wid=${notesId}`).then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
  getTrainingPlanes() {
    try {
      return instance.get(`workout/trainingplans`).then((Response) => Response.data);
    } catch (error) {
      return error;
    }
  },
};

export const MetricApi = {
  postFinalMetric(workoutId: string, finalJson: string) {
    try {
      return instance.post(`workout/summary?wid=${encodeURI(workoutId)}`, finalJson);
    } catch (error) {
      return error;
    }
  },
  postFinalMetricsActivities(finalJson: string) {
    try {
      return cpxApi.post("activities", finalJson);
    } catch (error) {
      return error;
    }
  },
  getCpxUserProfile() {
    try {
      return cpxApi.get("users");
    } catch (error) {
      return error;
    }
  },
  updateUserProfile(data: string) {
    try {
      return cpxApi.patch("users", data);
    } catch (error) {
      return error;
    }
  },
  updateCp20m(data: string) {
    try {
      return cpxApi.patch("users", data);
    } catch (error) {
      return error;
    }
  },
};

export const CoachApi = {
  changeSessionWorkout(workoutId: string, externalId: string) {
    try {
      return instance.get(
        `workout/changeWorkout?external_id=${externalId}&new_workout=${workoutId}`,
      );
    } catch (error) {
      return error;
    }
  },
  changeUserCp20m(userEmail: string, cp20m: number) {
    const apiConfig: AxiosRequestConfig = {
      headers: {
        email: userEmail,
      },
    };
    const data = JSON.stringify({
      Cp20M: cp20m,
    });

    try {
      return cpxApi.patch("users", data, apiConfig);
    } catch (error) {
      return error;
    }
  },
};

export const SubscriptionApi = {
  getSubscriptions() {
    try {
      return instance.get(`subscriptions/list`);
    } catch (error) {
      return error;
    }
  },
  getBookedClasses() {
    try {
      return instance.get(`subscriptions/bookingList?offset=${new Date().getTimezoneOffset()}`);
    } catch (error) {
      return error;
    }
  },
  getProducts() {
    try {
      return instance.get(`subscriptions/products`);
    } catch (error) {
      return error;
    }
  },
  buyService(purchaseDetails: string) {
    try {
      return instance.post(`subscriptions/purchaseClassPack`, purchaseDetails);
    } catch (error) {
      return error;
    }
  },
  buyContract(purchaseDetails: string) {
    try {
      return instance.post(`subscriptions/purchaseContract`, purchaseDetails);
    } catch (error) {
      return error;
    }
  },
  bookPrivateClass(classId: number) {
    try {
      return instance.post(`subscriptions/bookPrivateClass?external_id=${classId}`);
    } catch (error) {
      return error;
    }
  },
  updateCard(creditCardInfo: string) {
    try {
      return instance.post(`subscriptions/updateCardOnFile`, creditCardInfo);
    } catch (error) {
      return error;
    }
  },
};

export const FirebaseApi = {
  getFirebaseToken() {
    try {
      return cpxApi.get(`firebaseToken`);
    } catch (error) {
      return error;
    }
  },
};

interface TimeResponse {
  unixtime: number;
}

export const TimeApi = {
  async getTime() {
    try {
      return (await unixTimeApi.get<TimeResponse>("")).data.unixtime;
    } catch (error) {
      return 0;
    }
  },
};

export const DebugApi = {
  email: "",
  log(message: any) {
    try {
      return messageApi.post(
        "",
        JSON.stringify({
          email: this.email,
          message: message,
        }),
      );
    } catch (error) {
      return error;
    }
  },
};

export const setBearerToken = (token: string) => {
  instance.defaults.headers["X-CPX-Authorization"] = "Bearer " + token;
  cpxApi.defaults.headers.Authorization = "Bearer " + token;
};

export const setEmailToApiHeader = (email: string) => {
  cpxApi.defaults.headers.email = email;
  DebugApi.email = email;
};
