import { DeviceType } from "../redux/types";
import {
    UnknownDeviceString,
    SERVICE_UUID_HEART_RATE,
    CHARACTERISTIC_UUID_HEART_RATE_MEASUREMENT
} from "./common/BleMeterCommon";
import { SensorBase } from "./common/SensorBase";
import { HeartRateMonitorParser } from "./common/HeartRateMonitorParser";
import { BleMeter } from "./BleMeter.web";
import { DebugApi } from "../api/api";

export class BleHeartRateMonitor extends SensorBase {
    services: BluetoothRemoteGATTService[];
    constructor(
        device: BluetoothDevice,
        server: BluetoothRemoteGATTServer | undefined,
        services: BluetoothRemoteGATTService[]
    ) {
        const parser = new HeartRateMonitorParser();
        const bleDevice = new BleMeter(
            DeviceType.HeartRateMeterDevice,
            parser,
            device,
            server,
            0
        );
        const deviceName = device.name || UnknownDeviceString;

        super(deviceName, bleDevice);
        this.services = services;
    }

    async SetupCharacteristics() {
        const service = this.services.find(
            (o) => o.uuid === SERVICE_UUID_HEART_RATE);
        const characteristic = await service?.getCharacteristic(
            CHARACTERISTIC_UUID_HEART_RATE_MEASUREMENT
        );

        if (characteristic) {
            const bleMeterWeb = this.bleDevice as BleMeter;
            bleMeterWeb.notifyCharacteristic = characteristic;
            bleMeterWeb.listen();
        } else {
            console.log("Error: Unable to find characteristic");
            DebugApi.log({
                function: "bleHeartRateMonitor SetupCharacteristic",
                message: "Error: Unable to find characteristic",
            });
        }
    }
}
