import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  minMapContainer: {
    flex: 1,
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    padding: RFValue(5),
    borderRadius: RFValue(20),
    overflow: "hidden",
  },
  fullCourseContainer: {
    flex: 1,
  },
  minMapTextContainer: {
    position: "absolute",
    right: 0,
    left: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: RFValue(5),
  },
  minimapGradeText: {
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    fontSize: RFValue(27),
    borderRadius: RFValue(20),
  },
  minMapCurrenGradeText: {
    color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR,
  },
  minMapNextGradeText: {
    color: MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR,
  },
});
