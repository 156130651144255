/* ------------- Initial State ------------- */

import { COURSE_MOVEMENT_TYPE } from ".";
import { AccuracyStatusInterface } from "./coachControlsTypes";
import { TRAINING_TYPES } from "./trainingDetailsTypes";

export interface MetricsState {
  savedDistance: number;
  currentMetrics: CurrentMetricsInterface;
  currentSegmentMetrics: CurrentMetricsInterface;
  stepsMetricHistory: CurrentMetricsInterface[];
  segmentsMetricHistory: SegmentsMetricHistoryInterface[];
  advancedStepsMetrics: AdvancedStepMetricsInterface[];
  currentAvgValues: MetricsAvgInterface;
  currentSegmentAvgValues: MetricsAvgInterface;
  workoutAvgValues: MetricsAvgInterface;
  workoutMetrics: WorkoutMetricsInterface;
  setMetrics: SetMetricsInterface;
  samples: SampleInterface[];
  powerSamples: PowerSampleInterface[];
  newRecords: RecordType;
}

/* ------------- Reducers Actions ------------- */

export interface SetCurrentPowerMetricsAction {
  type: string;
  currentPowerMetrics: CurrentPowerMetricsInterface;
}

export interface SetCurrentCadenceMetricsAction {
  type: string;
  currentCadenceMetrics: CurrentCadenceMetricsInterface;
}

export interface SetCurrentHeartRateMetricsAction {
  type: string;
  currentHeartRateMetrics: CurrentHeartRateInterface;
}

export interface SetCurrentSpeedMetricsAction {
  type: string;
  currentSpeedMetrics: CurrentSpeedMetricsInterface;
}

export interface SetStepsMetricHistoryAction {
  type: string;
  advancedStepMetrics: AdvancedStepMetricsInterface;
  gameAvgScoreLine: string;
  timestamp: number;
  courseId?: string;
  courseMovementStatus?: COURSE_MOVEMENT_TYPE;
}

export interface ResetMetricsAction {
  type: string;
}

export interface StreamStatsAction {
  type: string;
  trainingType: TRAINING_TYPES | null;
}

export interface SetSampleAction {
  type: string;
  sample: SampleInterface;
}

export interface SetFirstStepTimestampAction {
  type: string;
  timestamp: number;
}

export interface SetCurrentLoadValuesAction {
  type: string;
  loadValues: CurrentLoadValuesInterface;
}

export interface SetLeaderboardScoreLineAction {
  type: string;
  leaderboardScoreLine: string;
}

export interface SetElevationAction {
  type: string;
  elevation: number;
}

export interface SetSegmentsMetricHistoryAction {
  type: string;
  courseId: string;
  segmentMetricHistory: CurrentMetricsInterface;
  gameAvgScoreLine: string;
  timestamp: number;
}

/* ------------- State Elements ------------- */

export interface CurrentMetricsInterface {
  startTimestamp: number;
  endTimestamp: number;
  cadence: number;
  cadence_avg: number;
  distance: number;
  kj: number;
  power: number;
  power_avg: number;
  speed: number;
  speed_avg: number;
  score: number;
  loadValues: CurrentLoadValuesInterface | null;
  currentStepSamples: SampleInterface[];
  accuracyStatus: AccuracyStatusInterface;
  gameAvgScoreLine: string | null;
  heartRate: number;
  heartRate_avg: number;
  heartRate_max: number;
}

export interface IntervalMetricsInterface {
  cadence: number;
  cadence_samples: number;
  endTs: number | null;
  intervalId: number;
  power: number;
  power_samples: number;
  setId: number;
  startTs: number;
}

export interface AdvancedStepMetricsInterface {
  average_cadence: number;
  average_power: number;
  avg_speed: number;
  cadence_accuracy: {
    diff: number;
    tbz: number;
    tiz: number;
    tot: number;
    toz: number;
  };
  cadence_status: string;
  criticalPower: number;
  distance: number;
  duration: number;
  end_ts: number;
  grade: number;
  intervalId: string;
  intervalType: string;
  maxCadence: number;
  maxPower: number;
  maxSpeed: number;
  minCadence: number;
  minPower: number;
  normalizedPower: number;
  power_accuracy: {
    diff: number;
    grade: number | null;
    tbz: number;
    tiz: number;
    tot: number;
    toz: number;
  };
  power_status: string;
  relative_power: number;
  setId: number;
  shortText: string;
  start_ts: number;
  targetCadence: number;
  targetCadenceWindow: number;
  targetPower: number;
  targetPowerWindow: number;
  totalWork: number;
}

export interface MetricsSetInterface {
  cadence: number;
  cadence_samples: number;
  endTs: number | null;
  power: number;
  power_samples: number;
  setId?: number;
  startTs?: number;
}

export interface MetricsAvgInterface {
  powerSum: number;
  powerCount: number;
  cadenceSum: number;
  cadenceCount: number;
  speedSum: number;
  speedCount: number;
  hearRateCount: number;
  heartRateSum: number;
}

export interface SetMetricsInterface {
  last_sample_time: number | null;
  distance: number;
}

export interface WorkoutMetricsInterface {
  avg_watts: number;
  distance: number;
  kj: number;
  speed: number;
  speed_avg: number;
  elevation: number;
  score: number;
  leaderboardScoreLine: string;
}

export interface CurrentPowerMetricsInterface {
  power: number;
  timeFromWorkoutStart: number;
  timeFromUserConnection: number;
  timeFromStepStart: number;
  timeFromSegmentStart: number;
  currentStepScore: number;
  currentSegmentScore: number;
  totalScore: number;
  powerStatus: ACCURACY_STATUS | null;
  powerStatusAvg: ACCURACY_STATUS | null;
  newRecords: RecordType;
}

export interface CurrentCadenceMetricsInterface {
  cadence: number;
  cadenceStatus: ACCURACY_STATUS;
  cadenceStatusAvg: ACCURACY_STATUS;
}

export interface CurrentHeartRateInterface {
  heartRate: number;
}

export interface CurrentSpeedMetricsInterface {
  speed: number;
  timeFromWorkoutStart: number;
  timeFromUserConnection: number;
  courseMovementStatus: COURSE_MOVEMENT_TYPE | null;
}

export interface DeviceDataPoint {
  power: number | null;
  cadence: number | null;
  powerBalance: number | null;
  speed: number | null;
  heartRate: number | null;
  isStatsHidden?: boolean | null;
}

export interface SampleInterface {
  c: number | null;
  p: number | null;
  s: number | null;
  b: number | null;
  h: number | null;
  tc: number | null;
  tp: number | null;
  e: number | null;
  g: number | null;
  ts: number;
}

export interface PowerSampleInterface {
  power: number;
  timeFromWorkoutStart: number;
}

export interface finalStepMetricInterface {
  Step: number;
  ResultString: string;
  AveragePower: number;
  WattsAvg: number;
  WattsMax: number;
  WattsNorm: number;
  TargetPower: number;
  TargetPowerWindow: number;
  CadenceAvg: number;
  CadenceMax: number;
  CadenceMin: number;
  CPX: number;
  IntervalTypeString: string;
  DistanceKMs: number;
  Rank: number | null;
  // !isGameInterval(interval.intervalType) ||
  // userMetrics === null ||
  // typeof userMetrics === "undefined"
  //   ? null
  //   : userMetrics.rank,
  Points: number | null;
  SpeedAvg: number;
  SpeedMax: number;
  TargetCAD: number;
  TargetCADWindow: number;
  MilesTraveled: number;
  NumberOfSecondsInTarget: number;
  NumberOfSecondsInOrAboveTarget: number;
  NumberOfSecondsInOrBelowTarget: number;
  WpKgInterval: number | null;
  WPrimePercentBalance_Start: number | null;
  WPrimePercentBalance_End: number | null;
  TimeInMinutesAtInterval_START: number;
  TimeInMinutesAtInterval_END: number;
}

export interface CurrentLoadValuesInterface {
  loadPower: number;
  loadPowerWindowLow: number;
  loadPowerWindowHigh: number;
  loadWatts: number;
  loadWattsWindowLow: number;
  loadWattsWindowHigh: number;
  loadCadence: number;
  loadCadenceWindowLow: number;
  loadCadenceWindowHigh: number;
}

export interface SegmentsMetricHistoryInterface {
  courseId: string;
  segmentMetricHistory: CurrentMetricsInterface[];
  stepMetricHistory: CurrentMetricsInterface[];
}

export enum ACCURACY_STATUS {
  LOW = "low",
  IN_ZONE = "in_zone",
  HIGH = "high",
}

export type RecordObjectType = {
  rank: number;
  timeFrame: number;
  intervalDuration: number;
  power: number;
};

export type RecordType = Array<RecordObjectType>;
