import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";

export const themedStyles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: 30,
    zIndex: 999,
    paddingHorizontal: 5,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  text: {
    color: MAIN_THEME.DEFAULT?.TEXT_COLOR,
  },
});
