import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    paddingVertical: RFValue(100),
  },
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
    padding: RFValue(24),
    borderRadius: RFValue(8),
  },
  button: {
    width: "max-content",
    marginTop: RFValue(16),
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(8),
    borderRadius: RFValue(4),
  },
  buttonText: {
    fontWeight: "500",
    fontSize: RFValue(16),
    lineHeight: RFValue(24),
  },
  titleContainer: { marginBottom: RFValue(15) },
  title: { fontSize: RFValue(28), lineHeight: RFValue(32) },
  contentContainer: {},
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
});
