import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { COMMON_STYLES } from "../../themes/commonStyles";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1.5,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    overflow: "hidden",
    borderRadius: 20,
    marginRight: 20,
    ...COMMON_STYLES.SHADOW_BOX,
  },
  appYouTube: {
    width: "100%",
    height: "100%",
  },
  helperOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
});
