import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

const containerPreset = {
  backgroundColor: MAIN_THEME.WELCOME_SCREEN.TRAINING_ITEM_BACKGROUND_COLOR,
  borderRadius: RFValue(8),
  marginBottom: RFValue(8),
};

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    ...containerPreset,
  },
  leftSide: { flexDirection: "row" },
  iconContainer: {
    padding: RFValue(8),
    backgroundColor: MAIN_THEME.DEFAULT?.PLACEHOLDER_COLOR,
    borderRadius: RFValue(8),
    marginHorizontal: RFValue(5),
  },
  trainingName: {
    lineHeight: RFValue(24),
    flex: 1,
    paddingRight: RFValue(5),
  },
  duration: {
    fontSize: RFValue(12),
    lineHeight: RFValue(24),
    color: MAIN_THEME.WELCOME_SCREEN.REMARK_TEXT_COLOR,
  },
  workoutContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: RFValue(8),
  },
  liveTimerContainer: {
    width: RFValue(60),
    alignItems: "flex-end",
  },
  hoveredContainer: { backgroundColor: MAIN_THEME.DEFAULT?.CONFORMING_BACKGROUND_COLOR },
  hoveredButtonContainer: { backgroundColor: MAIN_THEME.DEFAULT?.REJECTING_BACKGROUND_COLOR },
  buttonText: { lineHeight: RFValue(24) },
  soloVideoButtonsContainer: {
    flexDirection: "row",
  },
});
