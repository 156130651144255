import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import { ToolTipState, ShowToolTipAction, HideToolTipAction } from "../../redux/types/toolTipTypes";

interface ActionTypesInterface {
  SHOW: "SHOW";
  HIDE: "HIDE";
}

interface ActionCreatorsInterface extends ActionCreators {
  show: (x: number, y: number, text: string) => AnyAction;
  hide: () => AnyAction;
}

type Handler<A> = (state: ToolTipState, action: A) => ToolTipState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  show: ["x", "y", "text"],
  hide: null,
});

export const StoolTipTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ToolTipState = {
  x: 0,
  y: 0,
  isVisible: false,
  text: "",
};

/* ------------- Reducers ------------- */

const showTooltip: Handler<ShowToolTipAction> = (state, { x, y, text }) => {
  return {
    ...state,
    x,
    y,
    isVisible: true,
    text: text,
  };
};

const hideTooltip: Handler<HideToolTipAction> = (state) => {
  return {
    ...state,
    isVisible: false,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SHOW]: showTooltip,
  [Types.HIDE]: hideTooltip,
};

export const toolTipReducer = createReducer(INITIAL_STATE, HANDLERS);
