import React from "react";
import { ActivityIndicator, TouchableOpacity, View, ViewStyle } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { DefaultText } from "../../CommonElements";
import { themedStyles } from "../styles";

interface PropsType {
  tabsComponentArr: { title: string; component: JSX.Element }[];
  defaultActiveTabIndex?: number;
  tabIndex?: number;
  style?: {
    container?: ViewStyle | ViewStyle[] | [ViewStyle, undefined];
  };
  setTabIndex: (index: number) => void;
  isLoading?: boolean;
}

export function TabsSelectComponent({
  tabsComponentArr,
  tabIndex = 0,
  style: propStyle = {},
  setTabIndex,
  isLoading,
}: PropsType) {
  return (
    <View style={[themedStyles.contentContainer, propStyle.container]}>
      <View style={themedStyles.headerTabsMenu}>
        {tabsComponentArr.map((tabComponent, index) => (
          <TouchableOpacity
            key={`profile-title-${index}`}
            onPress={() => setTabIndex(index)}
            style={[
              themedStyles.tabContainer,
              tabIndex === index ? themedStyles.activeTabContainer : {},
            ]}
          >
            <DefaultText
              style={[themedStyles.tabText, tabIndex === index ? themedStyles.activeTabText : {}]}
            >
              {tabComponent.title}
            </DefaultText>
          </TouchableOpacity>
        ))}
      </View>
      {isLoading ? (
        <View style={themedStyles.loaderContainer}>
          <ActivityIndicator size="large" color={MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR} />
        </View>
      ) : (
        tabsComponentArr[tabIndex].component
      )}
    </View>
  );
}
