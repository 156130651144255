import { StyleSheet } from "react-native";
import { GAUGE_INFO_CONTAINER_WIDTH } from "../../components/StatisticInfo/styles.web";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

const SOLO_CONTENT_MARGIN = "20vw";

export const themedStyles = StyleSheet.create({
  contentContainer: {
    flex: 2,
    height: "65vh",
    flexDirection: "row",
    padding: 5,
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
  },
  statisticsBarContainer: {
    flexDirection: "row",
    height: "20vh",
    justifyContent: "center",
  },
  container: { maxHeight: "100vh" },
  soloContentContainer: { marginHorizontal: SOLO_CONTENT_MARGIN },
  statsContainerFullWidth: {},
  courseContainer: {
    flex: 1,
    marginRight: RFValue(20),
    alignItems: "flex-end",
  },
  barChartContainer: { flex: 1 },
  barChartWithCourseContainer: { alignItems: "flex-start" },
  courseMinimapContainer: {
    marginLeft: SOLO_CONTENT_MARGIN,
    maxWidth: GAUGE_INFO_CONTAINER_WIDTH,
    alignItems: "stretch",
  },
});
