import React from "react";
import { View } from "react-native";
import { themedStyles } from "./styles";

import { SVG } from "../../assets";

export function Preloader() {
  return (
    <View style={themedStyles.container}>
      <img src={SVG.loading} alt="Loading" />
    </View>
  );
}
