import { StyleSheet, Dimensions } from "react-native";
import { MAIN_THEME } from "../../themes";
import { COMMON_STYLES } from "../../themes/commonStyles";
import { RFValue } from "../../utils/resizeHelper";

const { width } = Dimensions.get("window");

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    borderRadius: RFValue(20),
    overflow: "hidden",
    ...COMMON_STYLES.SHADOW_BOX,
  },
  metricsContainer: { flexDirection: "row", height: "100%" },
  stepsContainer: { width: "max-content" },
  listItemContainer: {
    padding: RFValue(6),
    borderWidth: RFValue(1),
    borderTopWidth: RFValue(0),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: RFValue(40),
    minHeight: RFValue(40),
  },
  secondListItemContainer: {
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
  },
  activeMembersContainer: { width: "max-content", flexDirection: "row" },
  stepMetricsText: {
    fontSize: RFValue(25),
  },
  cellContainer: { flexDirection: "row" },
  scrollHelper: {
    flexDirection: "row",
    height: RFValue(40),
  },
  firstCell: {
    width: width * 0.2,
  },
  webCell: {
    width: RFValue(150),
  },
  webCellHided: { height: RFValue(52) },
  activeCell: {
    backgroundColor: MAIN_THEME.HOME_SCREEN.CURRENT_STEP_BACKGROUND_COLOR,
  },
  checkBox: {},
  text: {
    marginRight: RFValue(10),
  },
  header: {
    borderBottomWidth: RFValue(1),
  },
  scoreLineText: { fontSize: RFValue(14) },
});
