import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    borderRadius: RFValue(20),
  },

  metricLineContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: RFValue(10),
  },
  metricText: {
    fontSize: RFValue(24),
    lineHeight: RFValue(30),
    paddingRight: RFValue(10),
  },
  metricValue: { color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR },
});
