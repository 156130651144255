import React, { useCallback } from "react";
import ActiveTrainingCreators from "../../../redux/reducers/activeTrainingReducer";
import { FlatList, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackParams } from "../../../navigation";
import { StateInterface } from "../../../redux/reducers";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";

type NavigationProps = StackNavigationProp<StackParams, "VirtualTraining">;

function FinalTrainingModalComponent({ workoutMetrics, finishTraining }: CombinedProps) {
  const { replace } = useNavigation<NavigationProps>();

  const finalMetricArr = [
    {
      label: "Average power:",
      value: `${workoutMetrics.avg_watts.toFixed()} W`,
    },
    { label: "Distance:", value: `${workoutMetrics.distance.toFixed(2)} Km` },
    { label: "Total work:", value: `${workoutMetrics.kj.toFixed()} kj` },
    {
      label: "Average speed:",
      value: `${workoutMetrics.speed_avg.toFixed(1)} Km/h`,
    },
    {
      label: "Total Score:",
      value: `${workoutMetrics.score.toFixed()}`,
    },
  ];

  const onSavePress = useCallback(() => {
    finishTraining();
    replace("Welcome");
  }, [finishTraining, replace]);
  const onCancelPress = useCallback(() => {
    finishTraining();
    replace("Welcome");
  }, [finishTraining, replace]);

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.modalContainer}>
        <DefaultText style={themedStyles.title}>You finished workout.</DefaultText>
        <View style={themedStyles.finalMetricsContainer}>
          <FlatList
            keyExtractor={(item) => item.label}
            data={finalMetricArr}
            renderItem={({ item }) => (
              <View style={themedStyles.metricLineContainer}>
                <DefaultText style={themedStyles.metricText}>{item.label}</DefaultText>
                <DefaultText style={[themedStyles.metricText, themedStyles.metricValue]}>
                  {item.value}
                </DefaultText>
              </View>
            )}
          />
        </View>
        <DefaultText>
          Do you want to save statistics of the current session in your profile?
        </DefaultText>
        <View style={themedStyles.buttonsContainer}>
          <TouchableOpacity style={themedStyles.buttonTouchable} onPress={onCancelPress}>
            <DefaultText>Cancel</DefaultText>
          </TouchableOpacity>
          <TouchableOpacity
            style={[themedStyles.buttonTouchable, themedStyles.saveButton]}
            onPress={onSavePress}
          >
            <DefaultText>Save</DefaultText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  workoutMetrics: state.metrics.workoutMetrics,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  finishTraining: () => dispatch(ActiveTrainingCreators.finishTraining()),
});

export const FinalTrainingModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinalTrainingModalComponent);
