import { StyleSheet } from "react-native";
import { RFValue } from "../../../utils/resizeHelper";
// import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  achievementContainer: { flex: 1, justifyContent: "center", alignItems: "center" },
  iconContainer: { justifyContent: "center", alignItems: "center" },
  smallText: { fontSize: RFValue(14) },
  mediumText: { fontSize: RFValue(18) },
});
