import moment from "moment";
import React from "react";
import { View } from "react-native";
import { DefaultText, MyIcon } from "../..";
import { ICON } from "../../../constants";
import { RecordObjectType } from "../../../redux/types";
import { COLORS } from "../../../themes/colors";
import { RFValue } from "../../../utils/resizeHelper";
import { themedStyles } from "./styles";

type Props = {
  record?: RecordObjectType;
  achievementCount?: number;
};

const getAchievementIcon = (timeFrame: number) => {
  switch (timeFrame) {
    case 0:
      return ICON.TROPHY;
    case 1:
      return ICON.MEDAL_2;
    case 2:
      return ICON.MEDAL;

    default:
      return ICON.MEDAL;
  }
};
const getAchievementColor = (rank: number) => {
  switch (rank) {
    case 1:
      return COLORS.GOLD;
    case 2:
      return COLORS.SILVER;
    case 3:
      return COLORS.RAW_SIENNA;

    default:
      return COLORS.ALTO;
  }
};

export function Achievement({ record, achievementCount = 0 }: Props) {
  if (!record) return null;
  const iconSize = achievementCount > 9 ? RFValue(28) : RFValue(36);
  const textStyle = achievementCount > 9 ? themedStyles.smallText : themedStyles.mediumText;

  const achievementIcon = getAchievementIcon(record.timeFrame);
  const achievementColor = getAchievementColor(record.rank);
  const timeDuration =
    record.intervalDuration < 60
      ? `${record.intervalDuration}s`
      : moment.utc(record.intervalDuration * 1000).format("m") + "m";
  return (
    <View style={themedStyles.achievementContainer}>
      <View style={themedStyles.iconContainer}>
        <MyIcon icon={achievementIcon} color={achievementColor} size={iconSize} />
        <DefaultText style={textStyle}>{timeDuration}</DefaultText>
      </View>
      <DefaultText style={textStyle}>{record.power.toFixed()}w</DefaultText>
    </View>
  );
}
