import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";

export const themedStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderRadius: 8,
    backgroundColor: MAIN_THEME.DEFAULT?.ACTIVE_BUTTON_COLOR,
  },
  text: {
    fontSize: 16,
  },
  disabled: { backgroundColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR },
});
