/* ------------- Initial State ------------- */

export interface SubscriptionState {
  hasActiveMembership: boolean;
  isTrialAccount: boolean;
  isUpdateInProgress: boolean;
  isPaymentInProgress: boolean;
  isUpdateSuccess: boolean;
  isPaymentSuccess: boolean;
  productsList: ProductInterface[] | null;
  subscriptions: SubscriptionInterface[] | null;
  bookingsList: BookingInterface[] | null;
  selectedProduct: ProductInterface | null;
  accountStatus: ACCOUNT_STATUS | null;
  paymentErrorMessage: string | null;
  updateErrorMessage: string | null;
  paymentSuccessMessage: string | null;
  updateSuccessMessage: string | null;
  isNotBookedModalVisible: boolean;
  modalExternalId?: string;
  ccExpirationDate: string | null;
  monthsWhileCardValid: number | null;
}

/* ------------- Reducers Actions ------------- */

export interface SetProductsAction {
  type: string;
  productsList: ProductInterface[];
}

export interface SetSelectedProductAction {
  type: string;
  selectedProduct: ProductInterface | null;
}

export interface SetSubscriptionsAction {
  type: string;
  subscriptionResponse: SubscriptionsDataInterface;
}

export interface SetBookingsAction {
  type: string;
  bookingsList: BookingInterface[];
}

export interface ToggleNotSignedUpAction {
  type: string;
  isVisible: boolean;
  externalId?: string;
}

export interface BuyProductAction {
  type: string;
  purchaseInformation: PurchaseDetailsInterface;
}

export interface FailedAction {
  type: string;
  error: string;
}

export interface SuccessAction {
  type: string;
  message: string;
}

export interface UpdateCreditCardAction {
  type: string;
  creditCardInformation: UpdateCreditCardPayloadInterface;
}

/* ------------- State Elements ------------- */
export enum PRODUCT_TYPE {
  SERVICE = "service",
  CONTRACT = "contract",
}
export enum ACCESS_TYPE {
  UNLIMITED = "Unlimited",
  SERIES = "Series",
  DROP_IN = "DropIn",
}
export interface ProductInterface {
  count: number;
  default: boolean;
  expiration: string;
  id: number;
  name: string;
  price: number;
  tax: number;
  total: number;
  type: PRODUCT_TYPE;
  access_type?: ACCESS_TYPE;
  currency: string;
  auto_renewal: boolean;
}

export interface SubscriptionInterface {
  active: boolean;
  count: number;
  end: string;
  id: number;
  is_trial: boolean;
  name: string;
  period: string;
  remaining: number;
  start: string;
  type: string;
}

export interface BookingInterface {
  date: string;
  end_time: string;
  id: string;
  name: string;
  start_time: string;
}
export interface PaymentFormInterface {
  creditCardNumber: string;
  expMonth: string;
  expYear: number;
  cvv: string;
  billingName: string;
}

export interface PurchaseDetailsInterface {
  productId: number;
  productType: PRODUCT_TYPE;
  paymentInfo: PaymentFormInterface;
}

export interface PaymentInfoWebInterface {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
}
export enum VALID_TYPE {
  VALID = "valid",
  INVALID = "invalid",
  INCOMPLETE = "incomplete",
}

export interface PaymentInfoMobileInterface {
  status: {
    cvc: VALID_TYPE;
    expiry: VALID_TYPE;
    name: VALID_TYPE;
    number: VALID_TYPE;
  };
  valid: boolean;
  values: {
    cvc: string;
    expiry: string;
    name: string;
    number: string;
    type: string;
  };
}

export interface ProductsDataResponse {
  data: { center_name: string; products: ProductInterface[]; success: boolean };
}
export interface SubscriptionsDataResponse {
  data: SubscriptionsDataInterface;
}
export interface BookingDataResponse {
  data: BookingDataInterface;
}

export interface BookingDataInterface {
  success: boolean;
  booking: BookingInterface[];
}
export interface SubscriptionsDataInterface {
  has_active_membership: boolean;
  is_trial_account: boolean;
  allow_purchase: boolean;
  subscriptions: SubscriptionInterface[];
  success: boolean;
  cc_expiration_date: string;
  pricing_mode: PRICING_MODE;
}

export enum ACCOUNT_STATUS {
  TRIAL,
  MEMBERSHIP,
  COMMON,
}

export enum PRICING_MODE {
  PREMIUM = "premium",
  BASIC = "basic",
}

type PaymentIfoType = {
  credit_card_number: string;
  exp_month: string;
  exp_year: number;
  cvv: string;
  billing_name: string;
};

export interface BuyServicePayloadInterface {
  service_id: number;
  payment_info: PaymentIfoType;
}

export interface BuyContractPayloadInterface {
  contract_id: number;
  payment_info: PaymentIfoType;
}

export interface UpdateCreditCardPayloadInterface {
  payment_info: PaymentIfoType;
}
