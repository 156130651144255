import React from "react";
import { View } from "react-native";
import { DefaultText } from "../../index";
import { MAIN_THEME } from "../../../themes";
import { themedStyles } from "./styles";
import { RFValue } from "../../../utils/resizeHelper";

interface DatePickerItemProps {
  itemText: string;
  itemValue: string | undefined;
  itemName: string;
  handleChange: (name: string) => any;
}

export const DatePickerItem = ({
  itemText,
  itemValue,
  itemName,
  handleChange,
}: DatePickerItemProps) => {
  const inputStyles = {
    flex: 2,
    paddingLeft: RFValue(8),
    paddingRight: RFValue(8),
    paddingBottom: RFValue(12),
    paddingTop: RFValue(12),
    fontSize: RFValue(18),
    borderWidth: RFValue(1),
    borderRadius: RFValue(4),
    marginLeft: RFValue(10),
    fontFamily: "Poppins",
    color: MAIN_THEME.DEFAULT?.TEXT_COLOR,
    borderColor: MAIN_THEME.DEFAULT?.INACTIVE_BUTTON_COLOR,
    backgroundColor: MAIN_THEME.WELCOME_SCREEN.TRAINING_ITEM_BACKGROUND_COLOR,
  };

  return (
    <View style={themedStyles.formItemContainer}>
      <DefaultText style={themedStyles.formItemText}>{itemText}</DefaultText>
      <input
        style={inputStyles}
        type="date"
        onChange={handleChange(itemName)}
        value={itemValue?.substr(0, 10)}
      />
    </View>
  );
};
