import React, { useEffect } from "react";
import { Platform, ScrollView, View } from "react-native";
// import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp, useRoute } from "@react-navigation/core";
import { themedStyles } from "./styles";

import { ProductCards } from "./ProductCards";
import {
  ProductInterface,
  PurchaseDetailsInterface,
  SubscriptionState,
  UpdateCreditCardPayloadInterface,
} from "../../../../redux/types";
import { AccountInfoBlock } from "./AccountInfoBlock";
import { UpdateCreditCardBlock } from "./UpdateCreditCardBlock";
import { StackParams } from "../../../../navigation/index.web";
import { wrapScrollView } from "react-native-scroll-into-view";

type PropsType = {
  subscription: SubscriptionState;
  setSelectedProduct: (selectedProduct: ProductInterface | null) => void;
  buyProduct: (purchaseDetails: PurchaseDetailsInterface) => void;
  updateCreditCardInfo: (creditCardInformation: UpdateCreditCardPayloadInterface) => void;
};

type RouteProps = RouteProp<StackParams, "Welcome">;
const CustomScrollView = wrapScrollView(ScrollView);

export const AccountTab = ({
  subscription,
  buyProduct,
  setSelectedProduct,
  updateCreditCardInfo,
}: PropsType) => {
  const {
    productsList,
    subscriptions,
    accountStatus,
    selectedProduct,
    isPaymentInProgress,
    isUpdateSuccess,
    isUpdateInProgress,
    updateErrorMessage,
    updateSuccessMessage,
    paymentErrorMessage,
    isPaymentSuccess,
  } = subscription;
  const packageProduct = productsList?.filter((product) => product.type === "service") || [];
  const subscriptionProduct = productsList?.filter((product) => product.type === "contract") || [];
  const { params } = useRoute<RouteProps>();
  useEffect(() => {
    if (!!params?.productId && !!productsList) {
      const productId = params?.productId ? +params?.productId : null;
      const linkProduct = productsList.find((product) => product.id === productId);
      linkProduct && setSelectedProduct(linkProduct);
    } else setSelectedProduct(null);
    // eslint-disable-next-line
  }, [params?.productId]);

  const renderProductCards = () => {
    if (Platform.OS === "web") {
      return (
        <>
          <ProductCards
            productsList={subscriptionProduct}
            selectedProduct={selectedProduct}
            buyProduct={buyProduct}
            title="Subscription"
            isPaymentInProgress={isPaymentInProgress}
            paymentErrorMessage={paymentErrorMessage}
            isPaymentSuccess={isPaymentSuccess}
          />
          <ProductCards
            productsList={packageProduct}
            selectedProduct={selectedProduct}
            buyProduct={buyProduct}
            title="Class Packages"
            isPaymentInProgress={isPaymentInProgress}
            paymentErrorMessage={paymentErrorMessage}
            isPaymentSuccess={isPaymentSuccess}
          />
        </>
      );
    }
  };
  return (
    <View style={themedStyles.container}>
      <CustomScrollView showsVerticalScrollIndicator={false}>
        <AccountInfoBlock subscriptions={subscriptions} accountStatus={accountStatus} />
        <UpdateCreditCardBlock
          updateCreditCardInfo={updateCreditCardInfo}
          isUpdateInProgress={isUpdateInProgress}
          isUpdateSuccess={isUpdateSuccess}
          updateErrorMessage={updateErrorMessage}
          updateSuccessMessage={updateSuccessMessage}
        />
        {renderProductCards()}
      </CustomScrollView>
    </View>
  );
};
