import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 2,
    height: "100%",
    paddingVertical: 30,
  },
  userNickText: {
    color: MAIN_THEME.DEFAULT?.CONFORMING_TEXT_COLOR,
    marginHorizontal: "auto",
    marginBottom: 10,
    marginLeft: 20,
  },
  logoContainer: { marginBottom: 10, alignItems: "center" },
  logoImg: { width: 320, height: 35 },
  portfolioItem: { backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR },
  tabsContainer: {
    flex: 1,
    marginVertical: 20,
  },
  bottomContainer: { justifyContent: "flex-end" },
  divider: {
    borderBottomWidth: 1,
    marginVertical: 10,
    borderBottomColor: MAIN_THEME.DEFAULT?.PLACEHOLDER_COLOR,
  },
  subItemContainer: { marginLeft: 20 },
});
