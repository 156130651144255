import { DeviceDataPoint, DeviceType } from "../../redux/types";
import { IParser } from "./BleParserHelpers";
import { NamePowerTapP1L, NamePowerTapP1R } from "./BleMeterCommon";


export abstract class BleDeviceBase {
    name: string;
    deviceType: DeviceType;
    parser: IParser | null;
    userWeight: number;
    isListening: boolean;
    deviceUpdateListener: Function | null;

    constructor(
        deviceName: string,
        deviceType: DeviceType,
        parser: IParser | null,
        userWeight: number
    ) {
        this.name = deviceName;
        this.deviceType = deviceType;
        this.parser = parser;
        this.userWeight = userWeight;
        this.isListening = false;
        this.deviceUpdateListener = null;
    }

    public abstract disconnect(): void;

    public OnUnexpectedDisconnect?: () => void;

    public OnError?: (errorMessage: string) => void
    public OnInfo?: (infoMessage: string) => void
    public OnDebug?: (message: string) => void

    setDeviceUpdateListener(callback: (value: DeviceDataPoint, source: DeviceType) => void) {
        this.deviceUpdateListener = callback;
    }

    dispatch(deviceDataPoint: DeviceDataPoint, source: DeviceType) {
        if (this.deviceUpdateListener != null) {
            if (deviceDataPoint.power != null) {
                if (this.name === NamePowerTapP1L || this.name === NamePowerTapP1R) {
                    deviceDataPoint.power *= 2;
                }
            }

            this.deviceUpdateListener(deviceDataPoint, source);
        }
    }
}
