import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  currentStepContainer: {
    backgroundColor: MAIN_THEME.HOME_SCREEN.CURRENT_STEP_BACKGROUND_COLOR,
  },
  stepMetricsContainer: {
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(14),
    borderBottomWidth: RFValue(1),
    borderRightWidth: RFValue(1),
  },
  secondLineInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  secondLineInfoText: {
    fontSize: RFValue(25),
  },
  gameInfoContainer: {
    flexDirection: "row",
  },
  gameInfoText: { marginLeft: RFValue(5), fontSize: RFValue(21) },
  horizontalView: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  powerPercentText: { flex: 1.1 },
  powerText: { flex: 1.4 },
  cadenceText: { flex: 1.1 },
  heartRateText: { flex: 4 },
});
