import moment from "moment";
import { ActiveMemberInterface, CurrentMetricsInterface, INTERVAL_TYPE } from "../redux/types";
import { COLORS } from "../themes/colors";
import { MAX_CADENCE, MAX_POWER } from "./Statistics";

const STANDARD_USER_WEIGHT = 80;

interface SprScoreCalculatingProps {
  currentMetrics: CurrentMetricsInterface;
  userWeight: number;
}

export interface TizScoreInterface {
  tbz: number;
  tiz: number;
  toz: number;
  tbzAccuracy: number;
  tizAccuracy: number;
  tozAccuracy: number;
  deltaPower: number;
}

export interface AgrScoreInterface {
  avgPowerPercent: number;
  avgPower: number;
}

export interface GetGameInfoStrByType {
  type?: INTERVAL_TYPE;
  hasMetrics: boolean;
  isCurrentStepIndex: boolean;
  currentMetrics: CurrentMetricsInterface;
  historyMetrics: CurrentMetricsInterface;
  userWeight?: number;
  isSegment?: boolean;
}

interface TotalScoreCalculatingProps {
  type?: INTERVAL_TYPE;
  currentMetrics: CurrentMetricsInterface;
  userWeight?: number;
  isSegmentBasedStep?: boolean;
}

export const tarScoreCalculating = (currentMetrics: CurrentMetricsInterface) => {
  let wattsAccuracy =
    currentMetrics.power_avg / (currentMetrics.loadValues?.loadWatts || MAX_POWER);
  if (wattsAccuracy > 1) {
    wattsAccuracy = 2 - wattsAccuracy;
  }
  let cadenceAccuracy =
    currentMetrics.cadence_avg / (currentMetrics.loadValues?.loadCadence || MAX_CADENCE);
  if (cadenceAccuracy > 1) {
    cadenceAccuracy = 2 - cadenceAccuracy;
  }
  const tarScore = ((wattsAccuracy + cadenceAccuracy) * 100) / 2;
  return tarScore;
};

export const tizScoreCalculating = (currentMetrics: CurrentMetricsInterface) => {
  let tbz = 0;
  let tiz = 0;
  let toz = 0;
  const total = currentMetrics.currentStepSamples.filter((sample) => sample.p !== null).length || 1;

  const load = currentMetrics.loadValues;

  const minValue = (load?.loadWatts || MAX_POWER) - (load?.loadWattsWindowLow || 0);
  const maxValue = (load?.loadWatts || MAX_POWER) + (load?.loadWattsWindowHigh || 0);

  currentMetrics.currentStepSamples.forEach((sample) => {
    if (sample.p !== null) {
      if (sample.p < minValue) {
        tbz++;
      } else if (sample.p > maxValue) {
        toz++;
      } else {
        tiz++;
      }
    }
  });

  const tbzAccuracy = (tbz / total) * 100;
  const tizAccuracy = (tiz / total) * 100;
  const tozAccuracy = (toz / total) * 100;
  const deltaPower = currentMetrics.power_avg - (currentMetrics.loadValues?.loadWatts || MAX_POWER);
  const tizScore: TizScoreInterface = {
    tbz,
    tiz,
    toz,
    tbzAccuracy,
    tizAccuracy,
    tozAccuracy,
    deltaPower,
  };
  return tizScore;
};

export const agrScoreCalculating = (currentMetrics: CurrentMetricsInterface) => {
  if (currentMetrics.loadValues) {
    const avgPowerPercent =
      (currentMetrics.power_avg * currentMetrics.loadValues.loadPower) /
      (currentMetrics.loadValues.loadWatts || MAX_POWER);
    const agrScore: AgrScoreInterface = {
      avgPowerPercent,
      avgPower: currentMetrics.power_avg,
    };
    return agrScore;
  } else {
    return {
      avgPowerPercent: 0,
      avgPower: 0,
    };
  }
};

export const sprScoreCalculating = ({ currentMetrics, userWeight }: SprScoreCalculatingProps) => {
  const sprScore = currentMetrics.power_avg / userWeight;
  return sprScore;
};

export const kodScoreCalculating = (
  currentMetrics: CurrentMetricsInterface,
  isSegmentBasedStep?: boolean,
  isHistory?: boolean,
) => {
  const endTimePoint = isHistory ? currentMetrics.endTimestamp : Date.now();
  const timestampFromStart = currentMetrics.startTimestamp
    ? endTimePoint - currentMetrics.startTimestamp
    : 0;
  const sprScore = isSegmentBasedStep ? timestampFromStart : currentMetrics.distance;
  return sprScore;
};

export const stepScoreCalculating = ({
  type,
  currentMetrics,
  userWeight,
  isSegmentBasedStep,
}: TotalScoreCalculatingProps) => {
  const tarScore = tarScoreCalculating(currentMetrics);
  const tizScore = tizScoreCalculating(currentMetrics);
  const kodScore = kodScoreCalculating(currentMetrics, isSegmentBasedStep);
  const sprScore = sprScoreCalculating({
    currentMetrics,
    userWeight: userWeight || STANDARD_USER_WEIGHT,
  });
  switch (type) {
    case INTERVAL_TYPE.WARM_UP:
    case INTERVAL_TYPE.RECOVER:
      return 0;

    case INTERVAL_TYPE.ACTIVE_RECOVERY:
    case INTERVAL_TYPE.POWER:
    case INTERVAL_TYPE.POWER_SLOPE:
      return tizScore.tizAccuracy;

    case INTERVAL_TYPE.TAR:
    case INTERVAL_TYPE.POWER_CADENCE:
      return tarScore;

    case INTERVAL_TYPE.AGR:
      return currentMetrics.power_avg;

    case INTERVAL_TYPE.TIZ:
      return tizScore.tizAccuracy - Math.abs(tizScore.deltaPower);

    case INTERVAL_TYPE.TOZ:
      return (tizScore.tbzAccuracy + tizScore.tozAccuracy - Math.abs(tizScore.deltaPower)) / 100;
    case INTERVAL_TYPE.SPR:
      return sprScore * 100;
    case INTERVAL_TYPE.KOD:
    case INTERVAL_TYPE.KOM:
      const kodTotalScore = isSegmentBasedStep ? 100000 / kodScore : kodScore * 100;
      return kodTotalScore;

    default:
      return 0;
  }
};

export const getGameInfoStrByType = ({
  type,
  hasMetrics,
  isCurrentStepIndex,
  currentMetrics,
  historyMetrics,
  userWeight,
  isSegment,
}: GetGameInfoStrByType) => {
  switch (type) {
    case INTERVAL_TYPE.TIZ:
      if (!hasMetrics && !isCurrentStepIndex) {
        return `--.-% ± -.--W`;
      } else if (isCurrentStepIndex) {
        const tizScore = tizScoreCalculating(currentMetrics);
        const { tizAccuracy, deltaPower } = tizScore;
        return `${tizAccuracy.toFixed(1)}% ± ${deltaPower.toFixed(2)}W`;
      } else {
        const tizScore = tizScoreCalculating(historyMetrics);
        const { tizAccuracy, deltaPower } = tizScore;
        return `${tizAccuracy.toFixed(1)}% ± ${deltaPower.toFixed(2)}W`;
      }
    case INTERVAL_TYPE.SPR:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "-.-- W/kg";
      } else if (isCurrentStepIndex) {
        const sprScore = sprScoreCalculating({
          currentMetrics: currentMetrics,
          userWeight: userWeight || STANDARD_USER_WEIGHT,
        });
        return `${sprScore.toFixed(2)} W/kg`;
      } else {
        const sprScore = sprScoreCalculating({
          currentMetrics: historyMetrics,
          userWeight: userWeight || STANDARD_USER_WEIGHT,
        });
        return `${sprScore.toFixed(2)} W/kg`;
      }
    case INTERVAL_TYPE.AGR:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "---W --%";
      } else if (isCurrentStepIndex) {
        const agrScore = agrScoreCalculating(currentMetrics);
        return `${agrScore.avgPower.toFixed()}W ${agrScore.avgPowerPercent.toFixed()}%`;
      } else {
        const agrScore = agrScoreCalculating(historyMetrics);
        return `${agrScore.avgPower.toFixed()}W ${agrScore.avgPowerPercent.toFixed()}%`;
      }
    case INTERVAL_TYPE.TAR:
      if (!hasMetrics && !isCurrentStepIndex) {
        return "--.---";
      } else if (isCurrentStepIndex) {
        const tarScore = tarScoreCalculating(currentMetrics);
        return `${tarScore.toFixed(3)}`;
      } else {
        const tarScore = tarScoreCalculating(historyMetrics);
        return `${tarScore.toFixed(3)}`;
      }
    case INTERVAL_TYPE.KOD:
    case INTERVAL_TYPE.KOM:
      if (!hasMetrics && !isCurrentStepIndex) {
        const emptyText = isSegment ? "--:--" : "--.-- Km";
        return emptyText;
      } else if (isCurrentStepIndex) {
        const kodScore = kodScoreCalculating(currentMetrics, isSegment);
        const kodText = isSegment
          ? `${moment(kodScore).format("mm:ss")}`
          : `${kodScore.toFixed(2)} Km`;
        return kodText;
      } else {
        const kodScore = kodScoreCalculating(historyMetrics, isSegment, true);
        const kodText = isSegment
          ? `${moment(kodScore).format("mm:ss")}`
          : `${kodScore.toFixed(2)} Km`;
        return kodText;
      }
    default:
      return null;
  }
};

export const getSortedUserListByPlace = (userList: ActiveMemberInterface[]) => {
  const sortedByPlace = userList.sort((a, b) => b.stats.score - a.stats.score);
  const userListWithPlace = sortedByPlace.map((item, index) => ({
    ...item,
    place: index + 1,
  }));
  return userListWithPlace;
};

export const getPlaceNumberColor = (place: number, isGameStep: boolean) => {
  if (!isGameStep) {
    return COLORS.ALTO;
  }
  switch (place) {
    case 1:
      return COLORS.GOLD;
    case 2:
      return COLORS.SILVER;
    case 3:
      return COLORS.RAW_SIENNA;
    default:
      return COLORS.ALTO;
  }
};

export const getMemberWithPlaceList = (
  sortedActiveMembers: ActiveMemberInterface[],
  member: ActiveMemberInterface,
) => {
  let membersWithPlace = sortedActiveMembers;
  for (let i = 0; i < member.metrics.length; i++) {
    const sortedByPlace = [...sortedActiveMembers].sort((a, b) => {
      return b.metrics[i]?.score - a.metrics[i]?.score;
    });
    membersWithPlace = sortedActiveMembers.map((item) => {
      const position = sortedByPlace.findIndex((user) => user.userId === item.userId);
      if (item.metrics[i]) {
        item.metrics[i].place = position + 1;
      }
      return item;
    });
  }
  return membersWithPlace;
};
