import React, { useCallback, useEffect, useRef } from "react";
import { Dimensions, View } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { themedStyles } from "./styles";
import { StateInterface } from "../../redux/reducers";
import { DefaultText } from "../index";

import { StepBlock } from "../StepBlock";
import { getTimeFromSegmentStart, getTimeFromStepStart } from "../../redux/selectors";
import {
  CourseSegmentStepInterface,
  CurrentMetricsInterface,
  WorkoutIntervalInterface,
} from "../../redux/types";
import { useScrollIntoView } from "react-native-scroll-into-view";
const { height } = Dimensions.get("window");
const scrollOption = { insets: { bottom: height / 5, top: 0 } };

function StepsMetricsComponent({
  cp,
  trainingType,
  trainingSteps,
  trainingStatus,
  currentStepIndex,
  timeFromStepStart,
  userCurrentMetrics,
  timeFromSegmentStart,
  isTrainingInitialized,
  currentSegmentMetrics,
  segmentsMetricHistory,
  userStepsMetricHistory,
  currentCourseStepIndex,
}: CombinedProps) {
  const currentStep = currentStepIndex === null ? 0 : currentStepIndex + 1;
  const refContainer = useRef<View>(null);
  const scrollIntoView = useScrollIntoView();

  const scrollToCurrentStep = useCallback(() => {
    if (refContainer.current !== null) {
      scrollIntoView(refContainer.current, scrollOption);
    }
  }, [refContainer, scrollIntoView]);

  useEffect(() => {
    scrollToCurrentStep();
  }, [currentStep, trainingStatus, trainingSteps, scrollToCurrentStep, currentCourseStepIndex]);

  const renderCourseSegments = useCallback(
    (item: CourseSegmentStepInterface[]) => {
      const currentSegmentMetricHistory = segmentsMetricHistory.find(
        (segment) => segment.courseId === item[0].course,
      )?.segmentMetricHistory;
      return item.map((item, index) => {
        const ref = index === currentCourseStepIndex ? refContainer : null;
        return (
          <View key={`interval-id-${item.intervalId}-${index}`} ref={ref}>
            <StepBlock
              cp={cp}
              step={item}
              isCurrentStepIndex={index === currentCourseStepIndex}
              timeFromStepStart={timeFromSegmentStart}
              userCurrentMetrics={currentSegmentMetrics}
              userStepsMetricHistory={
                currentSegmentMetricHistory?.[index] as CurrentMetricsInterface
              }
              trainingType={trainingType}
              currentStepIndex={currentCourseStepIndex}
              stepIndex={index}
              isSegment
            />
          </View>
        );
      });
    },
    [
      currentCourseStepIndex,
      timeFromSegmentStart,
      cp,
      trainingType,
      currentSegmentMetrics,
      segmentsMetricHistory,
    ],
  );

  const renderStep = useCallback(
    (item: WorkoutIntervalInterface, index: number) => {
      const isCurrentStep = index === currentStepIndex;

      const ref = isCurrentStep && !item.courseSegments ? refContainer : null;
      return (
        <View key={item.intervalId} ref={ref}>
          <StepBlock
            cp={cp}
            step={item}
            isCurrentStepIndex={isCurrentStep}
            timeFromStepStart={timeFromStepStart}
            userCurrentMetrics={userCurrentMetrics}
            userStepsMetricHistory={userStepsMetricHistory[index]}
            trainingType={trainingType}
            currentStepIndex={currentStepIndex}
            stepIndex={index}
          />
          {item.courseSegments && renderCourseSegments(item.courseSegments)}
        </View>
      );
    },
    [
      cp,
      trainingType,
      currentStepIndex,
      timeFromStepStart,
      userCurrentMetrics,
      userStepsMetricHistory,
      renderCourseSegments,
    ],
  );

  if (!isTrainingInitialized) {
    return (
      <View>
        <DefaultText> Loading Workout...</DefaultText>
      </View>
    );
  }

  return (
    <View style={themedStyles.container}>
      {trainingSteps?.map((step, index) => renderStep(step, index))}
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  cp: state.settings.criticalPower,
  trainingStatus: state.activeTraining.status,
  timeFromStepStart: getTimeFromStepStart(state),
  timeFromSegmentStart: getTimeFromSegmentStart(state),
  trainingSteps: state.activeTraining.plan?.steps,
  trainingCourses: state.activeTraining.plan?.courses,
  trainingType: state.trainingDetails.trainingType,
  userCurrentMetrics: state.metrics.currentMetrics,
  currentSegmentMetrics: state.metrics.currentSegmentMetrics,
  trainingName: state.activeTraining.plan?.workoutName,
  userStepsMetricHistory: state.metrics.stepsMetricHistory,
  segmentsMetricHistory: state.metrics.segmentsMetricHistory,
  isTrainingInitialized: state.activeTraining.isInitialized,
  currentStepIndex: state.activeTraining.currentSteps.currentStepIndex,
  currentCourseStepIndex: state.activeTraining.currentSteps.currentCourseStepIndex,
});
const mapDispatchToProps = (_dispatch: Dispatch) => ({});
export const StepsMetrics = connect(mapStateToProps, mapDispatchToProps)(StepsMetricsComponent);
