import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import {
  CurrentCadenceMetricsInterface,
  CurrentPowerMetricsInterface,
  CurrentSpeedMetricsInterface,
  CurrentLoadValuesInterface,
  SetSampleAction,
  MetricsState,
  SetCurrentLoadValuesAction,
  SetCurrentCadenceMetricsAction,
  SetCurrentPowerMetricsAction,
  SetCurrentSpeedMetricsAction,
  SetStepsMetricHistoryAction,
  SetLeaderboardScoreLineAction,
  ResetMetricsAction,
  TRAINING_TYPES,
  SampleInterface,
  SetFirstStepTimestampAction,
  AdvancedStepMetricsInterface,
  SetElevationAction,
  SetSegmentsMetricHistoryAction,
  CurrentMetricsInterface,
  CurrentHeartRateInterface,
  SetCurrentHeartRateMetricsAction,
} from "../types";
import { COURSE_MOVEMENT_TYPE } from "../../redux/types";
import { INITIAL_AVG_VALUES, INITIAL_METRICS_VALUES } from "../../constants";

interface ActionTypesInterface {
  SET_CURRENT_POWER_METRICS: "SET_CURRENT_POWER_METRICS";
  SET_CURRENT_CADENCE_METRICS: "SET_CURRENT_CADENCE_METRICS";
  SET_CURRENT_SPEED_METRICS: "SET_CURRENT_SPEED_METRICS";
  SET_CURRENT_HEART_RATE_METRICS: "SET_CURRENT_HEART_RATE_METRICS";
  SET_STEPS_METRIC_HISTORY: "SET_STEPS_METRIC_HISTORY";
  SET_SEGMENTS_METRIC_HISTORY: "SET_SEGMENTS_METRIC_HISTORY";
  SET_LEADERBOARD_SCORE_LINE: "SET_LEADERBOARD_SCORE_LINE";
  CALCULATE_METRIC_HISTORY: "CALCULATE_METRIC_HISTORY";
  CALCULATE_SEGMENTS_METRIC_HISTORY: "CALCULATE_SEGMENTS_METRIC_HISTORY";
  RESET_METRICS: "RESET_METRICS";
  STREAM_STATS: "STREAM_STATS";
  SET_SAMPLE: "SET_SAMPLE";
  SET_FIRST_STEP_TIMESTAMP: "SET_FIRST_STEP_TIMESTAMP";
  SET_CURRENT_LOAD_VALUES: "SET_CURRENT_LOAD_VALUES";
  SET_ELEVATION: "SET_ELEVATION";
}

interface ActionCreatorsInterface extends ActionCreators {
  setCurrentPowerMetrics: (currentPowerMetrics: CurrentPowerMetricsInterface) => AnyAction;
  setCurrentCadenceMetrics: (currentCadenceMetrics: CurrentCadenceMetricsInterface) => AnyAction;
  setCurrentSpeedMetrics: (currentSpeedMetrics: CurrentSpeedMetricsInterface) => AnyAction;
  setCurrentHeartRateMetrics: (currentHeartRateMetrics: CurrentHeartRateInterface) => AnyAction;
  setStepsMetricHistory: (
    advancedStepMetrics: AdvancedStepMetricsInterface,
    gameAvgScoreLine: string,
    timestamp: number,
    courseId?: string,
    courseMovementStatus?: COURSE_MOVEMENT_TYPE,
  ) => AnyAction;
  setLeaderboardScoreLine: (leaderboardScoreLine: string) => AnyAction;
  calculateMetricHistory: () => AnyAction;
  calculateSegmentsMetricHistory: () => AnyAction;
  setSegmentsMetricHistory: (
    courseId: string,
    gameAvgScoreLine: string,
    timestamp: number,
  ) => AnyAction;
  resetMetrics: () => AnyAction;
  streamStats: (trainingType: TRAINING_TYPES | null) => AnyAction;
  setSample: (sample: SampleInterface) => AnyAction;
  setFirstStepTimestamp: (timestamp: number) => AnyAction;
  setCurrentLoadValues: (loadValues: CurrentLoadValuesInterface) => AnyAction;
  setElevation: (elevation: number) => AnyAction;
}

type Handler<A> = (state: MetricsState, action: A) => MetricsState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  setCurrentPowerMetrics: ["currentPowerMetrics"],
  setCurrentCadenceMetrics: ["currentCadenceMetrics"],
  setCurrentSpeedMetrics: ["currentSpeedMetrics"],
  setCurrentHeartRateMetrics: ["currentHeartRateMetrics"],
  setStepsMetricHistory: [
    "advancedStepMetrics",
    "gameAvgScoreLine",
    "timestamp",
    "courseId",
    "courseMovementStatus",
  ],
  setLeaderboardScoreLine: ["leaderboardScoreLine"],
  setSegmentsMetricHistory: ["courseId", "gameAvgScoreLine", "timestamp"],
  calculateMetricHistory: null,
  calculateSegmentsMetricHistory: null,
  resetMetrics: null,
  streamStats: ["trainingType"],
  setSample: ["sample"],
  setFirstStepTimestamp: ["timestamp"],
  setCurrentLoadValues: ["loadValues"],
  setElevation: ["elevation"],
});

export const MetricsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: MetricsState = {
  savedDistance: 0,
  currentMetrics: INITIAL_METRICS_VALUES,
  currentSegmentMetrics: INITIAL_METRICS_VALUES,
  stepsMetricHistory: [],
  segmentsMetricHistory: [],
  advancedStepsMetrics: [],
  currentAvgValues: INITIAL_AVG_VALUES,
  currentSegmentAvgValues: INITIAL_AVG_VALUES,
  workoutAvgValues: INITIAL_AVG_VALUES,
  setMetrics: {
    last_sample_time: null,
    distance: 0,
  },
  workoutMetrics: {
    avg_watts: 0,
    distance: 0,
    elevation: 0,
    kj: 0,
    speed: 0,
    speed_avg: 0,
    score: 0,
    leaderboardScoreLine: "",
  },
  samples: [],
  powerSamples: [],
  newRecords: [],
};

/* ------------- Reducers ------------- */

const resetMetrics: Handler<ResetMetricsAction> = () => {
  return {
    ...INITIAL_STATE,
  };
};

const setCurrentPowerMetrics: Handler<SetCurrentPowerMetricsAction> = (
  state,
  { currentPowerMetrics },
) => {
  return {
    ...state,
    currentMetrics: {
      ...state.currentMetrics,
      power: currentPowerMetrics.power,
      power_avg:
        (state.currentAvgValues.powerSum + currentPowerMetrics.power) /
        (state.currentAvgValues.powerCount + 1),
      distance: state.currentMetrics.speed_avg * (currentPowerMetrics.timeFromStepStart / 3600),
      score: currentPowerMetrics.currentStepScore,
      accuracyStatus: {
        ...state.currentMetrics.accuracyStatus,
        powerStatus: currentPowerMetrics.powerStatus,
        powerStatusAvg: currentPowerMetrics.powerStatusAvg,
      },
    },
    currentSegmentMetrics: {
      ...state.currentSegmentMetrics,
      power: currentPowerMetrics.power,
      power_avg:
        (state.currentSegmentAvgValues.powerSum + currentPowerMetrics.power) /
        (state.currentSegmentAvgValues.powerCount + 1),
      distance:
        state.currentSegmentMetrics.speed_avg * (currentPowerMetrics.timeFromSegmentStart / 3600),
      score: currentPowerMetrics.currentSegmentScore,
      accuracyStatus: {
        ...state.currentMetrics.accuracyStatus,
        powerStatus: currentPowerMetrics.powerStatus,
        powerStatusAvg: currentPowerMetrics.powerStatusAvg,
      },
    },
    workoutMetrics: {
      ...state.workoutMetrics,
      avg_watts:
        (state.workoutAvgValues.powerSum + currentPowerMetrics.power) /
        (state.workoutAvgValues.powerCount + 1),
      kj: (state.workoutMetrics.avg_watts * currentPowerMetrics.timeFromUserConnection) / 1000,
      score: currentPowerMetrics.totalScore,
    },
    currentAvgValues: {
      ...state.currentAvgValues,
      powerSum: state.currentAvgValues.powerSum + currentPowerMetrics.power,
      powerCount: state.currentAvgValues.powerCount + 1,
    },
    workoutAvgValues: {
      ...state.workoutAvgValues,
      powerSum: state.workoutAvgValues.powerSum + currentPowerMetrics.power,
      powerCount: state.workoutAvgValues.powerCount + 1,
    },
    currentSegmentAvgValues: {
      ...state.currentSegmentAvgValues,
      powerSum: state.currentSegmentAvgValues.powerSum + currentPowerMetrics.power,
      powerCount: state.currentSegmentAvgValues.powerCount + 1,
    },
    powerSamples: [
      ...state.powerSamples,
      {
        power: currentPowerMetrics.power,
        timeFromWorkoutStart: currentPowerMetrics.timeFromWorkoutStart,
      },
    ],
    newRecords: currentPowerMetrics.newRecords,
  };
};

const setCurrentSpeedMetrics: Handler<SetCurrentSpeedMetricsAction> = (
  state,
  { currentSpeedMetrics },
) => {
  let now = new Date().getTime();
  let lastSampleTime =
    currentSpeedMetrics.courseMovementStatus !== COURSE_MOVEMENT_TYPE.MOVING
      ? now
      : state.setMetrics.last_sample_time;
  if (lastSampleTime === null) lastSampleTime = now;
  let baseDistance;
  switch (currentSpeedMetrics.courseMovementStatus) {
    case COURSE_MOVEMENT_TYPE.STOPPED:
    case COURSE_MOVEMENT_TYPE.MOVING:
      baseDistance = state.setMetrics.distance || 0;
      break;
    case COURSE_MOVEMENT_TYPE.PENDING:
      baseDistance = 0;
      break;
    default:
      baseDistance = 0;
      break;
  }
  return {
    ...state,
    currentMetrics: {
      ...state.currentMetrics,
      speed: currentSpeedMetrics.speed,
      speed_avg:
        (state.currentAvgValues.speedSum + currentSpeedMetrics.speed) /
        (state.currentAvgValues.speedCount + 1),
    },
    currentSegmentMetrics: {
      ...state.currentSegmentMetrics,
      speed: currentSpeedMetrics.speed,
      speed_avg:
        (state.currentSegmentAvgValues.speedSum + currentSpeedMetrics.speed) /
        (state.currentSegmentAvgValues.speedCount + 1),
    },
    setMetrics: {
      last_sample_time: now,
      distance: baseDistance + ((now - lastSampleTime) / 3600) * currentSpeedMetrics.speed, //distance in meters
    },
    workoutMetrics: {
      ...state.workoutMetrics,
      speed: currentSpeedMetrics.speed,
      speed_avg:
        (state.workoutAvgValues.speedSum + currentSpeedMetrics.speed) /
        (state.workoutAvgValues.speedCount + 1),
      distance:
        state.workoutMetrics.speed_avg * (currentSpeedMetrics.timeFromUserConnection / 3600),
    },
    currentAvgValues: {
      ...state.currentAvgValues,
      speedSum: state.currentAvgValues.speedSum + currentSpeedMetrics.speed,
      speedCount: state.currentAvgValues.speedCount + 1,
    },
    workoutAvgValues: {
      ...state.workoutAvgValues,
      speedSum: state.workoutAvgValues.speedSum + currentSpeedMetrics.speed,
      speedCount: state.workoutAvgValues.speedCount + 1,
    },
    currentSegmentAvgValues: {
      ...state.currentSegmentAvgValues,
      speedSum: state.currentSegmentAvgValues.speedSum + currentSpeedMetrics.speed,
      speedCount: state.currentSegmentAvgValues.speedCount + 1,
    },
  };
};

const setCurrentCadenceMetrics: Handler<SetCurrentCadenceMetricsAction> = (
  state,
  { currentCadenceMetrics },
) => {
  return {
    ...state,
    currentMetrics: {
      ...state.currentMetrics,
      cadence: currentCadenceMetrics.cadence,
      cadence_avg:
        (state.currentAvgValues.cadenceSum + currentCadenceMetrics.cadence) /
        (state.currentAvgValues.cadenceCount + 1),
      accuracyStatus: {
        ...state.currentMetrics.accuracyStatus,
        cadenceStatus: currentCadenceMetrics.cadenceStatus,
        cadenceStatusAvg: currentCadenceMetrics.cadenceStatusAvg,
      },
    },
    currentSegmentMetrics: {
      ...state.currentSegmentMetrics,
      cadence: currentCadenceMetrics.cadence,
      cadence_avg:
        (state.currentSegmentAvgValues.cadenceSum + currentCadenceMetrics.cadence) /
        (state.currentSegmentAvgValues.cadenceCount + 1),
      accuracyStatus: {
        ...state.currentSegmentMetrics.accuracyStatus,
        cadenceStatus: currentCadenceMetrics.cadenceStatus,
        cadenceStatusAvg: currentCadenceMetrics.cadenceStatusAvg,
      },
    },
    currentAvgValues: {
      ...state.currentAvgValues,
      cadenceSum: state.currentAvgValues.cadenceSum + currentCadenceMetrics.cadence,
      cadenceCount: state.currentAvgValues.cadenceCount + 1,
    },
    workoutAvgValues: {
      ...state.workoutAvgValues,
      cadenceSum: state.workoutAvgValues.cadenceSum + currentCadenceMetrics.cadence,
      cadenceCount: state.workoutAvgValues.cadenceCount + 1,
    },
    currentSegmentAvgValues: {
      ...state.currentSegmentAvgValues,
      cadenceSum: state.currentSegmentAvgValues.cadenceSum + currentCadenceMetrics.cadence,
      cadenceCount: state.currentSegmentAvgValues.cadenceCount + 1,
    },
  };
};

const setCurrentHeartRateMetrics: Handler<SetCurrentHeartRateMetricsAction> = (
  state,
  { currentHeartRateMetrics },
) => {
  return {
    ...state,
    currentMetrics: {
      ...state.currentMetrics,
      heartRate: currentHeartRateMetrics.heartRate,
      heartRate_avg:
        (state.currentAvgValues.heartRateSum + currentHeartRateMetrics.heartRate) /
        (state.currentAvgValues.hearRateCount + 1),
      heartRate_max:
        currentHeartRateMetrics.heartRate > state.currentMetrics.heartRate_max
          ? currentHeartRateMetrics.heartRate
          : state.currentMetrics.heartRate_max,
    },
    currentSegmentMetrics: {
      ...state.currentSegmentMetrics,
      heartRate: currentHeartRateMetrics.heartRate,
      heartRate_avg:
        (state.currentSegmentAvgValues.heartRateSum + currentHeartRateMetrics.heartRate) /
        (state.currentSegmentAvgValues.hearRateCount + 1),
      heartRate_max:
        currentHeartRateMetrics.heartRate > state.currentSegmentMetrics.heartRate_max
          ? currentHeartRateMetrics.heartRate
          : state.currentSegmentMetrics.heartRate_max,
    },
    currentAvgValues: {
      ...state.currentAvgValues,
      heartRateSum: state.currentAvgValues.heartRateSum + currentHeartRateMetrics.heartRate,
      hearRateCount: state.currentAvgValues.hearRateCount + 1,
    },
    workoutAvgValues: {
      ...state.workoutAvgValues,
      heartRateSum: state.workoutAvgValues.heartRateSum + currentHeartRateMetrics.heartRate,
      hearRateCount: state.workoutAvgValues.hearRateCount + 1,
    },
    currentSegmentAvgValues: {
      ...state.currentSegmentAvgValues,
      heartRateSum: state.currentSegmentAvgValues.heartRateSum + currentHeartRateMetrics.heartRate,
      hearRateCount: state.currentSegmentAvgValues.hearRateCount + 1,
    },
  };
};

const setStepsMetricHistory: Handler<SetStepsMetricHistoryAction> = (
  state,
  { advancedStepMetrics, gameAvgScoreLine, timestamp, courseId, courseMovementStatus },
) => {
  const stepsMetricItem: CurrentMetricsInterface = {
    ...state.currentMetrics,
    endTimestamp: timestamp,
    gameAvgScoreLine: gameAvgScoreLine,
  };
  const currentSegmentMetricIdex = state.segmentsMetricHistory.findIndex(
    (course) => course.courseId === courseId,
  );
  const segmentsMetricHistory = [...state.segmentsMetricHistory];

  if (courseId && courseMovementStatus === COURSE_MOVEMENT_TYPE.MOVING) {
    if (currentSegmentMetricIdex >= 0) {
      segmentsMetricHistory[currentSegmentMetricIdex].stepMetricHistory.push(stepsMetricItem);
    } else {
      segmentsMetricHistory.push({
        courseId: courseId,
        stepMetricHistory: [stepsMetricItem],
        segmentMetricHistory: [],
      });
    }
  }

  const savedDistance =
    courseMovementStatus === COURSE_MOVEMENT_TYPE.MOVING
      ? state.currentMetrics.distance + state.savedDistance
      : 0;
  return {
    ...state,
    savedDistance: savedDistance,
    segmentsMetricHistory: segmentsMetricHistory,
    stepsMetricHistory: [...state.stepsMetricHistory, stepsMetricItem],
    currentMetrics: {
      ...INITIAL_STATE.currentMetrics,
      startTimestamp: timestamp,
    },
    advancedStepsMetrics: [...state.advancedStepsMetrics, advancedStepMetrics],
    currentAvgValues: INITIAL_STATE.currentAvgValues,
  };
};

const setSegmentsMetricHistory: Handler<SetSegmentsMetricHistoryAction> = (
  state,
  { courseId, gameAvgScoreLine, timestamp },
) => {
  const currentSegmentMetricIdex = state.segmentsMetricHistory.findIndex(
    (course) => course.courseId === courseId,
  );
  const segmentMetricItem = {
    ...state.currentSegmentMetrics,
    gameAvgScoreLine: gameAvgScoreLine,
    endTimestamp: timestamp,
  };

  const newArray = [...state.segmentsMetricHistory];

  if (currentSegmentMetricIdex >= 0) {
    newArray[currentSegmentMetricIdex].segmentMetricHistory.push(segmentMetricItem);
  } else {
    newArray.push({
      courseId: courseId,
      stepMetricHistory: [],
      segmentMetricHistory: [segmentMetricItem],
    });
  }
  
  return {
    ...state,
    segmentsMetricHistory: newArray,
    currentSegmentMetrics: {
      ...INITIAL_STATE.currentSegmentMetrics,
      startTimestamp: timestamp,
    },
    currentSegmentAvgValues: INITIAL_STATE.currentSegmentAvgValues,
  };
};

const setSample: Handler<SetSampleAction> = (state, { sample }) => {
  return {
    ...state,
    currentMetrics: {
      ...state.currentMetrics,
      currentStepSamples: [...state.currentMetrics.currentStepSamples, sample],
    },
    samples: [...state.samples, sample],
  };
};

const setCurrentLoadValues: Handler<SetCurrentLoadValuesAction> = (state, { loadValues }) => {
  return {
    ...state,
    currentMetrics: { ...state.currentMetrics, loadValues: loadValues },
    currentSegmentMetrics: { ...state.currentSegmentMetrics, loadValues: loadValues },
  };
};

const setFirstStepTimestamp: Handler<SetFirstStepTimestampAction> = (state, { timestamp }) => {
  return {
    ...state,
    currentMetrics: { ...state.currentMetrics, startTimestamp: timestamp },
  };
};

const setLeaderboardScoreLine: Handler<SetLeaderboardScoreLineAction> = (
  state,
  { leaderboardScoreLine },
) => {
  return {
    ...state,
    workoutMetrics: {
      ...state.workoutMetrics,
      leaderboardScoreLine: leaderboardScoreLine,
    },
  };
};

const setElevation: Handler<SetElevationAction> = (state, { elevation }) => {
  return {
    ...state,
    workoutMetrics: {
      ...state.workoutMetrics,
      elevation: elevation,
    },
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.SET_CURRENT_POWER_METRICS]: setCurrentPowerMetrics,
  [Types.SET_CURRENT_CADENCE_METRICS]: setCurrentCadenceMetrics,
  [Types.SET_CURRENT_SPEED_METRICS]: setCurrentSpeedMetrics,
  [Types.SET_CURRENT_HEART_RATE_METRICS]: setCurrentHeartRateMetrics,
  [Types.SET_STEPS_METRIC_HISTORY]: setStepsMetricHistory,
  [Types.RESET_METRICS]: resetMetrics,
  [Types.SET_SAMPLE]: setSample,
  [Types.SET_FIRST_STEP_TIMESTAMP]: setFirstStepTimestamp,
  [Types.SET_CURRENT_LOAD_VALUES]: setCurrentLoadValues,
  [Types.SET_LEADERBOARD_SCORE_LINE]: setLeaderboardScoreLine,
  [Types.SET_ELEVATION]: setElevation,
  [Types.SET_SEGMENTS_METRIC_HISTORY]: setSegmentsMetricHistory,
};

export const metricsReducer = createReducer(INITIAL_STATE, HANDLERS);
