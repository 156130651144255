import React from "react";
import { View } from "react-native";

import { themedStyles } from "./styles";
import { TabsComponent } from "../index";
import { WelcomeTabInterface } from "../../screens";

interface PropsType {
  tabsArr: WelcomeTabInterface[];
  tabIndex: number;
  subTabIndex: number | null;
}

export function WelcomeTrainingList({ tabsArr, tabIndex, subTabIndex }: PropsType) {
  return (
    <View style={themedStyles.workoutsListContainer}>
      <TabsComponent tabsComponentArr={tabsArr} tabIndex={tabIndex} subTabIndex={subTabIndex} />
    </View>
  );
}
