import { StyleSheet, Dimensions } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

const { width } = Dimensions.get("window");

export const themedStyles = StyleSheet.create({
  listItemContainer: {
    padding: RFValue(6),
    borderWidth: RFValue(1),
    borderTopWidth: RFValue(0),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: RFValue(40),
    minHeight: RFValue(40),
  },
  secondListItemContainer: {
    backgroundColor: MAIN_THEME.DEFAULT?.PRIMARY_BACKGROUND_COLOR,
  },
  firstCell: {
    width: width * 0.2,
  },
  activeCell: {
    backgroundColor: MAIN_THEME.HOME_SCREEN.CURRENT_STEP_BACKGROUND_COLOR,
  },
  scoreLineText: { fontSize: RFValue(14) },
  columnContainer: { flexDirection: "column" },
});
