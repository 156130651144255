import { NavigationContainer } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { Dispatch } from "redux";
import { AuthNavigation, MainNavigation } from "./index.web";
import { useAuth0 } from "@auth0/auth0-react";
import { Preloader, Container } from "../components";
import { StateInterface } from "../redux/reducers";
import { connect } from "react-redux";
import NotificationsCreators from "../redux/reducers/notificationsReducer";
import { NotificationInterface, NotificationLocation, TypesOfNotification } from "../redux/types";
import { LOCAL_STORAGE_ITEMS } from "../constants";

const linking = {
  prefixes: ["https://booking.powerwatts.co.il/", "booking.powerwatts.co.il//"],
  config: {
    screens: {
      Welcome: "/virtual-studio",
      VirtualTraining: "/virtual-training",
      Login: "/login",
      TrainingControls: "/training-controls",
      LiveSession: "/live-session",
    },
  },
};

const RootNavigationComponent = ({ addNotification }: CombinedProps) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [isShowDimensionsInfo, setIsShowDimensionsInfo] = useState(false);
  const [initUrlParams] = useState(window.location.search);
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!!initUrlParams && !isAuthenticated && !isLoading) {
      localStorage.setItem(LOCAL_STORAGE_ITEMS.INIT_PARAMS, initUrlParams);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, isLoading]);

  const debounce = (fn: () => void, ms: number) => {
    let timer: NodeJS.Timeout | number | null;
    return () => {
      clearTimeout(timer as NodeJS.Timeout);
      timer = setTimeout(() => {
        timer = null;
        fn();
      }, ms);
    };
  };

  const handleResize = useCallback(() => {
    if (
      Math.abs(dimensions.height - window.innerHeight) > 150 ||
      Math.abs(dimensions.width - window.innerWidth) > 150
    ) {
      setIsShowDimensionsInfo(true);
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    } else {
      setIsShowDimensionsInfo(false);
    }
  }, [setDimensions, dimensions]);

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
      if (isShowDimensionsInfo) {
        console.log("dimensions", dimensions);
        addNotification({
          location: NotificationLocation.Components,
          notificationType: TypesOfNotification.Info,
          description:
            "After resizing the window, it is recommended to reload the page so that all elements are drawn to fit your screen.",
        });
      }
    };
  });

  if (isLoading) {
    return (
      <Container>
        <Preloader />
      </Container>
    );
  }
  return (
    <NavigationContainer linking={linking}>
      {isAuthenticated ? <MainNavigation /> : <AuthNavigation />}
    </NavigationContainer>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (_state: StateInterface) => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationsCreators.addNotification(notification)),
});

export const RootNavigation = connect(mapStateToProps, mapDispatchToProps)(RootNavigationComponent);
