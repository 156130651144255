import { eventChannel } from "redux-saga";
import { call, put, select, take } from "redux-saga/effects";
import { DebugApi, SessionApi } from "../../api/api";
import { connectUserToLiveTraining, streamLiveTrainingById } from "../../services/firestore";
import { Event } from "../../services/googleAnalyticsTracking";
import { StateInterface } from "../reducers";
import ActiveTrainingCreators from "../reducers/activeTrainingReducer";
import CoachControlsCreators from "../reducers/coachControlsReducer";
import TrainingDetailsCreators from "../reducers/trainingDetailsReducer";
import WorkoutCreators from "../reducers/workoutsReducer";
import NotificationsCreators from "../reducers/notificationsReducer";
import TimerCreators from "../reducers/timerReducer";
import {
  DetailsInfoInterface,
  GetWorkoutNotesAction,
  InitializeTrainingAction,
  NotificationLocation,
  ProfileInterface,
  SessionInterface,
  TRAINING_TYPES,
  TypesOfNotification,
  USER_TYPES,
  WorkoutNotesInterface,
  CpxProfileInterface,
  ResponseZoomConnection,
  ZoomInfoInterface,
} from "../types";
import time from "../../utils/Time";
import { getUserNickname } from "../../utils/SvgHelpers";
import { getTrainingPlan } from "../../utils/sagasHelper";
import { unicodeStringToHtml } from "../../utils/helpers";
const defaultWorkoutUrl = "https://pw-virtual-studio.s3.amazonaws.com/test_workout.json";

export function* initializeTraining({ trainingId, lang }: InitializeTrainingAction) {
  try {
    const { workout: workoutDetails } = yield call(SessionApi.joinSession, trainingId, lang);
    const videoId =
      typeof workoutDetails.video_url === "undefined" || workoutDetails.video_url === null
        ? null
        : workoutDetails.video_url.replace("https://youtu.be/", "");

    Event("joined_workout", {
      booking_id: workoutDetails.external_id,
      coach_name: workoutDetails.coach_name,
      coach_id: workoutDetails.coach_id,
      video_id: videoId,
    });

    const trainingDetailsInfo: DetailsInfoInterface = {
      autoStartAt: time.getTime() + workoutDetails.start_in_seconds * 1000,
      autoStartInSeconds: workoutDetails.start_in_seconds,
      coachId: workoutDetails.coach_id || null,
      coachName: workoutDetails.coach_name || null,
      externalId: workoutDetails.external_id || null,
      sessionId: workoutDetails.rtid,
      videoId: videoId,
      videoStartSec: workoutDetails.video_start_sec ? workoutDetails.video_start_sec : null,
      workoutUrl: workoutDetails.url,
      zoomInfo: null,
    };

    const trainingType: TRAINING_TYPES = trainingDetailsInfo.videoId
      ? TRAINING_TYPES.VIRTUAL_COACH
      : TRAINING_TYPES.WORKOUT;

    yield put(TrainingDetailsCreators.setTrainingDetails(trainingType, trainingDetailsInfo));

    const workoutUrl = workoutDetails.url;
    const { data: workoutData } = yield call(SessionApi.getWorkoutByUrl, workoutUrl);

    const trainingPlan = getTrainingPlan(workoutData);
    Event("load_workout", {
      wid: workoutData.workoutId,
      workout_name: workoutData.workoutName,
      duration: trainingPlan.duration,
    });
    DebugApi.log({
      function: "trainingDetailsSagas.tsx initializeTraining",
      event: "Load Workout: " + workoutData.workoutName,
    });

    yield put(ActiveTrainingCreators.setTrainingPlan(trainingPlan));
    yield put(ActiveTrainingCreators.pendingStartTraining());
    yield put(ActiveTrainingCreators.pauseTraining());
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.TrainingSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}

export function* initializeLiveTraining({ trainingId, isItCoach }: InitializeTrainingAction) {
  const user: ProfileInterface = yield select((state: StateInterface) => state.user.profile);
  const userType: USER_TYPES | null = yield select((state: StateInterface) => state.user.userType);

  const cpxProfile: CpxProfileInterface | null = yield select(
    (state: StateInterface) => state.user.cpxProfile,
  );
  const cpxNickname = getUserNickname(cpxProfile);

  try {
    const cp: number = yield select((state: StateInterface) => state.settings.criticalPower);
    if (userType !== USER_TYPES.USER && !isItCoach) {
      yield call(connectUserToLiveTraining, trainingId, user, cp, cpxNickname);
    }

    const channel = eventChannel((emit) => streamLiveTrainingById(trainingId, emit));

    let workoutUrl = defaultWorkoutUrl;
    let workoutId = null;
    let zoomConnectionSuccess = false;
    let zoomInfo: ZoomInfoInterface | null = null;

    while (true) {
      const workoutDetailsDoc = (yield take(channel)) as { data: () => SessionInterface };
      const workoutDetails: SessionInterface = {
        ...workoutDetailsDoc.data(),
      };

      Event("joined_workout", {
        booking_id: workoutDetails.externalId,
        coach_name: workoutDetails.coach.name,
        coach_id: workoutDetails.coach.id,
        video_id: null,
      });

      const isInitialized: boolean = yield select(
        (state: StateInterface) => state.activeTraining.isInitialized,
      );

      const userConnectionTimestamp: boolean = yield select(
        (state: StateInterface) => state.timer.userConnectionTimestamp,
      );

      if (!isInitialized || workoutId !== workoutDetails.workoutId) {
        const { workout } = yield call(SessionApi.joinGroupSession, workoutDetails.externalId);
        const { success, zoom }: ResponseZoomConnection = yield call(
          SessionApi.joinZoomSession,
          workoutDetails.externalId,
        );
        workoutUrl = workout.url || defaultWorkoutUrl;
        workoutId = workoutDetails.workoutId;
        zoomConnectionSuccess = success;
        zoomInfo = zoom;
      }

      const trainingDetailsInfo: DetailsInfoInterface = {
        autoStartAt: workoutDetails.trainingStatus?.stepStartTimestamp,
        autoStartInSeconds: 60,
        coachId: workoutDetails.coach.id || null,
        coachName: workoutDetails.coach.name || null,
        externalId: workoutDetails.externalId || null,
        sessionId: workoutDetails.rtid || null,
        videoId: null,
        videoStartSec: null,
        workoutUrl: workoutUrl,
        zoomInfo: zoomConnectionSuccess ? zoomInfo : null,
      };

      const trainingType: TRAINING_TYPES = TRAINING_TYPES.LIVE_SESSION;

      yield put(TrainingDetailsCreators.setTrainingDetails(trainingType, trainingDetailsInfo));

      const { data: workoutData } = yield call(SessionApi.getWorkoutByUrl, workoutUrl);

      const trainingPlan = getTrainingPlan(workoutData);

      Event("load_workout", {
        wid: workoutData.workoutId,
        workout_name: workoutData.workoutName,
        duration: trainingPlan.duration,
      });
      yield put(ActiveTrainingCreators.setTrainingPlan(trainingPlan));
      yield put(CoachControlsCreators.setCoachTrainingStatus(workoutDetails.trainingStatus));

      if (workoutDetails.trainingStatus.workoutCurrentStepIndex >= 0 && !userConnectionTimestamp) {
        yield put(TimerCreators.setUserConnectionTimestamp(time.getTime()));
      } else if (
        workoutDetails.trainingStatus.workoutCurrentStepIndex < 0 &&
        userConnectionTimestamp
      ) {
        yield put(TimerCreators.setUserConnectionTimestamp(0));
      }
    }
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.TrainingSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}

export function* getWorkoutNotes({ notesId }: GetWorkoutNotesAction) {
  try {
    const htmlBody: string = yield SessionApi.getWorkoutNotes(notesId);
    const notes: WorkoutNotesInterface = {
      notesId: notesId,
      html: unicodeStringToHtml(htmlBody),
    };
    yield put(WorkoutCreators.setWorkoutNotes(notes));
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.TrainingSaga,
        notificationType: TypesOfNotification.Error,
        description: `${error}`,
      }),
    );
  }
}
