import "./GestureHandler";
import React, { useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { LiveSessionScreen, Login, VirtualTrainingScreen, WelcomeScreen } from "../screens";
import { commonScreenOptions } from "./routesOptions";
import { useAuth0 } from "@auth0/auth0-react";
import { Dispatch } from "redux";
import { Auth0UserInterface } from "../redux/types";
import UserCreators from "../redux/reducers/userReducer";
import { connect } from "react-redux";
import { StateInterface } from "../redux/reducers";
import { TrainingControlsScreen } from "../screens/TrainingControls";
import { setBearerToken, setEmailToApiHeader } from "../api/api";
import { WelcomeParams } from ".";

export type StackParams = {
  VirtualTraining: { wid?: string; externalId?: string; virtualCoach?: boolean | string };
  Login: undefined;
  Welcome?: WelcomeParams;
  TrainingControls: { externalId?: string } | undefined;
  LiveSession: { externalId: string };
};

const Stack = createStackNavigator<StackParams>();

const MainNavigationComponent = ({ initializeUser, userIsAuthenticated }: CombinedProps) => {
  const { user, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    if (!userIsAuthenticated) {
      getIdTokenClaims().then((promise) => {
        const idToken = encodeURI(promise?.__raw || "");
        if (user && user.email) {
          setBearerToken(idToken);
          setEmailToApiHeader(user.email.toLowerCase());
          initializeUser(idToken, user);
        }
      });
    }
  }, [user, userIsAuthenticated, getIdTokenClaims, initializeUser]);

  return (
    <Stack.Navigator screenOptions={commonScreenOptions}>
      <Stack.Screen name="Welcome" component={WelcomeScreen} />
      <Stack.Screen name="VirtualTraining" component={VirtualTrainingScreen} />
      <Stack.Screen name="TrainingControls" component={TrainingControlsScreen} />
      <Stack.Screen name="LiveSession" component={LiveSessionScreen} />
    </Stack.Navigator>
  );
};

export const AuthNavigation = () => {
  return (
    <Stack.Navigator screenOptions={commonScreenOptions}>
      <Stack.Screen name="Login" component={Login} />
    </Stack.Navigator>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initializeUser: (token: string, user: Auth0UserInterface) =>
    dispatch(UserCreators.initializeUser(token, user)),
});
const mapStateToProps = (state: StateInterface) => ({
  userIsAuthenticated: state.user.isAuthenticated,
});

export const MainNavigation = connect(mapStateToProps, mapDispatchToProps)(MainNavigationComponent);
