import React, { useEffect } from "react";
import Svg, { G, Text, Path } from "react-native-svg";
import { Platform } from "react-native";
import { toRelative } from "../../../utils/Statistics";
import { toArcPath } from "../../../utils/SvgHelpers";

interface PropsType {
  tbz: number;
  tiz: number;
  toz: number;
  diff: number;
  scoreLine: string;
  isUpdateScoreLine: boolean;
  isVisible?: boolean;
  setLeaderboardScoreLine: (scoreLine: string) => void;
}

interface DefaultPropsInterface {
  colors: {
    primaryColor: string;
    blue: string;
    green: string;
    red: string;
  };
  width: number;
  height: number;
  margin: number;
  accuracyMin: number;
  accuracyMax: number;
}

export const StepAccuracy = ({ isVisible = true, ...props }: CombinedProps) => {
  useEffect(() => {
    if (props.isUpdateScoreLine) {
      props.setLeaderboardScoreLine(props.scoreLine);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scoreLine, props.isUpdateScoreLine]);

  const getStyles = () => {
    let centerX = props.width / 2;
    let centerY = props.height;
    let radius = centerX - props.margin;
    let radiusY = radius;

    let tbz = toRelative(props.tbz, props.accuracyMin, props.accuracyMax, 180);
    let tiz = toRelative(props.tiz, props.accuracyMin, props.accuracyMax, 180);
    let toz = toRelative(props.toz, props.accuracyMin, props.accuracyMax, 180);

    let needleWidth = 23;
    return {
      colors: props.colors,
      width: props.width,
      height: props.height,
      centerX: centerX,
      centerY: centerY,
      radiosX: radius,
      radiosY: radiusY,
      needleWidth: needleWidth,
      tbz: {
        arcAngle: tbz,
        startAngle: 180,
        color: props.colors.blue,
      },
      tiz: {
        arcAngle: tiz,
        startAngle: 180 + tbz,
        color: props.colors.green,
      },
      toz: {
        arcAngle: toz,
        startAngle: 180 + tbz + tiz,
        color: props.colors.red,
      },
    };
  };

  const styles = getStyles();

  const viewBox = "-135 7 " + styles.width + " " + styles.height;
  if (isVisible) {
    return (
      <Svg viewBox={viewBox}>
        <G fill="none" fillOpacity="1" strokeWidth={styles.needleWidth}>
          <Path
            d={toArcPath(
              styles.centerX,
              styles.centerY,
              styles.radiosX,
              styles.tbz.startAngle,
              styles.tbz.arcAngle,
              0,
              styles.radiosY,
            )}
            id="tbz-gauge"
            stroke={styles.tbz.color}
          />
          <Path
            d={toArcPath(
              styles.centerX,
              styles.centerY,
              styles.radiosX,
              styles.tiz.startAngle,
              styles.tiz.arcAngle,
              0,
              styles.radiosY,
            )}
            id="tbz-gauge"
            stroke={styles.tiz.color}
          />
          <Path
            d={toArcPath(
              styles.centerX,
              styles.centerY,
              styles.radiosX,
              styles.toz.startAngle,
              styles.toz.arcAngle,
              0,
              styles.radiosY,
            )}
            id="tbz-gauge"
            stroke={styles.toz.color}
          />
        </G>
        <G id="texts-accuracy">
          <Text
            x={styles.centerX - 5}
            y={styles.centerY + 20}
            fill={styles.colors.primaryColor}
            textAnchor="middle"
            fontSize={17}
          >
            {`Δ  ${props.diff.toFixed(1)}`}
          </Text>
          <Text
            x={styles.centerX}
            y={styles.centerY}
            fill={styles.colors.green}
            fontSize={28}
            textAnchor="middle"
          >
            {`${props.tiz.toFixed()}%`}
          </Text>
        </G>
      </Svg>
    );
  } else {
    return null;
  }
};

StepAccuracy.defaultProps = {
  colors: {
    primaryColor: "#ffffff",
    blue: "#3595F0",
    green: "#2F8F00",
    red: "#C01818",
  },
  width: 400,
  height: Platform.OS === "web" ? 80 : 50,
  margin: 150,
  accuracyMin: 0,
  accuracyMax: 100,
};

type CombinedProps = PropsType & DefaultPropsInterface;
