import "react-credit-cards/es/styles-compiled.css";
import React, { useState, useRef, useEffect } from "react";
import { TouchableOpacity, View } from "react-native";
import Cards from "react-credit-cards";
import { Formik, FormikProps } from "formik";
import { DefaultText, InputItem } from "../../../../index";
import { themedStyles } from "./styles";

import { PaymentFormInterface, PaymentInfoWebInterface } from "../../../../../redux/types";
import { Event } from "../../../../../services/googleAnalyticsTracking";
import { PropsType } from ".";

type FocusType = "number" | "cvc" | "expiry" | "name" | undefined;

export const PaymentForm = ({
  isPaymentInProgress,
  paymentErrorMessage,
  isPaymentSuccess,
  buttonText = "Pay",
  paymentSuccessMessage = "Thank you for your purchase!",
  onHandleSubmit,
}: PropsType) => {
  const [focus, setFocus] = useState<FocusType>(undefined);
  const initialFormValues = {
    number: "",
    name: "",
    cvc: "",
    expiry: "",
  };
  const formikRef = useRef<FormikProps<typeof initialFormValues> | null>(null);

  useEffect(() => {
    if (isPaymentSuccess && formikRef.current) {
      formikRef.current.resetForm({});
    }
  }, [isPaymentSuccess]);

  const onSubmitHandler = async (paymentInfo: PaymentInfoWebInterface) => {
    if (paymentInfo) {
      const expiryArr = paymentInfo.expiry.split("/");
      const expYear = 2000 + parseInt(expiryArr[1]);
      const purchaseDetails: PaymentFormInterface = {
        billingName: paymentInfo.name,
        creditCardNumber: paymentInfo.number.replace(/\s/g, ""),
        cvv: paymentInfo.cvc,
        expMonth: expiryArr[0],
        expYear: expYear,
      };
      Event("click", { target: "pay" });
      onHandleSubmit(purchaseDetails);
    }
  };

  return (
    <View style={themedStyles.container}>
      <Formik initialValues={initialFormValues} onSubmit={onSubmitHandler} innerRef={formikRef}>
        {({ handleChange, handleBlur, handleSubmit, values }) => {
          return (
            <View style={themedStyles.formContainer}>
              <Cards
                cvc={values.cvc}
                expiry={values.expiry}
                focused={focus}
                name={values.name}
                number={values.number}
              />
              <View style={themedStyles.fieldsContainer}>
                <InputItem
                  maskType="custom"
                  maskOptions={{ mask: "9999 9999 9999 9999999" }}
                  itemName="number"
                  placeholder="Card number"
                  itemValue={values.number}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleFocus={() => setFocus("number")}
                  isValid={true}
                />
                <InputItem
                  itemName="name"
                  placeholder="Your name"
                  itemValue={values.name}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleFocus={() => setFocus("name")}
                  isValid={true}
                />
                <View style={themedStyles.doubleLineContainer}>
                  <InputItem
                    maskType="custom"
                    maskOptions={{ mask: "99/99" }}
                    itemName="expiry"
                    placeholder="MM/YY"
                    itemValue={values.expiry}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleFocus={() => setFocus("expiry")}
                    isValid={true}
                  />
                  <InputItem
                    maskType="custom"
                    maskOptions={{ mask: "999" }}
                    itemName="cvc"
                    placeholder="cvc"
                    itemValue={values.cvc}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleFocus={() => setFocus("cvc")}
                    isValid={true}
                  />
                </View>
              </View>
              {isPaymentSuccess ? (
                <DefaultText style={themedStyles.successMessage}>
                  {paymentSuccessMessage}
                </DefaultText>
              ) : paymentErrorMessage ? (
                <DefaultText style={themedStyles.errorMessage}>{paymentErrorMessage}</DefaultText>
              ) : null}
              <TouchableOpacity
                onPress={() => handleSubmit()}
                disabled={isPaymentInProgress}
                style={
                  isPaymentInProgress
                    ? [themedStyles.payButtonContainer, themedStyles.payButtonInactive]
                    : themedStyles.payButtonContainer
                }
              >
                <DefaultText>{isPaymentInProgress ? "Processing..." : buttonText}</DefaultText>
              </TouchableOpacity>
            </View>
          );
        }}
      </Formik>
    </View>
  );
};
