import React from "react";
import { FlatList, View } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { DefaultText } from "../index";

const FinalSegmentStatsComponent = ({ segmentsMetricHistory, currentCourseId }: CombinedProps) => {
  const currentCourseMetricHistory = segmentsMetricHistory.find(
    (course) => course.courseId === currentCourseId,
  );
  const stepMetricHistory = currentCourseMetricHistory?.stepMetricHistory;
  const finalStepMetrics = stepMetricHistory?.reduce((prevMetrics, currMetrics) => ({
    ...currMetrics,
    power_avg: (prevMetrics.power_avg + currMetrics.power_avg) / 2,
    distance: prevMetrics.distance + currMetrics.distance,
    speed_avg: (prevMetrics.speed_avg + currMetrics.speed_avg) / 2,
    score: prevMetrics.score + currMetrics.score,
    cadence_avg: (prevMetrics.cadence_avg + currMetrics.cadence_avg) / 2,
  }));

  const finalMetricArr = [
    { label: "Distance:", value: `${finalStepMetrics?.distance.toFixed(2) || 0} Km` },
    {
      label: "Average power:",
      value: `${finalStepMetrics?.power_avg.toFixed(1) || 0} W`,
    },
    { label: "Average cadence:", value: `${finalStepMetrics?.cadence_avg.toFixed(1) || 0} rpm` },
    {
      label: "Average speed:",
      value: `${finalStepMetrics?.speed.toFixed(1) || 0} Km/h`,
    },
  ];

  return (
    <View style={themedStyles.container}>
      <FlatList
        keyExtractor={(item) => item.label}
        data={finalMetricArr}
        renderItem={({ item }) => (
          <View style={themedStyles.metricLineContainer}>
            <DefaultText style={themedStyles.metricText}>{item.label}</DefaultText>
            <DefaultText style={[themedStyles.metricText, themedStyles.metricValue]}>
              {item.value}
            </DefaultText>
          </View>
        )}
      />
    </View>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  segmentsMetricHistory: state.metrics.segmentsMetricHistory,
  currentCourseId: state.activeTraining.currentSteps.course?.courseId,
});

const mapDispatchToProps = (_dispatch: Dispatch) => ({});

export const FinalSegmentStats = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinalSegmentStatsComponent);
