import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../../../themes";
import { RFValue } from "../../../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: RFValue(8),
    backgroundColor: MAIN_THEME.DEFAULT?.SECONDARY_BACKGROUND_COLOR,
    padding: RFValue(24),
    marginTop: RFValue(24),
    marginHorizontal: "15%",
  },
  subscriptionContainer: {
    width: "100%",
    borderRadius: RFValue(8),
    borderWidth: RFValue(1),
    borderColor: MAIN_THEME.DEFAULT?.PLACEHOLDER_COLOR,
    marginBottom: RFValue(8),
    padding: RFValue(8),
  },
  accountStatus: {
    fontSize: RFValue(36),
    color: MAIN_THEME.DEFAULT?.REJECTING_TEXT_COLOR,
    textAlign: "center",
  },
});
