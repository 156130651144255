import React from "react";
import { openLink } from "../../../utils/helpers";
import { DefaultText } from "../../CommonElements/DefaultText";
import { DefaultModal } from "../DefaultModal";

interface PropsType {
  onClosePress: () => void;
}

export const BetaModal = ({ onClosePress }: PropsType) => {
  const appLink = "http://app.powerwatts.com/";

  const onConfirmButtonPress = () => {
    openLink(appLink);
  };
  return (
    <DefaultModal
      title="This is a beta version of the site"
      cancelButtonText="Stay on Beta"
      confirmButtonText="Go to the current version"
      onCancelButtonPress={onClosePress}
      onConfirmButtonPress={onConfirmButtonPress}
    >
      <DefaultText>
        You have entered the beta version of the site. Do you want to go to the main version of the
        site?
      </DefaultText>
    </DefaultModal>
  );
};
