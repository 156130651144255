import { Device } from "react-native-ble-plx";
import { AnyAction } from "redux";
import { createReducer, createActions, ActionCreators } from "reduxsauce";
import { SmartTrainerBase } from "../../sensors/common/SmartTrainerBase";
import { SensorBase } from "../../sensors/common/SensorBase";
import {
  IDevicesState,
  BLE_REMARK_TYPES,
  BleStatusChangedAction,
  CONNECTION_STATUS_TYPES,
  DeviceDataPoint,
  DeviceDataChangedAction,
  UpdateSpecificBluetoothSensorAction,
  SearchForBleDeviceAction,
  SetDeviceAction,
  DeviceType,
  CreateBluetoothSensorAction,
  ResetDevicesListAction,
  CreateBluetoothSensorMobileAction,
  FinishDeviceSearchingTimerAction,
  StartDeviceSearchingTimerAction,
  ReconnectDeviceAction,
} from "../types";
import { DebugApi } from "../../api/api";

interface ActionTypesInterface {
  RESET_DEVICES_LIST: "RESET_DEVICES_LIST";
  SEARCH_FOR_BLE_DEVICES: "SEARCH_FOR_BLE_DEVICES";
  CONNECT_TO_MOBILE_BLE: "CONNECT_TO_MOBILE_BLE";
  CONNECT_MOCK_BLE: "CONNECT_MOCK_BLE";
  BLE_STATUS_CHANGED: "BLE_STATUS_CHANGED";
  DEVICE_DATA_CHANGED: "DEVICE_DATA_CHANGED";
  SET_DEVICE: "SET_DEVICE";
  CREATE_BLUETOOTH_SENSOR_MOBILE: "CREATE_BLUETOOTH_SENSOR_MOBILE";
  UPDATE_SPECIFIC_BLUETOOTH_SENSOR: "UPDATE_SPECIFIC_BLUETOOTH_SENSOR";
  CREATE_BLUETOOTH_SENSOR: "CREATE_BLUETOOTH_SENSOR";
  START_DEVICE_SEARCHING_TIMER: "START_DEVICE_SEARCHING_TIMER";
  FINISH_DEVICE_SEARCHING_TIMER: "FINISH_DEVICE_SEARCHING_TIMER";
  RECONNECT_DEVICE: "RECONNECT_DEVICE";
}

interface ActionCreatorsInterface extends ActionCreators {
  resetDevicesList: () => AnyAction;
  searchForBleDevices: (deviceType: DeviceType) => SearchForBleDeviceAction;
  updateSpecificBluetoothSensor: (
    device: SensorBase | null,
    deviceType: DeviceType,
  ) => UpdateSpecificBluetoothSensorAction;
  connectToMobileBle: () => AnyAction;
  connectMockBle: () => AnyAction;
  deviceDataChanged: (
    deviceData: DeviceDataPoint,
    deviceDataSource: DeviceType,
  ) => DeviceDataChangedAction;
  bleStatusChanged: (
    deviceType: DeviceType,
    blePowerMeterConnectionStatus: string,
    remark: string,
    blePowerMeterName?: string,
  ) => AnyAction;
  setDevice: (device: Device) => AnyAction;
  createBluetoothSensorMobile: (
    device: Device,
    deviceType: DeviceType,
  ) => CreateBluetoothSensorMobileAction;
  createBluetoothSensor: (
    device: BluetoothDevice,
    deviceType: DeviceType,
  ) => CreateBluetoothSensorAction;
  startDeviceSearchingTimer: () => StartDeviceSearchingTimerAction;
  finishDeviceSearchingTimer: (isNothingFounded: boolean) => FinishDeviceSearchingTimerAction;
  reconnectDevice: (
    bluetoothSensor: SensorBase,
    device: BluetoothDevice,
    deviceType: DeviceType,
  ) => ReconnectDeviceAction;
}

type Handler<A> = (state: IDevicesState, action: A) => IDevicesState;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<ActionTypesInterface, ActionCreatorsInterface>({
  resetDevicesList: null,
  searchForBleDevices: ["deviceType"],
  updateSpecificBluetoothSensor: ["device", "deviceType"],
  connectMockBle: null,
  connectToMobileBle: null,
  deviceDataChanged: ["deviceData", "deviceDataSource"],
  bleStatusChanged: ["deviceType", "status", "remark", "deviceName"],
  setDevice: ["device"],
  createBluetoothSensorMobile: ["device", "deviceType"],
  createBluetoothSensor: ["device", "deviceType"],
  startDeviceSearchingTimer: null,
  finishDeviceSearchingTimer: ["isNothingFounded"],
  reconnectDevice: ["bluetoothSensor", "device", "deviceType"],
});

export const DeviceActionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: IDevicesState = {
  bleSmartTrainerConnectionStatus: CONNECTION_STATUS_TYPES.NOT_CONNECTED,
  blePowerMeterConnectionStatus: CONNECTION_STATUS_TYPES.NOT_CONNECTED,
  bleHeartRateMeterConnectionStatus: CONNECTION_STATUS_TYPES.NOT_CONNECTED,
  bleSpeedCadenceSensorConnectionStatus: CONNECTION_STATUS_TYPES.NOT_CONNECTED,
  remark: BLE_REMARK_TYPES.NOT_CONNECTED,
  data: null,
  bluetoothDevices: [],
  smartTrainerDevice: null,
  powerMeterDevice: null,
  heartRateMeterDevice: null,
  speedCadenceSensorDevice: null,
  cscSupportsCadence: false,
  cscSupportsSpeed: false,
  grade: 0,
  isSearchingDevices: false,
  isNothingFounded: false,
};

/* ------------- Reducers ------------- */

const bleStatusChanged: Handler<BleStatusChangedAction> = (
  state,
  { deviceType, status, remark, deviceName },
) => {
  switch (deviceType) {
    case DeviceType.SmartTrainer:
      return {
        ...state,
        bleSmartTrainerConnectionStatus: status,
        bleSmartTrainerName: deviceName || null,
        remark: remark,
      };
    case DeviceType.PowerMeterDevice:
      return {
        ...state,
        blePowerMeterConnectionStatus: status,
        blePowerMeterName: deviceName || null,
        remark: remark,
      };
    case DeviceType.HeartRateMeterDevice:
      return {
        ...state,
        bleHeartRateMeterConnectionStatus: status,
        bleHeartRateMeterName: deviceName || null,
        remark: remark,
      };
    case DeviceType.SpeedCadenceSensor:
      return {
        ...state,
        bleSpeedCadenceSensorConnectionStatus: status,
        bleSpeedCadenceSensorName: deviceName || null,
        remark: remark,
      };
    default:
      throw new Error("Invalid Device Type!");
  }
};

const deviceDataChanged: Handler<DeviceDataChangedAction> = (
  devicesState,
  { deviceData, deviceDataSource },
) => {
  // Set what parameters the CSC device supports
  let cscSupportsCadence = devicesState.cscSupportsCadence;
  let cscSupportsSpeed = devicesState.cscSupportsSpeed;
  if (deviceDataSource === DeviceType.SpeedCadenceSensor) {
    // If the values are ever not null, then the sensor supports that value.
    // It will still sometimes be null if it didn't get a recent update.
    // This will make sure we start using it after the first piece of new data.
    if (deviceData.cadence != null) cscSupportsCadence = true;
    if (deviceData.speed != null) cscSupportsSpeed = true;
  }

  // Determine from which device the speed, cadence, and power data should be used.
  let powerDataSource = DeviceType.SmartTrainer;
  let speedDataSource = DeviceType.SmartTrainer;
  let cadenceDataSource = DeviceType.SmartTrainer;

  if (devicesState.smartTrainerDevice == null) {
    powerDataSource = DeviceType.PowerMeterDevice;
    speedDataSource = DeviceType.PowerMeterDevice;
    cadenceDataSource = DeviceType.PowerMeterDevice;
  }

  if (devicesState.speedCadenceSensorDevice != null) {
    if (cscSupportsSpeed) speedDataSource = DeviceType.SpeedCadenceSensor;
    if (cscSupportsCadence) cadenceDataSource = DeviceType.SpeedCadenceSensor;
  }

  // Data parameters from the input "deviceData" should only be used if they are not null.
  // If they are null that means that specific parameter is not changed in the call.
  // Speed and cadence data also need match their data sources to the deviceDataSource.
  const power =
    deviceData.power != null && deviceDataSource === powerDataSource
      ? deviceData.power
      : devicesState.data?.power || 0;

  const powerBalance =
    deviceData.powerBalance != null
      ? deviceData.powerBalance
      : devicesState.data?.powerBalance || null;

  const speed =
    deviceData.speed != null && deviceDataSource === speedDataSource
      ? deviceData.speed
      : devicesState.data?.speed || 0;

  const cadence =
    deviceData.cadence != null && deviceDataSource === cadenceDataSource
      ? deviceData.cadence
      : devicesState.data?.cadence || 0;

  const heartRate =
    deviceData.heartRate != null ? deviceData.heartRate : devicesState.data?.heartRate || null;

  const grade = devicesState.smartTrainerDevice?.getGrade() || 0;
  return {
    ...devicesState,
    data: {
      power: power,
      powerBalance: powerBalance,
      cadence: cadence,
      speed: speed,
      heartRate: heartRate,
    },
    cscSupportsCadence: cscSupportsCadence,
    cscSupportsSpeed: cscSupportsSpeed,
    grade: grade,
  };
};

const resetDevicesList: Handler<ResetDevicesListAction> = (state) => {
  return {
    ...state,
    bluetoothDevices: INITIAL_STATE.bluetoothDevices,
  };
};

const setDevice: Handler<SetDeviceAction> = (state, { device }) => {
  const isDevicesExist = state.bluetoothDevices.some((item: Device) => item.id === device.id);
  if (!isDevicesExist) {
    return {
      ...state,
      bluetoothDevices: [...state.bluetoothDevices, device],
    };
  }
  return {
    ...state,
  };
};

const updateSpecificBluetoothSensor: Handler<UpdateSpecificBluetoothSensorAction> = (
  state,
  { device, deviceType },
) => {
  // When adding a new sensor, clear out old data incase new sensor doesn't support that data.
  let newData: DeviceDataPoint = {
    cadence: null,
    power: null,
    speed: null,
    powerBalance: null,
    heartRate: null,
  };

  switch (deviceType) {
    case DeviceType.SmartTrainer:
      return {
        ...state,
        smartTrainerDevice: device as SmartTrainerBase,
        data: newData,
      };
    case DeviceType.PowerMeterDevice:
      return {
        ...state,
        powerMeterDevice: device,
        data: newData,
      };
    case DeviceType.HeartRateMeterDevice:
      newData.heartRate = null;
      return {
        ...state,
        heartRateMeterDevice: device,
        data: newData,
      };
    case DeviceType.SpeedCadenceSensor:
      return {
        ...state,
        speedCadenceSensorDevice: device,
        data: newData,
      };
    default:
      console.log("Device Type Not Found.");
      DebugApi.log({
        function: "updateSpecificBluetoothSensor",
        message: `Device Type "${deviceType}" Not Found.`,
      });
      return {
        ...state,
      };
  }
};

const startDeviceSearchingTimer: Handler<StartDeviceSearchingTimerAction> = (state) => {
  return {
    ...state,
    isSearchingDevices: true,
  };
};

const finishDeviceSearchingTimer: Handler<FinishDeviceSearchingTimerAction> = (
  state,
  { isNothingFounded },
) => {
  return {
    ...state,
    isSearchingDevices: false,
    isNothingFounded,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const HANDLERS = {
  [Types.BLE_STATUS_CHANGED]: bleStatusChanged,
  [Types.DEVICE_DATA_CHANGED]: deviceDataChanged,
  [Types.RESET_DEVICES_LIST]: resetDevicesList,
  [Types.SET_DEVICE]: setDevice,
  [Types.UPDATE_SPECIFIC_BLUETOOTH_SENSOR]: updateSpecificBluetoothSensor,
  [Types.START_DEVICE_SEARCHING_TIMER]: startDeviceSearchingTimer,
  [Types.FINISH_DEVICE_SEARCHING_TIMER]: finishDeviceSearchingTimer,
};

export const devicesReducer = createReducer(INITIAL_STATE, HANDLERS);
