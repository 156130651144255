import { StyleSheet } from "react-native";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "max-content",
  },
  text: {
    fontSize: RFValue(25),
  },
});
