import { Platform } from "react-native";

export const Event = (event_name: EventNames, eventParameters: EventParams) => {
  if (Platform.OS === "web") {
    window.gtag("event", event_name, eventParameters);
  }
};

export const TrackingProps = (properties: CustomParams) => {
  if (Platform.OS === "web") {
    window.gtag("set", "user_properties", properties);
  }
};

type EventNames =
  | "add_payment_info"
  | "add_to_cart"
  | "add_to_wishlist"
  | "begin_checkout"
  | "checkout_progress"
  | "exception"
  | "generate_lead"
  | "login"
  | "page_view"
  | "purchase"
  | "refund"
  | "remove_from_cart"
  | "screen_view"
  | "search"
  | "select_content"
  | "set_checkout_option"
  | "share"
  | "sign_up"
  | "timing_complete"
  | "view_item"
  | "view_item_list"
  | "view_promotion"
  | "view_search_results"
  | "click"
  | string;

interface EventParams {
  checkout_option?: string;
  checkout_step?: number;
  content_id?: string;
  content_type?: string;
  coupon?: string;
  currency?: string;
  description?: string;
  fatal?: boolean;
  items?: Item[];
  method?: string;
  number?: string;
  promotions?: Promotion[];
  screen_name?: string;
  search_term?: string;
  shipping?: Currency;
  tax?: Currency;
  transaction_id?: string;
  value?: number;
  event_label?: string;
  event_category?: string;
  target?: string;
  [key: string]: any;
}

type Currency = string | number;

interface Item {
  brand?: string;
  category?: string;
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  location_id?: string;
  name?: string;
  price?: Currency;
  quantity?: number;
}

interface Promotion {
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  name?: string;
}

interface CustomParams {
  [key: string]: any;
}
