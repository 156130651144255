import React, { useCallback, useEffect, /* useRef, */ useState } from "react";
import Modal from "modal-react-native-web";
import TimerCreators from "../../redux/reducers/timerReducer";
import CoachControlsCreators from "../../redux/reducers/coachControlsReducer";
import NotificationsCreators from "../../redux/reducers/notificationsReducer";
import { View } from "react-native";
import { connect, /* useDispatch */ } from "react-redux";
import { Dispatch } from "redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  BleConnectionModal,
  Container,
  Header,
  HeaderTimers,
  StepsTable,
  StatisticInfo,
  StatisticsBarChart,
  CourseImage,
  ToolTipProvider,
  Leaderboard,
  AchievementInfoModal,
} from "../../components";
import { StateInterface } from "../../redux/reducers";
import { themedStyles } from "./styles";
import { RouteProp, useRoute } from "@react-navigation/native";
import { StackParams } from "../../navigation";
import TrainingDetailsCreators from "../../redux/reducers/trainingDetailsReducer";
import ActiveTrainingCreators from "../../redux/reducers/activeTrainingReducer";
import MetricsCreators from "../../redux/reducers/metricsReducer";
import DeviceCreators from "../../redux/reducers/devicesReducer";
import SettingsCreators from "../../redux/reducers/settingsReducer";
import {
  // ActiveMemberInterface,
  DeviceType,
  NotificationInterface,
  NotificationLocation,
  RemoveMemberInfoInterface,
  TRAINING_STATUS_TYPES,
  TRAINING_TYPES,
  TypesOfNotification,
  USER_TYPES,
} from "../../redux/types";
import { useWakeLock } from "react-screen-wake-lock";
import { useBeforeunload } from "react-beforeunload";
// import { useUpdateActiveMembersCallback } from "../../redux/hooks";
// import { FirebaseSocketReactivePagination } from "../../services/socket";
// import { SocketCollectionPreset } from "../../services/socket.options";
import { Event } from "../../services/googleAnalyticsTracking";
import { determineDeviceType } from "../../sensors/common/BleMeterCommon";
import { getMyCp20m } from "../../redux/selectors";

type RouteProps = RouteProp<StackParams, "LiveSession">;

const LiveSession = (props: CombinedProps) => {
  const { params } = useRoute<RouteProps>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isMetricHistoryChecked, setIsMetricHistoryChecked] = useState(false);
  const [isAchievementInfoModal, setIsAchievementInfoModal] = useState(false);

  const handle = useFullScreenHandle();
  Modal.setAppElement("body");
  const {
    user,
    myCp20m,
    trainingType,
    criticalPower,
    trainingStatus,
    isDisplayCourse,
    currentStepIndex,
    currentCourseStepIndex,
    calculateSegmentsMetricHistory,
    initializeGroupTraining,
    calculateMetricHistory,
    updateCriticalPower,
    addNotification,
    finishTraining,
    streamStats,
    pauseTimer,
    startTimer,
    connectToBleDevice,
    removeMember,
    finishLiveTraining,
  } = props;

  // Automatically connect to previosuly paired bluetooth devices
  useEffect(() => {
    const connectToPreviouslyPairedBluetoothDevices = async () => {
      try {
        const devices = await navigator.bluetooth.getDevices();
        console.log("Found " + devices.length + " previously paired Bluetooth devices.");

        // These devices may not be powered on or in range, so scan for
        // advertisement packets from them before connecting.
        for (const device of devices) {
          addNotification({
            location: NotificationLocation.Components,
            notificationType: TypesOfNotification.Info,
            description: "Looking for device: " + device.name + "..",
          });
          scanAndConnectToBluetoothDevice(device);
        }
      } catch (error) {
        // Just send this message to the console, as the feature is in beta.
        console.log(
          "To auto-connect BLE devices enable flags #enable-web-bluetooth-new-permissions-backend and #enable-experimental-web-platform-features.",
        );
      }
    };

    const scanAndConnectToBluetoothDevice = async (device: BluetoothDevice) => {
      let isFired: boolean = false;
      device.addEventListener("advertisementreceived", async () => {
        if (!isFired) {
          isFired = true;
          addNotification({
            location: NotificationLocation.Components,
            notificationType: TypesOfNotification.Info,
            description: 'Found & connecting to device "' + device.name + '"...',
          });

          // Determine device type before trying to connect
          const server = await device?.gatt?.connect();
          const services = await server?.getPrimaryServices();
          if (services) {
            let deviceType = determineDeviceType(services);
            connectToBleDevice(device, deviceType);
          }
        }
      });

      await device.watchAdvertisements();
    };

    connectToPreviouslyPairedBluetoothDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { request } = useWakeLock({
    onRequest: () => {},
    onError: () =>
      addNotification({
        location: NotificationLocation.Components,
        notificationType: TypesOfNotification.Warning,
        description: "Your computer can now go to sleep mode",
      }),
    onRelease: () => {
      isFullScreen && handle.exit();
    },
  });

  const onFullScreenPress = () => {
    if (isFullScreen) {
      handle.exit();
    } else {
      handle.enter();
      request();
    }
  };

  const onFullScreenChange = (fullScreeState: boolean) => {
    setIsFullScreen(fullScreeState);
  };

  const removeUser = useCallback(() => {
    if (params?.externalId && user.profile?.cpxUserId) {
      removeMember({
        liveTrainingId: params?.externalId,
        userId: user.profile?.cpxUserId,
      });
    }
  }, [user, params, removeMember]);

  const stopStreamStats = useCallback(() => {
    streamStats(null);
  }, [streamStats]);

  useEffect(() => {
    Event("page_view", { page_title: "Workout Page" });
    return () => {
      removeUser();
      stopStreamStats();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBeforeunload(() => {
    removeUser();
    stopStreamStats();
    return "Are you sure?";
  });

  useEffect(() => {
    if (user.cpxProfile && trainingStatus === TRAINING_STATUS_TYPES.NOT_INITIALIZED) {
      try {
        if (params?.externalId) {
          initializeGroupTraining(params.externalId);
        }
      } catch (error) {
        addNotification({
          location: NotificationLocation.Components,
          notificationType: TypesOfNotification.Error,
          description: `${error}`,
        });
      }
    }
  }, [params, user.cpxProfile, trainingStatus, initializeGroupTraining, addNotification]);

  useEffect(() => {
    return () => {
      finishTraining();
      handle.exit();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishTraining]);

  useEffect(() => {
    trainingType && user.userType !== USER_TYPES.USER && streamStats(trainingType);
  }, [trainingType, streamStats, user.userType]);

  useEffect(() => {
    if ((currentStepIndex || 0) > 0 && isMetricHistoryChecked === false) {
      for (let i = 0; i < (currentStepIndex || 0); i++) {
        calculateMetricHistory();
      }
      setIsMetricHistoryChecked(true);
    } else {
      if ((currentStepIndex || 0) > 0) {
        calculateMetricHistory();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex, setIsMetricHistoryChecked, calculateMetricHistory]);

  useEffect(() => {
    if ((currentCourseStepIndex || 0) > 0) {
      calculateSegmentsMetricHistory();
    }
  }, [currentCourseStepIndex, calculateSegmentsMetricHistory]);

  useEffect(() => {
    if (
      trainingStatus === TRAINING_STATUS_TYPES.PAUSED ||
      trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED
    ) {
      pauseTimer();
    } else {
      startTimer();
    }

    if (trainingStatus === TRAINING_STATUS_TYPES.FINISHED) {
      finishLiveTraining();
    }
  }, [trainingStatus, pauseTimer, startTimer, finishLiveTraining]);

  const closeBleModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);
  const openBleModal = useCallback(() => {
    setIsModalVisible(true);
    handle.exit();
    request();
  }, [setIsModalVisible, request, handle]);
  const handleCloseAchievementInfoModal = () => {
    setIsAchievementInfoModal(false);
    handle.enter();
  };
  const handleOpenAchievementInfoModal = () => {
    setIsAchievementInfoModal(true);
    handle.exit();
  };

  // const dispatch = useDispatch();

  // const onUpdateActiveMembersList = useUpdateActiveMembersCallback(dispatch);

  // const firebaseSocketConnection = useRef(
  //   new FirebaseSocketReactivePagination<ActiveMemberInterface>(
  //     SocketCollectionPreset.ActiveMembersList,
  //     params.externalId,
  //   ),
  // );

  // useEffect(() => {
  //   const currentFBSocket = firebaseSocketConnection.current;
  //   currentFBSocket.subscribe(onUpdateActiveMembersList);

  //   return () => {
  //     currentFBSocket.unsubscribe();
  //   };
  // }, [onUpdateActiveMembersList]);

  useEffect(() => {
    if (myCp20m && myCp20m !== criticalPower) {
      updateCriticalPower(myCp20m);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCp20m]);

  const onConnectDevice = () => {
    handle.enter();
  };
  const renderModals = () => {
    return (
      <>
        <Modal animationType="fade" transparent={true} visible={isModalVisible}>
          <BleConnectionModal
            closeBleModal={closeBleModal}
            request={request}
            onConnectDevice={onConnectDevice}
          />
        </Modal>
        <Modal animationType="fade" transparent={true} visible={isAchievementInfoModal}>
          <AchievementInfoModal closeModal={handleCloseAchievementInfoModal} />
        </Modal>
      </>
    );
  };

  return (
    <FullScreen handle={handle} onChange={onFullScreenChange}>
      {renderModals()}
      <ToolTipProvider>
        <Container style={themedStyles.container}>
          <Header
            onFullScreenPress={onFullScreenPress}
            onBleButtonPress={openBleModal}
            isFullScreen={isFullScreen}
          />
          <HeaderTimers />
          <View style={themedStyles.contentContainer}>
            <View style={themedStyles.leftSideContainer}>
              { /* <Zoom /> */ }
              <View style={themedStyles.bottomSvgContainer}>
                {isDisplayCourse ? <CourseImage /> : <StatisticsBarChart isSplitMode={false} />}
              </View>
            </View>
            <View style={themedStyles.rightSideContainer}>
              <View style={themedStyles.statisticsBarContainer}>
                <StatisticInfo openAchievementInfoModal={handleOpenAchievementInfoModal} />
                <Leaderboard />
              </View>
              <StepsTable />
            </View>
          </View>
        </Container>
      </ToolTipProvider>
    </FullScreen>
  );
};

type CombinedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: StateInterface) => ({
  user: state.user,
  trainingType: state.trainingDetails.trainingType,
  trainingStatus: state.activeTraining.status,
  currentStepIndex: state.activeTraining.currentSteps.currentStepIndex,
  currentCourseStepIndex: state.activeTraining.currentSteps.currentCourseStepIndex,
  isDisplayCourse: !!state.activeTraining.currentSteps.currentStep?.courseMovementStatus,
  myCp20m: getMyCp20m(state),
  criticalPower: state.settings.criticalPower,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  initializeGroupTraining: (externalId: string) =>
    dispatch(TrainingDetailsCreators.initializeGroupTraining(externalId)),
  finishTraining: () => dispatch(ActiveTrainingCreators.finishTraining()),
  finishLiveTraining: () => dispatch(ActiveTrainingCreators.finishLiveTraining()),
  streamStats: (trainingType: TRAINING_TYPES | null) =>
    dispatch(MetricsCreators.streamStats(trainingType)),
  startTimer: () => dispatch(TimerCreators.startTimer()),
  pauseTimer: () => dispatch(TimerCreators.pauseTimer()),
  calculateMetricHistory: () => dispatch(MetricsCreators.calculateMetricHistory()),
  calculateSegmentsMetricHistory: () => dispatch(MetricsCreators.calculateSegmentsMetricHistory()),
  connectToBleDevice: (device: BluetoothDevice, deviceType: DeviceType) =>
    dispatch(DeviceCreators.createBluetoothSensor(device, deviceType)),
  removeMember: (removeMemberInfo: RemoveMemberInfoInterface) =>
    dispatch(CoachControlsCreators.removeMember(removeMemberInfo)),
  addNotification: (notification: NotificationInterface) =>
    dispatch(NotificationsCreators.addNotification(notification)),
  updateCriticalPower: (criticalPower: number) =>
    dispatch(SettingsCreators.updateCriticalPower(criticalPower)),
});
export const LiveSessionScreen = connect(mapStateToProps, mapDispatchToProps)(LiveSession);
