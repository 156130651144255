import React, { useEffect } from "react";
import MetricsCreators from "../../redux/reducers/metricsReducer";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { ICON } from "../../constants";
import { INTERVAL_TYPE } from "../../redux/types";
import { StateInterface } from "../../redux/reducers";
import { getTimeFromStepStart } from "../../redux/selectors";
import {
  agrScoreCalculating,
  kodScoreCalculating,
  sprScoreCalculating,
  tarScoreCalculating,
  tizScoreCalculating,
} from "../../utils/GameScoreCalculating";
import { Dispatch } from "redux";
import { GameAccuracy } from "./GameAccuracy";
import { StepAccuracy } from "./StepAccuracy";
import moment from "moment";

interface DefaultPropsInterface {
  colors: {
    primaryColor: string;
    blue: string;
    green: string;
    pink: string;
    red: string;
    grey: string;
  };
}

const AccuracyGaugeComponent = (props: CombinedProps) => {
  const showPreviousAccuracy = props.timeFromStepStart < 15;
  const isSegmentBasedStep = props.currentStep?.intervalType === INTERVAL_TYPE.SEGMENT_BASED;
  const currentMetrics = isSegmentBasedStep ? props.currentSegmentMetrics : props.currentMetrics;
  const currentStep = isSegmentBasedStep ? props.currentCourseStep : props.currentStep;

  useEffect(() => {
    if (props.currentStepIndex === null || props.currentStepIndex === 0) {
      const powerPercent = (currentMetrics.power_avg * 100) / props.cp20m;
      const scoreLine = `${powerPercent.toFixed()}% ${currentMetrics.cadence_avg.toFixed(1)}`;
      props.setLeaderboardScoreLine(scoreLine);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timeFromStepStart]);

  if (props.currentStepIndex === null || props.currentStepIndex === 0) {
    return <View />;
  }
  const getMetrics = (stepIndex: number) => {
    const metrics = props.advancedStepsMetrics[stepIndex];
    if (typeof metrics === "undefined") {
      return null;
    }
    return metrics;
  };

  const metrics = getMetrics(props.currentStepIndex - 1);
  if (metrics === null) {
    return <View />;
  }

  const getIsUpdateScoreLine = () => {
    if (metrics.intervalType === INTERVAL_TYPE.RECOVER) {
      return true;
    } else {
      if (currentStep?.intervalType === INTERVAL_TYPE.RECOVER) {
        return false;
      }
      return true;
    }
  };

  const isUpdateScoreLine = getIsUpdateScoreLine();

  switch (currentStep?.intervalType) {
    case INTERVAL_TYPE.TAR:
      const tarScore = tarScoreCalculating(currentMetrics);
      const tarScoreLine = tarScore.toFixed(3);

      return (
        <GameAccuracy
          color={props.colors.grey}
          icon={ICON.TARGET}
          score={tarScoreLine}
          isUpdateScoreLine={isUpdateScoreLine}
          setLeaderboardScoreLine={props.setLeaderboardScoreLine}
        />
      );

    case INTERVAL_TYPE.AGR:
      const agrScore = agrScoreCalculating(currentMetrics);
      const agrScoreLine = `${agrScore.avgPowerPercent.toFixed(1)}%`;

      return (
        <GameAccuracy
          color={props.colors.red}
          icon={ICON.FIRE}
          score={agrScoreLine}
          isUpdateScoreLine={isUpdateScoreLine}
          setLeaderboardScoreLine={props.setLeaderboardScoreLine}
        />
      );

    case INTERVAL_TYPE.SPR:
      const sprScore = sprScoreCalculating({
        currentMetrics: currentMetrics,
        userWeight: props.userWeight,
      });
      const sprScoreLine = `${sprScore.toFixed(2)} w/kg`;

      return (
        <GameAccuracy
          color={props.colors.green}
          icon={ICON.ROCKET}
          score={sprScoreLine}
          isUpdateScoreLine={isUpdateScoreLine}
          setLeaderboardScoreLine={props.setLeaderboardScoreLine}
        />
      );

    case INTERVAL_TYPE.TIZ:
      const tizScore = tizScoreCalculating(currentMetrics);
      const tizScoreLine = `${tizScore.tizAccuracy.toFixed()}% Δ${tizScore.deltaPower.toFixed(1)}`;

      return (
        <View style={style.positionHelper}>
          <StepAccuracy
            tbz={tizScore.tbzAccuracy}
            tiz={tizScore.tizAccuracy}
            toz={tizScore.tozAccuracy}
            diff={tizScore.deltaPower}
            scoreLine={tizScoreLine}
            isUpdateScoreLine={isUpdateScoreLine}
            setLeaderboardScoreLine={props.setLeaderboardScoreLine}
          />
        </View>
      );

    case INTERVAL_TYPE.KOD:
    case INTERVAL_TYPE.KOM:
      const kodScore = kodScoreCalculating(currentMetrics, isSegmentBasedStep);

      const kodScoreLine = isSegmentBasedStep
        ? `${moment(kodScore).format("mm:ss")}`
        : `${kodScore.toFixed(2)} Km`;

      const accuracyColor =
        currentStep.intervalType === INTERVAL_TYPE.KOD ? props.colors.blue : props.colors.pink;
      const accuracyIcon = currentStep.intervalType === INTERVAL_TYPE.KOD ? ICON.KOD : ICON.KOM;
      return (
        <GameAccuracy
          color={accuracyColor}
          icon={accuracyIcon}
          score={kodScoreLine}
          isUpdateScoreLine={isUpdateScoreLine}
          setLeaderboardScoreLine={props.setLeaderboardScoreLine}
        />
      );

    default:
      const powerPercent = (currentMetrics.power_avg * 100) / props.cp20m;
      const defaultScoreLine = `${powerPercent.toFixed()}% ${currentMetrics.cadence_avg.toFixed(
        1,
      )}`;
      if (showPreviousAccuracy && metrics.intervalType !== INTERVAL_TYPE.RECOVER) {
        return (
          <View style={style.positionHelper}>
            <StepAccuracy
              tbz={metrics.power_accuracy.tbz}
              tiz={metrics.power_accuracy.tiz}
              toz={metrics.power_accuracy.toz}
              diff={metrics.power_accuracy.diff}
              scoreLine={defaultScoreLine}
              isUpdateScoreLine={isUpdateScoreLine}
              setLeaderboardScoreLine={props.setLeaderboardScoreLine}
            />
          </View>
        );
      } else
        return (
          <View style={style.positionHelper}>
            <StepAccuracy
              tbz={metrics.power_accuracy.tbz}
              tiz={metrics.power_accuracy.tiz}
              toz={metrics.power_accuracy.toz}
              diff={metrics.power_accuracy.diff}
              scoreLine={defaultScoreLine}
              isUpdateScoreLine={isUpdateScoreLine}
              isVisible={false}
              setLeaderboardScoreLine={props.setLeaderboardScoreLine}
            />
          </View>
        );
  }
};

const style = StyleSheet.create({
  positionHelper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
  },
});

AccuracyGaugeComponent.defaultProps = {
  colors: {
    primaryColor: "#ffffff",
    blue: "#0080FF",
    pink: "#a25361",
    green: "#2F8F00",
    red: "#C01818",
    grey: "#696969",
  },
};

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  DefaultPropsInterface;

const mapStateToProps = (state: StateInterface) => ({
  currentStepIndex: state.activeTraining.currentSteps.currentStepIndex,
  currentCourseStepIndex: state.activeTraining.currentSteps.currentCourseStepIndex,
  advancedStepsMetrics: state.metrics.advancedStepsMetrics,
  timeFromStepStart: getTimeFromStepStart(state),
  currentStep: state.activeTraining.currentSteps.currentStep,
  currentCourseStep: state.activeTraining.currentSteps.currentCourseStep,
  currentMetrics: state.metrics.currentMetrics,
  userWeight: state.user.cpxProfile?.weight || 80,
  cp20m: state.settings.criticalPower,
  currentSegmentMetrics: state.metrics.currentSegmentMetrics,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLeaderboardScoreLine: (leaderboardScoreLine: string) =>
    dispatch(MetricsCreators.setLeaderboardScoreLine(leaderboardScoreLine)),
});

export const AccuracyGauge = connect(mapStateToProps, mapDispatchToProps)(AccuracyGaugeComponent);
