import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../../themes";
import { RFValue } from "../../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: RFValue(16),
    paddingVertical: RFValue(24),
    overflow: "hidden",
  },
  contentContainer: {
    flex: 1,
    flexDirection: "row",
  },
  scrollView: { flex: 1 },
  tabTitle: {
    fontSize: RFValue(21),
    lineHeight: RFValue(27),
    paddingBottom: RFValue(16),
    textAlign: "center",
  },
  descriptionContainer: { flex: 1, paddingLeft: RFValue(16) },
  ebookLinkContainer: {
    margin: RFValue(10),
    alignSelf: "center",
  },
  linkText: {
    fontWeight: "500",
    fontSize: RFValue(21),
    lineHeight: RFValue(27),
    width: "max-content",
    color: MAIN_THEME.WELCOME_SCREEN.ACTIVE_TEXT_COLOR,
    borderBottomWidth: RFValue(1),
    borderBottomColor: MAIN_THEME.WELCOME_SCREEN.ACTIVE_TEXT_COLOR,
  },
  workoutsContainer: {
    flex: 2,
  },
});
