import { StyleSheet } from "react-native";
import { MAIN_THEME } from "../../themes";
import { RFValue } from "../../utils/resizeHelper";

export const themedStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingVertical: RFValue(24),
    width: "100%",
    height: "100%",
    paddingHorizontal: RFValue(24),
  },

  title: {
    fontSize: RFValue(20),
    lineHeight: RFValue(30),
    marginBottom: RFValue(16),
  },
  trainWithCoach: {
    flex: 1,
    marginRight: RFValue(16),
  },
  activeSession: {
    flex: 1,
  },
  remark: {
    fontSize: RFValue(14),
    lineHeight: RFValue(24),
    fontStyle: "italic",
    color: MAIN_THEME.WELCOME_SCREEN.REMARK_TEXT_COLOR,
    marginBottom: RFValue(8),
  },
  scrollView: { flex: 1 },
});
