import React from "react";
import { TouchableOpacity, View } from "react-native";
import { themedStyles } from "./styles";
import { DefaultText } from "../../index";

type PropsTypes = {
  onModalYesPress: () => void;
  onModalNoPress: () => void;
};

export const StopTrainingModal = ({ onModalYesPress, onModalNoPress }: PropsTypes) => {
  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.modalContainer}>
        <DefaultText>Are you sure you want to Stop this workout?</DefaultText>
        <View style={themedStyles.buttonsContainer}>
          <TouchableOpacity
            onPress={onModalYesPress}
            style={[themedStyles.button, themedStyles.confirmButton]}
          >
            <DefaultText>Yes</DefaultText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onModalNoPress}
            style={[themedStyles.button, themedStyles.rejectingButton]}
          >
            <DefaultText>No</DefaultText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
